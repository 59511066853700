import { useQueryContractTypeMap } from 'queries/sam/revoluters'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { QuerySwitch } from 'components/QuerySwitch'
import { Flex, Icon, Text, TextSkeleton, Tooltip, useTooltip } from '@revolut/ui-kit'

type Props = {
  id: string | null | undefined
}

export const RevolutersContractType = ({ id }: Props) => {
  const tooltip = useTooltip()

  const { data, status: qs, fetchStatus: fs } = useQueryContractTypeMap()

  if (!id) {
    return <>{EMPTY_VALUE_FALLBACK}</>
  }

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data?.get(id)}
      renderError={() => <Text>{id}</Text>}
      renderIdle={() => <Text>{id}</Text>}
      renderLoading={() => <TextSkeleton size={15} />}
      renderSuccess={({ data: contractType }) => (
        <>
          <Flex alignItems="center" gap="s-4">
            <Text ml="s-8">{contractType.name}</Text>
            <Icon
              name="16/ExclamationMarkOutline"
              size={16}
              color="grey-tone-50"
              {...tooltip.getAnchorProps()}
            />
          </Flex>
          <Tooltip {...tooltip.getTargetProps()}>{contractType.description}</Tooltip>
        </>
      )}
    />
  )
}
