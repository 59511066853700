import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { sortBy } from 'lodash'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { abbreviate } from '@revolut/ui-kit'

export const getItems = (
  userIds: string[],
  userMap: Map<string, UserListItem>,
  avatars?: Map<string, RevolutersAvatar>,
) => {
  return sortBy(
    userIds.map((userId) => {
      const user = userMap.get(userId)
      const title = user ? getName(user) : userId
      const avatar = getAvatarUrl({ user, avatars, type: 'imageMedium' })
      return {
        id: userId,
        title,
        description: user?.email,
        avatar: getAvatarProps({ label: abbreviate(title), id: userId, avatar }),
      }
    }),
    'title',
  )
}
