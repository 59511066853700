import {
  Action,
  Box,
  Subheader,
  Text,
  Token,
  TransitionCollapse,
  useToggle,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { Comment, Log, XCheckDetails } from 'api/types/xChecks'
import { formatDateTimePrecise } from 'utils/date'
import { PrincipalComponentType } from 'view/XChecks/XCheck/lib/types'
import { MarkdownRenderer } from 'view/XChecks/XCheck/lib/components/common/MarkdownRenderer'
import { DetailsGroup } from 'components/DetailsGroup'
import { useXCheckSide } from 'view/XChecks/XCheck/lib/hooks'
import { getGroupRuleSide } from 'view/XChecks/XCheck/lib/components/Side/utils'
import { LogItemHeader } from './components/LogItemHeader'
import { getLogDetails } from './utils'

type Props = {
  log: Log
  Principal: PrincipalComponentType
  commentMap: Map<string, Comment>
  xCheck?: XCheckDetails
}

export const LogItem = ({ log, Principal, xCheck, commentMap }: Props) => {
  return (
    <Box>
      <LogItemHeader log={log} Principal={Principal} />
      <LogItemContent
        log={log}
        PrincipalComponent={Principal}
        xCheck={xCheck}
        commentMap={commentMap}
      />
      <LogFooter log={log} />
    </Box>
  )
}

export const LogFooter = ({ log }: { log: Log }) => {
  return (
    <Box>
      <Text variant="tiny" color={Token.color.greyTone50}>
        {formatDateTimePrecise(log.createdDate)}
      </Text>
    </Box>
  )
}

export const LogItemContent = ({
  log,
  PrincipalComponent,
  xCheck,
  commentMap,
}: {
  log: Log
  PrincipalComponent: PrincipalComponentType
  xCheck?: XCheckDetails
  commentMap: Map<string, Comment>
}) => {
  const { setSide } = useXCheckSide()
  const { infoRows, textWidgets } = getLogDetails({
    log,
    commentMap,
    PrincipalComponent,
    xCheck,
    onGroupClick: (group) =>
      setSide(getGroupRuleSide({ groupRule: group, setSideView: setSide })),
  })
  const [checked, toggleChecked] = useToggle()
  if (!infoRows && !textWidgets?.length) {
    return null
  }

  return (
    <>
      <Action onClick={() => toggleChecked()}>
        {checked ? 'Show less' : ' Show more'}
      </Action>
      <TransitionCollapse in={checked}>
        {textWidgets?.length ? (
          <VStack gap="s-4">
            {textWidgets.map(({ title, value }, idx) => (
              <Box key={idx}>
                <Box mt="-s-8" mb="-s-6">
                  <Subheader variant="nested">
                    <Subheader.Title>{title}</Subheader.Title>
                  </Subheader>
                </Box>
                <Widget px="s-12" py="s-8" mb="s-8" borderRadius="r8">
                  <MarkdownRenderer>{value}</MarkdownRenderer>
                </Widget>
              </Box>
            ))}
          </VStack>
        ) : null}

        {!!infoRows && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Info</Subheader.Title>
            </Subheader>
            <Widget px="s-2" py="s-2" mb="s-8">
              <DetailsGroup rows={infoRows} />
            </Widget>
          </>
        )}
      </TransitionCollapse>
    </>
  )
}
