import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { RevolutersContractType, RevolutersEmployeeType } from 'api/sam/revoluters'
import { SAM_PERMISSIONS } from 'security'
import { Caption, Text } from '@revolut/ui-kit'
import { BaseSelect, BaseSelectWrapperProps } from '../BaseSelect'
import { contractTypeToOption } from './utils'

export const ContractTypeSelect = (
  props: BaseSelectWrapperProps<RevolutersContractType> & {
    employeeType: RevolutersEmployeeType
  },
) => {
  const {
    data: revoluters,
    status: qs,
    fetchStatus: fs,
  } = useQueryRevolutersDictionaries()
  const queryResult = mergeQueryStatuses({ qs, fs })
  const items = useMemo(
    () => revoluters?.contractTypes[props.employeeType] || [],
    [props.employeeType, revoluters?.contractTypes],
  )

  return (
    <BaseSelect
      items={items}
      queryResult={queryResult}
      itemToOption={contractTypeToOption}
      requiredPermission={SAM_PERMISSIONS.REVOLUTERS_STRUCTURE_VIEW_LIST}
      renderOption={(option) => (
        <>
          <Text use="p">{option.label}</Text>
          <Caption color="grey-tone-50">{option.value.description}</Caption>
        </>
      )}
      {...props}
    />
  )
}
