import { EntityComponentType, EntityContext } from 'view/XChecks/XCheck/lib/types'
import { Link, Tag, Text } from '@revolut/ui-kit'
import { getContextTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { match } from 'ts-pattern'
import { TEXT_ADDED_STYLE, TEXT_REMOVED_STYLE } from 'view/XChecks/XCheck/lib/consts'
import { getEntityUrl } from '../utils'

export const getContextTagProps = (context: EntityContext) =>
  match(context)
    .with(EntityContext.arrayAdded, () => TEXT_ADDED_STYLE)
    .with(EntityContext.arrayRemoved, () => TEXT_REMOVED_STYLE)
    .otherwise(() => undefined)

export const EntityTag: EntityComponentType = ({ descriptor: entity, context }) => {
  const { name, id } = entity
  const href = getEntityUrl(entity)
  const contextTextProps = getContextTextStyle(context)
  const contextTagProps = getContextTagProps(context)
  const tagHrefProps = href ? { use: Link, target: '_blank', href } : {}

  return (
    <Tag {...tagHrefProps} {...contextTagProps} variant="faded">
      <Text {...contextTextProps}>{href ? name : `${name} (${id})`}</Text>
    </Tag>
  )
}
