import { UuidParam } from 'api/types/xChecks'
import { CopyableText } from 'components/CopyableText'
import { Text } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { getVariantTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { ItemValueProps } from './types'

export const UuidValue = ({ value, variant }: ItemValueProps<UuidParam>) => {
  const uuidValue = value?.value
  const textStyle = getVariantTextStyle(variant)

  return uuidValue ? (
    <CopyableText value={uuidValue} textProps={textStyle}>
      {uuidValue}
    </CopyableText>
  ) : (
    <Text {...textStyle}>{EMPTY_VALUE_FALLBACK_TEXT}</Text>
  )
}
