import { MutableRefObject, useCallback, useState } from 'react'
import { BottomSheet, Button, Input } from '@revolut/ui-kit'
import { useEditPopup } from 'hooks/useEditPopup'
import { useClampedIntChange } from 'hooks/useClampedIntChange'
import { Row } from '../types'
import { getDuration, setDuration } from '../../../utils'

type UseDurationPopupParams = {
  row: Row
  durationsRef: MutableRefObject<Record<string, number>>
}
export const useDurationPopup = ({ row, durationsRef }: UseDurationPopupParams) => {
  const { setPopup, closePopup } = useEditPopup()

  return useCallback(() => {
    setPopup({
      title: 'Update duration',
      isBottomSheet: true,
      headerVariant: 'bottom-sheet',
      content: (
        <DurationPopup durationsRef={durationsRef} row={row} closePopup={closePopup} />
      ),
    })
  }, [setPopup, closePopup, durationsRef, row])
}

type Props = {
  durationsRef: MutableRefObject<Record<string, number>>
  row: Row
  closePopup: () => void
}
export const DurationPopup = (props: Props) => {
  const { durationsRef, row, closePopup } = props

  const [value, onValueChange] = useState(getDuration(row, durationsRef))
  const submit = useCallback(() => {
    setDuration(row.id, value, durationsRef)
    closePopup()
  }, [closePopup, value, row, durationsRef])

  const onChange = useClampedIntChange(onValueChange, 1, row.maximalDuration)

  return (
    <>
      <Input
        type="number"
        value={value}
        label={`Duration up to ${row.maximalDuration}`}
        onChange={onChange}
      />

      <BottomSheet.Actions horizontal>
        <Button elevated onClick={closePopup} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={submit} variant="primary">
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
