import { SamPolicy } from 'api/sam/policies'
import { Resource } from 'api/sam/types/resources'
import { uniq } from 'lodash'
import { normalizeResources } from 'view/Sam/utils'

export const getResourceUpdateParams = (
  policyIds: string[],
  policyMap: Map<string, SamPolicy>,
  newResourceIds: string[],
  resources: Resource[],
) => {
  return policyIds.reduce<{ policyId: string; resourceIds: string[] }[]>(
    (acc, policyId) => {
      const policy = policyMap.get(policyId)
      if (!policy) {
        return acc
      }
      const resourceIds = normalizeResources(
        uniq(policy.resourceIds.concat(newResourceIds)),
        resources,
      )

      return [
        ...acc,
        {
          policyId,
          resourceIds,
        },
      ]
    },
    [],
  )
}
