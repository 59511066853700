import { Box, Subheader, VStack } from '@revolut/ui-kit'
import { EntityParam } from 'api/types/xChecks'
import { ParamInput } from '../../../../ParamInput'

export const EntityAttributes = ({
  param,
  prefix,
  index,
}: {
  param: EntityParam
  prefix: string
  index: number
}) => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{param.name}</Subheader.Title>
      </Subheader>
      <VStack gap="s-12" pl="s-12">
        {param.value?.value?.map((item, idx) => (
          <ParamInput
            param={item}
            index={idx}
            key={idx}
            prefix={`${prefix}.${index}.value.value`}
          />
        ))}
      </VStack>
    </Box>
  )
}
