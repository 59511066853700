import { AuditLogEntityParam } from 'api/idave/auditsLog'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { IconName } from '@revolut/ui-kit'
import { DataMaps } from './types'

export const stringifyEntity = ({
  dataMaps,
  value,
  shortVersion = true,
}: {
  value: AuditLogEntityParam['value']
  dataMaps: DataMaps
  shortVersion?: boolean
}) => {
  switch (value.entity) {
    case 'Client':
      const client = dataMaps.clientsMap?.get(value.id)
      return client?.name || value.id
    case 'Permission':
    case 'OrphanedPermission':
      const permission = dataMaps.permissionsMap?.get(value.id)
      return permission?.name || value.id
    case 'Role':
      const role = dataMaps.rolesMap?.get(value.id)
      return role?.name || value.id
    case 'User':
      const user = dataMaps.userMap?.get(value.id)
      return user ? getName(user) : value.id
    case 'Organisation':
      const org = dataMaps.organisationMap?.get(value.id)
      return org?.name || value.id
    case 'Group':
      const group = dataMaps.groupMap?.get(value.id)
      return group ? group.name : value.id
    case 'ToxicPermissionCombination':
      const toxicPair = dataMaps.toxicPairsMap?.get(value.id)
      return toxicPair ? toxicPair.name : value.id

    case 'MigrationUnitExecution':
    case 'VerifyToken':
    case 'RefreshToken':
    case 'Device':
    case 'AuthCode':
    case 'UserAccessLogEntry':
    case 'ToxicPermissionAlert':
    default:
      return shortVersion ? EMPTY_VALUE_FALLBACK : value.id
  }
}

export const getActionIcon = (action: string): IconName => {
  switch (action) {
    case 'Client Create':
    case 'Client Delete':
    case 'Client Update':
      return 'Apps'

    case 'Organisation Create':
    case 'Organisation Update':
    case 'Organisation Delete':
      return 'Bank'

    case 'Orphaned Permission Create':
    case 'Orphaned Permission Delete':
    case 'Permission Delete':
    case 'Permission Matrix Sync':
    case 'Permission Snapshot Update':
    case 'Permission Update':
    case 'Toxic Permission Alert Calculate':
    case 'Toxic Permission Combination Create':
      return 'Controls'

    case 'Role Assign':
    case 'Role Create':
    case 'Role Create Old':
    case 'Role Delete':
    case 'Roles Clear':
    case 'Role Unassign':
    case 'Role Update':
    case 'Role Update Old':
      return 'GroupVault'

    case 'User AccessLog Create':
    case 'User Change Password':
    case 'User Create':
    case 'User Delete':
    case 'User RoleSwap':
    case 'User Terminate':
    case 'User Unterminate':
    case 'User Update':
      return 'Profile'

    case 'Auth Code Issue':
    case 'Auth Code Validate':
      return 'ChangePasscode'

    case 'Verify Token Issue':
    case 'Verify Token Validate':
    case 'Refresh Token Clean Up':
    case 'Refresh Token Issue':
    case 'Refresh Token Revoke':
    case 'Refresh Token Verify':
    case 'Device Create':
      return 'LockClosed'

    default:
      return 'Form'
  }
}
