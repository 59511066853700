import { BottomSheet, Button, Header, Input } from '@revolut/ui-kit'
import { Param, Value } from 'api/types/xChecks'
import { useCallback, useState } from 'react'
import { match } from 'ts-pattern'
import { notNullable } from 'utils/common'
import { valueTypeMatcher } from 'view/XChecks/XCheck/lib/utils'
import {
  TextItem,
  BoolItem,
  EntityItem,
  NumberItem,
  TimestampItem,
  UuidItem,
} from './components'
import { InputProps } from './types'

type Props = {
  name: string
  defaultValue: Value<Param>
  submit: (newValue: Value<Param>) => void
  onClose: () => void
  disabled: boolean
}
export const ItemPopup = ({ onClose, name, defaultValue, submit, disabled }: Props) => {
  const [value, setValue] = useState(defaultValue)
  const isCreating = !notNullable(defaultValue.value)
  const [isValid, setIsValid] = useState(true)
  const isDisabled = disabled || (isCreating && !notNullable(value.value)) || !isValid

  const onSubmit = useCallback(() => {
    if (!isDisabled) {
      submit(value)
      onClose()
    }
  }, [onClose, submit, value, isDisabled])

  return (
    <BottomSheet open onClose={onClose}>
      <Header>
        <Header.Title>{isCreating ? 'Add new item' : 'Edit item'}</Header.Title>
        <Header.Description>{name}</Header.Description>
      </Header>

      <ItemInput
        value={value}
        onChange={setValue}
        setIsValid={setIsValid}
        isValid={isValid}
      />

      <BottomSheet.Actions>
        <Button elevated onClick={onSubmit} disabled={isDisabled}>
          Save
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

const ItemInput = (props: InputProps) => {
  return match(props)
    .with(valueTypeMatcher('text'), TextItem)
    .with(valueTypeMatcher('number'), NumberItem)
    .with(valueTypeMatcher('timestamp'), TimestampItem)
    .with(valueTypeMatcher('uuid'), UuidItem)
    .with(valueTypeMatcher('bool'), BoolItem)
    .with(valueTypeMatcher('entity'), EntityItem)
    .otherwise(() => <Input label="Unsupported type" disabled />)
}
