import { createRoot } from 'react-dom/client'
import { useEffect } from 'react'
import { dedupeIntegration, extraErrorDataIntegration } from '@sentry/integrations'
import {
  reactRouterV6BrowserTracingIntegration,
  init as initSentry,
  setUser,
} from '@sentry/react'
import {
  RouterProvider,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import 'normalize.css'
import { isProduction } from 'utils/common'
import { getRouter } from 'helpers/getRouter'
import { auth, isOnSignInPage, storeUser } from 'services/auth'
import { permissions } from 'services/permissions'
import { PermissionValues } from '@revolut-internal/idave-web-auth'
import { IDAVE_PERMISSIONS } from 'security'
import { configAxios } from './helpers/configAxios'

const init = async () => {
  await configAxios()

  const router = getRouter()
  const element = document.getElementById('mount')!
  const root = createRoot(element)
  root.render(<RouterProvider router={router} />)
}

if (process.env.NODE_ENV === 'production') {
  initSentry({
    dsn: 'https://b3e9fa55547e4b00b17d6b9c8f95410f@o4508601944047696.ingest.de.sentry.io/4508602000670811',
    environment: isProduction() ? 'production' : 'development',
    release: process.env.REACT_APP_SENTRY_RELEASE_TAG,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      dedupeIntegration(),
      extraErrorDataIntegration({ depth: 6 }),
    ],
    tracesSampleRate: 0.2,
  })
}

if (isOnSignInPage()) {
  init()
} else {
  auth.init().then((user) => {
    if (user) {
      storeUser(user)
      setUser({
        email: user.email,
        id: user.id,
        username: user.username,
      })
      permissions
        .init({
          idave: user.permissions as PermissionValues<typeof IDAVE_PERMISSIONS>,
        })
        .then(init)
    }
  })
}
