import { useCallback, useEffect } from 'react'
import { Param, Value } from 'api/types/xChecks'
import { ArrayParamValue } from './types'
import { getStandalonePath } from '../utils'
import { useFormContext } from '../../../../FormProvider'

type SetValueFn = React.Dispatch<React.SetStateAction<ArrayParamValue>>

export const useAdd = (setValue: SetValueFn) =>
  useCallback(
    (item: Value<Param>) => {
      setValue(
        (currentValue) =>
          // matching types of item & currentValue guaranteed by surrounding code
          // form of ItemPopup guarantee e.g TextParam will be replaced with TextParam
          (currentValue ? [...currentValue, item] : [item]) as ArrayParamValue,
      )
    },
    [setValue],
  )

export const useReplace = (setValue: SetValueFn) =>
  useCallback(
    (index: number, item: Value<Param>) =>
      setValue(
        (currentValue) =>
          // matching types of item & currentValue guaranteed by surrounding code
          // form of ItemPopup guarantee e.g TextParam will be replaced with TextParam
          (currentValue
            ? currentValue.map((currentItem, idx) => (idx === index ? item : currentItem))
            : [item]) as ArrayParamValue,
      ),
    [setValue],
  )

export const useDelete = (setValue: SetValueFn) =>
  useCallback(
    (index: number) => {
      setValue(
        (currentValue = []) =>
          // ts couldn't shrink type here and identify that filtered array has same type
          currentValue.filter((_, idx) => idx !== index) as ArrayParamValue,
      )
    },
    [setValue],
  )

type SubscriptionParams = { value: ArrayParamValue; index: number; prefix: string }
export const useArrayFormSubscription = ({
  index,
  prefix,
  value,
}: SubscriptionParams) => {
  const path = getStandalonePath(prefix, index)
  const { updateForm } = useFormContext()
  useEffect(() => updateForm(path, value), [value, updateForm, path])
}
