import { Box, Search, Widget } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useCurrentUserId } from 'queries/idave/users'
import { useQueryUsers } from 'queries/idave/users'
import { memo, useCallback, useMemo, useState } from 'react'
import { IDAVE_PERMISSIONS } from 'security'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { UserListHeader } from './components/UserListHeader'
import { UserListSkeleton } from './components/UserListSkeleton'
import { UserList as UserListComponent } from './components/UserList'
import { UserListVariant } from './types'
import { getFilteredUsers, getUserEntities } from './utils'

type UserListProps = {
  variant: UserListVariant
  exclude?: string[]
  excludeInactive?: boolean
  usePeopleOpsIds?: boolean
  bubbleUp?: string[]
  scrollRef?: React.RefObject<HTMLDivElement>
  onChange: (selected: string[]) => void
  defaultValue?: string[]
  hoistYourself?: boolean
}

export const UserList = memo(
  ({
    onChange,
    variant,
    exclude,
    scrollRef,
    defaultValue = [],
    bubbleUp,
    excludeInactive,
    usePeopleOpsIds,
    hoistYourself = true,
  }: UserListProps) => {
    const { data: users = [], status: qs, fetchStatus: fs } = useQueryUsers()
    const { data: currentUserId } = useCurrentUserId()
    const { data: avatars } = useQueryMappedEmployeeIdAvatars()

    const [value, setValue] = useState<string[]>(defaultValue)
    const selectedHash = useMemo(() => toPresenceMap(value), [value])
    const excludeHash = useMemo(() => toPresenceMap(exclude || []), [exclude])

    const [showSelected, setShowSelected] = useState(false)

    const update = useCallback(
      (newIds: string[]) => {
        onChange(newIds)
        setValue(newIds)
        if (!newIds.length && showSelected) {
          setShowSelected(false)
        }
      },
      [onChange, showSelected],
    )

    const entities = useMemo(
      () =>
        getUserEntities({
          users,
          bubbleUp,
          excludeInactive,
          excludeHash,
          usePeopleOpsIds,
          currentUserId,
          hoistYourself,
        }),
      [
        users,
        bubbleUp,
        excludeInactive,
        excludeHash,
        usePeopleOpsIds,
        currentUserId,
        hoistYourself,
      ],
    )
    const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })

    const items = useMemo(
      () =>
        getFilteredUsers({
          users: searched,
          selectedHash,
          showSelected,
          usePeopleOpsIds,
        }),
      [searched, selectedHash, showSelected, usePeopleOpsIds],
    )

    const onShowSelectedClick = useCallback(() => {
      setShowSelected((v) => !v)
      setSearchValue('')
    }, [setShowSelected, setSearchValue])

    const onSearchUpdate = useCallback(
      (search: string) => {
        setShowSelected(false)
        setSearchValue(search)
      },
      [setSearchValue, setShowSelected],
    )

    return (
      <>
        <UserListHeader
          variant={variant}
          count={value.length}
          size={users.length}
          onShowSelectedClick={onShowSelectedClick}
          showSelected={showSelected}
        />
        <Widget>
          <Box p="s-16">
            <Search
              value={searchValue}
              placeholder="Search"
              onChange={onSearchUpdate}
              autoFocus
            />
          </Box>
          <QuerySwitch
            required={[{ qs, fs }]}
            data={searched}
            renderIdle={() => (
              <NoAccessWidget
                title={`Looks like you don’t have sufficient permissions to view this (${IDAVE_PERMISSIONS.USERS_VIEW_LIST.value})`}
              />
            )}
            renderLoading={UserListSkeleton}
            renderSuccess={() => (
              <UserListComponent
                update={update}
                items={items}
                selectedHash={selectedHash}
                variant={variant}
                avatars={avatars}
                scrollRef={scrollRef}
                usePeopleOpsIds={usePeopleOpsIds}
              />
            )}
          />
        </Widget>
      </>
    )
  },
)
