import { Box, Subheader } from '@revolut/ui-kit'
import { OrganisationData } from 'api/idave/organisations'
import { DetailsGroup } from 'components/DetailsGroup'

export const OrganisationInfo = (props: { organisation: OrganisationData }) => {
  const { organisation } = props

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          ['Name', organisation.name],
          ['Code', organisation.code],
        ]}
      />
    </Box>
  )
}
