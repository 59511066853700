import { createContext, useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Param } from 'api/types/xChecks'
import { useXCheckData } from './useXCheckData'
import { useApi } from './useAPI'
import { useXCheckSecurity } from './useXCheckSecurity'
import { getDataValue, useQueryData } from '../utils'
import { Data } from '../types'

export const PARAMS_QUERY_KEY = 'PARAMS_QUERY_KEY'
const REQUEST_PARAMS_PERMISSION = 'cross_checks.view.params.list'

export type ParamsData = Data<Param[]>
export const ParamsContext = createContext<ParamsData>({ status: 'loading' })

export const useParams = () => useContext(ParamsContext)

export const ParamsProvider = ({ children }: { children: React.ReactNode }) => {
  const { fetchParams, sourceServiceMap } = useApi()
  const { hasPermission } = useXCheckSecurity()
  const { xCheckData, xCheckId } = useXCheckData()

  const source = getDataValue(xCheckData)?.source
  const enabled =
    source &&
    hasPermission({
      service: sourceServiceMap[source],
      value: REQUEST_PARAMS_PERMISSION,
    })

  const paramsQuery = useQuery({
    queryKey: [PARAMS_QUERY_KEY, xCheckId, enabled, source],
    queryFn: () => (source ? fetchParams({ xCheckId, source }) : Promise.reject()),
    enabled,
  })
  const paramsData = useQueryData(paramsQuery)
  const contextValue = useMemo(
    () => (xCheckData.status === 'success' ? paramsData : { status: 'loading' as const }),
    [paramsData, xCheckData],
  )

  return <ParamsContext.Provider value={contextValue}>{children}</ParamsContext.Provider>
}
