import { ArrayChange } from 'api/types/xChecks'

const MAX_CHANGE_AMOUNT = 1000

export const isChangePayloadBig = (arrayChange: ArrayChange) => {
  const oldValueLength = arrayChange.oldValue.value?.length || 0
  const newValueLength = arrayChange.newValue.value?.length || 0

  return oldValueLength >= MAX_CHANGE_AMOUNT || newValueLength >= MAX_CHANGE_AMOUNT
}
