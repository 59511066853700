import { EntityComponentType } from 'view/XChecks/XCheck/lib/types'
import { ChangeViewType } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { sortBy } from 'lodash'
import { useToggle } from '@revolut/ui-kit'
import { getArrayColumns } from './getArrayColumns'
import { getEntities } from './getEntities'
import { TableItem } from '../../types'

export const EXPANDABLE_AMOUNT = 30
const EXPAND_MINIMAL_DIFF = 10

export const useTableInfo = (
  table: TableItem,
  Entity: EntityComponentType,
  view?: ChangeViewType,
) => {
  const entities = sortBy(getEntities(table, view), 'variant')
  // using min diff approach to not show expand
  // if amount a little bit bigger than border value
  // e.g amount is 11 for 10 max amount (1 item collapsed)
  const isExpandable = entities.length >= EXPANDABLE_AMOUNT + EXPAND_MINIMAL_DIFF
  const [expanded, toggleExpanded] = useToggle({ defaultState: false })

  const columns = getArrayColumns({
    entities,
    Entity,
    isExpandable,
    expanded,
    toggleExpanded,
  })

  return {
    entities: isExpandable && !expanded ? entities.slice(0, EXPANDABLE_AMOUNT) : entities,
    columns,
  }
}
