import {
  Page,
  TextSkeleton,
  PageHeaderProps as UIKitPageHeaderProps,
} from '@revolut/ui-kit'
import { notNullable } from 'utils/common'
import { Title } from './Title'

type PageHeaderProps = Omit<UIKitPageHeaderProps, 'children'> & {
  pageTitle?: string
  postfix?: string
}

export const PageHeader = (props: PageHeaderProps) => {
  const title = [props.pageTitle, props.postfix].filter(notNullable).join(' | ')

  return (
    <>
      <Title value={title} />
      <Page.Header {...props}>
        {props.pageTitle || <TextSkeleton size={15} />}
      </Page.Header>
    </>
  )
}
