import { Text } from '@revolut/ui-kit'
import { RequestErrorWidget } from 'components/RequestErrorWidget'

export const getSubmitLabel = (selectedAmount: number) =>
  !selectedAmount ? 'Request' : `Request ${selectedAmount} selected`

export const makeErrorWidget =
  ({ refetch }: { refetch: () => void }) =>
  () => {
    return (
      <RequestErrorWidget
        description={
          <Text>
            An error occurred during loading list of available resources.
            <br />
            Try to pull list of available resources again or get back and update
            justification/jira ticket to get available resources
          </Text>
        }
        action={refetch}
        actionText="Pull list of resources"
      />
    )
  }
