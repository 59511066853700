import { AvatarBar, Table } from '@revolut/ui-kit'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'
import { useMemo } from 'react'
import { RegionTableCell } from './RegionTableCell'

type Item<T extends {} = {}> = T & { regions?: string[] }
type Props = { row: { original: Item } }

export const useRegionsColumn = (items: Item[]) => {
  const hasColumn = useMemo(() => items.some((item) => item.regions?.length), [items])
  return useMemo(
    () =>
      hasColumn
        ? {
            Header: 'Regions',
            disableSortBy: true,
            Cell: RegionsTableCell,
          }
        : undefined,
    [hasColumn],
  )
}

export const RegionsTableCell = ({
  row: {
    original: { regions },
  },
}: Props) => {
  if (!regions?.length) {
    return <Table.EmptyCell />
  }

  if (regions.length === 1) {
    const region = regions[0]
    return <RegionTableCell row={{ original: { region, id: region } }} />
  }

  return (
    <Table.Cell>
      <AvatarBar maxCount={4}>
        {regions.map((region, idx) => {
          const regionImage = getRegionFlagImageUrl(region)
          return (
            <AvatarBar.Item
              key={`${region}_${idx}`}
              size={24}
              uuid={region}
              label={region}
              title={region}
              image={regionImage}
            />
          )
        })}
      </AvatarBar>
    </Table.Cell>
  )
}
