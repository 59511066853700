import { BottomSheet, Button, Header, InputGroup, Popup, Token } from '@revolut/ui-kit'
import { FieldInput } from 'components/fields/FieldInput'
import { FieldTextArea } from 'components/fields/FieldTextArea'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

type Props = {
  onClose: () => void
  onContinue: () => void
}
const MINIMAL_LENGTH = 10
const JUSTIFICATION_RULES = {
  validate: (value: string) => !!value && value.length >= MINIMAL_LENGTH,
}

export const Reason = ({ onClose, onContinue }: Props) => {
  const { formState, trigger } = useFormContext()
  // triggers check for justification field on initial render
  useEffect(() => {
    trigger('justification')
  }, [trigger])

  return (
    <BottomSheet onClose={onClose} open>
      <Header variant="main">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Justify resource access</Header.Title>
      </Header>

      <InputGroup>
        <FieldTextArea
          name="justification"
          label="Business justification"
          autoFocus
          rows={5}
          rules={JUSTIFICATION_RULES}
        />

        <FieldInput
          name="jiraTicket"
          label="JIRA ticket reference"
          style={{ fontSize: Token.fontSize.body2 }}
          description="Enter jira ticket if access is linked to a specific task"
        />
      </InputGroup>
      <Popup.Actions>
        <Button elevated onClick={onContinue} disabled={!formState.isValid}>
          Continue
        </Button>
      </Popup.Actions>
    </BottomSheet>
  )
}
