import { UserLink } from 'components/Links/UserLink'
import { EntityComponentType } from 'view/XChecks/XCheck/lib/types'
import { getContextTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { DefaultEntity } from './DefaultEntity'

export const UserEntity: EntityComponentType = (props) => {
  const { id } = props.descriptor

  return id ? (
    <UserLink id={id} avatarSize={20} textProps={getContextTextStyle(props.context)} />
  ) : (
    <DefaultEntity {...props} />
  )
}
