import { Input, VStack } from '@revolut/ui-kit'

export const DetailFieldsSkeleton = () => {
  return (
    <VStack gap="s-24">
      <Input label="Client" pending disabled />
      <Input name="gcpSA" label="GCP Service agent (Optional)" pending disabled />
    </VStack>
  )
}
