import { VStack } from '@revolut/ui-kit'
import { FieldInput } from 'components/fields/FieldInput'
import { FieldSelectUser } from 'components/fields/FieldSelectUser'
import { RULE_REQUIRED } from 'utils/form/rules'

export const Details = () => {
  return (
    <VStack space="s-24">
      <FieldInput name="name" label="Name" rules={RULE_REQUIRED} maxLength={255} />
      <FieldInput name="description" label="Description (Optional)" />
      <FieldSelectUser name="ownerId" label="Owner" onlyActive rules={RULE_REQUIRED} />
    </VStack>
  )
}
