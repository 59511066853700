import { EntityParam } from 'api/types/xChecks'
import { EntityContext } from '../../../common/EntityContext'

type Props = {
  entityContexts: EntityParam[]
}

export const EntitiesParams = ({ entityContexts }: Props) => (
  <>
    {entityContexts.map(({ value }, idx) => (
      <EntityContext key={idx} entity={value} defaultExpanded={idx === 0} />
    ))}
  </>
)
