import { ComponentProps } from 'react'

import Markdown from 'markdown-to-jsx'

import { Link, Text } from '@revolut/ui-kit'
import { mentionsToMarkdownLinks } from './utils'

export const MarkdownRenderer = ({
  children,
  ...rest
}: ComponentProps<typeof Markdown>) => {
  return (
    <Markdown
      {...rest}
      options={{
        ...rest?.options,

        overrides: {
          code: (props) => <Text {...props} use="code" fontFamily="monospace" />,
          pre: (props) => <Text {...props} use="pre" />,
          p: (props) => <Text {...props} whiteSpace="pre-line" use="p" />,
          a: (props) => <Link {...props} target="_blank" />,
          blockquote: (props) => (
            <blockquote
              {...props}
              style={{
                borderInlineStart: '0.4em solid black',
                marginInlineStart: '0',
                paddingInlineStart: '1em',
              }}
            />
          ),
          ...rest?.options?.overrides,
        },
      }}
    >
      {mentionsToMarkdownLinks(children)}
    </Markdown>
  )
}
