import { Page, PageMainSize, VStack } from '@revolut/ui-kit'

import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import { Section } from './components/Section'
import { SECTIONS } from './constants'

export const System = () => {
  const HeaderActions = useHeaderActions()
  return (
    <>
      <PageHeader actions={HeaderActions && <HeaderActions />} pageTitle="System" />
      <Page.Main size={PageMainSize.NARROW}>
        <VStack gap="s-24">
          {SECTIONS.map((section) => (
            <Section key={section.title} {...section} />
          ))}
        </VStack>
      </Page.Main>
    </>
  )
}
