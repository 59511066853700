import { Action, Subheader, chain } from '@revolut/ui-kit'

type UserListHeaderProps = {
  count: number
  size: number
  onShowSelectedClick: () => void
  showSelected: boolean
}
export const UserListHeader = ({
  count,
  size,
  showSelected,
  onShowSelectedClick,
}: UserListHeaderProps) => {
  const countText = count ? `${count} of ${size}` : size

  return (
    <Subheader variant="nested">
      <Subheader.Title>{chain('Users', countText)}</Subheader.Title>
      <Subheader.Side>
        <Action onClick={onShowSelectedClick} disabled={!count}>
          {showSelected ? 'Show all' : 'Show selected'}
        </Action>
      </Subheader.Side>
    </Subheader>
  )
}
