import { EntityParam } from 'api/types/xChecks'
import { EntityData, FetchEntities } from 'view/XChecks/XCheck/lib/hooks'
import { SelectInput } from '@revolut/ui-kit'
import { useCallback, useMemo, useState } from 'react'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { InputProps } from '../../../types'
import { useEntityFetcher } from '../../../../../../hooks'
import { EntityIdItem } from './EntityIdItem'
import {
  dataToParam,
  getDefaultValue,
  getOptions,
  makeEntityDataOptions,
  makeRenderPrefix,
} from '../../../../../../utils'
import { MIN_SEARCH_AMOUNT } from '../../../../../../../Fields/consts'

export const EntitySelectItem = (
  props: InputProps<EntityParam> & { fetcher: FetchEntities },
) => {
  const { value, fetcher } = props
  const result = useEntityFetcher(fetcher, value.type.entityType)

  if (result.status === 'error') {
    return <EntityIdItem {...props} />
  }

  if (result.status === 'loading') {
    return <SelectInput pending options={[]} disabled label={value.type.entityType} />
  }

  return <Inner data={result.data} {...props} />
}

type InnerProps = {
  data: EntityData[]
} & InputProps<EntityParam>
const Inner = ({ data, value, onChange: originalOnChange }: InnerProps) => {
  const options = getOptions(data)
  const defaultValue = getDefaultValue(options, value)

  const [localValue, setLocalValue] = useState<Nullable<EntityData>>(defaultValue)

  const renderOption = useMemo(() => makeEntityDataOptions(data), [data])
  const renderPrefix = useMemo(
    () => makeRenderPrefix(data, localValue),
    [data, localValue],
  )

  const onChange = useCallback(
    (option: Nullable<EntityData>) => {
      setLocalValue(option)
      originalOnChange({ ...value, value: dataToParam(option, value.type.entityType) })
    },
    [originalOnChange, setLocalValue, value],
  )

  return (
    <SelectInput
      renderOption={renderOption}
      renderPrefix={renderPrefix}
      options={options}
      defaultValue={defaultValue}
      searchable={options.length >= MIN_SEARCH_AMOUNT}
      onChange={onChange}
      label={value.type.entityType}
      clearable
    />
  )
}
