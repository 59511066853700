import { useMemo } from 'react'
import { Subheader } from '@revolut/ui-kit'
import { DetailRow, DetailsGroup } from 'components/DetailsGroup'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { QuerySwitch } from 'components/QuerySwitch'
import { CopyableText } from 'components/CopyableText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getIsMultiSubject } from 'view/Sam/utils'
import { makeSubjectTypeRows } from './utils'
import { SamPolicyDetailsActions } from './SamPolicyDetailsActions'

type SamPolicyDetailsProps = {
  id: string
  isActive: boolean
}

export const SamPolicyDetails = ({ id, isActive }: SamPolicyDetailsProps) => {
  const { data: policy, refetch, status, fetchStatus } = useQuerySamPolicy(id)
  const mainDetails = useMemo<DetailRow[]>(
    () => [
      ['Name', policy?.policyName],
      ['Description', policy?.businessReason || EMPTY_VALUE_FALLBACK],
      ...makeSubjectTypeRows(policy?.subject),
      ['Policy ID', <CopyableText value={id} key={id} />],
    ],
    [policy, id],
  )

  const isMultiSubject = getIsMultiSubject(policy?.subject)

  return (
    <>
      {isActive && <SamPolicyDetailsActions id={id} isMultiSubject={isMultiSubject} />}
      <>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>

        <QuerySwitch
          required={[{ qs: status, fs: fetchStatus }]}
          data={policy}
          renderError={() => <RequestErrorWidget action={refetch} />}
          renderLoading={() => <DetailsGroupSkeleton />}
          renderSuccess={() => <DetailsGroup rows={mainDetails} />}
        />
      </>
    </>
  )
}
