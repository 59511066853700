import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { useLinkNavigate } from 'hooks/useLinkNavigate'
import { useCallback, useState } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { useToasts } from 'hooks/useToasts'
import { useStorageItem } from 'hooks/useStorageItem'
import { QueryKey } from 'helpers/configQuery'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { PageHeader } from 'components/PageHeader'
import { getOrgPayload, isOrgValid } from './OrganisationEditForm/utils'
import { OrganisationEditForm, OrganisationEditFormState } from './OrganisationEditForm'

const ORG_STORAGE_NAME = 'orgCreate'

export const OrganisationCreate = () => {
  const toOrgs = useLinkNavigate(Url.Organisations)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { clearItem, setItem, item } = useStorageItem<{
    value: OrganisationEditFormState
  }>(ORG_STORAGE_NAME)

  const [org, setOrg] = useState<OrganisationEditFormState>({
    code: item?.value.code || '',
    description: item?.value.description || '',
    name: item?.value.name || '',
  })

  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { mutate, isLoading } = useMutation({
    mutationFn: idaveApi.organisations.add,
    onMutate: () => showLoadingPopup({ title: 'Adding...' }),
    onSuccess: (response) => {
      hideLoadingPopup()
      queryClient.resetQueries({
        queryKey: [QueryKey.Organisations],
      })
      clearItem()
      showSuccessToast('Organisation added')
      const url =
        'id' in response.data
          ? generatePath(Url.OrganisationDetails, { organisationId: response.data.id })
          : Url.Organisations
      navigate(url)
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Organisation addition failed',
        error: err,
      })
    },
  })

  const onChange = useCallback(
    (value: OrganisationEditFormState) => {
      setItem({ value })
      setOrg(value)
    },
    [setItem, setOrg],
  )

  const disabled = !isOrgValid(org)
  const onSubmit = useCallback(() => {
    mutate(getOrgPayload(org))
  }, [mutate, org])

  return (
    <>
      <PageHeader onBack={toOrgs} pageTitle="Create organisation" />
      <Page.Main size={PageMainSize.NARROW}>
        <OrganisationEditForm
          initialValue={org}
          isLoading={isLoading}
          onChange={onChange}
        />
        <Page.MainActions>
          <Button
            id="org-create-save"
            variant="primary"
            elevated
            disabled={isLoading || disabled}
            onClick={onSubmit}
            pending={isLoading}
          >
            Create
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
