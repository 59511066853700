import { CrossCheckDetail } from 'api/types/crossCheck'
import { CREATE_NAMES } from '../../../../ChangeSwitcher/utils'
import { ChangeType } from '../../../types'

export const getPolicySubjectChangeType = (crossCheck: CrossCheckDetail): ChangeType => {
  if (CREATE_NAMES.includes(crossCheck.executableName)) {
    return 'EntityCreation'
  }

  if (
    crossCheck.executableName === 'UpdatePolicy' &&
    crossCheck.executableParams.subject_type
  ) {
    return 'Edited'
  }

  return 'NoChange'
}
