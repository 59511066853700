import { Change } from 'api/types/xChecks'
import { VStack } from '@revolut/ui-kit'
import { ChangeViewType } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { DetailParamItem } from '../../DetailParamItem'

export const SingleChangeItem = ({
  change,
  view,
}: {
  change: Change
  view?: ChangeViewType
}) => {
  switch (view) {
    case 'new':
      return <DetailParamItem value={change.newValue} variant="added" />
    case 'old':
      return <DetailParamItem value={change.oldValue} variant="removed" />
    default:
      return (
        <VStack gap="s-4">
          <DetailParamItem value={change.oldValue} variant="removed" />
          <DetailParamItem value={change.newValue} variant="added" />
        </VStack>
      )
  }
}
