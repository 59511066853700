import { ArrayParam } from 'api/types/xChecks'
import { Flex, Text } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { ItemValueProps } from './types'
import { DetailParamItem } from '../DetailParamItem'

export const ArrayValue = ({ value, variant }: ItemValueProps<ArrayParam>) => {
  const array = value?.value

  return (
    <Flex gap="s-8" flexWrap="wrap" justifyContent="end">
      {Array.isArray(array) && array?.length ? (
        array.map((item, idx) => (
          <DetailParamItem value={item} isArrayItem variant={variant} key={idx} />
        ))
      ) : (
        <Text>{EMPTY_VALUE_FALLBACK_TEXT}</Text>
      )}
    </Flex>
  )
}
