import {
  DataPoint,
  MoreBar,
  Search,
  TableFilters,
  TableSortingRule,
  TableWidget,
  Table as UiKitTable,
  TableProps as UiKitTableProps,
} from '@revolut/ui-kit'
import { XChecksListItem } from 'api/types/xChecks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { pluralForm } from 'utils/string'
import { WHEEL_BUTTON_ID } from 'utils/constants/mouseEvents'
import { openInOtherTab, openLink } from 'components/EntitiesTable/utils'
import { COLUMNS } from './columns'
import { SearchErrorMessage } from './components/SearchErrorText'
import { XChecksFilters, XChecksSort } from './types'
import { NoResultsLabel } from './components/NoResultsLabel'

export const Table = ({
  currentReviewer,
  data,
  search,
  loadingState,
  onSearch,
  onFilter,
  onRowClick,
  getRowLink,
  onRequestMore,
  onRetry,
  hasMore,
  defaultFilters = {},
}: {
  currentReviewer?: string
  search?: string
  getRowLink?: (row: XChecksListItem, event: React.MouseEvent) => string
  onSearch: (query: string) => void
  defaultFilters?: XChecksFilters
  onFilter: (filtersObj: XChecksFilters) => void
} & Omit<UiKitTableProps<XChecksListItem>, 'onFilter' | 'columns'>) => {
  const debouncedSearch = useDebouncedValue(search, 500)
  const invalidSearch =
    !!debouncedSearch && debouncedSearch.length > 0 && debouncedSearch.length < 4

  const [filters, setFilters] = useState<XChecksFilters>(defaultFilters)

  const onTableFilter = useCallback((tableFilters: TableFilters<XChecksListItem>) => {
    const filtersObj = tableFilters.reduce<XChecksFilters>((result, filter) => {
      switch (filter?.id) {
        case 'executable':
          result.executableId = filter.value
          break
        case 'entities':
          result.entityId = filter.value
          break
        case 'Requester':
          result.requester = filter.value
          break
        default:
          result[filter.id as keyof XChecksFilters] = filter.value
          break
      }

      return result
    }, {})

    setFilters((prevFilters) => ({ ...prevFilters, ...filtersObj }))
  }, [])

  const onTableSort = useCallback((tableSort: TableSortingRule<XChecksListItem>[]) => {
    const sortObj = tableSort.reduce((result, sort) => {
      if (sort.id === 'createdDate') {
        result.order = 'CREATED_DATE'
        result.direction = sort.desc ? 'DESC' : 'ASC'
      } else if (sort.id === 'updatedDate') {
        result.order = 'UPDATED_DATE'
        result.direction = sort.desc ? 'DESC' : 'ASC'
      }
      return result
    }, {} as XChecksSort)

    setFilters((prevFilters) => ({ ...prevFilters, ...sortObj }))
  }, [])

  useEffect(() => {
    onFilter(filters)
  }, [filters, onFilter])

  const onToggleOverdue = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      currentReviewer: prevFilters.currentReviewer ? undefined : currentReviewer,
    }))
  }, [currentReviewer])

  const onRowClickWrapped = useMemo(
    () =>
      !onRowClick && !getRowLink
        ? undefined
        : (row: XChecksListItem, event: React.MouseEvent) => {
            const link = getRowLink?.(row, event)
            const openInOther = openInOtherTab(event)

            if (openInOther && link) {
              return openLink(link, openInOther)
            }

            if (onRowClick) {
              return onRowClick(row, event)
            }

            return link ? openLink(link, openInOther) : undefined
          },
    [getRowLink, onRowClick],
  )

  const onRowAuxClick = useCallback(
    (row: XChecksListItem, event: React.MouseEvent) => {
      if (event.button === WHEEL_BUTTON_ID) {
        const link = getRowLink?.(row, event)
        return link ? openLink(link, true) : undefined
      }
      return undefined
    },
    [getRowLink],
  )

  return (
    <TableWidget>
      <TableWidget.Info>
        <DataPoint>
          <DataPoint.Value use="h3">{data.length}</DataPoint.Value>
          <DataPoint.Label>
            {pluralForm(data.length, ['Request loaded', 'Requests loaded'])}
          </DataPoint.Label>
        </DataPoint>
      </TableWidget.Info>

      <TableWidget.Search>
        <Search
          placeholder="Search"
          defaultValue={search}
          onChange={onSearch}
          invalid={invalidSearch}
        />
        {invalidSearch && <SearchErrorMessage message="At least 4 characters" />}
      </TableWidget.Search>

      <TableWidget.Actions>
        <MoreBar>
          <MoreBar.Action
            onClick={onToggleOverdue}
            variant={filters.currentReviewer ? 'accent' : 'primary'}
            disabled={!currentReviewer}
          >
            Assigned to me
          </MoreBar.Action>
        </MoreBar>
      </TableWidget.Actions>

      <TableWidget.Table>
        <UiKitTable
          data={data}
          columns={COLUMNS}
          loadingState={loadingState}
          onRowClick={onRowClickWrapped}
          onRowAuxClick={onRowAuxClick}
          onFilter={onTableFilter}
          manualFilters
          onRequestMore={onRequestMore}
          onRetry={onRetry}
          hasMore={hasMore}
          manualSortBy
          onSortBy={onTableSort}
          disableMultiSort
          labelSortAsc="Sort ascending"
          labelSortDesc="Sort descending"
          labelSortCancel="Cancel sorting"
          labelNoResults={
            <NoResultsLabel filters={filters} currentReviewer={currentReviewer} />
          }
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
