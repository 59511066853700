import { TabBar } from '@revolut/ui-kit'
import { useParams } from 'view/XChecks/XCheck/lib/hooks/useParams'
import { match, P } from 'ts-pattern'
import { Changes as TChanges, Param, XCheckDetails } from 'api/types/xChecks'
import { useCallback, useState } from 'react'
import { Params } from './components/Params'
import { getDataValue } from '../../utils'
import { useChanges } from '../../hooks/useChanges'
import { Changes } from './components/Changes'
import { UpdateSkeleton } from './components/UpdatesSkeleton'
import { UpdatesError } from './components/UpdatesError'
import { UpdateTab } from './types'
import { useXCheckData } from '../../hooks'
import { getTabs } from './utils/getTabs'
import { useUpdateXCheckTabs } from './hooks'

export const Updates = () => {
  const paramsData = useParams()
  const changesData = useChanges()
  const { xCheckData } = useXCheckData()

  return match({ params: paramsData, changes: changesData, xCheckData })
    .with(
      P.union(
        { params: { status: 'loading' } },
        { changes: { status: 'loading' } },
        { xCheckData: { status: 'loading' } },
      ),
      UpdateSkeleton,
    )
    .with(
      P.union(
        { params: { status: 'error' }, changes: { status: 'error' } },
        { xCheckData: { status: 'error' } },
      ),
      () => <UpdatesError />,
    )
    .otherwise(({ params, changes }) => (
      <Inner
        params={getDataValue(params)}
        changes={getDataValue(changes)}
        xCheck={getDataValue(xCheckData)}
      />
    ))
}

type Props = {
  xCheck?: XCheckDetails
  params?: Param[]
  changes?: TChanges
}
const Inner = (props: Props) => {
  const { xCheck } = props
  const tabs = getTabs(props)
  const [tab, setTab] = useState<UpdateTab>(tabs[0].type)
  const onTabChange = useCallback(
    (newTab: UpdateTab | null) => {
      if (newTab) {
        setTab(newTab)
      }
    },
    [setTab],
  )

  useUpdateXCheckTabs({ xCheck, setTab, tab })

  return (
    <>
      {tabs.length > 1 ? (
        <TabBar variant="segmented" defaultValue={tab} onChange={onTabChange} mb="s-16">
          {tabs.map((item, idx) => (
            <TabBar.Item to={item.type} key={idx}>
              {item.label}
            </TabBar.Item>
          ))}
        </TabBar>
      ) : null}

      {match({ ...props, tab })
        .with({ tab: 'params', params: P.nonNullable }, ({ params }) => (
          <Params params={params} />
        ))
        .with({ tab: 'changes', changes: P.nonNullable }, ({ changes }) => (
          <Changes changes={changes} />
        ))
        .otherwise(() => (
          <UpdatesError tab={tab} />
        ))}
    </>
  )
}
