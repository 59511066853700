export function get3DImageSrcSetProps(code: string, version?: 'v2') {
  const versionPart = version ? `-${version}` : ''

  return {
    src: `https://assets.revolut.com/assets/3d-images${versionPart}/${code}.png`,
    srcSet: `https://assets.revolut.com/assets/3d-images${versionPart}/${code}@2x.png 2x, https://assets.revolut.com/assets/3d-images${versionPart}/${code}@3x.png 3x`,
  }
}

export const getCategoriesImageProps = (code: string) => ({
  default: `https://assets.revolut.com/assets/categories-v2/${code}.webp`,
  '2x': `https://assets.revolut.com/assets/categories-v2/${code}@2x.webp`,
  '3x': `https://assets.revolut.com/assets/categories-v2/${code}@3x.webp 3x`,
})
