import { Avatar, Text, HStack } from '@revolut/ui-kit'
import { getRegionFlagImageUrl } from 'utils/url/getRegionFlagImageUrl'

export const RegionText = ({
  id,
  title,
  region,
}: {
  id?: string
  title: string
  region?: string
}) => {
  const regionImage = region ? getRegionFlagImageUrl(region) : undefined

  return (
    <HStack space="s-8" align="center">
      {regionImage && (
        <Avatar
          size={24}
          uuid={id}
          label={region}
          title={title || region}
          image={regionImage}
        />
      )}
      <Text>{title}</Text>
    </HStack>
  )
}
