import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  CrossCheckDecision,
  CrossCheckDetail,
  CrossCheckSystem,
} from 'api/types/crossCheck'
import { QueryKey } from 'helpers/configQuery'
import { useEditPopup } from 'hooks/useEditPopup'
import { useCallback, useEffect } from 'react'
import { useCurrentUserIds } from 'hooks/useCurrentUserIds'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { getCrossCheckApi } from 'view/CrossChecks/utils'
import { LOADING_POPUP_TEXT, SUCCESS_TOAST_TEXT, canReview } from './utils'
import { ReviewPopup } from './ReviewPopup'

type Props = {
  crossCheck: CrossCheckDetail
  source: CrossCheckSystem
}
const POPUP_TITLE_APPROVE = 'Approval confirmation'
const POPUP_TITLE_REJECT = 'Rejection confirmation'

export const ReviewActions = (props: Props) => {
  const { crossCheck, source } = props

  const queryClient = useQueryClient()

  const { setPopup, closePopup, popup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const { mutate } = useMutation({
    mutationFn: (params: {
      id: string
      reason?: string
      decision: CrossCheckDecision
    }) => {
      const { id, reason, decision } = params
      const api = getCrossCheckApi(source)
      return api.reviewCrossCheck(id, {
        decision,
        reason,
      })
    },
    onMutate: ({ decision }) => {
      showLoadingPopup({ title: LOADING_POPUP_TEXT[decision] })
    },
    onSuccess: (_data, { decision }) => {
      showSuccessToast(SUCCESS_TOAST_TEXT[decision])
      hideLoadingPopup()
      queryClient.invalidateQueries([QueryKey.CrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.SamCrossCheckDetails, crossCheck?.id])
      queryClient.invalidateQueries([QueryKey.DartCrossCheckDetails, crossCheck?.id])
      closePopup()
    },
    onError: (err: Error) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Review submission failed', error: err })
    },
  })

  const submit = useCallback(
    (decision: CrossCheckDecision, reason?: string) => {
      mutate({
        id: crossCheck.id,
        decision,
        reason,
      })
    },
    [mutate, crossCheck],
  )

  const openReviewPopup = useCallback(
    (decision: CrossCheckDecision) => {
      const title = decision === 'APPROVE' ? POPUP_TITLE_APPROVE : POPUP_TITLE_REJECT
      setPopup({
        title,
        content: (
          <ReviewPopup onCancel={closePopup} onSubmit={submit} decision={decision} />
        ),
        isBottomSheet: true,
        headerVariant: 'bottom-sheet',
      })
    },
    [setPopup, closePopup, submit],
  )

  const openApprovePopup = useCallback(
    () => openReviewPopup('APPROVE'),
    [openReviewPopup],
  )
  const openRejectPopup = useCallback(() => openReviewPopup('REJECT'), [openReviewPopup])

  const { data: userIds } = useCurrentUserIds()
  const permissionUtils = usePermissions()
  const { canApprove, canReject } = canReview({
    source,
    crossCheck,
    ids: userIds,
    permissionUtils,
  })

  // closing opened Approve or Reject popup after unmounting
  useEffect(
    () => () => {
      if (popup?.title === POPUP_TITLE_APPROVE || popup?.title === POPUP_TITLE_REJECT) {
        closePopup()
      }
    },
    [popup, closePopup],
  )

  if (!canApprove && !canReject) {
    return null
  }

  return (
    <>
      {canApprove && (
        <MoreBar.Action useIcon="Check" onClick={openApprovePopup} variant="accent">
          Approve
        </MoreBar.Action>
      )}
      {canReject && (
        <MoreBar.Action useIcon="Cross" onClick={openRejectPopup}>
          Reject
        </MoreBar.Action>
      )}
    </>
  )
}
