import { ArrayChange, Change, EntityChange } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { EntityToEntityViewFn, EntityView } from 'view/XChecks/XCheck/lib/types'
import {
  ARRAY_CHANGE_PATTERN,
  ARRAY_ENTITY_CHANGE_PATTERN,
  ENTITY_CHANGE_PATTERN,
} from 'view/XChecks/XCheck/lib/utils/params'
import {
  extendDetails,
  extendTables,
  extendWidgets,
  isComplexEntity,
} from '../../../../../utils'

export type ChangesView = {
  details: Change[]
  tables: ArrayChange[]
  widgets: EntityChange[]
}
type GetParamsView = {
  changes: Change[]
  entityToEntityView: EntityToEntityViewFn
}
export const getUpdateInfo = ({ entityToEntityView, changes }: GetParamsView) => {
  return changes.reduce<ChangesView>(
    (result, change) => {
      return match(change)
        .with(ARRAY_CHANGE_PATTERN, addArrayToChangesView(result))
        .with(ENTITY_CHANGE_PATTERN, addEntityToChangesView(result, entityToEntityView))
        .otherwise(() => extendDetails(result, change))
    },
    {
      details: [],
      tables: [],
      widgets: [],
    },
  )
}

const addEntityToChangesView =
  (changesView: ChangesView, entityToEntityView: EntityToEntityViewFn) =>
  (change: EntityChange) => {
    if (
      entityToEntityView(change.oldValue) === EntityView.widget ||
      entityToEntityView(change.newValue) === EntityView.widget
    ) {
      return extendWidgets(changesView, change)
    }
    return extendDetails<Change, ChangesView>(changesView, change)
  }

const addArrayToChangesView = (changesView: ChangesView) => (change: ArrayChange) => {
  return match(change)
    .with(ARRAY_ENTITY_CHANGE_PATTERN, addEntityArrayChanges(changesView))
    .otherwise(() => extendDetails<Change, ChangesView>(changesView, change))
}

const addEntityArrayChanges = (changesView: ChangesView) => (param: ArrayChange) =>
  match(param)
    .when(isComplexEntityArrayChange, (array) => extendTables(changesView, array))
    .otherwise(() => extendDetails<Change, ChangesView>(changesView, param))

const isComplexEntityArrayChange = (array: ArrayChange) => {
  const oldItems = array.oldValue.value
  const newItems = array.newValue.value

  return oldItems?.some(isComplexEntity) || newItems?.some(isComplexEntity)
}
