import { Text } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getScopeLabel } from 'utils/permission/getScopeLabel'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'

type Props = {
  permission: Permission
}
export const PermissionScope = ({ permission }: Props) => {
  const label = getSecurityLabel(permission.scope, getScopeLabel(permission?.scope))

  return <Text>{label || EMPTY_VALUE_FALLBACK}</Text>
}
