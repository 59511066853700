import { Text } from '@revolut/ui-kit'
import { Role, RoleClassification as TRoleClassification } from 'api/idave/roles'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'

type Props = {
  role: Role
}
export const RoleDataClassification = ({ role }: Props) => {
  const label = getSecurityLabel(role.classification, getLabel(role))

  return <Text>{label || EMPTY_VALUE_FALLBACK}</Text>
}

const getLabel = (role: Role) => {
  switch (role.classification) {
    case TRoleClassification.internal:
      return 'Internal'
    case TRoleClassification.confidential:
      return 'Confidential'
    case TRoleClassification.private:
      return 'Private'
    case TRoleClassification.sensitive:
      return 'Sensitive'
    case TRoleClassification.unclassified:
      return 'Unclassified'
    default:
      return 'Unknown'
  }
}
