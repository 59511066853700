import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { IDAVE_PERMISSIONS } from 'security'
import { pluralForm } from 'utils/string'

export const useRolesAdd = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()

  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.USERS_ASSIGN_ROLES,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_ROLE_ASSIGN_ACTION,
  )
  const { mutate: submitRoles } = useMutation({
    mutationFn: (params: { userId: string; roles: string[]; expiryDate?: number }) => {
      const { roles, expiryDate, userId } = params

      return idaveApi.user.addRoles(userId, {
        payload: roles.map((id) => ({ id, expiryDate })),
        crossCheck: isCrossCheck,
      })
    },

    onMutate: () => {
      showLoadingPopup({ title: 'Adding...' })
    },

    onError: (err, params) => {
      hideLoadingPopup()
      showErrorPopup({
        title: pluralForm(params.roles.length, [
          'Role addition failed',
          'Roles addition failed',
        ]),
        error: err,
      })
    },

    onSuccess: (response, params) => {
      const { roles, userId } = params
      hideLoadingPopup()

      onSuccess()

      const crosscheck = getCrossCheckDescriptor(response)

      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        showSuccessToast(pluralForm(roles.length, ['Role added', 'Roles added']))
        queryClient.invalidateQueries({
          queryKey: [QueryKey.User, userId],
          refetchType: 'all',
        })
      }
    },
  })

  return { submitRoles }
}
