import { Box, MoreBar, Subheader, TextSkeleton } from '@revolut/ui-kit'
import { EntitiesTable } from 'components/EntitiesTable'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { times } from 'lodash'
import { ReactChild } from 'react'

export const EntitySkeleton = (props: {
  variant: 'details' | 'table'
  title?: ReactChild
  hasActions: boolean
}) => {
  const { variant, hasActions, title = 'Details' } = props
  switch (variant) {
    case 'table':
      return (
        <EntitiesTable
          columns={[]}
          data={[]}
          loadingState="pending"
          entitiesTypeLabel="Entities"
          pluralForms={['entity', 'entities']}
        />
      )
    default:
    case 'details':
      return (
        <Box>
          {hasActions && (
            <Box pb="s-4">
              <MoreBar>
                {times(3, (idx) => (
                  <MoreBar.Action useIcon="Loading" key={idx}>
                    <TextSkeleton size={6} />
                  </MoreBar.Action>
                ))}
              </MoreBar>
            </Box>
          )}
          <Subheader variant="nested">
            <Subheader.Title>{title}</Subheader.Title>
          </Subheader>
          <DetailsGroupSkeleton />
        </Box>
      )
  }
}
