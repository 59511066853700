import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { RevolutersJobRole } from 'api/sam/revoluters'
import { SAM_PERMISSIONS } from 'security'
import { BaseSelect, BaseSelectWrapperProps } from '../BaseSelect'
import { jobRoleToOption } from './utils'

export const JobRoleSelect = (props: BaseSelectWrapperProps<RevolutersJobRole>) => {
  const {
    data: revoluters,
    status: qs,
    fetchStatus: fs,
  } = useQueryRevolutersDictionaries()
  const queryResult = mergeQueryStatuses({ qs, fs })
  const items = useMemo(() => revoluters?.jobRoles || [], [revoluters])

  return (
    <BaseSelect
      items={items}
      queryResult={queryResult}
      itemToOption={jobRoleToOption}
      requiredPermission={SAM_PERMISSIONS.REVOLUTERS_STRUCTURE_VIEW_LIST}
      {...props}
    />
  )
}
