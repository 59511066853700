import { VStack } from '@revolut/ui-kit'
import { Changes as TChanges } from 'api/types/xChecks'
import { Updated } from './components/Updated'
import { Created } from './components/Created'
import { Deleted } from './components/Deleted'

type Props = {
  changes?: TChanges
}
export const Changes = ({ changes }: Props) => {
  const updated = changes?.updated
  const created = changes?.created
  const deleted = changes?.deleted

  const hasUpdated = !!updated?.length
  const hasCreated = !!created?.length

  return (
    <VStack pt="s-16" gap="s-32">
      <Updated updated={updated} firstItemExpanded={hasUpdated} />
      <Created created={created} firstItemExpanded={!hasUpdated} />
      <Deleted deleted={deleted} firstItemExpanded={!hasUpdated && !hasCreated} />
    </VStack>
  )
}
