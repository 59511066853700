export const X_CHECKS_PERMISSIONS = {
  CROSS_CHECKS_UPDATE_COMMENT: 'cross_checks.update.comment',
  CROSS_CHECKS_UPDATE_DECLINE: 'cross_checks.update.decline',
  CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS: 'cross_checks.update.groups.reviewers',
  CROSS_CHECKS_UPDATE_JUSTIFY: 'cross_checks.update.justify',
  CROSS_CHECKS_UPDATE_PROVIDE_INFO: 'cross_checks.update.provide_info',
  CROSS_CHECKS_UPDATE_REFRESH: 'cross_checks.update.refresh',
  CROSS_CHECKS_UPDATE_REQUEST_INFO: 'cross_checks.update.request_info',
  CROSS_CHECKS_UPDATE_RETRY: 'cross_checks.update.retry',
  CROSS_CHECKS_UPDATE_REVIEW: 'cross_checks.update.review',
  CROSS_CHECKS_VIEW_DETAILS: 'cross_checks.view.details',
  CROSS_CHECKS_VIEW_ENTITIES_LIST: 'cross_checks.view.entities.list',
  CROSS_CHECKS_VIEW_EXECUTABLES_LIST: 'cross_checks.view.executables.list',
  CROSS_CHECKS_VIEW_LIST: 'cross_checks.view.list',
  CROSS_CHECKS_VIEW_LOG_LIST: 'cross_checks.view.log.list',
  CROSS_CHECKS_VIEW_PRINCIPALS_DETAILS: 'cross_checks.view.principals.details',
  CROSS_CHECKS_VIEW_PRINCIPALS_LIST: 'cross_checks.view.principals.list',
  PERMISSIONS_VIEW_ACL_LIST: 'permissions.view.acl.list',
  WHOAMI_VIEW_DETAILS: 'whoami.view.details',
}
