import { useToggle } from '@revolut/ui-kit'
import { FormProvider, useForm } from 'react-hook-form'
import { useCallback, useEffect, useRef } from 'react'
import { Reason } from './components/Reason'
import { AvailableResourceList } from './components/ResourceList'
import { useResourceRequest } from './hooks'
import { ResourceRequestForm, ResourceSelectRow } from './types'
import { getResources } from './utils'

type Props = {
  idaveUserId: string
  close: () => void
}

export const ResourceRequest = ({ idaveUserId, close }: Props) => {
  const reasonForm = useForm<ResourceRequestForm>({
    defaultValues: {
      idaveUserId,
      justification: '',
    },
  })
  const [isResourceListOpen, toggleResourceListOpen] = useToggle({ defaultState: false })
  const { pending, requestResources } = useResourceRequest({ onSuccess: close })
  /**
   * using ref as storage of changed durations values to prevent rerenders
   * as they are required in columns cells, changes of object reference leads to full table rerender
   *  */
  const durationsRef = useRef<Record<string, number>>({})

  useEffect(() => {
    durationsRef.current = {}
  }, [isResourceListOpen])

  const submit = useCallback(
    (rows: ResourceSelectRow[]) => {
      const { justification, jiraTicket } = reasonForm.getValues()

      requestResources({
        idaveUserId,
        justification,
        jiraTicket,
        resources: getResources(rows, durationsRef),
      })
    },
    [requestResources, reasonForm, idaveUserId],
  )

  return (
    <FormProvider {...reasonForm}>
      <Reason onClose={close} onContinue={toggleResourceListOpen.on} />
      {isResourceListOpen && (
        <AvailableResourceList
          onSelect={submit}
          onClose={toggleResourceListOpen.off}
          pending={pending}
          durationsRef={durationsRef}
        />
      )}
    </FormProvider>
  )
}
