import { mapify } from 'utils/array'
import { pluralForm } from 'utils/string'
import { JustificationPopup } from 'components/JustificationPopup'
import { CustomerCompany } from '../../types'

export const getActionDisability = (activeIds: string[], entities: CustomerCompany[]) => {
  if (!activeIds.length) {
    return { disabledAdd: true, disabledRevoke: true }
  }

  const entityMap = mapify(entities, (e) => e.id)
  const isAllowed = !!entityMap.get(activeIds[0])?.accessModifierId
  const isAllSame = activeIds.every(
    (id) => !!entityMap.get(id)?.accessModifierId === isAllowed,
  )

  return {
    disabledAdd: !isAllSame || isAllowed,
    disabledRevoke: !isAllSame || !isAllowed,
  }
}

type ActionType = 'revoke' | 'add'
const getPopupSettings = (action: ActionType, companiesAmount: number) => {
  switch (action) {
    case 'revoke':
      return {
        title: pluralForm(companiesAmount, [
          'Are you sure you want to revoke access to selected customer company?',
          'Are you sure you want to revoke access to selected customer companies?',
        ]),
        description: pluralForm(companiesAmount, [
          'Users of this employee company will not have access to selected customer company data.',
          'Users of this employee company will not have access to selected customer companies data.',
        ]),

        submitButtonLabel: 'Revoke',
      }
    default:
    case 'add':
      return {
        title: pluralForm(companiesAmount, [
          'Are you sure you want to allow access to selected customer company?',
          'Are you sure you want to allow access to selected customer companies?',
        ]),
        description: pluralForm(companiesAmount, [
          'Users of this employee company will have access to selected customer company data.',
          'Users of this employee company will have access to selected customer companies data.',
        ]),
        submitButtonLabel: 'Add',
      }
  }
}

type GetJustificationPopupParams = {
  action: ActionType
  onSubmit: (justification?: string) => void
  companiesAmount: number
}
export const getJustificationPopup = ({
  action,
  companiesAmount,
  onSubmit,
}: GetJustificationPopupParams) => {
  const { submitButtonLabel, ...popupSettings } = getPopupSettings(
    action,
    companiesAmount,
  )
  return {
    ...popupSettings,
    isBottomSheet: true,
    content: (
      <JustificationPopup onSubmit={onSubmit} submitButtonLabel={submitButtonLabel} />
    ),
  }
}
