import { useController, useFormContext } from 'react-hook-form'
import {
  PermissionActionSelectProps,
  PermissionActionSelect,
} from 'components/Selects/PermissionActionSelect'
import { Fielded } from 'utils/types/fielded'

export const FieldPermissionActionSelect = (
  props: Fielded<PermissionActionSelectProps>,
) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return <PermissionActionSelect {...rest} {...field} ref={undefined} />
}
