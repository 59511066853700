import {
  XCheckDetails,
  State,
  AndReviewRule,
  CountingReviewRule,
  GroupReviewRule,
  MultistageReviewRule,
  NoRejectionsReviewRule,
  OrReviewRule,
  ReviewRule,
  RuleTypeDescriptor,
} from 'api/types/xChecks'

export const isFinished = (xCheck: XCheckDetails) => {
  switch (xCheck.state) {
    case State.Declined:
    case State.Executed:
    case State.Rejected:
      return true
    default:
      return false
  }
}

/**
 * returns flat view of review rules
 */
const traverseRules = (rule?: ReviewRule) => {
  if (!rule) {
    return []
  }

  const result: ReviewRule[] = []
  const ruleToTraverse = [rule]

  while (ruleToTraverse.length) {
    const reviewRule = ruleToTraverse.shift()
    if (reviewRule) {
      result.push(reviewRule)
    }

    if (
      reviewRule?.type === RuleTypeDescriptor.and ||
      reviewRule?.type === RuleTypeDescriptor.multiStage ||
      reviewRule?.type === RuleTypeDescriptor.or
    ) {
      ruleToTraverse.push(...reviewRule.reviewRules)
    }
  }

  return result
}

type FilteredRules<T extends RuleTypeDescriptor> = T extends RuleTypeDescriptor.and
  ? AndReviewRule
  : T extends RuleTypeDescriptor.or
  ? OrReviewRule
  : T extends RuleTypeDescriptor.noRejections
  ? NoRejectionsReviewRule
  : T extends RuleTypeDescriptor.counting
  ? CountingReviewRule
  : T extends RuleTypeDescriptor.group
  ? GroupReviewRule
  : T extends RuleTypeDescriptor.multiStage
  ? MultistageReviewRule
  : never

export const getRulesByType = <T extends RuleTypeDescriptor>(
  typeDescriptor: T,
  xCheck: XCheckDetails,
) => {
  return traverseRules(xCheck.reviewRule).filter<FilteredRules<T>>(
    (ruleItem: ReviewRule): ruleItem is FilteredRules<T> =>
      ruleItem.type === typeDescriptor,
  )
}

export const getReviewGroup = (xCheck: XCheckDetails, groupId: string) => {
  let group: GroupReviewRule | undefined
  const rulesToTraverse = [xCheck.reviewRule]

  while (!group && rulesToTraverse.length) {
    const rule = rulesToTraverse.shift()
    if (
      rule?.type === RuleTypeDescriptor.and ||
      rule?.type === RuleTypeDescriptor.or ||
      rule?.type === RuleTypeDescriptor.multiStage
    ) {
      rulesToTraverse.push(...rule.reviewRules)
    }

    if (rule?.type === RuleTypeDescriptor.group && rule.id === groupId) {
      group = rule
    }
  }

  return group
}
