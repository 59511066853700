import { Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { valueTypeMatcher } from 'view/XChecks/XCheck/lib/utils'
import { Variant } from 'view/XChecks/XCheck/lib/components/Updates/types'
import {
  ArrayValue,
  BooleanValue,
  NumberValue,
  TextValue,
  EntityValue,
  UuidValue,
  TimeStampValue,
} from './Values'

export const DetailParamItem = ({
  value,
  isArrayItem,
  variant,
}: {
  value: Value
  isArrayItem?: boolean
  variant?: Variant
}) =>
  match({ value, isArrayItem, variant })
    .with(valueTypeMatcher('text'), TextValue)
    .with(valueTypeMatcher('uuid'), UuidValue)
    .with(valueTypeMatcher('bool'), BooleanValue)
    .with(valueTypeMatcher('number'), NumberValue)
    .with(valueTypeMatcher('timestamp'), TimeStampValue)
    .with(valueTypeMatcher('entity'), EntityValue)
    .with(valueTypeMatcher('array'), ArrayValue)
    .exhaustive()
