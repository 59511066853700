import { Avatar, AvatarSize, Flex, Text, TextProps, abbreviate } from '@revolut/ui-kit'
import { UserStates } from 'api/idave/user'
import { useUserStateTooltip } from 'hooks/useUserStateTooltip'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { HTMLAttributeAnchorTarget } from 'react'
import { InternalLink } from '../InternalLink'
import { ExternalLink } from '../ExternalLink'

export type UserLinkViewProps = {
  id: string
  name: string
  avatarUrl?: string
  state?: UserStates
  target?: HTMLAttributeAnchorTarget
  displayId?: string
  isExternal?: boolean
  href: string
  isReversed?: boolean
  textProps?: TextProps
  avatarSize?: AvatarSize
}
const DEFAULT_AVATAR_SIZE = 24

export const UserLinkView = ({
  id,
  name,
  state,
  avatarUrl,
  target,
  displayId,
  isExternal,
  href,
  isReversed,
  textProps,
  avatarSize = DEFAULT_AVATAR_SIZE,
}: UserLinkViewProps) => {
  const { anchorProps, avatarProps, titleProps, tooltip } = useUserStateTooltip(state)
  const LinkComponent = isExternal ? ExternalLink : InternalLink
  const label = abbreviate(name)

  return (
    <LinkComponent href={href} target={target}>
      <Flex
        {...anchorProps}
        display="inline-flex"
        gap="s-8"
        alignItems="center"
        flexWrap="nowrap"
        flexDirection={isReversed ? 'row-reverse' : undefined}
      >
        <Avatar
          size={avatarSize}
          {...getAvatarProps({ id, avatar: avatarUrl, label })}
          {...avatarProps}
        />
        <Text whiteSpace="nowrap" {...titleProps} {...textProps}>
          {name}
          {displayId ? `(${displayId})` : null}
        </Text>
      </Flex>
      {tooltip}
    </LinkComponent>
  )
}
