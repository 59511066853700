import { PermissionAction } from 'api/idave/permissions'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'
import { SelectInput, SelectInputProps, SelectOptionItemType } from '@revolut/ui-kit'
import { getActionLabel } from 'utils/permission/getActionLabel'
import { stringEnumToOptions } from './utils'

const values = PermissionAction as Record<string, PermissionAction>
export const OPTIONS: SelectOptionItemType<string>[] = stringEnumToOptions(
  PermissionAction,
  (item) => getSecurityLabel(values[item], getActionLabel(values[item])),
)

type StringSelect = SelectInputProps<string, SelectOptionItemType<string>>

export type PermissionActionSelectProps = Omit<StringSelect, 'options'>

export const PermissionActionSelect = (props: PermissionActionSelectProps) => (
  <SelectInput searchable={false} {...props} options={OPTIONS} />
)
