import { Role } from 'api/idave/roles'
import { VStack } from '@revolut/ui-kit'
import { getInheritanceTree } from 'view/Roles/utils'
import { useSideBox } from 'view/SideBox/SideBox'
import { useCallback, useMemo } from 'react'
import { useQueryRoleIdMap, useQueryRoles } from 'queries/idave/roles'
import { EntityDescription } from 'components/EntityDescription'
import { RoleActions } from './components/RoleActions'
import { RoleDetailsInfo } from './components/RoleDetailsInfo'
import { RoleInheritanceTree } from './components/RoleInheritanceTree'

export const RoleDetails = ({ role }: { role: Role }) => {
  const { data: roles = [] } = useQueryRoles()
  const { data: roleMap = new Map() } = useQueryRoleIdMap()
  const { openSide } = useSideBox()
  const inheritanceTree = useMemo(
    () => getInheritanceTree({ roles, roleId: role.id }),
    [roles, role.id],
  )

  const openInheritanceTree = useCallback(
    () =>
      openSide({
        title: 'Inheritance tree',
        resizable: true,
        body: (
          <RoleInheritanceTree
            inheritanceTree={inheritanceTree}
            sourceRole={role}
            roleMap={roleMap}
          />
        ),
      }),
    [openSide, role, inheritanceTree, roleMap],
  )

  return (
    <>
      <RoleActions role={role} />
      <VStack space="s-16">
        <RoleDetailsInfo role={role} openInheritanceTree={openInheritanceTree} />
        <EntityDescription description={role.description} />
      </VStack>
    </>
  )
}
