import { Principal, ReviewRuleState } from './common'

export enum RuleTypeDescriptor {
  and = 'AND',
  multiStage = 'MULTISTAGE',
  or = 'OR',
  group = 'GROUP',
  counting = 'COUNTING',
  noRejections = 'NO_REJECTIONS',
}

type BaseReviewRule = {
  id: string
  name: string
  state: ReviewRuleState
  type: RuleTypeDescriptor
}

export type AndReviewRule = BaseReviewRule & {
  type: RuleTypeDescriptor.and
  reviewRules: ReviewRule[]
}

export type MultistageReviewRule = BaseReviewRule & {
  type: RuleTypeDescriptor.multiStage
  reviewRules: ReviewRule[]
}

export type OrReviewRule = BaseReviewRule & {
  type: RuleTypeDescriptor.or
  reviewRules: ReviewRule[]
}

export type GroupReviewRule = BaseReviewRule & {
  type: RuleTypeDescriptor.group
  config: {
    approvals: number
    reviewers: Principal[]
  }
}

export type CountingReviewRule = BaseReviewRule & {
  type: RuleTypeDescriptor.counting
  config: {
    approvals: number
  }
}

export type NoRejectionsReviewRule = BaseReviewRule & {
  type: RuleTypeDescriptor.noRejections
}

export type ReviewRule =
  | MultistageReviewRule
  | AndReviewRule
  | OrReviewRule
  | GroupReviewRule
  | CountingReviewRule
  | NoRejectionsReviewRule
