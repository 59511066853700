import { Permission } from 'api/idave/permissions'
import { ToxicAlert, ToxicPair } from 'api/idave/toxic'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { AlertRow } from './types'

type GetRowsParams = {
  toxicAlerts?: ToxicAlert[]
  toxicPairs?: Map<string, ToxicPair>
  permissionMap?: Map<string, Permission>
}
export const getRows = ({
  toxicAlerts = [],
  toxicPairs,
  permissionMap,
}: GetRowsParams): AlertRow[] => {
  return toxicAlerts.map((alert) => {
    const toxicPair = toxicPairs?.get(alert.toxicPermissionId)

    if (!toxicPair) {
      return {
        id: alert.toxicPermissionId,
        toxicPairName: alert.toxicPermissionId,
        firstPermissionName: EMPTY_VALUE_FALLBACK,
        secondPermissionName: EMPTY_VALUE_FALLBACK,
      }
    }

    const firstPermission = permissionMap?.get(toxicPair.firstPermission)
    const secondPermission = permissionMap?.get(toxicPair.secondPermission)

    return {
      id: alert.toxicPermissionId,
      toxicPairName: toxicPair?.name || alert.toxicPermissionId,
      firstPermissionId: toxicPair.firstPermission,
      secondPermissionId: toxicPair.secondPermission,
      firstPermissionName: firstPermission?.name || EMPTY_VALUE_FALLBACK,
      secondPermissionName: secondPermission?.name || EMPTY_VALUE_FALLBACK,
      state: alert.state,
      createdDate: alert.createdDate,
      deletedDate: alert.deletedDate,
    }
  })
}
