import { PageMainSize } from '@revolut/ui-kit'
import { ToxicPairTab } from './types'

export const tabToPageSize = (tab: ToxicPairTab) => {
  switch (tab) {
    case 'alerts':
    case 'auditLogs':
      return PageMainSize.FULL
    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
