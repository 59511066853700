import { DeletedEntity as TDeletedEntity } from 'api/types/xChecks'
import { DeletedEntity } from './components/DeletedEntity'

type Props = {
  deleted?: TDeletedEntity[]
  firstItemExpanded: boolean
}
export const Deleted = ({ deleted, firstItemExpanded }: Props) => {
  if (!deleted?.length) {
    return null
  }
  return (
    <>
      {deleted.map((deletedEntity, idx) => (
        <DeletedEntity
          key={idx}
          entity={deletedEntity}
          defaultExpanded={idx === 0 && firstItemExpanded}
        />
      ))}
    </>
  )
}
