import { Color, Icon, IconName } from '@revolut/ui-kit'
import { Review } from 'api/types/xChecks'

export const ReviewIcon = ({ review }: { review?: Review }) => {
  if (!review) {
    return <Icon size={20} name="RadiobuttonOff" color="grey-tone-20" />
  }

  const isApproved = review.decision === 'APPROVE'

  const name: IconName = isApproved ? 'CheckSuccess' : 'CrossCircle'
  const color: Color = isApproved ? 'green' : 'red'

  return <Icon size={20} name={name} color={color} />
}
