import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

export const XCheckQueryClientProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
