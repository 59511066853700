import { useController, useFormContext } from 'react-hook-form'
import { PermissionActionSelectProps } from 'components/Selects/PermissionActionSelect'
import { Fielded } from 'utils/types/fielded'
import { PermissionScopeSelect } from 'components/Selects/PermissionScopeSelect'

export const FieldPermissionScopeSelect = (
  props: Fielded<PermissionActionSelectProps>,
) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return <PermissionScopeSelect {...rest} {...field} ref={undefined} />
}
