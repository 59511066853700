import { Button, Page, Skeleton, VStack } from '@revolut/ui-kit'
import { times } from 'utils/array/times'

export const PermissionEditSkeleton = () => {
  return (
    <>
      <VStack space="s-24">
        {times(
          (idx) => (
            <Skeleton height="56px" width="100%" key={idx} />
          ),
          8,
        )}
      </VStack>
      <Page.MainActions>
        <Button variant="primary" elevated disabled pending>
          Save changes
        </Button>
      </Page.MainActions>
    </>
  )
}
