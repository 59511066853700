import { MultistageReviewRule, ReviewRule } from 'api/types/xChecks'
import { Text, VStack } from '@revolut/ui-kit'
import { RuleItem } from './RuleItem'
import { getParentRulePrefix } from '../utils'
import { Rule } from '../Rule'

type Props = {
  rule: MultistageReviewRule
  parentRule?: ReviewRule
  isFirst?: boolean
}

export const MultistageRule = ({ rule, parentRule, isFirst }: Props) => {
  return (
    <>
      <RuleItem rule={rule}>
        <Text variant="secondary">
          {getParentRulePrefix(parentRule, isFirst)} has to proceed through stages:
        </Text>
      </RuleItem>
      <VStack ml="s-24">
        {rule.reviewRules.map((r, idx) => (
          <Rule key={r.id} parentRule={rule} rule={r} isFirst={idx === 0} />
        ))}
      </VStack>
    </>
  )
}
