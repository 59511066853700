import { VStack } from '@revolut/ui-kit'
import { XCheckDetails } from './components/XCheckDetails'
import { Entities } from './components/Entities'
import { Events } from './components/Events'
import { useViews } from '../../hooks/useViews'
import { useXCheckData } from '../../hooks'

export const Details = () => {
  const { xCheckData, userIdData, xCheckLogData } = useXCheckData()
  const { Entity } = useViews()
  return (
    <VStack gap="s-16" overflow="hidden">
      <XCheckDetails xCheck={xCheckData} />
      <Entities xCheck={xCheckData} Entity={Entity} />
      <Events xCheck={xCheckData} log={xCheckLogData} currentUserId={userIdData} />
    </VStack>
  )
}
