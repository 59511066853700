import { useNavigateToList } from 'hooks/useBackToResults'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useCurrentUserId } from 'queries/idave/users'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { useQueryClient } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { Principal, Entity, entityToEntityView, getEntityUrl } from './components'
import { useQueryData } from './lib/utils'
import { XCheck as LibXCheck } from './lib'
import { useApiSettings } from '../hooks/useApiSettings'

type Props = {
  id: string
}

const VIEW_SETTINGS = {
  Principal,
  Entity,
  entityToEntityView,
  getEntityUrl,
}

export const XCheck = ({ id: xCheckId }: Props) => {
  const onBackClick = useNavigateToList({
    listUrl: Url.XChecks,
    entityUrl: generatePath(Url.XCheck, { xCheckId }),
  })

  const { hasPermission } = usePermissions()
  const queryClient = useQueryClient()
  const onXCheckUpdate = useCallback(() => {
    queryClient.resetQueries({
      queryKey: [QueryKey.XChecks],
      type: 'all',
    })
  }, [queryClient])

  const userIdQuery = useCurrentUserId()
  const userIdData = useQueryData(userIdQuery)
  const apiSettings = useApiSettings()

  return (
    <LibXCheck
      xCheckId={xCheckId}
      hasPermission={hasPermission}
      userIdData={userIdData}
      viewSettings={VIEW_SETTINGS}
      onXCheckUpdate={onXCheckUpdate}
      apiSettings={apiSettings}
      onBackClick={onBackClick}
    />
  )
}
