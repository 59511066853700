import { useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Account } from 'api/idave/accounts'
import { DetailFields } from 'view/Clients/Account/components/DetailFields'
import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { useEditAccount } from '../../hooks/useEditAccount'
import { AccountFormValues } from '../../AccountAdd/types'
import { Header } from './Header'

export const AccountEditForm = ({ account }: { account: Account }) => {
  const editAccount = useEditAccount({ accountId: account.id, editTab: 'details' })

  const accountForm = useForm<AccountFormValues>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: {
      clientId: account?.clientId,
      gcpSA: account?.authentication.gcpSA,
      permissionIds: account?.permissionIds || [],
    },
  })

  const onSubmit = useCallback(() => {
    const { clientId, gcpSA, permissionIds } = accountForm.getValues()
    return editAccount({
      id: account.id,
      clientId,
      authentication: {
        gcpSA,
      },
      permissionIds,
    })
  }, [editAccount, accountForm, account.id])

  return (
    <>
      <Header accountId={account.id} />
      <FormProvider {...accountForm}>
        <Page.Main size={PageMainSize.NARROW}>
          <DetailFields isCreating={false} />
          <Page.MainActions>
            <Button
              id="account-edit-save"
              variant="primary"
              elevated
              disabled={!accountForm.formState.isValid || !accountForm.formState.isDirty}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Page.MainActions>
        </Page.Main>
      </FormProvider>
    </>
  )
}
