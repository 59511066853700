import { PermissionScope as TPermissionScope } from 'api/idave/permissions'

export const getScopeLabel = (scope?: TPermissionScope) => {
  switch (scope) {
    case TPermissionScope.individual:
      return 'Individual record in a single data category'
    case TPermissionScope.multiple:
      return 'Multiple records across a single data category'
    case TPermissionScope.multipleAcrossCategories:
      return 'Multiple records across multiple data categories'
    default:
      return 'Unknown'
  }
}
