import { Table, Text } from '@revolut/ui-kit'
import { BooleanParam } from 'api/types/xChecks'
import { StatusText } from 'components/StatusText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { stringifyBoolean } from 'utils/boolean/stringifyBoolean'

export const BoolValueCell = ({ param }: { param: BooleanParam }) => {
  const value = param.value.value

  return (
    <Table.Cell>
      {typeof value === 'boolean' ? (
        <StatusText fontSize="caption" level={value ? 'success' : 'alert'}>
          {stringifyBoolean(value)}
        </StatusText>
      ) : (
        <Text>{EMPTY_VALUE_FALLBACK}</Text>
      )}
    </Table.Cell>
  )
}
