import { Box, Group, ItemSkeleton, SubheaderSkeleton, VStack } from '@revolut/ui-kit'

export const UserDataAccessSkeleton = () => (
  <VStack gap="s-24">
    <Box>
      <SubheaderSkeleton>
        <SubheaderSkeleton.Title />
      </SubheaderSkeleton>

      <Group>
        <ItemSkeleton />
      </Group>
    </Box>

    <Box>
      <SubheaderSkeleton>
        <SubheaderSkeleton.Title />
      </SubheaderSkeleton>

      <Group>
        <ItemSkeleton />
      </Group>
    </Box>
  </VStack>
)
