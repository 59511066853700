import { ResourceType } from 'api/sam/types/resources'
import { lowerCase } from 'lodash'
import { capitalizeFirst } from 'utils/string'

export const getResourceTypeLabel = (resourceType?: ResourceType) => {
  switch (resourceType) {
    case ResourceType.GoogleGroup:
      return 'Google group'
    case ResourceType.MetabaseGroup:
      return 'Metabase group'
    case ResourceType.TeamCityGroup:
      return 'Teamcity group'
    case ResourceType.LookerGroup:
      return 'Looker group'
    case ResourceType.IdaveRole:
      return 'iDave role'
    case ResourceType.TestFlight:
      return 'TestFlight group'
    case ResourceType.AppStoreApp:
      return 'AppStore app'
    case ResourceType.AppStoreUserRole:
      return 'AppStore user role'
    case ResourceType.OktaGroup:
      return 'Okta group'
    case ResourceType.AppStoreUserProperty:
      return 'AppStore user property'
    case ResourceType.DartAllowCustomerCompany:
      return 'Customer company access'
    case ResourceType.RiskPlatformAccess:
      return 'Risk platform access'
    case ResourceType.ZeusRole:
      return 'Zeus role'
    default:
      return capitalizeFirst(lowerCase(resourceType))
  }
}
