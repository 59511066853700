import { PageMainSize } from '@revolut/ui-kit'
import { SamResourceTab } from './types'

export const tabToPageSize = (tab: SamResourceTab) => {
  switch (tab) {
    case 'policies':
      return PageMainSize.FULL
    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
