import { PermissionOversight } from 'api/idave/permissions'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'
import {
  MultiSelectInput,
  MultiSelectInputProps,
  SelectOptionItemType,
} from '@revolut/ui-kit'
import { useCallback, useMemo, useState } from 'react'
import { getOversightLabel } from 'utils/permission/getOversightLabel'
import { isOptionDisabled } from './utils'

const values = PermissionOversight as Record<string, PermissionOversight>
type StringSelect = MultiSelectInputProps<string, SelectOptionItemType<string>>

export type PermissionOversightsSelectProps = Omit<StringSelect, 'options'>

export const PermissionOversightsSelect = (props: PermissionOversightsSelectProps) => {
  const [activeItems, setActiveItems] = useState(props.value)
  const onChange = useCallback(
    (items: string[]) => {
      setActiveItems(items)
      props?.onChange?.(items)
    },
    [props, setActiveItems],
  )

  const options: SelectOptionItemType<string>[] = useMemo(
    () =>
      Object.keys(PermissionOversight).map((item) => ({
        key: values[item],
        value: values[item],
        disabled: isOptionDisabled(activeItems, values[item]),
        label: getSecurityLabel(values[item], getOversightLabel(values[item])),
      })),
    [activeItems],
  )

  return (
    <MultiSelectInput
      searchable={false}
      {...props}
      options={options}
      onChange={onChange}
    />
  )
}
