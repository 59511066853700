import { Table, TableColumn, TableData, TableFilterProps, Text } from '@revolut/ui-kit'
import { State, XChecksListItem } from 'api/types/xChecks'
import {
  getStateColor,
  stringifyState,
} from 'view/XChecks/XCheck/lib/components/Header/utils'
import { CheckboxSelectTableFilter } from './CheckboxSelectTableFilter'

const StateTableFilter = <Entity extends TableData>({
  column,
}: Pick<TableFilterProps<Entity>, 'column'>) => {
  const options = Object.values(State).map((value) => ({
    value,
    label: stringifyState(value),
    key: value,
  }))

  return <CheckboxSelectTableFilter column={column} options={options} />
}

const StateTableCell = ({ value }: { value: State }) => (
  <Table.Cell>
    <Text color={getStateColor(value)}>{stringifyState(value)}</Text>
  </Table.Cell>
)

export const STATE_TABLE_CELL: TableColumn<XChecksListItem> = {
  Header: 'State',
  accessor: 'state',
  Filter: StateTableFilter,
  Cell: StateTableCell,
  // xChecks API does not provide sorting order functionality
  disableSortBy: true,
}
