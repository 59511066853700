import { Check } from '@revolut/icons'
import { StatusPopup, Toast, useStatusPopup, useToast } from '@revolut/ui-kit'
import { useMutation } from '@tanstack/react-query'
import { Param, XCheckDetails } from 'api/types/xChecks'
import { useApi, useViews, useXCheckData } from 'view/XChecks/XCheck/lib/hooks'
import { extractErrorMsg } from 'view/XChecks/XCheck/lib/utils'

type MutateParams = {
  xCheck: XCheckDetails
}
export const useMutateParams = ({ xCheck }: MutateParams) => {
  const statusPopup = useStatusPopup()
  const toast = useToast()
  const { onXCheckUpdate } = useXCheckData()
  const { updateParams } = useApi()
  const { setIsEditing } = useViews()

  return useMutation({
    mutationFn: (newParams: Param[]) =>
      updateParams({
        params: newParams,
        source: xCheck.source,
        xCheckId: xCheck.id,
      }),
    onMutate: () => {
      statusPopup.show(
        <StatusPopup variant="loading">
          <StatusPopup.Title>Updating requester input...</StatusPopup.Title>
        </StatusPopup>,
      )
    },
    onSuccess: () => {
      statusPopup.hide()
      setIsEditing(false)
      toast.show(
        <Toast>
          <Toast.Indicator>
            <Check />
          </Toast.Indicator>
          <Toast.Label>Updated</Toast.Label>
        </Toast>,
        'short',
      )
      onXCheckUpdate()
    },
    onError: (error) => {
      statusPopup.hide()
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Updating failed</StatusPopup.Title>
          <StatusPopup.Description>{extractErrorMsg(error)}</StatusPopup.Description>
        </StatusPopup>,
      )
    },
  })
}
