import { Value } from 'api/types/xChecks'
import { createEnumMap } from 'utils/types/createEnumMap'
import { ValueOf } from 'utils/types/valueOf'

export type ChangeItem<T> = {
  variant?: Variant
  value: T
}

export type ParamChangeItem = ChangeItem<Value>

export const Variant = createEnumMap('removed', 'added')
export type Variant = ValueOf<typeof Variant>

export const ChangeViewType = createEnumMap('old', 'new', 'diff')
export type ChangeViewType = ValueOf<typeof ChangeViewType>

export const UpdateTab = createEnumMap('params', 'changes')
export type UpdateTab = ValueOf<typeof UpdateTab>

export type Tab = {
  type: UpdateTab
  label: string
}
