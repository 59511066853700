import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'

export type Permission = {
  id: string
  name: string
  description?: string
  client: string
  createdDate: number
  action?: PermissionAction
  classification?: PermissionClassification
  scope?: PermissionScope
  oversight?: PermissionOversight[]
  domain?: string
}

export enum PermissionAction {
  read = 'R',
  download = 'D',
  update = 'U',
  delete = 'DEL',
}

export enum PermissionClassification {
  highlyConfidential = 'HC',
  confidential = 'C',
  monetary = 'M',
  sensitive = 'S',
  internal = 'I',
  public = 'P',
}

export enum PermissionScope {
  individual = 'I',
  multiple = 'MI',
  multipleAcrossCategories = 'MM',
}

export enum PermissionOversight {
  xCheck = 'CC',
  explicit = 'EO',
  masked = 'M',
  none = 'X',
}

export type OrphanPermission = {
  permission: string
  createdDate: string
}

export type RoleByPermission = {
  id: string
  name: string
  parent: string
}

export type EditPermissionPayload = {
  description: string
  action?: PermissionAction
  classification?: PermissionClassification
  scope?: PermissionScope
  oversight?: PermissionOversight[]
  domain?: string
}

const permissions = {
  getPermissions: (): AxiosPromise<Permission[]> => axios.get(`/api/permissions`),

  getOrphanedPermissions: (): AxiosPromise<OrphanPermission[]> =>
    axios.get(`/api/orphaned-permissions`),

  getPermission: (permissionId: string): AxiosPromise<Permission> =>
    axios.get(`/api/permissions/${permissionId}`),

  getRolesByPermission: (permissionId: string): AxiosPromise<RoleByPermission[]> =>
    axios.get(`/api/permissions/${permissionId}/roles`),

  editDescription: (
    permissionId: string,
    data: EditPermissionPayload,
  ): AxiosPromise<ActionResponse> =>
    axios.patch(`/api/permissions/${permissionId}/description`, data),
}

export default permissions
