import { XCheckApiErrorHandler } from 'view/XChecks/lib/types'
import { ApiMethod } from './types'

const DEFAULT_RESPONSE_MESSAGE = "Response isn't ok"

export const handleRequest = async <Result = unknown>(
  apiCall: Promise<Response>,
  onXCheckDataError: XCheckApiErrorHandler,
  apiMethod: ApiMethod,
  withResponse = true,
) => {
  let response: Response | undefined

  try {
    response = await apiCall
  } catch (e) {
    // Request failed
    const error = e instanceof Error ? e : new Error('Request failed')
    onXCheckDataError({ apiMethod, error })
    throw error
  }

  if (!response) {
    onXCheckDataError({ apiMethod, response })
    throw new Error(DEFAULT_RESPONSE_MESSAGE, { cause: response })
  }

  if (!response.ok) {
    // Response not 2xx (401 e.g)
    let message: string
    const badResponse = response.clone()
    try {
      const badResponseData = await badResponse.json()
      message =
        typeof badResponseData === 'object' && 'message' in badResponseData
          ? badResponseData.message
          : DEFAULT_RESPONSE_MESSAGE
    } catch (_e) {
      message = DEFAULT_RESPONSE_MESSAGE
    }

    onXCheckDataError({ apiMethod, response })
    throw new Error(message, { cause: response })
  }

  if (!withResponse) {
    return Promise.resolve() as Promise<Result>
  }

  try {
    return response.json() as Promise<Result>
  } catch (e) {
    // Data missing
    const error = e instanceof Error ? e : new Error("Response data isn't ok")
    onXCheckDataError({ apiMethod, response, error })
    throw new Error("Response data isn't ok", { cause: response })
  }
}

export const get = async <Result>({
  path,
  apiMethod,
  onXCheckApiError,
}: {
  path: string
  apiMethod: ApiMethod
  onXCheckApiError: XCheckApiErrorHandler
}) => {
  return handleRequest<Result>(fetch(path), onXCheckApiError, apiMethod)
}

export const post = async <Payload extends object, Result = unknown>({
  path,
  apiMethod,
  payload,
  onXCheckApiError,
}: {
  path: string
  apiMethod: ApiMethod
  payload?: Payload
  onXCheckApiError: XCheckApiErrorHandler
}) => {
  return handleRequest<Result>(
    fetch(path, {
      method: 'post',
      body: payload ? JSON.stringify(payload) : undefined,
    }),
    onXCheckApiError,
    apiMethod,
    false,
  )
}

export const defaultApiErrorHandler: XCheckApiErrorHandler = (params) =>
  // eslint-disable-next-line no-console
  console.error(params)
