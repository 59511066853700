import { PermissionOversight } from 'api/idave/permissions'
import { isEmpty } from 'lodash'
import { toPresenceMap } from 'utils/array/toPresenceMap'

export const isOptionDisabled = (activeItems?: string[], value?: string) => {
  // if none selected all active
  if (!activeItems?.length) {
    return false
  }

  const active = toPresenceMap(activeItems)

  // if none is selected other are disabled
  if (active[PermissionOversight.none] && value !== PermissionOversight.none) {
    return true
  }

  // any other active none is disabled
  if (
    !active[PermissionOversight.none] &&
    !isEmpty(active) &&
    value === PermissionOversight.none
  ) {
    return true
  }

  return false
}
