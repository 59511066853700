import { Page, PageMainSize } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { useQueryPermission } from 'queries/idave/permissions'
import { useQueryClientIdMap } from 'queries/idave/clients'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { IDAVE_PERMISSIONS } from 'security'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import { PermissionEditForm } from './components/PermissionEditForm'
import { PermissionEditSkeleton } from './components/PermissionEditSkeleton'

type Props = {
  permissionId: string
}

export const PermissionEdit = ({ permissionId }: Props) => {
  const {
    data: permission,
    status: qs,
    fetchStatus: fs,
  } = useQueryPermission(permissionId)
  const {
    data: clientMap = new Map(),
    status: clientsQS,
    fetchStatus: clientsFS,
  } = useQueryClientIdMap()
  const client = useMemo(() => clientMap.get(permission?.client), [clientMap, permission])
  const navigate = useNavigate()
  const toPermissionPage = useCallback(
    () => navigate(generatePath(Url.Permission, { permissionId })),
    [navigate, permissionId],
  )

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      optional={[{ qs: clientsQS, fs: clientsFS }]}
      data={permission}
      renderIdle={() => (
        <Outer permissionId={permissionId} toPermissionPage={toPermissionPage}>
          <NoAccessWidget
            title={`Looks like you don’t have sufficient permissions to view permission details (${IDAVE_PERMISSIONS.PERMISSIONS_VIEW_DETAILS.value})`}
          />
        </Outer>
      )}
      renderLoading={() => (
        <Outer permissionId={permissionId} loading toPermissionPage={toPermissionPage}>
          <PermissionEditSkeleton />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer
          permissionId={permissionId}
          permission={data}
          toPermissionPage={toPermissionPage}
        >
          <PermissionEditForm
            permission={data}
            client={client}
            permissionId={permissionId}
            toPermissionPage={toPermissionPage}
          />
        </Outer>
      )}
    />
  )
}

const Outer = ({
  permissionId,
  permission,
  children,
  loading,
  toPermissionPage,
}: {
  permissionId: string
  children: React.ReactNode
  permission?: Permission
  loading?: boolean
  toPermissionPage: () => void
}) => {
  const HeaderActions = useHeaderActions()
  return (
    <>
      <PageHeader
        onBack={toPermissionPage}
        actions={HeaderActions && <HeaderActions />}
        pageTitle={loading ? undefined : permission?.name || permissionId}
        postfix="Edit | Permissions"
      />
      <Page.Main size={PageMainSize.NARROW}>{children}</Page.Main>
    </>
  )
}
