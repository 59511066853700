import { Table, TableColumn, TableData, TableFilterProps, Text } from '@revolut/ui-kit'
import { Source, XChecksListItem } from 'api/types/xChecks'
import { capitalizeFirst } from 'utils/string'
import { CheckboxSelectTableFilter } from './CheckboxSelectTableFilter'
import { useApi } from '../../../hooks'

const SourceTableFilter = <Entity extends TableData>({
  column,
}: Pick<TableFilterProps<Entity>, 'column'>) => {
  const { sources } = useApi()

  const options = sources.map((source) => ({
    key: source,
    label: capitalizeFirst(source),
    value: source,
  }))

  return <CheckboxSelectTableFilter column={column} options={options} />
}

const SourceTableCell = ({ value }: { value: Source }) => (
  <Table.Cell>
    <Text>{capitalizeFirst(value)}</Text>
  </Table.Cell>
)

export const SOURCE_TABLE_CELL: TableColumn<XChecksListItem> = {
  Header: 'Source',
  accessor: 'source',
  // xChecks API does not provide sorting order functionality
  disableSortBy: true,
  Filter: ({ column }: Pick<TableFilterProps<XChecksListItem>, 'column'>) => (
    <SourceTableFilter column={column} />
  ),
  Cell: SourceTableCell,
}
