import { SelectInput, SelectInputProps, SelectOptionItemType } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { identity } from 'lodash'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { getFieldErrorState } from '../utils'
import { Fielded } from '../types'
import { MIN_SEARCH_AMOUNT } from '../consts'
import { FieldValue } from '../../../../../types'
import { useFormContext } from '../../../../FormProvider'

type Props<Value> = Fielded<
  Value,
  SelectInputProps<Value, SelectOptionItemType<Value>> & {
    setValueAs?: (value: Nullable<Value>) => FieldValue | undefined
  }
>

export const FieldSelect = <Value,>(props: Props<Value>) => {
  const { errors, setError, updateForm } = useFormContext()
  const {
    path,
    validate,
    setValueAs = identity,
    onChange: originalOnChange,
    ...rest
  } = props
  const onChange = useCallback(
    (value: Nullable<Value>) => {
      originalOnChange?.(value || null)
      const newValue = setValueAs(value)
      updateForm(path, newValue)
      setError(path, validate(newValue))
    },
    [setError, updateForm, path, validate, setValueAs, originalOnChange],
  )

  return (
    <SelectInput
      {...rest}
      searchable={rest.options.length >= MIN_SEARCH_AMOUNT}
      onChange={onChange}
      {...getFieldErrorState(errors, path)}
    />
  )
}
