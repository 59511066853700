import { Principal as TPrincipal, Review } from 'api/types/xChecks'
import { HStack, VStack, Widget } from '@revolut/ui-kit'
import { sortBy } from 'lodash'
import { getDataValue } from 'view/XChecks/XCheck/lib/utils'
import { useViews, useXCheckData } from 'view/XChecks/XCheck/lib/hooks'
import { GroupSide } from 'view/XChecks/XCheck/lib/hooks/useXCheckSide/types'
import { PrincipalContext } from 'view/XChecks/XCheck/lib/types'
import { ReviewIcon } from '../../../common/ReviewIcon'

type Props = {
  side: GroupSide
}

const getReviewDecision = ({
  reviewer,
  reviews = [],
}: {
  reviews?: Review[]
  reviewer: TPrincipal
}) => {
  return reviews.find((review) => review.author.id === reviewer.id)
}

export const GroupRuleSideBody = ({ side }: Props) => {
  const { Principal } = useViews()
  const { xCheckData } = useXCheckData()
  const reviews = getDataValue(xCheckData)?.reviews

  return (
    <Widget>
      <VStack space="s-12" p="s-16">
        {sortBy(side.group.config.reviewers, 'id').map((reviewer, idx) => {
          const review = getReviewDecision({ reviews, reviewer })
          return (
            <HStack key={idx} align="center" space="s-12">
              <ReviewIcon review={review} />
              <Principal context={PrincipalContext.reviewGroup} principal={reviewer} />
            </HStack>
          )
        })}
      </VStack>
    </Widget>
  )
}
