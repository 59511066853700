import { IconName } from '@revolut/ui-kit'

const HALLOWEEN_ICONS: IconName[] = ['Unknown', 'Ghost', 'JackOLantern']
const X_MAS_ICONS: IconName[] = ['Outdoor', 'Snowflake', 'Present', 'Gift']

// js enumerates months from 0
const OCTOBER = 9
const DECEMBER = 11

const isHalloweenDates = () => {
  const now = new Date()
  const date = now.getDate()
  const month = now.getMonth()

  return date >= 29 && month === OCTOBER
}

export const isXMasDates = () => {
  const now = new Date()
  const date = now.getDate()
  const month = now.getMonth()

  return date >= 15 && month === DECEMBER
}

const getIcon = (avatarIcons: IconName[], userId: string) => {
  // assume that digits in userId distributed uniformly
  const idNumber = userId.replaceAll(/\D/g, '').slice(-5)
  const avatarIdx = Number(idNumber) % avatarIcons.length

  return avatarIcons[avatarIdx]
}

export const getSpecialOccasionIcon = (userId: string): IconName | undefined => {
  if (isHalloweenDates()) {
    return getIcon(HALLOWEEN_ICONS, userId)
  }

  if (isXMasDates()) {
    return getIcon(X_MAS_ICONS, userId)
  }

  return undefined
}
