import { generatePath, useNavigate } from 'react-router'
import { useCallback } from 'react'
import { Url } from 'routing'
import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { FormProvider, useForm } from 'react-hook-form'
import { useQueryGroup } from 'queries/idave/groups'
import { PageHeader } from 'components/PageHeader'
import { Details } from '../GroupAdd/components/Details'
import { useEditGroup } from './useEditGroup'
import { GroupEditFormValues } from './types'

export const GroupEdit = ({ groupId }: { groupId: string }) => {
  const { data: group } = useQueryGroup(groupId)

  const navigate = useNavigate()

  const navigateToGroup = useCallback(
    () => navigate(generatePath(Url.SystemGroup, { groupId })),
    [groupId, navigate],
  )

  const editGroup = useEditGroup(groupId, navigateToGroup)

  const groupForm = useForm<GroupEditFormValues>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: {
      name: group?.name,
      description: group?.description,
      ownerId: group?.ownerIds[0],
    },
  })

  const onSubmit = useCallback(() => {
    const { ownerId, ...rest } = groupForm.getValues()
    return editGroup({
      groupId,
      data: {
        ...group!,
        ...rest,
        ownerIds: [ownerId],
      },
    })
  }, [editGroup, group, groupForm, groupId])

  return (
    <>
      <PageHeader onBack={navigateToGroup} pageTitle="Edit group" />
      <FormProvider {...groupForm}>
        <Page.Main size={PageMainSize.NARROW}>
          <Details />
          <Page.MainActions>
            <Button
              id="group-edit-save"
              variant="primary"
              elevated
              disabled={!groupForm.formState.isValid || !groupForm.formState.isDirty}
              onClick={onSubmit}
            >
              Save
            </Button>
          </Page.MainActions>
        </Page.Main>
      </FormProvider>
    </>
  )
}
