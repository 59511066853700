import { Input } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { TimestampParam } from 'api/types/xChecks'
import { timestampToDatetimeLocalWithMs } from '../../../../utils'
import { InputProps } from '../types'

export const TimestampItem = ({ value, onChange }: InputProps<TimestampParam>) => {
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      const timestamp = newValue ? new Date(newValue).getTime() : undefined
      return onChange({
        ...value,
        value: timestamp,
      })
    },
    [onChange, value],
  )

  return (
    <Input
      type="datetime-local"
      step={0.1}
      value={timestampToDatetimeLocalWithMs(value.value)}
      onChange={onInputChange}
    />
  )
}
