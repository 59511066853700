import { abbreviate, Avatar, Item, Text } from '@revolut/ui-kit'
import { UserStates } from 'api/idave/user'
import { useUserStateTooltip } from 'hooks/useUserStateTooltip'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { UnstyledLink } from 'components/Links/UnstyledLink'
import { formatLongDateTime } from 'utils/date'
import { getAvatarProps } from 'utils/common/getAvatarProps'

export type UserItemProps = {
  id?: string
  name: string
  state?: UserStates
  description?: string
  descriptionHref?: string
  href?: string
  avatarUrl?: string
  createdDate?: number
}

export const UserItem = ({
  name,
  state,
  description,
  descriptionHref,
  id,
  href,
  avatarUrl,
  createdDate,
}: UserItemProps) => {
  const itemProps = href ? { href, target: '_blank', use: 'a' as const } : undefined

  /**
   * Wrap title and description in link if item doesn't
   * behave as link itself
   */
  const userProfileLink =
    id && !href ? generatePath(Url.UserProfile, { userId: id }) : undefined
  const descriptionLink = descriptionHref && !href ? descriptionHref : undefined

  const { anchorProps, avatarProps, titleProps, tooltip } = useUserStateTooltip(
    state,
    'top-start',
  )

  const label = abbreviate(name)

  return (
    <>
      <Item {...anchorProps} {...itemProps}>
        {id && (
          <Item.Avatar>
            <UnstyledLink href={userProfileLink}>
              <Avatar
                size={40}
                {...getAvatarProps({ label, id, avatar: avatarUrl })}
                {...avatarProps}
              />
            </UnstyledLink>
          </Item.Avatar>
        )}
        <Item.Content>
          <Item.Title>
            <UnstyledLink href={userProfileLink}>
              <Text {...titleProps}>{name}</Text>
            </UnstyledLink>
          </Item.Title>
          {description && (
            <Item.Description>
              <UnstyledLink href={descriptionLink} target="_blank">
                {description}
              </UnstyledLink>
            </Item.Description>
          )}
        </Item.Content>
        {createdDate && (
          <Item.Side>
            <Item.Value variant="secondary">{formatLongDateTime(createdDate)}</Item.Value>
          </Item.Side>
        )}
      </Item>
      {tooltip}
    </>
  )
}
