import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryRoles } from 'queries/idave/roles'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import {
  RoleEditForm,
  RoleEditFormState,
  roleToRoleState,
  RoleEditFormSkeleton,
} from 'view/Roles/components/RoleEditForm'
import { FormProvider, useForm } from 'react-hook-form'
import { useGroupOwnerId } from 'hooks/useGroupOwnerId'
import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import { mergeStateAndRole } from '../utils'
import { useEditRole } from './useEditRole'

export const RoleEdit = (props: { roleId: string }) => {
  const { data: roles = [], status, fetchStatus } = useQueryRoles()
  const role = useMemo(
    () => roles.find((r) => r.id === props.roleId),
    [roles, props.roleId],
  )

  return (
    <QuerySwitch
      data={role}
      required={[{ qs: status, fs: fetchStatus }]}
      renderLoading={() => (
        <Outer roleId={props.roleId}>
          <RoleEditFormSkeleton />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer roleId={props.roleId}>
          <Inner roles={roles} role={data} roleId={props.roleId} />
        </Outer>
      )}
    />
  )
}

const Outer = ({ roleId, children }: { roleId: string; children: React.ReactNode }) => {
  const navigate = useNavigate()
  const onBackClick = useCallback(
    () => navigate(generatePath(Url.Role, { roleId })),
    [roleId, navigate],
  )

  const HeaderActions = useHeaderActions()
  return (
    <>
      <PageHeader
        onBack={onBackClick}
        actions={HeaderActions && <HeaderActions />}
        pageTitle="Edit role"
      />
      <Page.Main size={PageMainSize.NARROW}>{children}</Page.Main>
    </>
  )
}

const Inner = (props: {
  roleId: string
  roles: Role[]
  role: Role
  loading?: boolean
}) => {
  const { roleId, loading, roles, role } = props

  const getGroupOwnerId = useGroupOwnerId()

  const roleForm = useForm<RoleEditFormState>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: roleToRoleState(role),
  })

  const { mutate, isLoading: isUploading } = useEditRole()

  const isSubmitDisabled =
    isUploading || !roleForm.formState.isValid || !roleForm.formState.isDirty

  const onSubmit = useCallback(() => {
    const values = { ...roleForm.getValues() }
    const groupOwnerId = getGroupOwnerId(values.ownerGroupId)
    if (groupOwnerId === values.approverId) {
      values.approverId = undefined
    }
    mutate(mergeStateAndRole(values, role))
  }, [roleForm, mutate, role, getGroupOwnerId])

  return (
    <FormProvider {...roleForm}>
      <RoleEditForm roleId={roleId} isDisabled={loading || isUploading} roles={roles} />
      <Page.MainActions>
        <Button
          id="role-edit-save"
          variant="primary"
          elevated
          disabled={isSubmitDisabled}
          onClick={onSubmit}
          pending={isUploading}
        >
          Save
        </Button>
      </Page.MainActions>
    </FormProvider>
  )
}
