import { Table } from './Table'
import { TableItem } from './types'

type Props = {
  tables: TableItem[]
}

export const Tables = ({ tables }: Props) => (
  <>
    {tables.map((table, idx) => (
      <Table table={table} key={idx} />
    ))}
  </>
)
