import { EntityParam } from 'api/types/xChecks'
import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { SelectInput } from '@revolut/ui-kit'
import { InputProps } from '../../../../type'
import { getStandaloneLabel } from '../../../../utils'

export const EntityPlaceholder = ({ param }: InputProps<EntityParam>) => {
  const descriptor = getEntityDescriptor(param.value)
  const value = descriptor.id || descriptor.name
  return (
    <SelectInput
      disabled
      label={getStandaloneLabel(param)}
      defaultValue={value}
      options={[{ value, label: descriptor.name, key: descriptor.name }]}
    />
  )
}
