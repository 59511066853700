import { StatusText } from 'components/StatusText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { StatusCellLevels } from '@revolut/ui-kit'
import { CrossCheckValueTab } from '../../../types'
import { DiffedText } from './DiffedText'
import { BooleanValue, DetailRow } from '../types'
import { DetailEntity } from './DetailEntity'
import { DetailEntities } from './DetailEntities'
import { getIdComponent } from '../utils/getIDComponent'

export const DetailContent = (props: {
  tab: CrossCheckValueTab
  row: DetailRow
  isFinished: boolean
}) => {
  const { row, tab, isFinished } = props
  const { value, changeType } = row

  const { type } = value
  const IDComponent = getIdComponent(row)

  switch (type) {
    case 'text':
      return (
        <DiffedText
          value={value}
          tab={tab}
          isFinished={isFinished}
          changeType={changeType}
        />
      )
    case 'boolean':
      return <BooleanEntity value={value} tab={tab} />
    case 'Group':
    case 'Role':
    case 'Client':
    case 'AccessPolicy':
    case 'User':
    case 'EmployeeTeam':
    case 'EmployeeSpecialisation':
    case 'CustomerCompany':
    case 'EmployeeCompany':
    case 'TowerComponent':
    case 'ServiceAccount':
      return IDComponent ? (
        <DetailEntity
          IDComponent={IDComponent}
          value={value}
          tab={tab}
          isFinished={isFinished}
        />
      ) : null

    case 'EmployeeTypes':
    case 'ContractTypes':
    case 'Employees':
    case 'Seniorities':
    case 'Specialisations':
    case 'Teams':
    case 'Departments':
    case 'Roles':
    case 'Functions':
      return <DetailEntities value={value} tab={tab} isFinished={isFinished} />
    default:
      return null
  }
}

const BooleanEntity = (props: { tab: CrossCheckValueTab; value: BooleanValue }) => {
  const { value, tab } = props
  const { text, level } = getBooleanStatusTex(value[tab])

  return <StatusText level={level}>{text}</StatusText>
}

const getBooleanStatusTex = (value?: string) => {
  switch (value) {
    case 'True':
      return { text: 'True', level: StatusCellLevels.SUCCESS }
    case 'False':
      return { text: 'False', level: StatusCellLevels.WARNING }
    default:
      return { text: EMPTY_VALUE_FALLBACK, level: StatusCellLevels.DISABLED }
  }
}
