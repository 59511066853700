import { Box, Skeleton, Subheader } from '@revolut/ui-kit'

export const RulesSkeleton = () => {
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Approval requirements</Subheader.Title>
      </Subheader>
      <Skeleton radius="r16" height="s-72" />
    </Box>
  )
}
