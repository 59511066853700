import { Page } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useViews } from '../../hooks'
import { Title } from './components/Title'
import { Description } from './components/Description'

export const Header = ({ onBackClick }: { onBackClick: () => void }) => {
  const { isEditing, setIsEditing } = useViews()
  const onBack = useCallback(
    () => (isEditing ? setIsEditing(false) : onBackClick()),
    [isEditing, setIsEditing, onBackClick],
  )

  return (
    <Page.Header onBack={onBack} description={<Description />}>
      <Title />
    </Page.Header>
  )
}
