import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { stringifyBoolean } from 'utils/boolean/stringifyBoolean'
import { formatDateTimePrecise } from 'utils/date'
import { match, P } from 'ts-pattern'
import {
  NumberParam,
  TextParam,
  UuidParam,
  BooleanParam,
  TimestampParam,
  EntityParam,
} from 'api/types/xChecks'
import {
  getEntityDescriptor,
  valueTypeMatcher,
} from 'view/XChecks/XCheck/lib/utils/params'
import { TableEntity } from '../getEntities'

type TextAccessorCell = TextParam | UuidParam | NumberParam
export const makeTextAccessor = (cell: TextAccessorCell) => {
  const value = cell.value.value
  if (typeof value === 'undefined') {
    return EMPTY_VALUE_FALLBACK
  }
  return value
}

export const makeBooleanAccessor = (cell: BooleanParam) => {
  const value = cell.value.value
  return typeof value === 'boolean' ? stringifyBoolean(value) : EMPTY_VALUE_FALLBACK
}

export const makeEntityAccessor = (cell: EntityParam) =>
  getEntityDescriptor(cell.value).name

export const makeTimestampAccessor = (cell: TimestampParam) => {
  const value = cell.value.value
  return typeof value === 'number' ? formatDateTimePrecise(value) : EMPTY_VALUE_FALLBACK
}

export const unsupportedTypeAccessor = () => {
  return 'UNSUPPORTED TYPE'
}

export const makeTableAccessor = (name: string) => (cell: TableEntity) => {
  return match(cell.entityAttrs.get(name))
    .with(valueTypeMatcher('text'), makeTextAccessor)
    .with(valueTypeMatcher('number'), makeTextAccessor)
    .with(valueTypeMatcher('uuid'), makeTextAccessor)
    .with(valueTypeMatcher('bool'), makeBooleanAccessor)
    .with(valueTypeMatcher('timestamp'), makeTimestampAccessor)
    .with(valueTypeMatcher('entity'), makeEntityAccessor)
    .with(valueTypeMatcher('array'), unsupportedTypeAccessor)
    .with(P.nullish, () => EMPTY_VALUE_FALLBACK)
    .otherwise(unsupportedTypeAccessor)
}
