export const extendDetails = <
  Item,
  Result extends {
    details: Item[]
  },
>(
  changesView: Result,
  change: Item,
) => ({
  ...changesView,
  details: [...changesView.details, change],
})

export const extendWidgets = <
  Item,
  Result extends {
    widgets: Item[]
  },
>(
  changesView: Result,
  change: Item,
) => ({
  ...changesView,
  widgets: [...changesView.widgets, change],
})

export const extendTables = <
  Item,
  Result extends {
    tables: Item[]
  },
>(
  changesView: Result,
  change: Item,
) => ({
  ...changesView,
  tables: [...changesView.tables, change],
})

export const extendContexts = <
  Item,
  Result extends {
    entityContexts: Item[]
  },
>(
  changesView: Result,
  change: Item,
) => ({
  ...changesView,
  entityContexts: [...changesView.entityContexts, change],
})
