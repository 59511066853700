import { MoreBar } from '@revolut/ui-kit'

export const SKELETON_BUTTONS = [
  {
    label: 'Approve',
    variant: 'accent' as const,
  },
  {
    label: 'Reject',
    variant: 'negative' as const,
  },
  {
    label: 'Retry',
  },
]
export const ActionsSkeleton = () => {
  return (
    <MoreBar>
      {SKELETON_BUTTONS.map((action) => (
        <MoreBar.Action pending key={action.label} variant={action.variant}>
          {action.label}
        </MoreBar.Action>
      ))}
    </MoreBar>
  )
}
