import { createContext, useContext } from 'react'
import { NavigationSettings } from './types'
import { noop } from '../../../../lib/hooks/utils'

export type TNavigationContext = NavigationSettings

const DEFAULT = {
  getRowLink: () => '',
  onRowClick: noop,
}

export const NavigationContext = createContext<TNavigationContext>(DEFAULT)

type NavigationProviderProps = NavigationSettings & {
  children?: React.ReactNode
}

export const NavigationProvider = ({
  children,
  ...navigationSettings
}: NavigationProviderProps) => {
  return (
    <NavigationContext.Provider value={navigationSettings}>
      {children}
    </NavigationContext.Provider>
  )
}
export const useNavigation = () => useContext(NavigationContext)
