import { Button, Input, Page, PageMainSize, TextArea, VStack } from '@revolut/ui-kit'
import { PageHeader } from 'components/PageHeader'

type Props = {
  onBackClick: () => void
}

export const ToxicPairCreateSkeleton = ({ onBackClick }: Props) => {
  return (
    <>
      <PageHeader onBack={onBackClick} pageTitle="Create toxic pair" />
      <Page.Main size={PageMainSize.NARROW}>
        <VStack space="s-16">
          <Input disabled label="Toxic pair name" />
          <Input disabled label="First permission" />
          <Input disabled label="Second permission" />
          <Input disabled label="Owner" />
          <TextArea
            disabled
            rows={3}
            label="Justification (with related ticket mention)..."
          />
        </VStack>
        <Page.MainActions>
          <Button variant="primary" elevated disabled>
            Create
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
