import {
  Decision,
  Principal,
  XCheckDetails,
  Log,
  Source,
  Param,
  Changes,
} from 'api/types/xChecks'
import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { defaultApiErrorHandler, get, post } from './utils'
// import { stubParams } from './tmpStub'

export type IdPayload<T = {}> = T & {
  xCheckId: string
}

export type CommentPayload = IdPayload<{ comment: string }>
export type CommentEditPayload = IdPayload<{
  commentId: string
  comment: string
}>
export type ReviewPayload = IdPayload<{
  decision: Decision
  reason?: string
}>
export type DeclinePayload = IdPayload<{
  reason: string
}>
export type JustifyPayload = IdPayload<{
  justification: string
}>
export type AddReviewersPayload = IdPayload<{
  groupId: string
  reviewers: Principal[]
  reason: string
}>

export type SourcePayload = IdPayload<{
  source: Source
}>

export type UpdateParamsPayload = SourcePayload & {
  params: Param[]
}

export const makeService = ({
  apiPrefix,
  onXCheckApiError = defaultApiErrorHandler,
  sourcePrefixMap,
}: ApiSettings) => ({
  fetchXCheck: ({ xCheckId }: IdPayload) =>
    get<XCheckDetails>({
      apiMethod: 'fetchXCheck',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}`,
    }),

  fetchXCheckLog: ({ xCheckId }: IdPayload) =>
    get<Log[]>({
      apiMethod: 'fetchXCheckLog',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/log`,
    }),

  fetchParams: ({ xCheckId, source }: SourcePayload) =>
    //  Promise.resolve(stubParams),
    get<Param[]>({
      apiMethod: 'fetchParams',
      onXCheckApiError,
      path: `${sourcePrefixMap[source]}/cross-checks/${xCheckId}/params`,
    }),

  updateParams: ({ xCheckId, source, params }: UpdateParamsPayload) =>
    //    {
    //   console.log(params)
    //   return Promise.reject(undefined)
    // },
    post<Param[]>({
      apiMethod: 'updateParams',
      onXCheckApiError,
      path: `${sourcePrefixMap[source]}/cross-checks/${xCheckId}/params`,
      payload: params,
    }),

  fetchChanges: ({ xCheckId, source }: SourcePayload) =>
    get<Changes>({
      apiMethod: 'fetchChanges',
      onXCheckApiError,
      path: `${sourcePrefixMap[source]}/cross-checks/${xCheckId}/changes`,
    }),

  comment: ({ comment, xCheckId }: CommentPayload) =>
    post({
      apiMethod: 'comment',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/comment`,
      payload: { comment },
    }),

  requestInfo: ({ comment, xCheckId }: CommentPayload) =>
    post({
      apiMethod: 'requestInfo',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/request-info`,
      payload: { comment },
    }),

  provideInfo: ({ comment, xCheckId }: CommentPayload) =>
    post({
      apiMethod: 'provideInfo',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/provide-info`,
      payload: { comment },
    }),

  editComment: ({ comment, commentId, xCheckId }: CommentEditPayload) =>
    post({
      apiMethod: 'editComment',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/comment/${commentId}`,
      payload: { comment },
    }),

  review: ({ xCheckId, decision, reason }: ReviewPayload) =>
    post({
      apiMethod: 'review',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/review`,
      payload: { decision, reason },
    }),

  decline: ({ xCheckId, reason }: DeclinePayload) =>
    post({
      apiMethod: 'decline',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/decline`,
      payload: { reason },
    }),

  justify: ({ xCheckId, justification }: JustifyPayload) =>
    post({
      apiMethod: 'justify',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/justify`,
      payload: { justification },
    }),

  extendReviewGroup: ({ xCheckId, groupId, reason, reviewers }: AddReviewersPayload) =>
    post({
      apiMethod: 'extendReviewGroup',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/groups/${groupId}/reviewers`,
      payload: { reason, reviewers },
    }),

  retry: ({ xCheckId }: IdPayload) =>
    post({
      apiMethod: 'retry',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/retry`,
    }),

  refresh: ({ xCheckId }: IdPayload) =>
    post({
      apiMethod: 'refresh',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/${xCheckId}/refresh`,
    }),
})
