import { XCheckDetails } from 'api/types/xChecks'
import { Data, PrincipalComponentType } from 'view/XChecks/XCheck/lib/types'
import { Comments } from './components/Comments'
import { CommentForm } from './components/CommentForm'

type Props = {
  Principal: PrincipalComponentType
  xCheck: XCheckDetails
  currentUserId: Data<string>
}

export const CommentTab = ({ Principal, xCheck, currentUserId }: Props) => {
  return (
    <>
      <CommentForm xCheck={xCheck} />
      <Comments Principal={Principal} xCheck={xCheck} currentUserId={currentUserId} />
    </>
  )
}
