import { Text, TextSkeleton } from '@revolut/ui-kit'
import { useXCheckData } from '../../../hooks'

export const Title = () => {
  const { xCheckData } = useXCheckData()

  switch (xCheckData.status) {
    case 'success':
      return <Text>{xCheckData.data.name}</Text>

    case 'error':
      return <Text>Something went wrong</Text>

    case 'loading':
      return <TextSkeleton size={18} />

    default:
      return null
  }
}
