import { CrossCheckDetail } from 'api/types/crossCheck'
import { EmployeeCompany } from 'api/dart/types'
import { CustomerCompany } from 'api/dart/customerCompanies'
import {
  useQueryCustomerCompany,
  useQueryEmployeeCompanyMap,
} from 'queries/dart/companies'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { useMemo, useState } from 'react'
import { Box, Subheader, Table } from '@revolut/ui-kit'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { makeGetChangeRowState } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/utils'
import { getColumns } from './columns'
import {
  CrosscheckTabSwitcher,
  getDefaultTab,
  getSwitcherTabs,
} from '../../../../../ChangeSwitcher'
import { getAccessModifierRows, getEmptyTableLabel } from './utils'

export const EmployeeCompaniesOfCustomerCompany = ({
  crossCheck,
}: {
  crossCheck: CrossCheckDetail
}) => {
  const {
    data: customerCompany,
    status: customerCompanyS,
    fetchStatus: customerCompanyFS,
  } = useQueryCustomerCompany(crossCheck.entityId)
  const {
    data,
    status: employeeCompaniesS,
    fetchStatus: employeeCompaniesFS,
  } = useQueryEmployeeCompanyMap()

  return (
    <QuerySwitch
      required={[
        { qs: customerCompanyS, fs: customerCompanyFS },
        { qs: employeeCompaniesS, fs: employeeCompaniesFS },
      ]}
      data={data}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view employee companies" />
      )}
      renderLoading={() => <Inner crossCheck={crossCheck} />}
      renderSuccess={({ data: employeeCompaniesMap }) => (
        <Inner
          crossCheck={crossCheck}
          entity={customerCompany}
          employeeCompaniesMap={employeeCompaniesMap}
        />
      )}
    />
  )
}

const Inner = ({
  crossCheck,
  entity,
  employeeCompaniesMap = new Map(),
}: {
  crossCheck: CrossCheckDetail
  entity?: CustomerCompany
  employeeCompaniesMap?: Map<string, EmployeeCompany>
}) => {
  const tabs = useMemo(() => getSwitcherTabs(crossCheck, 'attributes'), [crossCheck])

  const { rows, counters } = useMemo(
    () =>
      getAccessModifierRows({
        crossCheck,
        entity,
        employeeCompaniesMap,
        tabs,
      }),
    [crossCheck, entity, employeeCompaniesMap, tabs],
  )

  const defaultTab = useMemo(() => getDefaultTab(tabs, counters), [counters, tabs])
  const [tab, setTab] = useState(defaultTab)
  const columns = useMemo(() => getColumns(tab, crossCheck), [tab, crossCheck])
  const getRowState = useMemo(() => makeGetChangeRowState(tab), [tab])
  const data = rows[tab]
  const isLoading = !employeeCompaniesMap.size

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>
          Employee companies
        </Subheader.Title>
        <Subheader.Side>
          <CrosscheckTabSwitcher
            tabs={tabs}
            currentTab={tab}
            counters={counters}
            onChange={setTab}
            isFinished={isFinished(crossCheck)}
          />
        </Subheader.Side>
      </Subheader>
      <Table
        columns={columns}
        data={data || []}
        loadingState={isLoading ? 'pending' : 'ready'}
        getRowState={getRowState}
        labelEmptyState={getEmptyTableLabel(tab)}
      />
    </Box>
  )
}
