import { PEOPLE_URL } from 'constants/urls'
import { useQueryTeamMap } from 'queries/sam/revoluters'
import { QuerySwitch } from 'components/QuerySwitch'
import { TextSkeleton } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { ExternalTextLink } from './ExternalTextLink'

type RevolutersTeamTabs = 'summary' | 'talent'

export const getRevolutersTeamLink = (id: string, tab: RevolutersTeamTabs = 'summary') =>
  `${PEOPLE_URL}/team/${tab}/${id}`

type Props = {
  id?: string
  withId?: boolean
}

export const RevolutersTeamLink = ({ id, withId }: Props) => {
  const { data, status: qs, fetchStatus: fs } = useQueryTeamMap()

  if (!id) {
    return <>{EMPTY_VALUE_FALLBACK}</>
  }

  const href = getRevolutersTeamLink(id)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderError={() => <ExternalTextLink href={href} text={id} />}
      renderIdle={() => <ExternalTextLink href={href} text={id} />}
      renderLoading={() => <TextSkeleton size={15} />}
      renderSuccess={({ data: teams }) => {
        const team = teams?.get(id)
        const text = withId && team ? `${team.name} (${id})` : team?.name
        return <ExternalTextLink href={href} text={text || id} />
      }}
    />
  )
}
