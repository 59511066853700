import { indexOf } from 'lodash'
import { ChangeItem, Variant } from '../../types'
import { difference } from './difference'

export const getNewTabItems = <T>(newItems: T[], oldItems: T[]): ChangeItem<T>[] => {
  const added = difference(newItems, oldItems)
  return newItems.map((value) => {
    const isAdded = indexOf(added, value) !== -1
    return { value, variant: isAdded ? Variant.added : undefined }
  })
}
