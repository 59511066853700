import { EntityParam, Param, ParamType, Value } from 'api/types/xChecks'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { match, P } from 'ts-pattern'
import { isEqual, uniqWith } from 'lodash'
import { format } from 'date-fns'
import { EntityDescriptor } from '../types'
import { EntityWellKnownAttrs, TIMESTAMP_FORMAT } from '../consts'

export const paramMatcher = <T,>(name: string, type: T) => ({
  name,
  ...valueTypeMatcher(type),
})

export const valueTypeMatcher = <T,>(type: T) => ({
  value: {
    type: { type },
  },
})

export const typeMatcher = <T,>(type: T) => ({
  type: { type },
})

const DEFAULT_DESCRIPTOR = {
  id: undefined,
  name: EMPTY_VALUE_FALLBACK_TEXT,
  entityType: 'Unknown',
  isComplexEntity: false,
}

export const getEntityDescriptor = (entity?: Value<EntityParam>) =>
  entityAttrsToDescriptor(entity?.value)

export const entityAttrsToDescriptor = (attrs: Param[] = []) =>
  attrs.reduce<EntityDescriptor>((acc, attr) => {
    return match(attr)
      .with(paramMatcher(EntityWellKnownAttrs.id, 'text'), ({ value }) => ({
        ...acc,
        id: value.value || acc.id,
      }))
      .with(paramMatcher(EntityWellKnownAttrs.name, 'text'), ({ value }) => ({
        ...acc,
        name: value.value || acc.name,
      }))
      .with(paramMatcher(EntityWellKnownAttrs.entityType, 'text'), ({ value }) => ({
        ...acc,
        entityType: value.value || acc.entityType,
      }))
      .otherwise(() => ({ ...acc, isComplexEntity: true }))
  }, DEFAULT_DESCRIPTOR)

type ParamMatcher = {
  name: string
  value: {
    type: {
      type: ParamType['type']
    }
  }
}

const containsKnownParams = (params: Param[], matchers: ParamMatcher[]) => {
  if (params.length > matchers.length) {
    return false
  }

  return params.every((param) =>
    matchers.find((matcher) =>
      match(param)
        .with(matcher, () => true)
        .otherwise(() => false),
    ),
  )
}

export const isEntityWellKnownAttr = (name: string) => name in EntityWellKnownAttrs

export const entityParamsMatches = (
  entity: Value<EntityParam>,
  ...matchers: ParamMatcher[]
) => {
  const entityAttrs = entity.value || []
  const entityMatchers = uniqWith(
    [
      ...Object.values(EntityWellKnownAttrs).map((attr) =>
        paramMatcher(attr, 'text' as const),
      ),
      ...matchers,
    ],
    isEqual,
  )

  return containsKnownParams(entityAttrs, entityMatchers)
}

export const searchParam = <T extends Param = Param>(
  params: Param[],
  name: string,
  type: ParamType['type'],
) =>
  params.find<T>((param): param is T =>
    match(param)
      .with(paramMatcher(name, type), () => true)
      .otherwise(() => false),
  )

export const CHANGE_PATTERN = {
  oldValue: P.nonNullable,
  newValue: P.nonNullable,
}

export const ARRAY_CHANGE_PATTERN = P.union(
  {
    oldValue: {
      type: { type: 'array' as const },
    },
  },
  {
    newValue: {
      type: { type: 'array' as const },
    },
  },
)

export const ENTITY_CHANGE_PATTERN = P.union(
  {
    oldValue: {
      type: { type: 'entity' as const },
    },
  },
  {
    newValue: {
      type: { type: 'entity' as const },
    },
  },
)

export const ARRAY_CHANGE_LIST_PATTERN = P.union(
  {
    oldValue: {
      type: { type: 'array' as const, arrayType: 'list' },
    },
  },
  {
    newValue: {
      type: { type: 'array' as const, arrayType: 'list' },
    },
  },
)

export const ARRAY_ENTITY_CHANGE_PATTERN = P.union({
  oldValue: {
    type: { type: 'array' as const, subType: { type: 'entity' as const } },
  },
  newValue: {
    type: { type: 'array' as const, subType: { type: 'entity' as const } },
  },
})

export const formatTimestamp = (timestamp: number) =>
  format(timestamp, TIMESTAMP_FORMAT).replace(/(:00\.000|\.000)$/, '')
