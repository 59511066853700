import { State } from 'api/types/xChecks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GetActionsParams, XCheckActionSettings } from '../types'

export const getJustifyAction = ({
  xCheck,
  xCheckId,
  userId,
  hasXCheckPermission,
  onSubmit,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const isRequester = xCheck && 'id' in xCheck.requester && xCheck.requester.id === userId

  if (
    !userId ||
    !xCheck ||
    !isRequester ||
    xCheck.state !== State.Requested ||
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_JUSTIFY)
  ) {
    return undefined
  }

  return {
    onSubmit,
    xCheckId,
    actionType: 'justify',
    autoTrigger: true,
    popupSettings: {
      title: 'Justify request',
      description:
        "Please provide a reason for changes you made. Justification can't be edited later, so ensure your reason is clear and accurate.",
      primaryActionLabel: 'Justify',
      primaryActionVariant: 'primary',
      textAreaLabel: 'Justification',
      isTextRequired: true,
    },
    actionSettings: {
      label: 'Justify',
      useIcon: 'Statement',
      variant: 'accent',
    },
  }
}
