import { Page, PageMainSize } from '@revolut/ui-kit'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import {
  getActiveTabs,
  isBackwardDisabled,
  isForwardDisabled,
  isLastTab,
  getNextTabIfActive,
  getPrevTabIfPresents,
  getCheckedTabs,
} from './utils'
import { FormMultiStepTabs, Tab } from './FormMultiStepTabs'
import { FormMultiStepNavigation } from './FormMultiStepNavigation'

interface FormMultiStepProps<TabName extends string> {
  tabs: Tab<TabName>[]
  title: string
  submitting?: boolean
  loading?: boolean
  onCloseClick: () => void
  onSubmit: () => void
  renderTab: (tab: TabName) => ReactNode
  checkTabValid: (currentTab: TabName) => boolean
  checkTabFilled: (currentTab: TabName) => boolean
  tabToPageSize: (tab: TabName) => PageMainSize
}

export const FormMultiStep = <TabName extends string>(
  props: FormMultiStepProps<TabName>,
) => {
  const {
    title,
    tabs,
    submitting,
    loading,
    onCloseClick,
    checkTabFilled,
    checkTabValid,
    onSubmit,
    renderTab,
    tabToPageSize,
  } = props

  const [currentTab, setCurrentTab] = useState<TabName>(tabs[0]?.value)
  const [visitedTabs, setVisitedTabs] = useState<TabName[]>([tabs[0]?.value])
  const checkedTabs = getCheckedTabs(tabs, visitedTabs, checkTabFilled, checkTabValid)

  const activeTabs = useMemo(() => getActiveTabs(tabs, checkedTabs), [tabs, checkedTabs])

  const setTab = useCallback(
    (tab: TabName) => {
      setCurrentTab(tab)
      setVisitedTabs((alredyVisited) =>
        alredyVisited.includes(tab) ? alredyVisited : [...alredyVisited, tab],
      )
    },
    [setCurrentTab],
  )

  const onForwardClick = useCallback(() => {
    if (isLastTab(tabs, currentTab)) {
      onSubmit()
    }

    const nextTab = getNextTabIfActive(tabs, activeTabs, currentTab)
    if (nextTab) {
      setTab(nextTab)
    }
  }, [setTab, onSubmit, tabs, currentTab, activeTabs])

  const onBackwardClick = useCallback(() => {
    const prevTab = getPrevTabIfPresents(tabs, currentTab)
    if (prevTab) {
      setTab(prevTab)
    }
  }, [setTab, tabs, currentTab])

  const labelForward = isLastTab(tabs, currentTab) ? 'Complete' : 'Continue'
  const HeaderActions = useHeaderActions()

  return (
    <>
      <PageHeader
        onBack={onCloseClick}
        actions={HeaderActions && <HeaderActions />}
        pageTitle={title}
      />
      <Page.Tabs>
        <FormMultiStepTabs
          activeTab={currentTab}
          tabs={tabs}
          onTabClick={setTab}
          availableTabs={activeTabs}
          checkedTabs={checkedTabs}
        />
      </Page.Tabs>
      <Page.Main size={tabToPageSize(currentTab)}>
        {renderTab(currentTab)}
        <FormMultiStepNavigation
          disabledBackward={isBackwardDisabled(tabs, currentTab)}
          disabledForward={isForwardDisabled(tabs, checkedTabs, currentTab)}
          labelForward={labelForward}
          onBackwardClick={onBackwardClick}
          onForwardClick={onForwardClick}
          pending={submitting || loading}
        />
      </Page.Main>
    </>
  )
}
