import dartApi from 'api/dart'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useToasts } from 'hooks/useToasts'
import { useEditPopup } from 'hooks/useEditPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { DART_PERMISSIONS } from 'security'
import { DeleteRestrictionPayload, UnblockEmployeePayload } from 'api/dart/employee'
import { JustificationPopup } from 'components/JustificationPopup'
import { UserRestrictionCreate } from './components/UserRestrictionCreate'
import { EmployeeModifier } from './types'

export const useCreateRestriction = ({
  employeeId,
  onSuccess,
}: {
  employeeId?: string
  onSuccess: () => void
}) => {
  const { setPopup } = useEditPopup()
  const { showSuccessToast } = useToasts()

  const onCreateRestriction = useCallback(
    () =>
      setPopup({
        content: (
          <UserRestrictionCreate
            employeeId={employeeId}
            showSuccessToast={showSuccessToast}
            onSuccess={onSuccess}
          />
        ),
        title: 'Add user restriction',
      }),
    [employeeId, onSuccess, setPopup, showSuccessToast],
  )

  return { onCreateRestriction }
}

export const useDeleteRestriction = (onSuccess: () => void) => {
  const { showSuccessToast } = useToasts()
  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()

  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { isCrossCheck: isDeleteCrossCheck, toCrosscheck: toDeleteCrossCheck } =
    useCrossCheck(
      DART_PERMISSIONS.EMPLOYEES_DELETE_RESTRICTIONS,
      DART_PERMISSIONS.EMPLOYEES_DELETE_RESTRICTIONS_CROSS_CHECK,
    )

  const { mutate: deleteRestriction } = useMutation({
    mutationFn: (params: { employeeId: string; payload: DeleteRestrictionPayload }) =>
      dartApi.employee.deleteRestriction(params.employeeId, {
        payload: params.payload,
        crossCheck: isDeleteCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isDeleteCrossCheck) {
        toDeleteCrossCheck(crosscheck)
      } else {
        showSuccessToast('Restriction deleted')
        onSuccess()
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      closeEditPopup()
      showErrorPopup({
        title: 'Restriction deletion failed',
        error: err,
      })
    },
  })

  const { mutate: unblock } = useMutation({
    mutationFn: (params: { employeeId: string; payload: UnblockEmployeePayload }) =>
      dartApi.employee.unblock(params),
    onMutate: () => showLoadingPopup({ title: 'Unblocking...' }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isDeleteCrossCheck) {
        toDeleteCrossCheck(crosscheck)
      } else {
        showSuccessToast('Block deleted')
        onSuccess()
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      closeEditPopup()
      showErrorPopup({
        title: 'Block deletion failed',
        error: err,
      })
    },
  })

  const makeOnSubmit = useCallback(
    (employeeModifier: EmployeeModifier) => {
      return (justification?: string) => {
        if (
          employeeModifier.type === 'restrictedRelation' &&
          employeeModifier.customerId
        ) {
          return deleteRestriction({
            employeeId: employeeModifier.employeeId,
            payload: {
              justification,
              userIds: [employeeModifier.customerId],
            },
          })
        }

        if (employeeModifier.type === 'blockedEmployee') {
          return unblock({
            employeeId: employeeModifier.employeeId,
            payload: {
              justification,
            },
          })
        }

        return undefined
      }
    },

    [deleteRestriction, unblock],
  )

  const onDeleteRestriction = useCallback(
    (employeeModifier: EmployeeModifier) => {
      setEditPopup(
        getJustificationPopup(employeeModifier, makeOnSubmit(employeeModifier)),
      )
    },
    [setEditPopup, makeOnSubmit],
  )

  return { onDeleteRestriction }
}

const getPopupSettings = (employeeModifier: EmployeeModifier) => {
  if (employeeModifier.type === 'restrictedRelation') {
    return {
      title: 'Are you sure you want to delete the access restriction?',
      description: 'Employee will get access to the customer by companies setting.',
      submitButtonLabel: 'Delete restriction',
    }
  }

  return {
    title: 'Are you sure you want to unblock employee?',
    description: 'Employee will get all access to customers he had before blocking.',
    submitButtonLabel: 'Unblock',
  }
}

export const getJustificationPopup = (
  employeeModifier: EmployeeModifier,
  onSubmit: (justification?: string) => void,
) => {
  const { submitButtonLabel, ...popupSettings } = getPopupSettings(employeeModifier)
  return {
    ...popupSettings,
    isBottomSheet: true,
    content: (
      <JustificationPopup onSubmit={onSubmit} submitButtonLabel={submitButtonLabel} />
    ),
  }
}
