import { State } from 'api/types/xChecks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GetActionsParams, XCheckActionSettings } from '../types'

export const getRetryAction = ({
  xCheck,
  xCheckId,
  hasXCheckPermission,
  onSubmit,
}: GetActionsParams): XCheckActionSettings | undefined => {
  if (
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_RETRY) ||
    !xCheck ||
    xCheck.state !== State.Approved
  ) {
    return undefined
  }

  return {
    onSubmit,
    actionType: 'retry',
    xCheckId,
    actionSettings: {
      label: 'Retry',
      useIcon: 'Retry',
    },
  }
}
