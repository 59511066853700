import { Role } from 'api/idave/roles'
import { ToxicPair } from 'api/idave/toxic'
import { mapify } from 'utils/array'
import { Permission } from 'api/idave/permissions'
import { uniq } from 'lodash'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { getPermissionToxicPairsTable } from 'view/Permissions/utils'
import { PageMainSize, Token } from '@revolut/ui-kit'
import { getRolePermissions } from './components/RolePermissions/utils'
import { RoleEditFormState } from '../components/RoleEditForm'
import { RoleTab } from './Role'

export const hasToxicPermissions = (params: {
  role?: Role
  roles: Role[]
  permissions: Permission[]
  toxicPermissions: ToxicPair[]
}) => {
  const { role, roles, permissions, toxicPermissions } = params
  if (!role) {
    return false
  }

  const roleMap = mapify(roles, (r) => r.id)
  const permissionMap = mapify(permissions, (p) => p.id)

  const rolePermissions = getRolePermissions({
    role,
    roleMap,
    permissionMap,
  })

  const permissionIds = uniq(rolePermissions.map((i) => i.permissionId))
  const permissionPresenceMap = toPresenceMap(permissionIds)

  return !!toxicPermissions
    .filter((toxicPair) => toxicPair.state === 'ACTIVE')
    .find(
      (toxic) =>
        permissionPresenceMap[toxic.firstPermission] &&
        permissionPresenceMap[toxic.secondPermission],
    )
}

export const normalizeRole = (role: Role) => {
  const { id, description, name, permissions, ownerGroupId, client, parent, approvers } =
    role
  const response = {
    id,
    description,
    name,
    permissions,
    ownerGroupId,
    client,
    parent,
  }

  if (approvers?.length) {
    return { ...response, approvers }
  }

  return response
}

export const mergeStateAndRole = (roleState: RoleEditFormState, role: Role) => {
  return {
    ...normalizeRole(role),
    name: roleState.name.trim(),
    description: roleState.description.trim(),
    ownerGroupId: roleState.ownerGroupId,
    parent: roleState.parent,
    approvers: roleState.approverId ? ([roleState.approverId] as [string]) : undefined,
  }
}

export const isRoleDetailsFilled = (roleState: RoleEditFormState) => {
  const { name, ownerGroupId, description } = roleState
  return !!name?.trim() && !!ownerGroupId && !!description
}

export const makeGetToxicRowState = <Entity extends { id: string }>(
  permissionIds: string[],
  toxicPermissions: ToxicPair[],
) => {
  const permissionToxicTable = getPermissionToxicPairsTable(toxicPermissions)
  const permissionHash = toPresenceMap(permissionIds)

  return ({ id }: Entity) => {
    const possiblePairs = permissionToxicTable[id]
    if (!possiblePairs?.length) {
      return {}
    }

    if (possiblePairs.some((permissionId) => permissionHash[permissionId])) {
      return {
        indicatorColor: Token.color.red,
      }
    }

    return {}
  }
}

export const makeStaticGetToxicRowState = <Entity extends { permissionId: string }>(
  toxicItems: ToxicPair[],
) => {
  const toxicPermissionsPresenceMap = toPresenceMap(
    toxicItems.flatMap(({ firstPermission, secondPermission }) => [
      firstPermission,
      secondPermission,
    ]),
  )

  return (params: { value: Entity }) => {
    const { permissionId } = params.value

    return toxicPermissionsPresenceMap[permissionId]
      ? {
          indicatorColor: Token.color.red,
        }
      : {}
  }
}

export const tabToPageSize = (tab: RoleTab) => {
  switch (tab) {
    case 'auditLog':
    case 'permissions':
    case 'policies':
    case 'users':
      return PageMainSize.FULL

    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
