import { BottomSheet, Button } from '@revolut/ui-kit'
import { Group, GroupType } from 'api/idave/groups'
import { Resource } from 'api/sam/types/resources'
import { GroupSelect } from 'components/Selects/GroupSelect'
import { useCallback, useState } from 'react'

type Props = {
  submit: (ownerGroupId?: string) => void
  resource: Resource
}

const GROUP_TYPES: GroupType[] = ['peopleops:team']

export const ResourceOwnerSetPopup = ({ submit, resource }: Props) => {
  const [newOwner, setNewOwner] = useState(resource.ownerGroupId)
  const isSubmitDisabled = newOwner === resource.ownerGroupId
  const onChange = useCallback(
    (group?: Group | null) => {
      setNewOwner(group?.id)
    },
    [setNewOwner],
  )
  const onSubmit = useCallback(() => {
    submit(newOwner)
  }, [newOwner, submit])

  return (
    <>
      <GroupSelect
        itemId={newOwner}
        useGroupTypes={GROUP_TYPES}
        label="Resource owner group"
        onChange={onChange}
        clearable
      />

      <BottomSheet.Actions>
        <Button elevated disabled={isSubmitDisabled} onClick={onSubmit}>
          Change owner group
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
