import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'api/sam'
import { SamMutatePolicyError } from 'api/sam/policies'
import { AxiosError } from 'axios'
import { QueryKey } from 'helpers/configQuery'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { useToasts } from 'hooks/useToasts'
import { SAM_PERMISSIONS } from 'security'
import { useCallback } from 'react'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useNavigateToPolicy } from 'hooks/useNavigateToPolicy'
import { getCrossCheckDescriptor } from 'helpers/utils'

export const usePolicyDelete = (policyId: string) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { closePopup } = useConfirmationPopup()
  const { isDirect, toCrosscheck } = useCrossCheck(
    SAM_PERMISSIONS.POLICIES_DELETE,
    SAM_PERMISSIONS.POLICIES_DELETE_WITH_CROSS_CHECK,
  )
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const reloadAndNavigateToPolicy = useNavigateToPolicy()

  const deleteMutation = useMutation({
    mutationFn: () => api.policies.deletePolicy(policyId),
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: () => {
      hideLoadingPopup()
      queryClient.refetchQueries([QueryKey.SamPolicies])
      queryClient.removeQueries([QueryKey.SamPolicy, policyId])
      closePopup()
      showSuccessToast('Policy successfully deleted')
      navigate(Url.SamPolicies)
    },
    onError: (error: AxiosError<SamMutatePolicyError>) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({ title: 'Policy deletion failed', error })
    },
  })

  const crossCheckMutation = useMutation({
    mutationFn: () => api.policies.crossCheckDeletePolicy(policyId),
    onMutate: () => showLoadingPopup({ title: 'Creating cross-check...' }),
    onSuccess: (response) => {
      if (response.data.crossCheckId) {
        hideLoadingPopup()
        closePopup()
        const crosscheck = getCrossCheckDescriptor(response)
        const data = response.data
        const crossCheckDescriptor = {
          id: data.crossCheckId || crosscheck.id,
          version: crosscheck.version,
        }
        toCrosscheck(crossCheckDescriptor)
      } else {
        reloadAndNavigateToPolicy(policyId)
        showSuccessToast('Policy edited')
      }
    },
    onError: (error: AxiosError<SamMutatePolicyError>) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Policy deletion cross-check creation failed',
        error,
      })
    },
  })

  const mutate = useCallback(() => {
    isDirect ? deleteMutation.mutate() : crossCheckMutation.mutate()
  }, [deleteMutation, crossCheckMutation, isDirect])

  const isLoading = isDirect ? deleteMutation.isLoading : crossCheckMutation.isLoading

  return {
    deletePolicy: mutate,
    isLoading,
  }
}
