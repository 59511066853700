import { createContext, useContext, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Changes } from 'api/types/xChecks'
import { useXCheckData } from './useXCheckData'
import { useApi } from './useAPI'
import { useXCheckSecurity } from './useXCheckSecurity'
import { getDataValue, isFinished, useQueryData } from '../utils'
import { Data } from '../types'

export const CHANGES_QUERY_KEY = 'CHANGES_QUERY_KEY'
const REQUEST_CHANGES_PERMISSION = 'cross_checks.view.changes.list'

export type ChangesData = Data<Changes>
export const ChangesContext = createContext<ChangesData>({ status: 'loading' })

export const useChanges = () => useContext(ChangesContext)

export const ChangesProvider = ({ children }: { children: React.ReactNode }) => {
  const { fetchChanges, sourceServiceMap } = useApi()
  const { hasPermission } = useXCheckSecurity()
  const { xCheckData, xCheckId } = useXCheckData()

  const xCheck = getDataValue(xCheckData)
  const source = xCheck?.source
  const enabled =
    xCheck &&
    !isFinished(xCheck) &&
    source &&
    hasPermission({
      service: sourceServiceMap[source],
      value: REQUEST_CHANGES_PERMISSION,
    })

  const changesQuery = useQuery({
    queryKey: [CHANGES_QUERY_KEY, xCheckId, enabled, source],
    queryFn: () => (source ? fetchChanges({ xCheckId, source }) : Promise.reject()),
    enabled,
  })

  const changesData = useQueryData(changesQuery)
  const contextValue = useMemo(
    () =>
      xCheckData.status === 'success' ? changesData : { status: 'loading' as const },
    [changesData, xCheckData],
  )

  return (
    <ChangesContext.Provider value={contextValue}>{children}</ChangesContext.Provider>
  )
}
