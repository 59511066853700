import { Box, ItemSkeleton } from '@revolut/ui-kit'
import { times } from 'utils/array/times'

export const UserListSkeleton = () => {
  return (
    <>
      {times(
        (idx) => (
          <Box pl="s-48" key={idx}>
            <ItemSkeleton />
          </Box>
        ),
        3,
      )}
    </>
  )
}
