import { SamPolicySubjectType } from 'api/sam/policies'
import { PolicyDetailsSubject, SamPolicySubjectField } from '../types'

export const isSingleField = (
  field: SamPolicySubjectField,
  enabledMultiSubject: boolean,
  subjectType?: SamPolicySubjectType,
) => {
  if (enabledMultiSubject) return false

  return (
    (field === 'departmentIds' && subjectType === SamPolicySubjectType.Department) ||
    (field === 'teamIds' && subjectType === SamPolicySubjectType.Team) ||
    (field === 'employeeTypes' && subjectType === SamPolicySubjectType.EmployeeType) ||
    (field === 'specialisationIds' &&
      subjectType === SamPolicySubjectType.Specialisation) ||
    (field === 'employeeIds' && subjectType === SamPolicySubjectType.Employee) ||
    (field === 'positionIds' && subjectType === SamPolicySubjectType.Role) ||
    (field === 'functionIds' && subjectType === SamPolicySubjectType.Function)
  )
}

export const getEmployeeTypeForContractType = (subject?: PolicyDetailsSubject) => {
  if (
    subject &&
    (subject.subjectType === SamPolicySubjectType.Team ||
      subject.subjectType === SamPolicySubjectType.EmployeeType ||
      subject.subjectType === SamPolicySubjectType.Specialisation) &&
    subject.employeeTypes.length === 1
  ) {
    return subject.employeeTypes[0]
  }

  return undefined
}

export * from './name'
export * from './rules'
export * from './fields'
