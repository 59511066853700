import { createEnumMap } from 'utils/types/createEnumMap'
import { ValueOf } from 'utils/types/valueOf'

export enum ResourceType {
  IdaveRole = 'idave_role',
  GoogleGroup = 'google_group',
  TeamCityGroup = 'teamcity_group',
  MetabaseGroup = 'metabase_group',
  LookerGroup = 'looker_group',
  TestFlight = 'testflight_beta_group',
  AppStoreUserRole = 'app_store_user_role',
  AppStoreApp = 'app_store_app',
  AppStoreUserProperty = 'app_store_user_property',
  OktaGroup = 'okta_group',
  DartAllowCustomerCompany = 'dart_allow_customer_company',
  RiskPlatformAccess = 'risk_platform_access',
  ZeusRole = 'zeus_role',
}

export type Resource = {
  resourceId: string
  externalId: string
  resourceType: ResourceType
  resourceName: string
  ownerGroupId?: string
  regions?: string[]
}

export const AccessState = createEnumMap('ACTIVE', 'REVOKED', 'EXPIRED')
export type AccessState = ValueOf<typeof AccessState>
export type Access = {
  createdAt: string
  crossCheckId: string
  expirationDate: string
  id: string
  state: AccessState
}

/**
 * represents provided for a user resource access
 * with related request reference, current status & timings
 * */
export type ResourceAccess = {
  resource: Resource
  access: Access
}

/**
 * represents available for a user
 * resource to request by provided
 * justification, jira_ticket and userId
 * */
export type AvailableResource = {
  resource: Resource
  maximalDuration: number
  available: boolean
}
