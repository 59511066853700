import { XCheckDetails } from 'api/types/xChecks'
import { ExpandableCell, List } from '@revolut/ui-kit'
import { Data, EntityComponentType, EntityContext } from '../../../types'

type Props = {
  xCheck: Data<XCheckDetails>
  Entity: EntityComponentType
}

export const Entities = ({ xCheck, Entity }: Props) => {
  if (xCheck.status === 'loading') {
    return null
  }

  if (xCheck.status === 'error') {
    return null
  }

  if (!xCheck.data.entities.length) {
    return null
  }

  return (
    <ExpandableCell variant="header" prefersExpanded={xCheck.data.entities.length > 1}>
      <ExpandableCell.Title>Entities</ExpandableCell.Title>
      <ExpandableCell.Note>
        <List variant="compact" space="s-16">
          {xCheck.data.entities.map(({ entityName, entityType, id, entityId }) => {
            const descriptor = {
              id: entityId,
              name: entityName,
              entityType,
              isComplexEntity: false,
            }

            return (
              <List.Item key={id}>
                <Entity descriptor={descriptor} context={EntityContext.entityList} />
              </List.Item>
            )
          })}
        </List>
      </ExpandableCell.Note>
    </ExpandableCell>
  )
}
