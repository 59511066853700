import { StatusWidget, VStack, ActionButton } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { NavigateToRequests } from './NavigateToRequests'

export const EmptyUserResources = (props: {
  idaveUserId: string
  onRequestClick: () => void
  requestAllowed: boolean
}) => {
  const { idaveUserId, onRequestClick, requestAllowed } = props

  return (
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D299', 'v2')} size={104} />
      <StatusWidget.Title role="heading">
        No resources temporarily assigned
      </StatusWidget.Title>
      <StatusWidget.Description>
        There are currently no resources temporarily assigned. Assign resources to see
        them listed here.
      </StatusWidget.Description>
      <StatusWidget.Actions>
        <VStack align="center" gap="s-12">
          {requestAllowed ? (
            <ActionButton onClick={onRequestClick} useIcon="Plus">
              Request resource access
            </ActionButton>
          ) : null}
          <NavigateToRequests idaveUserId={idaveUserId} variant="action" />
        </VStack>
      </StatusWidget.Actions>
    </StatusWidget>
  )
}
