import { CountingReviewRule, ReviewRule } from 'api/types/xChecks'
import { Text } from '@revolut/ui-kit'
import { pluralForm } from 'utils/string'
import { RuleItem } from './RuleItem'
import { getParentRulePrefix } from '../utils'

type Props = {
  rule: CountingReviewRule
  parentRule?: ReviewRule
  isFirst?: boolean
}

export const CountingRule = ({ rule, parentRule, isFirst }: Props) => {
  return (
    <RuleItem rule={rule}>
      <Text variant="secondary">
        {getParentRulePrefix(parentRule, isFirst)}{' '}
        {pluralForm(rule.config.approvals, [
          `at least ${rule.config.approvals} approval`,
          `at least ${rule.config.approvals} approvals`,
        ])}
      </Text>
    </RuleItem>
  )
}
