import { Table as KitTable } from '@revolut/ui-kit'
import { useViews } from 'view/XChecks/XCheck/lib/hooks'

import { useTableInfo, getRowState } from './utils'
import { TableItem } from '../types'
import { ChangeSubheaderWrapper } from '../../ChangeSubheaderWrapper'
import { hasSomeChanges as hasChanges } from '../../../../utils'
import { getCounters } from '../utils'
import { ChangeViewType } from '../../../../types'

type Props = {
  table: TableItem
}

export const Table = ({ table }: Props) => (
  <ChangeSubheaderWrapper
    title={table.name}
    showViewSwitcher={hasChanges(table)}
    renderComponent={({ view }) => <Inner view={view} table={table} />}
    counters={getCounters(table)}
  />
)

const Inner = ({ view, table }: { view?: ChangeViewType; table: TableItem }) => {
  const { Entity } = useViews()
  const { columns, entities } = useTableInfo(table, Entity, view)

  return <KitTable columns={columns} data={entities} getRowState={getRowState} />
}
