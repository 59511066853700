import { Resource } from 'api/sam/types/resources'
import { CrossCheckDetail, CrossCheckResourceAccess } from 'api/types/crossCheck'
import { notNullableMap } from 'utils/array'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'
import { ResourceRow } from './types'

type GetResourceRowsInfoParams = {
  crossCheck: CrossCheckDetail
  resourceMap: Map<string, Resource>
}
export const getResourceRowsInfo = (params: GetResourceRowsInfoParams) => {
  const { crossCheck, resourceMap } = params

  const isValidExecutable = crossCheck.executableName === 'RequestTemporaryResourceAccess'

  if (!isValidExecutable) {
    return []
  }

  return notNullableMap<string | CrossCheckResourceAccess, ResourceRow>(
    crossCheck.executableParams.resources || [],
    (resource) => {
      if (typeof resource === 'string') {
        return undefined
      }

      const resourceItem = resourceMap.get(resource.resource_id)

      return {
        duration: resource.days_requested,
        id: resource.resource_id,
        resourceName: resourceItem?.resourceName || resource.resource_id,
        regions: resourceItem?.regions || [],
        resourceType: resourceItem?.resourceType
          ? getResourceTypeLabel(resourceItem?.resourceType)
          : 'Unknown',
      }
    },
  )
}
