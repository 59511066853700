import { Change } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { ARRAY_CHANGE_PATTERN } from 'view/XChecks/XCheck/lib/utils'
import { ChangeViewType } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { SingleChangeItem } from './components/SingleChangeItem'
import { ArrayChangeItem } from './components/ArrayChangeItem'

export const DetailChangeItem = ({
  change,
  view = ChangeViewType.diff,
}: {
  change: Change
  view?: ChangeViewType
}) =>
  match({ change, view })
    .with({ change: ARRAY_CHANGE_PATTERN }, ArrayChangeItem)
    .otherwise(SingleChangeItem)
