import { notNullable } from 'utils/common'
import { hashItem } from './hashItem'

export const difference = <T>(arrayA: T[], arrayB: T[]): T[] => {
  const map = arrayA.reduce<Record<string, T | undefined>>((acc, item) => {
    acc[hashItem(item)] = item
    return acc
  }, {})

  arrayB.forEach((item) => {
    map[hashItem(item)] = undefined
  })

  return Object.values(map).filter(notNullable)
}
