import { ArrayChange, ArrayParam, EntityParam, Param, Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import {
  ARRAY_CHANGE_PATTERN,
  getEntityDescriptor,
  typeMatcher,
  valueTypeMatcher,
} from 'view/XChecks/XCheck/lib/utils/params'
import { EntityDescriptor } from 'view/XChecks/XCheck/lib/types'
import { mapify } from 'utils/array'
import { ChangeViewType, Variant } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { getArrayChangeViewItems } from 'view/XChecks/XCheck/lib/components/Updates/utils'
import { TableItem } from '../../types'

export type TableEntity = {
  variant?: Variant
  entity: Value<EntityParam>
  descriptor: EntityDescriptor
  entityAttrs: Map<string, Param>
}

export const getEntities = (
  table: TableItem,
  view: ChangeViewType = ChangeViewType.diff,
): TableEntity[] =>
  match(table)
    .with(valueTypeMatcher('array'), getTableEntities)
    .with(ARRAY_CHANGE_PATTERN, (change) => getTableChangeEntities({ change, view }))
    .otherwise(() => [])

const getTableEntities = (arrayParam: ArrayParam) =>
  (arrayParam.value.value || []).reduce<TableEntity[]>(
    (acc, item) =>
      match(item)
        .with(typeMatcher('entity'), (entity) => [
          ...acc,
          {
            entity,
            descriptor: getEntityDescriptor(entity),
            entityAttrs: mapify(entity.value, (param) => param.name),
          },
        ])
        .otherwise(() => acc),
    [],
  )

const getTableChangeEntities = ({
  change,
  view,
}: {
  change: ArrayChange
  view: ChangeViewType
}): TableEntity[] =>
  getArrayChangeViewItems({ change, view }).reduce<TableEntity[]>(
    (acc, item) =>
      match(item)
        .with(valueTypeMatcher('entity'), (entityItem) => [
          ...acc,
          {
            entity: entityItem.value,
            variant: entityItem.variant,
            entityAttrs: mapify(entityItem.value.value, (param) => param.name),
            descriptor: getEntityDescriptor(entityItem.value),
          },
        ])
        .otherwise(() => acc),
    [],
  )
