import { BooleanParam } from 'api/types/xChecks'
import { StatusText } from 'components/StatusText'
import { Text } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { stringifyBoolean } from 'utils/boolean'
import { getVariantTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { ItemValueProps } from './types'

export const BooleanValue = ({ value, variant }: ItemValueProps<BooleanParam>) => {
  const booleanValue = value?.value
  const textStyle = getVariantTextStyle(variant)

  return typeof booleanValue === 'boolean' ? (
    <StatusText
      fontSize="caption"
      level={booleanValue ? 'success' : 'alert'}
      {...textStyle}
    >
      {stringifyBoolean(booleanValue)}
    </StatusText>
  ) : (
    <Text {...textStyle}>{EMPTY_VALUE_FALLBACK_TEXT}</Text>
  )
}
