import { SelectItemFilter } from 'components/SelectItemFilter'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'
import { notNullable } from 'utils/common'
import { MutableRefObject, useMemo } from 'react'
import { useRegionsColumn } from 'components/RegionsTableCell'
import { Row } from './types'
import { useEditDurationColumn } from './components/EditDurationCell'

export const useColumns = (
  entities: Row[],
  durationsRef: MutableRefObject<Record<string, number>>,
) => {
  const regionsColumn = useRegionsColumn(entities)
  const durationColumn = useEditDurationColumn(durationsRef)

  return useMemo(
    () =>
      [
        {
          Header: 'Name',
          id: 'name',
          accessor: (v: Row) => v.resource.resourceName,
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        },
        {
          Header: 'Type',
          id: 'type',
          accessor: (v: Row) => getResourceTypeLabel(v.resource.resourceType),
          filter: 'includesValue' as const,
          align: 'center' as const,
          Filter: SelectItemFilter,
        },
        regionsColumn,
        durationColumn,
      ].filter(notNullable),
    [regionsColumn, durationColumn],
  )
}
