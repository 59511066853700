import { Navigate } from 'react-router-dom'
import { Page } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { useQueryClientByScope } from 'queries/idave/clients'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { NoAccessPage } from 'components/NoAccessWidget'

type Props = {
  scope: string
}
export const ClientScope = ({ scope }: Props) => {
  const { data, status, fetchStatus } = useQueryClientByScope(scope)

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={data}
      renderLoading={() => (
        <Page.Main size="wide">
          <EntitySkeleton variant="details" hasActions title="Details" />
        </Page.Main>
      )}
      renderIdle={() => <NoAccessPage />}
      renderError={() => <RequestErrorPage />}
      renderSuccess={({ data: client }) => (
        <Navigate to={generatePath(Url.Client, { clientId: client.id })} replace />
      )}
    />
  )
}
