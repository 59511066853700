import { abbreviate, Avatar } from '@revolut/ui-kit'
import { User } from 'api/idave/user'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { getName } from 'utils/string/getName'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useHeaderActions } from 'components/HeaderActions'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { PageHeader } from 'components/PageHeader'
import { UserActions } from './components/UserActions'
import { UserHeaderSubtitle } from './components/UserHeaderSubtitle'

export const UserHeader = (props: {
  user?: User
  userId: string
  onBackClick: () => void
}) => {
  const { user, userId, onBackClick } = props
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()
  const avatarUrl = getAvatarUrl({ user, type: 'imageMedium', avatars })
  const employeeDescriptions = useGetEmployeeRevoDescription()
  const HeaderActions = useHeaderActions()
  const label = user ? abbreviate(getName(user)) : undefined

  return (
    <PageHeader
      onBack={onBackClick}
      description={
        <UserHeaderSubtitle user={user} employeeDescriptions={employeeDescriptions} />
      }
      actions={HeaderActions && <HeaderActions />}
      avatar={
        <Avatar size={56} {...getAvatarProps({ label, id: userId, avatar: avatarUrl })} />
      }
      meta={<UserActions userId={userId} />}
      pageTitle={user && getName(user)}
    />
  )
}
