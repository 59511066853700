import { useQueryCustomerCompanies } from 'queries/dart/companies'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { findEntityById } from 'utils/array'
import { DataWrapperProps, InnerProps } from './types'

export const CustomerCompanyDataWrapper = ({
  Component,
  crossCheck,
  id,
  source,
}: DataWrapperProps) => {
  if (!id) {
    const entity = {
      type: 'CustomerCompany' as const,
      value: undefined,
    }
    return <Component crossCheck={crossCheck} entity={entity} source={source} />
  }

  return (
    <CustomerCompanyDataWrapperBase
      id={id}
      crossCheck={crossCheck}
      Component={Component}
      source={source}
    />
  )
}

const CustomerCompanyDataWrapperBase = ({
  Component,
  crossCheck,
  id,
  source,
}: InnerProps) => {
  // TODO: revert to useQueryCustomerCompany after https://revolut.atlassian.net/browse/BP-2675
  const {
    data: customerCompanies = [],
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryCustomerCompanies()
  const customerCompany = findEntityById(customerCompanies, id)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={customerCompany}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view policies" />
      )}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={({ data }) => (
        <Component
          crossCheck={crossCheck}
          entity={{
            type: 'CustomerCompany',
            value: data,
          }}
          source={source}
        />
      )}
    />
  )
}
