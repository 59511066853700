import {
  Button,
  Header,
  Popup,
  StatusPopup,
  TextArea,
  ToggleFn,
  useStatusPopup,
  VStack,
} from '@revolut/ui-kit'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useRef, useState } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { FetchEntities, useApi, useXCheckData } from 'view/XChecks/XCheck/lib/hooks'
import { QueryKey } from 'view/XChecks/XCheck/lib/consts'
import { UserList } from './components/UserList'
import { getErrorMessage } from './utils'

export const GroupUpdatePopup = ({
  toggleOpen,
  reasonText,
  onReasonChange,
  groupId,
  xCheckId,
  fetchUsers,
}: {
  reasonText: string
  onReasonChange: (value: string) => void
  toggleOpen: ToggleFn
  groupId: string
  xCheckId: string
  fetchUsers: FetchEntities
}) => {
  const changeHandler = useInputStringChange(onReasonChange)
  const { extendReviewGroup, refresh } = useApi()
  const { onXCheckUpdate } = useXCheckData()
  const {
    data = [],
    fetchStatus: fs,
    status: qs,
  } = useQuery({
    queryFn: fetchUsers,
    queryKey: [QueryKey.Users],
  })
  const [reviewersIds, setReviewersIds] = useState<string[]>([])
  const onReviewersChange = useCallback(
    (reviewers: string[]) => setReviewersIds(reviewers),
    [setReviewersIds],
  )
  const scrollRef = useRef(null)
  const statusPopup = useStatusPopup()

  const { mutate } = useMutation({
    mutationFn: extendReviewGroup,
    onMutate: () =>
      statusPopup.show(
        <StatusPopup variant="loading">
          <StatusPopup.Title>Updating the group</StatusPopup.Title>
        </StatusPopup>,
      ),
    onSuccess: () => {
      toggleOpen.off()
      // on group update we should try to refresh the review state
      refresh({ xCheckId }).finally(() => {
        onXCheckUpdate()
        statusPopup.hide()
      })
    },
    onError: (error) => {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Review group update failed</StatusPopup.Title>
          <StatusPopup.Description>
            {getErrorMessage(error, 'Something went wrong')}
          </StatusPopup.Description>
        </StatusPopup>,
      )
      toggleOpen.off()
    },
  })

  const onSubmitClick = useCallback(
    () =>
      mutate({
        groupId,
        xCheckId,
        reason: reasonText,
        reviewers: reviewersIds.map((id) => ({ type: 'EMPLOYEE', id })),
      }),
    [groupId, reasonText, reviewersIds, xCheckId, mutate],
  )

  const valid = Boolean(reasonText && reviewersIds.length)

  return (
    <Popup
      open
      onClose={toggleOpen.off}
      variant="bottom-sheet"
      shouldKeepMaxHeight={false}
      scrollRef={scrollRef}
    >
      <Header variant="bottom-sheet">
        <Header.Title>Extend group</Header.Title>
      </Header>
      <VStack space="s-16">
        <TextArea label="Reason" rows={2} onChange={changeHandler} value={reasonText} />
        <UserList
          onChange={onReviewersChange}
          scrollRef={scrollRef}
          loadingState={mergeQueryStatuses({ qs, fs })}
          users={data}
        />
      </VStack>

      <Popup.Actions horizontal>
        <Button elevated onClick={toggleOpen.off} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={onSubmitClick} variant="primary" disabled={!valid}>
          Update
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
