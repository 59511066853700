import { useCallback, useMemo } from 'react'
import { useQueryResources } from 'queries/sam/resources'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { EntitiesTable } from 'components/EntitiesTable'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { useQueryGroupIdMap } from 'queries/idave/groups'
import { getColumns, Resource } from './columns'

export const SamResources = () => {
  const { data, status, fetchStatus } = useQueryResources()

  const {
    data: groupMap = new Map(),
    status: groupsStatus,
    fetchStatus: groupsFetchStatus,
  } = useQueryGroupIdMap()

  const resources = useMemo(
    () => data?.map((r) => ({ ...r, id: r.resourceId })) || [],
    [data],
  )

  const tableLoadState = useMemo(
    () =>
      getLoadingState(
        mergeQueryStatuses(
          { qs: status, fs: fetchStatus },
          { qs: groupsStatus, fs: groupsFetchStatus },
        ),
        resources.length,
      ),
    [status, fetchStatus, groupsStatus, groupsFetchStatus, resources.length],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities: resources,
  })

  const navigate = useNavigate()
  const getRowLink = useCallback(
    (row: Resource) => generatePath(Url.SamResource, { resourceId: row.resourceId }),
    [],
  )
  const onRowClick = useCallback(
    (row: Resource) => {
      navigate(getRowLink(row))
    },
    [navigate, getRowLink],
  )

  const columns = useMemo(() => getColumns(groupMap), [groupMap])

  return (
    <EntitiesTable
      columns={columns}
      data={searched}
      loadingState={tableLoadState}
      entitiesTypeLabel="Resources"
      pluralForms={['resource', 'resources']}
      enableNavigation
      totalCount={resources.length}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      onRowClick={onRowClick}
      getRowLink={getRowLink}
      searchAutoFocus
    />
  )
}
