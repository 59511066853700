import { filterActiveIds } from 'components/EntitiesTable/utils'
import { MoreBar } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { AddAccessPayload } from 'api/dart/types'
import { DART_PERMISSIONS } from 'security'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useEditPopup } from 'hooks/useEditPopup'
import { CustomerCompany } from '../../types'
import { getActionDisability, getJustificationPopup } from './utils'

type Props = {
  reset: () => void
  selectedHash: Record<string, boolean>
  companyId: string
  isEditable: boolean
  entities: CustomerCompany[]
}

export const CustomerCompanyEditActions = ({
  selectedHash,
  companyId,
  isEditable,
  entities,
  reset,
}: Props) => {
  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()
  const queryClient = useQueryClient()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()

  const activeIds = filterActiveIds(selectedHash)
  const {
    isCrossCheck: isAddXCheck,
    toCrosscheck: toAddXCheck,
    isDirect: isAddAllowed,
  } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_ADD_COMPANY_ACCESS,
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_ADD_COMPANY_ACCESS_CROSS_CHECK,
  )
  const {
    isCrossCheck: isRevokeXCheck,
    toCrosscheck: toDelXCheck,
    isDirect: isRevokeDirect,
  } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_REVOKE_COMPANY_ACCESS,
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_REVOKE_COMPANY_ACCESS_CROSS_CHECK,
  )

  const { mutate: add, isLoading: isAdding } = useMutation({
    mutationFn: (payload: AddAccessPayload) =>
      dartApi.employeeCompany.addAccess(companyId, { payload, crossCheck: isAddXCheck }),
    onMutate: () => showLoadingPopup({ title: 'Requesting access allowance' }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isAddXCheck && crosscheck) {
        toAddXCheck(crosscheck)
      } else {
        showSuccessToast('Access allowed')
        reset()
        queryClient.invalidateQueries([QueryKey.EmployeeCompanies])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Access allowance failed', error: err })
      closeEditPopup()
    },
  })

  const { mutate: revoke, isLoading: isRevoking } = useMutation({
    mutationFn: (payload: AddAccessPayload) =>
      dartApi.employeeCompany.revokeAccess(companyId, {
        payload,
        crossCheck: isRevokeXCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Revoking access' }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isRevokeXCheck && crosscheck) {
        toDelXCheck(crosscheck)
      } else {
        showSuccessToast('Access revoked')
        reset()
        queryClient.invalidateQueries([QueryKey.EmployeeCompanies])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Access revoking failed', error: err })
      closeEditPopup()
    },
  })

  const { disabledAdd, disabledRevoke } = getActionDisability(activeIds, entities)

  const onAddSubmit = useCallback(
    (justification?: string) => add({ justification, companyIds: activeIds }),
    [activeIds, add],
  )
  const addAccess = useCallback(() => {
    setEditPopup(
      getJustificationPopup({
        action: 'add',
        onSubmit: onAddSubmit,
        companiesAmount: activeIds.length,
      }),
    )
  }, [setEditPopup, onAddSubmit, activeIds])

  const onRevokeSubmit = useCallback(
    (justification?: string) => revoke({ justification, companyIds: activeIds }),
    [activeIds, revoke],
  )
  const revokeAccess = useCallback(() => {
    setEditPopup(
      getJustificationPopup({
        action: 'revoke',
        onSubmit: onRevokeSubmit,
        companiesAmount: activeIds.length,
      }),
    )
  }, [setEditPopup, onRevokeSubmit, activeIds])

  if (!isEditable) {
    return null
  }

  if (!activeIds.length) {
    return null
  }

  return (
    <>
      {isAddAllowed || isAddXCheck ? (
        <MoreBar.Action
          onClick={addAccess}
          disabled={disabledAdd}
          pending={isAdding}
          useIcon="16/CheckCircle"
        >
          Allow access
        </MoreBar.Action>
      ) : null}
      {isRevokeDirect || isRevokeXCheck ? (
        <MoreBar.Action
          onClick={revokeAccess}
          disabled={disabledRevoke}
          pending={isRevoking}
          useIcon="CrossVoid"
          variant="negative"
        >
          Revoke access
        </MoreBar.Action>
      ) : null}
    </>
  )
}
