import { abbreviate, AvatarBar, Table, Text, TextSkeleton } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback } from 'react'
import { useQueryGroupIdMap } from 'queries/idave/groups'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { Group } from 'api/idave/groups'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { QuerySwitch } from './QuerySwitch'
import { GroupOwners } from './GroupOwners'

export const GroupOwnersCell = ({ groupId }: { groupId?: string }) => {
  return (
    <Table.Cell>
      {groupId ? <Inner groupId={groupId} /> : <Text>{EMPTY_VALUE_FALLBACK}</Text>}
    </Table.Cell>
  )
}

export const Inner = (props: { groupId: string }) => {
  const { data: groupMap, status: qs, fetchStatus: fs } = useQueryGroupIdMap()
  const { groupId } = props
  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedEmployeeIdAvatars()
  const { data: userMap, status: userMapQS, fetchStatus: userMapFS } = useQueryUserIdMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      optional={[
        { qs: avatarsStatus, fs: avatarsFS },
        { qs: userMapQS, fs: userMapFS },
      ]}
      data={groupMap?.get(groupId)}
      renderLoading={() => <TextSkeleton size={15} />}
      renderError={() => <GroupOwners groupId={groupId} />}
      renderIdle={() => <GroupOwners groupId={groupId} />}
      renderSuccess={({ data }) => {
        if (data.ownerIds.length > 1) {
          return (
            <AvatarBar maxCount={4}>
              {data.ownerIds.map((owner) => {
                const user = userMap?.get(owner)
                const avatarUrl = getAvatarUrl({ user, avatars })
                const label = user && abbreviate(getName(user))

                return (
                  <AvatarBar.Item
                    key={owner}
                    {...getAvatarProps({ label, id: owner, avatar: avatarUrl })}
                  />
                )
              })}
            </AvatarBar>
          )
        }

        return <GroupOwners groupId={groupId} />
      }}
    />
  )
}

export const useGroupOwnersCellAccessor = () => {
  const { data: groupsMap = new Map<string, Group>() } = useQueryGroupIdMap()

  const { data: userMap } = useQueryUserIdMap()

  const accessor = useCallback(
    (groupId?: string) => {
      const group = groupId ? groupsMap.get(groupId) : undefined
      if (group && group.ownerIds.length > 1) {
        return 'Multiple owners'
      }

      const user = group ? userMap?.get(group.ownerIds[0]) : undefined
      return user ? getName(user) : 'No owner'
    },
    [groupsMap, userMap],
  )

  return accessor
}
