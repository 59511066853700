import { SamPolicySubjectType } from 'api/sam/policies'
import { assertNonReachable } from 'utils/error'
import {
  EMPLOYEE_INPUTS,
  EMPLOYEE_TYPE_INPUTS,
  SPEC_INPUTS,
  TEAM_INPUTS,
  DEPARTMENT_INPUTS,
  DEFAULT_SUBJECT_EMPLOYEE,
  DEFAULT_SUBJECT_EMPLOYEE_TYPE,
  DEFAULT_SUBJECT_SPEC,
  DEFAULT_SUBJECT_TEAM,
  DEFAULT_SUBJECT_DEPARTMENT,
  ROLE_INPUTS,
  FUNCTION_INPUTS,
  DEFAULT_SUBJECT_ROLE,
  DEFAULT_SUBJECT_FUNCTION,
} from 'view/Sam/components/SamEditPolicyDetails/consts'

export const getPolicySubjectFields = (subjectType?: SamPolicySubjectType) => {
  if (!subjectType) {
    return []
  }

  switch (subjectType) {
    case SamPolicySubjectType.Specialisation:
      return SPEC_INPUTS

    case SamPolicySubjectType.Team:
      return TEAM_INPUTS

    case SamPolicySubjectType.Department:
      return DEPARTMENT_INPUTS

    case SamPolicySubjectType.EmployeeType:
      return EMPLOYEE_TYPE_INPUTS

    case SamPolicySubjectType.Employee:
      return EMPLOYEE_INPUTS

    case SamPolicySubjectType.Role:
      return ROLE_INPUTS

    case SamPolicySubjectType.Function:
      return FUNCTION_INPUTS

    default:
      return assertNonReachable(subjectType)
  }
}

export const getDefaultSubject = (subjectType?: SamPolicySubjectType) => {
  switch (subjectType) {
    case SamPolicySubjectType.Employee:
      return DEFAULT_SUBJECT_EMPLOYEE
    case SamPolicySubjectType.Specialisation:
      return DEFAULT_SUBJECT_SPEC
    case SamPolicySubjectType.Team:
      return DEFAULT_SUBJECT_TEAM
    case SamPolicySubjectType.Department:
      return DEFAULT_SUBJECT_DEPARTMENT
    case SamPolicySubjectType.Role:
      return DEFAULT_SUBJECT_ROLE
    case SamPolicySubjectType.Function:
      return DEFAULT_SUBJECT_FUNCTION
    default:
    case SamPolicySubjectType.EmployeeType:
      return DEFAULT_SUBJECT_EMPLOYEE_TYPE
  }
}
