import { useCallback, useEffect, useState } from 'react'
import { SideAvatar } from 'view/SideBox'
import { RESOURCE_SIDE } from 'view/SideBox/hooks/useSideboxNavigation'
import { useSideBox } from 'view/SideBox/SideBox'
import { Title } from './components/Title'
import { Body } from './components/Body'
import { Actions } from './components/Actions'

export const useResourcePreview = () => {
  const [params, setParams] = useState<{ resourceId?: string; crossCheckId?: string }>()
  const { openSide } = useSideBox()
  const onClose = useCallback(() => {
    setParams(undefined)
  }, [setParams])

  useEffect(() => {
    if (!params?.resourceId) {
      return undefined
    }
    const queryParams = {
      type: RESOURCE_SIDE,
      id: params.resourceId,
      subjectId: params.crossCheckId,
    }

    return openSide({
      title: <Title resourceId={params.resourceId} />,
      avatar: <SideAvatar iconName="People" />,
      body: <Body resourceId={params.resourceId} />,
      actions: (
        <Actions resourceId={params.resourceId} crossCheckId={params.crossCheckId} />
      ),
      queryParams,
      onClose,
    })
  }, [openSide, onClose, params])

  return {
    openResourceSide: (resourceId?: string, crossCheckId?: string) =>
      setParams({ resourceId, crossCheckId }),
  }
}
