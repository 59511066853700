import { IconButton, Toast, Tooltip, useToast, useTooltip } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Check, CrossVoid } from '@revolut/icons'
import { useApi, useXCheckData, useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { isFinished } from 'view/XChecks/XCheck/lib/utils'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'

export const RefreshAction = () => {
  const { hasXCheckPermission } = useXCheckSecurity()
  const tooltip = useTooltip()
  const api = useApi()
  const { xCheckData, xCheckId, onXCheckUpdate } = useXCheckData()
  const toast = useToast()

  const { mutate, isLoading } = useMutation({
    mutationFn: () => api.refresh({ xCheckId }),
    onSuccess: (_result: unknown) => {
      toast.show(
        <Toast>
          <Toast.Indicator>
            <Check />
          </Toast.Indicator>
          <Toast.Label>Rules state refreshed</Toast.Label>
        </Toast>,
        'short',
      )
      onXCheckUpdate()
    },
    onError: () => {
      toast.show(
        <Toast>
          <Toast.Indicator>
            <CrossVoid />
          </Toast.Indicator>
          <Toast.Label>Rules state refresh failed</Toast.Label>
        </Toast>,
        'short',
      )
    },
  })
  const refresh = useCallback(() => {
    mutate()
  }, [mutate])

  if (!hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_REFRESH)) {
    return null
  }

  if (xCheckData.status !== 'success' || isFinished(xCheckData.data)) {
    return null
  }

  return (
    <>
      <IconButton
        active
        disabled={isLoading}
        useIcon="Retry"
        onClick={refresh}
        size={16}
        {...tooltip.getAnchorProps()}
      />
      <Tooltip {...tooltip.getTargetProps()}>Refresh rules state</Tooltip>
    </>
  )
}
