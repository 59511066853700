import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ActionButtonProps } from '@revolut/ui-kit'
import { Account, AccountCustomization } from 'api/idave/accounts'
import { IDAVE_PERMISSIONS } from 'security'
import {
  ACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT,
  TERMINATE_ACCOUNT,
} from 'view/Clients/permissions'

type GetAccountActionsParams = {
  permissionUtils: ReturnType<typeof usePermissions>
  account: Account
  customization?: AccountCustomization
  onActivateClick: () => void
  onEditClick: () => void
  onResetClick: () => void
  onTerminateClick: () => void
  onDeleteClick: () => void
  onRotateTokensClick: () => void
}

type GetAccountActions = Array<{
  variant: ActionButtonProps['variant']
  useIcon: ActionButtonProps['useIcon']
  onClick: () => void
  label: string
}>

export const getAccountActions = ({
  account,
  customization,
  permissionUtils: { hasSomePermissions, hasPermission },
  onActivateClick,
  onEditClick,
  onResetClick,
  onTerminateClick,
  onDeleteClick,
  onRotateTokensClick,
}: GetAccountActionsParams) => {
  const result: GetAccountActions = []

  if (account.state === 'DELETED') {
    // terminated account allows do nothing
    return result
  }

  if (account.state === 'TERMINATED' && hasSomePermissions(...ACTIVATE_ACCOUNT)) {
    result.push({
      label: 'Activate',
      variant: 'primary',
      useIcon: '16/CheckCircle',
      onClick: onActivateClick,
    })

    // terminated account allows only activation
    return result
  }

  /**
   * if account belong to idave => only account action checks
   * otherwise check for customization permissions
   */
  const canEdit =
    hasSomePermissions(...EDIT_ACCOUNT) &&
    (account.source === 'idave' ||
      hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_UPDATE_SERVICE_ACCOUNTS))

  if (canEdit) {
    result.push({
      label: 'Edit',
      onClick: onEditClick,
      useIcon: 'Pencil',
      variant: 'primary',
    })
  }

  if (
    !!customization &&
    hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_DELETE_SERVICE_ACCOUNTS)
  ) {
    result.push({
      label: 'Reset',
      onClick: onResetClick,
      useIcon: 'ExclamationTriangle',
      variant: 'negative',
    })
  }

  if (hasPermission(IDAVE_PERMISSIONS.SESSIONS_ROTATE)) {
    result.push({
      label: 'Rotate tokens',
      onClick: onRotateTokensClick,
      useIcon: 'ArrowRepeat',
      variant: 'negative',
    })
  }

  if (account.state === 'ACTIVE' && hasSomePermissions(...TERMINATE_ACCOUNT)) {
    result.push({
      label: 'Terminate',
      onClick: onTerminateClick,
      useIcon: 'MinusCircle',
      variant: 'negative',
    })
  }

  if (hasSomePermissions(...DELETE_ACCOUNT) && account.source === 'idave') {
    result.push({
      label: 'Delete',
      onClick: onDeleteClick,
      useIcon: 'Delete',
      variant: 'negative',
    })
  }

  return result
}
