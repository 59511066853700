import { TimestampParam, Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { notNullable } from 'utils/common'
import { notNullableMap } from 'utils/array'
import { formatTimestamp } from 'view/XChecks/XCheck/lib/utils'
import { FieldInput, FieldSelect } from '../Fields'
import { InputProps } from './type'
import { PARAM_WITH_OPTION_PATTERN } from './constants'
import {
  getStandaloneLabel,
  getStandalonePath,
  timestampToDatetimeLocalWithMs,
  useStandaloneValidate,
} from './utils'

const getOptions = (options: Value<TimestampParam>[] = []) =>
  notNullableMap(options, ({ value }, idx) =>
    value
      ? {
          key: `${value}_${idx}`,
          value,
          label: formatTimestamp(value),
        }
      : undefined,
  )

const setSelectValue = (value: Nullable<number>) =>
  notNullable(value) ? Number(value) : undefined

const setInputValue = (value: Nullable<string>) => {
  return notNullable(value) ? new Date(value).getTime() : undefined
}

export const TimestampInput = (props: InputProps<TimestampParam>) => {
  const { param, prefix, index } = props
  const defaultValue = param.value.value

  const required = !!param.value?.editConfig?.required
  const validate = useStandaloneValidate(param)

  return match(param)
    .with(PARAM_WITH_OPTION_PATTERN, (optionedParam) => (
      <FieldSelect
        label={getStandaloneLabel(param)}
        path={getStandalonePath(prefix, index)}
        defaultValue={defaultValue}
        clearable={!required}
        options={getOptions(optionedParam.value.editConfig.options)}
        validate={validate}
        setValueAs={setSelectValue}
      />
    ))
    .otherwise(() => (
      <FieldInput
        type="datetime-local"
        step={0.1}
        label={getStandaloneLabel(param)}
        path={getStandalonePath(prefix, index)}
        defaultValue={timestampToDatetimeLocalWithMs(defaultValue)}
        disabled={!param.value.editConfig}
        validate={validate}
        setValueAs={setInputValue}
      />
    ))
}
