import { ReviewRule, RuleTypeDescriptor } from 'api/types/xChecks'

export const getParentRulePrefix = (parentRule?: ReviewRule, isFirst?: boolean) => {
  if (isFirst) {
    return undefined
  }
  switch (parentRule?.type) {
    case RuleTypeDescriptor.and:
      return 'and'
    case RuleTypeDescriptor.or:
      return 'or'
    case RuleTypeDescriptor.multiStage:
      return 'then'
    default:
      return undefined
  }
}
