import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyComments = () => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D001', 'v2')} />
    <StatusWidget.Title>Nobody commented request yet</StatusWidget.Title>
    <StatusWidget.Description>
      Comment it or wait until a comment appear
    </StatusWidget.Description>
  </StatusWidget>
)
