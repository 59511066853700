import { filterActiveIds } from 'components/EntitiesTable/utils'
import { MoreBar } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { CustomerCompanyAccessPayload } from 'api/dart/types'
import { DART_PERMISSIONS } from 'security'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useEditPopup } from 'hooks/useEditPopup'
import { EmployeeCompany } from '../../types'
import { getActionDisability, getJustificationPopup } from './utils'

type Props = {
  reset: () => void
  selectedHash: Record<string, boolean>
  companyId: string
  isEditable: boolean
  entities: EmployeeCompany[]
}

export const EmployeeCompanyEditActions = ({
  selectedHash,
  companyId,
  isEditable,
  entities,
  reset,
}: Props) => {
  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const activeIds = filterActiveIds(selectedHash)

  const { isCrossCheck: isCrossCheckAdd, toCrosscheck: toCrosscheckAdd } = useCrossCheck(
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_COMPANY_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_COMPANY_ACCESS_CROSS_CHECK,
  )

  const { isCrossCheck: isCrossCheckDelete, toCrosscheck: toCrossCheckDelete } =
    useCrossCheck(
      DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_COMPANY_ACCESS,
      DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_COMPANY_ACCESS_CROSS_CHECK,
    )

  const { mutate: add, isLoading: isAdding } = useMutation({
    mutationFn: (payload: CustomerCompanyAccessPayload) =>
      dartApi.customerCompany.addAccessCompanies(companyId, {
        payload,
        crossCheck: isCrossCheckAdd,
      }),
    onMutate: () => showLoadingPopup({ title: 'Requesting access allowance...' }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isCrossCheckAdd && crosscheck) {
        toCrosscheckAdd(crosscheck)
      } else {
        showSuccessToast('Access allowed')
        reset()
        queryClient.invalidateQueries([QueryKey.CustomerCompany, companyId])
        queryClient.invalidateQueries([QueryKey.EmployeeCompanies])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Access allowance failed', error: err })
      closeEditPopup()
    },
  })

  const { mutate: revoke, isLoading: isRevoking } = useMutation({
    mutationFn: (payload: CustomerCompanyAccessPayload) =>
      dartApi.customerCompany.revokeAccessCompanies(companyId, {
        payload,
        crossCheck: isCrossCheckDelete,
      }),
    onMutate: () => showLoadingPopup({ title: 'Revoking access...' }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isCrossCheckDelete && crosscheck) {
        toCrossCheckDelete(crosscheck)
      } else {
        showSuccessToast('Access revoked')
        reset()
        queryClient.invalidateQueries([QueryKey.CustomerCompany, companyId])
        queryClient.invalidateQueries([QueryKey.EmployeeCompanies])
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Access revoking failed', error: err })
      closeEditPopup()
    },
  })

  const { disableAdd, disableDelete } = getActionDisability(activeIds, entities)

  const onAddSubmit = useCallback(
    (justification?: string) => add({ justification, employeeCompanyIds: activeIds }),
    [activeIds, add],
  )

  const addAccess = useCallback(() => {
    setEditPopup(
      getJustificationPopup({
        action: 'add',
        onSubmit: onAddSubmit,
        companiesAmount: activeIds.length,
      }),
    )
  }, [setEditPopup, onAddSubmit, activeIds])

  const onRevokeSubmit = useCallback(
    (justification?: string) => revoke({ justification, employeeCompanyIds: activeIds }),
    [activeIds, revoke],
  )
  const revokeAccess = useCallback(() => {
    setEditPopup(
      getJustificationPopup({
        action: 'revoke',
        onSubmit: onRevokeSubmit,
        companiesAmount: activeIds.length,
      }),
    )
  }, [setEditPopup, onRevokeSubmit, activeIds])

  if (!isEditable) {
    return null
  }

  if (!activeIds.length) {
    return null
  }

  return (
    <>
      <MoreBar.Action
        onClick={addAccess}
        disabled={disableAdd}
        pending={isAdding}
        useIcon="16/CheckCircle"
      >
        Allow access
      </MoreBar.Action>
      <MoreBar.Action
        onClick={revokeAccess}
        disabled={disableDelete}
        pending={isRevoking}
        useIcon="CrossVoid"
        variant="negative"
      >
        Revoke access
      </MoreBar.Action>
    </>
  )
}
