import {
  Button,
  Cell,
  Description,
  Group,
  Input,
  Popup,
  Radio,
  RadioGroup,
  Text,
  TextArea,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { useQueryDirectRelations } from 'queries/dart/employees'
import { UserSelect } from 'components/Selects/UserSelect'
import { useEditPopup } from 'hooks/useEditPopup'
import { UserListItem } from 'api/idave/user'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { EmployeeModifierType } from '../../types'
import { getRestrictionErrors } from './utils'

export type FormState = {
  type: EmployeeModifierType
  employeeId?: string
  subjectId?: string
  justification?: string
}

type Props = {
  employeeId?: string
  // using outside toast show to render it in page context (not popup)
  showSuccessToast: (message: string) => void
  onSuccess: () => void
}
export function UserRestrictionCreate({
  employeeId,
  showSuccessToast,
  onSuccess,
}: Props) {
  const { data: accesses = [] } = useQueryDirectRelations()
  const { closePopup } = useEditPopup()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: restrict } = useMutation({
    mutationFn: dartApi.employee.addRestriction,
    onMutate: () => showLoadingPopup({ title: 'Creating restriction...' }),
    onSuccess: () => {
      closePopup()
      hideLoadingPopup()
      showSuccessToast('Restriction created')
      onSuccess()
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Restriction creation failed',
        error: err,
      })
    },
  })

  const { mutate: block } = useMutation({
    mutationFn: dartApi.employee.block,
    onMutate: () => showLoadingPopup({ title: 'Blocking employee...' }),
    onSuccess: () => {
      closePopup()
      hideLoadingPopup()
      showSuccessToast('Employee blocked')
      onSuccess()
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Blocking was failed',
        error: err,
      })
    },
  })

  const [formState, setFormState] = useState<FormState>({
    employeeId,
    type: 'blockedEmployee',
  })

  const error = getRestrictionErrors({
    formState,
    accesses,
  })

  const onSubmit = useCallback(() => {
    const id = formState.employeeId
    if (!id) {
      return undefined
    }
    if (formState.type === 'blockedEmployee') {
      return block({
        employeeId: id,
        payload: {
          justification: formState.justification,
        },
      })
    }

    if (formState.type === 'restrictedRelation' && formState.subjectId) {
      return restrict({
        employeeId: id,
        payload: {
          justification: formState.justification,
          userIds: [formState.subjectId],
        },
      })
    }

    return undefined
  }, [formState, block, restrict])

  const onRelationTypeChange = useCallback(
    (type: EmployeeModifierType | null) => {
      if (type) {
        setFormState((state) => ({ ...state, type }))
      }
    },
    [setFormState],
  )

  const onEmployeeChange = useCallback(
    (user?: UserListItem | null) => {
      setFormState((state) => ({ ...state, employeeId: user?.id }))
    },
    [setFormState],
  )

  const onSubjectIdChange = useCallback(
    (subjectId: string) => setFormState((state) => ({ ...state, subjectId })),
    [setFormState],
  )
  const handleSubjectIdChange = useInputStringChange(onSubjectIdChange)

  const onJustificationChange = useCallback(
    (justification: string) =>
      setFormState((state) => ({ ...state, justification: justification || undefined })),
    [setFormState],
  )
  const handleJustificationChange = useInputStringChange(onJustificationChange)

  return (
    <>
      <VStack space="s-16">
        <RadioGroup onChange={onRelationTypeChange} value={formState.type}>
          {(group) => (
            <Group>
              <Cell use="label">
                <Radio {...group.getInputProps({ value: 'blockedEmployee' })}>
                  <VStack>
                    <Text>Block access</Text>
                    <Description>Block access to all customer companies</Description>
                  </VStack>
                </Radio>
              </Cell>
              <Cell use="label">
                <Radio {...group.getInputProps({ value: 'restrictedRelation' })}>
                  <VStack>
                    <Text>Limit customer access</Text>
                    <Description>Block access to a specific customer</Description>
                  </VStack>
                </Radio>
              </Cell>
            </Group>
          )}
        </RadioGroup>

        {!employeeId && (
          <UserSelect
            itemId={formState.employeeId}
            label="Employee"
            onChange={onEmployeeChange}
            clearable
            onlyActive
          />
        )}

        {formState.type === 'restrictedRelation' ? (
          <Input
            label="Customer ID"
            onChange={handleSubjectIdChange}
            value={formState.subjectId}
          />
        ) : null}

        <TextArea
          label="Justification (Optional)"
          onChange={handleJustificationChange}
          value={formState.justification}
        />

        {error && (
          <Text color={Token.color.danger} fontSize="small">
            {error}
          </Text>
        )}
      </VStack>

      <Popup.Actions>
        <Button elevated variant="primary" onClick={onSubmit} disabled={!!error}>
          Add restriction
        </Button>
      </Popup.Actions>
    </>
  )
}
