import { Box, chain, Subheader, TabBar } from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { ChangeViewType } from '../../../types'

type OwnProps = {
  title: string
  showViewSwitcher: boolean
  counters?: ChangeTabCounters
}

type ChangeTabCounters = {
  old?: number
  diff?: number
  new?: number
}

type ChangeWrapperProps = { view?: ChangeViewType }

type ComponentProps<Props extends ChangeWrapperProps> = {
  renderComponent: (props: Props) => React.ReactNode
}

type WrapperProps<T extends ChangeWrapperProps> = OwnProps & ComponentProps<T>

export const ChangeSubheaderWrapper = ({
  title,
  showViewSwitcher,
  renderComponent,
  counters,
  ...componentProps
}: WrapperProps<ChangeWrapperProps>) => {
  const [view, setView] = useState<ChangeViewType | undefined>(
    showViewSwitcher ? 'new' : undefined,
  )
  const onViewChange = useCallback(
    (newView: Nullable<ChangeViewType>) => {
      if (newView) {
        setView(newView)
      }
    },
    [setView],
  )

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
        {showViewSwitcher && (
          <Subheader.Side>
            <TabBar value={view} onChange={onViewChange} variant="segmented fit">
              <TabBar.Item to="old">{chain('Old', counters?.old)}</TabBar.Item>
              <TabBar.Item to="diff">{chain('Difference', counters?.diff)}</TabBar.Item>
              <TabBar.Item to="new">{chain('New', counters?.new)}</TabBar.Item>
            </TabBar>
          </Subheader.Side>
        )}
      </Subheader>
      {renderComponent({
        ...componentProps,
        view,
      })}
    </Box>
  )
}
