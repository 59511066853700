import axios from 'axios'
import { ActionResponse } from 'api/types/actionResponse'
import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import {
  AccessModifier,
  AccessModifierLimited,
  AddAccessPayload,
  RevokeAccessPayload,
} from './types'
import { EmployeeCompany } from './types'

export type EmployeeAccesses = {
  id: string
  accessModifiers: AccessModifier[]
}

export type EmployeeDetailsNotLimited = {
  id: string
  company?: EmployeeCompany
  accessModifiers: AccessModifier[]
}

type EmployeeDetailsLimited = Omit<EmployeeDetailsNotLimited, 'accessModifiers'> & {
  accessModifiers: AccessModifierLimited[]
}

export type EmployeeDetails = EmployeeDetailsNotLimited | EmployeeDetailsLimited

export type UnblockEmployeePayload = {
  justification?: string
}

type BlockEmployeePayload = {
  justification?: string
  expirationDate?: number
}

export type AddRestrictionPayload = {
  userIds: string[]
  justification?: string
  expirationDate?: number
}

export type DeleteRestrictionPayload = {
  userIds: string[]
  justification?: string
}

export const employee = {
  getEmployees: () => axios.get<EmployeeAccesses[]>('/dart-api/dart/employees'),

  getEmployeeDetails: <T = EmployeeDetails>(id: string) =>
    axios.get<T>(`/dart-api/dart/employees/details/${id}`),

  getCurrentEmployee: () =>
    axios.get<EmployeeAccesses>('/dart-api/dart/employees/current'),

  addRestriction: (params: { employeeId: string; payload: AddRestrictionPayload }) => {
    return axios.post<ActionResponse>(
      `/dart-api/employees/${params.employeeId}/restrictions/add`,
      params.payload,
    )
  },

  deleteRestriction: (
    employeeId: string,
    payload: CrossCheckedPayload<DeleteRestrictionPayload>,
  ) => {
    const { body, headers } = splitPayloadHeader(payload)
    return axios.post<ActionResponse>(
      `/dart-api/employees/${employeeId}/restrictions/delete`,
      body,
      { headers },
    )
  },

  addAccess: (employeeId: string, payload: CrossCheckedPayload<AddAccessPayload>) => {
    const { body, headers } = splitPayloadHeader(payload)
    return axios.post<ActionResponse>(
      `/dart-api/employees/${employeeId}/company-access/add`,
      body,
      { headers },
    )
  },
  revokeAccess: (
    employeeCompanyId: string,
    payload: CrossCheckedPayload<RevokeAccessPayload>,
  ) => {
    const { body, headers } = splitPayloadHeader(payload)
    return axios.post<ActionResponse>(
      `/dart-api/employees/${employeeCompanyId}/company-access/revoke`,
      body,
      { headers },
    )
  },

  block: (params: { employeeId: string; payload: BlockEmployeePayload }) =>
    axios.post<ActionResponse>(
      `/dart-api/employees/${params.employeeId}/block`,
      params.payload,
    ),
  unblock: (params: { employeeId: string; payload: UnblockEmployeePayload }) =>
    axios.post<ActionResponse>(
      `/dart-api/employees/${params.employeeId}/unblock`,
      params.payload,
    ),
}
