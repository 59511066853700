import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useCallback } from 'react'
import samApi from 'api/sam'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { QueryKey } from 'helpers/configQuery'
import { Row } from './types'

export const useResourceRevoke = (idaveUserId: string) => {
  const { setPopup, closePopup } = useConfirmationPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationFn: (row: Row) => samApi.resources.revokeResource(row.access.id),
    onMutate: () => showLoadingPopup({ title: 'Revoking...' }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.ResourceAccesses, idaveUserId],
      })
      hideLoadingPopup()
      closePopup()
    },
    onError: (error) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Revoking failed',
        error,
      })
    },
  })

  return useCallback(
    (row: Row) => {
      setPopup({
        title: 'Are you sure you want to revoke access to the resource?',
        message: `After revoking the employee won't be able to operate with ${row.resource.resourceName}`,
        confirmButtonText: 'Revoke',
        onConfirmClick: () => mutate(row),
        confirmButtonVariant: 'negative',
      })
    },
    [mutate, setPopup],
  )
}
