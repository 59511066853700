import { useFormContext, useController } from 'react-hook-form'
import { TextAreaProps } from '@revolut/ui-kit'
import { Fielded } from 'utils/types/fielded'
import { TextArea } from 'components/Inputs/TextArea'
import { getFieldErrorState } from '../utils'

export const FieldTextArea = (props: Fielded<TextAreaProps>) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control, rules })

  return <TextArea {...rest} {...field} {...getFieldErrorState(fieldState)} />
}
