import { orderBy } from 'lodash'
import { EmployeeDetails } from 'api/dart/employee'
import { HIDDEN_DATA_FALLBACK } from 'constants/string'
import { AccessModifierLimited } from 'api/dart/types'
import {
  getEmployeeModifier,
  getModifierDeleteDisabled,
} from 'view/Dart/UserRestrictions/utils'
import { PermissionUtils } from 'services/permissions'

const getDescription = (item: AccessModifierLimited): string => {
  let description = item.entityId
    ? `Customer ID: ${item.entityId.id}` || HIDDEN_DATA_FALLBACK
    : 'No access to customer companies'

  if (item.justification) {
    description += `\n${item.justification}`
  }

  return description
}

export const getItems = (params: {
  details: EmployeeDetails | undefined
  permissionUtils?: PermissionUtils
}) => {
  const { details, permissionUtils } = params
  if (!details) {
    return []
  }

  const filteredAndSorted = orderBy(
    details.accessModifiers.filter((item) => item.type === 'DENIED'),
    ['entityId', 'expirationDate'],
    ['desc', 'asc'],
  )

  return filteredAndSorted.map((item) => {
    const employeeModifier = getEmployeeModifier(details.id, item)
    return {
      id: item.id,
      title: item.entityId ? 'Limited customer access' : 'Blocked access',
      entityId: item.entityId?.id,
      description: getDescription(item),
      employeeModifier,
      deleteDisabled: permissionUtils
        ? getModifierDeleteDisabled(employeeModifier, permissionUtils)
        : true,
    }
  })
}
