import { NumberParam, Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { notNullable } from 'utils/common'
import { notNullableMap } from 'utils/array'
import { FieldSelect, FieldInput } from '../Fields'
import { InputProps } from './type'
import { PARAM_WITH_OPTION_PATTERN } from './constants'
import { getStandaloneLabel, getStandalonePath, useStandaloneValidate } from './utils'

const getOptions = (options: Value<NumberParam>[] = []) =>
  notNullableMap(options, ({ value }, idx) =>
    notNullable(value)
      ? {
          key: `${value}_${idx}`,
          value,
          label: String(value),
        }
      : undefined,
  )

const setValueAs = (value: Nullable<number | string>) =>
  notNullable(value) ? Number(value) : undefined

export const NumberInput = (props: InputProps<NumberParam>) => {
  const { param, prefix, index } = props
  const defaultValue = param.value.value

  const required = !!param.value?.editConfig?.required
  const validate = useStandaloneValidate(param)

  return match(param)
    .with(PARAM_WITH_OPTION_PATTERN, (optionedParam) => (
      <FieldSelect
        label={getStandaloneLabel(param)}
        path={getStandalonePath(prefix, index)}
        defaultValue={defaultValue}
        clearable={!required}
        options={getOptions(optionedParam.value.editConfig.options)}
        validate={validate}
        setValueAs={setValueAs}
      />
    ))
    .otherwise(() => (
      <FieldInput
        type="number"
        label={getStandaloneLabel(param)}
        path={getStandalonePath(prefix, index)}
        defaultValue={defaultValue}
        disabled={!param.value.editConfig}
        validate={validate}
        setValueAs={setValueAs}
      />
    ))
}
