import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { XCheckSecurityProvider } from 'view/XChecks/XCheck/lib/hooks'
import { Data, HasPermissionFn } from '../../lib/types'
import { XCheckQueryClientProvider } from '../../lib/XCheckQueryClientProvider'
import {
  ApiProvider,
  NavigationProvider,
  NavigationSettings,
  XChecksDataProvider,
} from './hooks'

type Props = {
  children: React.ReactNode
  userIdData: Data<string>
  hasPermission: HasPermissionFn
  apiSettings: ApiSettings
  navigationSettings: NavigationSettings
}

export const XCheckProvider = ({
  children,
  userIdData,
  apiSettings,
  navigationSettings,
  hasPermission,
}: Props) => {
  return (
    <ApiProvider {...apiSettings}>
      <NavigationProvider {...navigationSettings}>
        <XCheckSecurityProvider hasPermission={hasPermission} apiSettings={apiSettings}>
          <XCheckQueryClientProvider>
            <XChecksDataProvider userIdData={userIdData}>{children}</XChecksDataProvider>
          </XCheckQueryClientProvider>
        </XCheckSecurityProvider>
      </NavigationProvider>
    </ApiProvider>
  )
}
