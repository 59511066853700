import { PermissionScope } from 'api/idave/permissions'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'
import { SelectInput, SelectInputProps, SelectOptionItemType } from '@revolut/ui-kit'
import { getScopeLabel } from 'utils/permission/getScopeLabel'
import { stringEnumToOptions } from './utils'

const values = PermissionScope as Record<string, PermissionScope>
export const OPTIONS: SelectOptionItemType<string>[] = stringEnumToOptions(
  PermissionScope,
  (item) => getSecurityLabel(values[item], getScopeLabel(values[item])),
)

type StringSelect = SelectInputProps<string, SelectOptionItemType<string>>

export type PermissionScopeSelectProps = Omit<StringSelect, 'options'>

export const PermissionScopeSelect = (props: PermissionScopeSelectProps) => (
  <SelectInput searchable={false} {...props} options={OPTIONS} />
)
