import { usePermissions } from '@revolut-internal/idave-web-auth'
import { MoreBar } from '@revolut/ui-kit'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CustomerEmployeesAccessPayload } from 'api/dart/types'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useEditPopup } from 'hooks/useEditPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { DART_PERMISSIONS } from 'security'
import dartApi from 'api/dart'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { QueryKey } from 'helpers/configQuery'
import { useCallback } from 'react'
import { filterActiveIds } from 'components/EntitiesTable/utils'
import { getJustificationPopup } from './utils'

export const RevokeAction = ({
  selectedHash,
  companyId,
  reset,
}: {
  companyId: string
  selectedHash: Record<string, boolean>
  reset: () => void
}) => {
  const { hasSomePermissions } = usePermissions()
  const showDeletePermissions = hasSomePermissions(
    DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_EMPLOYEE_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_EMPLOYEE_ACCESS_CROSS_CHECK,
  )

  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()

  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()

  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_EMPLOYEE_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_EMPLOYEE_ACCESS_CROSS_CHECK,
  )

  const { mutate: revoke } = useMutation({
    mutationFn: (payload: CustomerEmployeesAccessPayload) =>
      dartApi.customerCompany.revokeAccessEmployees(companyId, {
        payload,
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Revoking...' }),
    onSuccess(response) {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        showSuccessToast('Access revoked')
        reset()
        queryClient.invalidateQueries([QueryKey.CustomerCompany, companyId])
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Revocation failed',
        error,
      })
      closeEditPopup()
    },
  })

  const onRevokeSubmit = useCallback(
    (justification?: string) => {
      const employeeIds = filterActiveIds(selectedHash)
      return revoke({
        employeeIds,
        justification,
      })
    },
    [revoke, selectedHash],
  )

  const selectedCount = filterActiveIds(selectedHash).length
  const onRevokeClick = useCallback(() => {
    setEditPopup(
      getJustificationPopup({
        onSubmit: onRevokeSubmit,
        employeesAmount: selectedCount,
      }),
    )
  }, [setEditPopup, onRevokeSubmit, selectedCount])

  return (
    <>
      {!!selectedCount && showDeletePermissions && (
        <MoreBar.Action variant="negative" onClick={onRevokeClick} useIcon="Delete">
          Revoke
        </MoreBar.Action>
      )}
    </>
  )
}
