import { useController, useFormContext } from 'react-hook-form'
import {
  PermissionOversightsSelect,
  PermissionOversightsSelectProps,
} from 'components/Selects/PermissionOversightSelect/PermissionOversightsSelect'
import { Fielded } from 'utils/types/fielded'

export const FieldPermissionOversightsSelect = (
  props: Fielded<PermissionOversightsSelectProps>,
) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return <PermissionOversightsSelect {...rest} {...field} ref={undefined} />
}
