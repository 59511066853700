import { useQuerySamPolicy } from 'queries/sam/policies'
import { TabBar, TextSkeleton, Text, chain, Token, Page } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { SAM_PERMISSIONS } from 'security'
import { TabBarLink } from 'components/TabBarLink'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

import { useNavigateToList } from 'hooks/useBackToResults'
import { SamPolicyDetails } from './SamPolicyDetails'
import { SamPolicyResources } from './SamPolicyResources'
import { SamPolicyTrainings } from './SamPolicyTrainings'
import { SamPolicyLogs } from './SamPolicyLogs'
import { SamPolicyEmployees } from './SamPolicyEmployees'
import { tabToPageSize } from './utils'

type SamPolicyProps = {
  policyId: string
  tab: 'details' | 'resources' | 'trainings' | 'employees' | 'logs'
}

export const SamPolicy = ({ policyId, tab }: SamPolicyProps) => {
  const { data: policy } = useQuerySamPolicy(policyId)
  const isActive = policy?.status === 'active'
  const onBackClick = useNavigateToList({
    listUrl: Url.SamPolicies,
    entityUrl: generatePath(Url.SamPolicy, { policyId }),
  })

  return (
    <>
      <Page.Header onBack={onBackClick}>
        {chain(
          policy?.policyName || <TextSkeleton variant="h1" size={16} />,
          policy?.status === 'removed' && <Text color={Token.color.red}>Removed</Text>,
        )}
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <TabBarLink to={generatePath(Url.SamPolicy, { policyId })}>Details</TabBarLink>

          <PermissionsCheck permission={SAM_PERMISSIONS.RESOURCES_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.SamPolicyResources, { policyId })}>
              Resources
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.TRAININGS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.SamPolicyTrainings, { policyId })}>
              Trainings
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_VIEW_EMPLOYEES_LIST}>
            <TabBarLink to={generatePath(Url.SamPolicyEmployees, { policyId })}>
              Users
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.AUDIT_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.SamPolicyLogs, { policyId })}>
              Logs
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>

      <Page.Main size={tabToPageSize(tab)}>
        {tab === 'details' && <SamPolicyDetails id={policyId} isActive={isActive} />}

        {tab === 'resources' && <SamPolicyResources id={policyId} isActive={isActive} />}

        {tab === 'trainings' && <SamPolicyTrainings id={policyId} isActive={isActive} />}

        {tab === 'employees' && <SamPolicyEmployees id={policyId} />}

        {tab === 'logs' && <SamPolicyLogs id={policyId} />}
      </Page.Main>
    </>
  )
}
