import { Avatar, Banner, Token } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'

export const CustomizationBanner = (props: {
  hasCustomization?: boolean
  client: Client
}) => {
  const {
    hasCustomization,
    client: { source },
  } = props
  if (!hasCustomization || !source || source === 'idave') {
    return null
  }

  return (
    <Banner mt="s-16">
      <Banner.Avatar>
        <Avatar useIcon="ExclamationTriangle" size={56} color={Token.color.warning} />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>App is customized</Banner.Title>
        <Banner.Description>
          {`This application is currently managed by ${source}. All changes made to the app
          will be stored as app customization settings, which override data from the 
          ${source}`}
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
