import { EmployeeCompany } from 'api/dart/types'

export const getDartCompanyType = (employeeCompany?: EmployeeCompany) => {
  switch (employeeCompany?.type) {
    case 'EXTERNAL':
      return 'Vendor'
    case 'INTERNAL':
      return 'Entity'
    default:
      return undefined
  }
}
