import { TabBar, Text, HStack, Page } from '@revolut/ui-kit'
import { useQueryPermissions, useQueryToxicPairs } from 'queries/idave/permissions'
import { useQueryRoles, useQueryRole } from 'queries/idave/roles'
import { IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'
import { Role as TRole } from 'api/idave/roles'
import { NoAccessPage } from 'components/NoAccessWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { TabBarLink } from 'components/TabBarLink'
import { useMemo } from 'react'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import { PageHeader } from 'components/PageHeader'
import { RoleDetails } from './components/RoleDetails/RoleDetails'
import { RolePermissions } from './components/RolePermissions'
import { RoleAuditLogs, RolePolicies } from './components'
import { RoleUsers } from './components/RoleUsers'
import { hasToxicPermissions, tabToPageSize } from './utils'
import { RoleSkeleton } from './components/RoleSkeleton'

export type RoleTab = 'details' | 'permissions' | 'policies' | 'users' | 'auditLog'

export const Role = (props: { roleId: string; tab: RoleTab }) => {
  const { tab, roleId } = props
  const { data: role, status, fetchStatus, refetch } = useQueryRole({ roleId })

  const onBackClick = useNavigateToList({
    listUrl: Url.Roles,
    entityUrl: generatePath(Url.Role, { roleId }),
  })

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={role}
      renderLoading={() => (
        <Outer roleId={roleId} toRoles={onBackClick} tab={tab}>
          <RoleSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer roleId={roleId} role={data} toRoles={onBackClick} tab={tab}>
          <Inner tab={tab} role={data} />
        </Outer>
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={roleId}
          title="Something went wrong"
          description="Requested role info fetch failed"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => <NoAccessPage pageTitle={roleId} onBackClick={onBackClick} />}
    />
  )
}

const Outer = (props: {
  roleId: string
  children: React.ReactNode
  role?: TRole
  toRoles: () => void
  tab: RoleTab
}) => {
  const { roleId, children, role, toRoles, tab } = props

  const { data: roles = [] } = useQueryRoles()
  const { data: permissions = [] } = useQueryPermissions()
  const { data: toxicPermissions = [] } = useQueryToxicPairs()

  const showPermissionWarning = useMemo(
    () =>
      hasToxicPermissions({
        role,
        roles,
        permissions,
        toxicPermissions,
      }),
    [roles, role, permissions, toxicPermissions],
  )

  return (
    <>
      <PageHeader onBack={toRoles} pageTitle={role?.name} postfix="Roles" />
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.ROLES_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.RoleDetails, { roleId })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              IDAVE_PERMISSIONS.ROLES_VIEW_DETAILS,
              IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST,
            ]}
          >
            <TabBarLink
              to={generatePath(Url.RolePermissions, { roleId })}
              hasDot={showPermissionWarning}
            >
              <HStack space="s-8" align="center">
                <Text>Permissions</Text>
              </HStack>
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.RolePolicies, { roleId })}>
              Policies
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              IDAVE_PERMISSIONS.ROLES_VIEW_DETAILS,
              IDAVE_PERMISSIONS.USERS_VIEW_LIST,
            ]}
          >
            <TabBarLink to={generatePath(Url.RoleUsers, { roleId })}>Users</TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.RoleAuditLogs, { roleId })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main size={tabToPageSize(tab)}>{children}</Page.Main>
    </>
  )
}

const Inner = ({ tab, role }: { role: TRole; tab: RoleTab }) => {
  switch (tab) {
    case 'permissions':
      return <RolePermissions role={role} />
    case 'auditLog':
      return <RoleAuditLogs roleId={role.id} />
    case 'policies':
      return <RolePolicies roleId={role.id} />
    case 'users':
      return <RoleUsers roleId={role.id} />
    default:
    case 'details':
      return <RoleDetails role={role} />
  }
}
