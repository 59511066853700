import { useQueryClient } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { useCallback } from 'react'

export function useInvalidtePolicyCache() {
  const queryClient = useQueryClient()

  const resetCache = useCallback(
    (id: string) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SamPolicies],
        refetchType: 'all',
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SamPolicy, id],
      })
      queryClient.resetQueries({
        queryKey: [QueryKey.SamAuditLogs, 'access-policy', id],
      })
    },
    [queryClient],
  )

  return resetCache
}
