import { AvatarBar, abbreviate, Text } from '@revolut/ui-kit'
import { match } from 'ts-pattern'
import { PrincipalComponentType } from '../../../types'

export const Principal: PrincipalComponentType = (params) =>
  match(params)
    .with({ context: 'reviewGroupAvatarBar' }, ({ principal }) => (
      <AvatarBar.Item uuid={principal.id} label={abbreviate(principal.id)} />
    ))
    .otherwise(({ principal }) => <Text>{`${principal.type}:${principal.id}`}</Text>)
