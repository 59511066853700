import { Text, VStack } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getOversightLabel } from 'utils/permission/getOversightLabel'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'

type Props = {
  permission: Permission
}
export const PermissionOversights = ({ permission }: Props) => {
  if (!permission.oversight?.length) {
    return <Text>{EMPTY_VALUE_FALLBACK}</Text>
  }

  return (
    <VStack>
      {permission.oversight.map((value, idx) => (
        <Text key={`${value}_${idx}`}>
          {getSecurityLabel(value, getOversightLabel(value)) || EMPTY_VALUE_FALLBACK}
        </Text>
      ))}
    </VStack>
  )
}
