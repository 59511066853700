import { BottomSheet, Button, TextArea } from '@revolut/ui-kit'
import { useEditPopup } from 'hooks/useEditPopup'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'

type Props = {
  onSubmit: (justification?: string) => void
  submitButtonLabel: string
}

export const JustificationPopup = ({ onSubmit, submitButtonLabel }: Props) => {
  const { closePopup } = useEditPopup()
  const [justification, setJustification] = useState<string>('')
  const changeHandler = useInputStringChange(setJustification)
  const submit = useCallback(
    () => onSubmit(justification || undefined),
    [onSubmit, justification],
  )
  return (
    <>
      <TextArea
        label="Justification"
        rows={5}
        onChange={changeHandler}
        value={justification}
      />

      <BottomSheet.Actions horizontal>
        <Button elevated onClick={closePopup} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={submit} variant="primary">
          {submitButtonLabel}
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
