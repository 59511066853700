import { Permission, PermissionAction as TPermissionAction } from 'api/idave/permissions'

export const getActionLabel = (action: Permission['action']) => {
  switch (action) {
    case TPermissionAction.delete:
      return 'Delete'
    case TPermissionAction.download:
      return 'Download / Export'
    case TPermissionAction.read:
      return 'Read'
    case TPermissionAction.update:
      return 'Update / Write'
    default:
      return undefined
  }
}
