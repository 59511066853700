import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ActionButtonProps } from '@revolut/ui-kit'
import { Client, ClientCustomization } from 'api/idave/clients'
import { IDAVE_PERMISSIONS } from 'security'
import { DELETE_CLIENT, EDIT_CLIENT } from 'view/Clients/permissions'

type GetClientActionsParams = {
  permissionUtils: ReturnType<typeof usePermissions>
  client: Client
  customization?: ClientCustomization
  onEditClick: () => void
  onDeleteClick: () => void
  onResetClick: () => void
}

type GetClientActionsResult = Array<{
  variant: ActionButtonProps['variant']
  useIcon: ActionButtonProps['useIcon']
  onClick: () => void
  label: string
}>

export const getClientActions = ({
  permissionUtils: { hasSomePermissions, hasPermission },
  customization,
  client,
  onEditClick,
  onDeleteClick,
  onResetClick,
}: GetClientActionsParams): GetClientActionsResult => {
  const result: GetClientActionsResult = []

  if (client.state !== 'ACTIVE') {
    return result
  }

  /**
   * if client belong to idave => only client action checks
   * otherwise check for customization permissions
   */
  const canEdit =
    hasSomePermissions(...EDIT_CLIENT) &&
    (client.source === 'idave' ||
      hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_UPDATE_CLIENTS))

  if (canEdit) {
    result.push({
      label: 'Edit',
      onClick: onEditClick,
      useIcon: 'Pencil',
      variant: 'primary',
    })
  }

  if (hasPermission(IDAVE_PERMISSIONS.CUSTOMIZATIONS_DELETE_CLIENTS) && !!customization) {
    result.push({
      label: 'Reset',
      onClick: onResetClick,
      useIcon: 'ExclamationTriangle',
      variant: 'negative',
    })
  }

  if (hasSomePermissions(...DELETE_CLIENT) && client.source === 'idave') {
    result.push({
      label: 'Delete',
      onClick: onDeleteClick,
      useIcon: 'Delete',
      variant: 'negative',
    })
  }

  return result
}
