import { Box, Subheader, Widget } from '@revolut/ui-kit'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { CrossCheckRulesSkeleton } from './components/CrossCheckRulesSkeleton'
import { CrossCheckRule } from './components/CrossCheckRule'

type Props = {
  crossCheck?: CrossCheckDetail
}

export const CrossCheckRules = (props: Props) => {
  const { crossCheck } = props

  if (crossCheck && !crossCheck.rules.length) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Approval requirements</Subheader.Title>
      </Subheader>

      {crossCheck ? <Inner crossCheck={crossCheck} /> : <CrossCheckRulesSkeleton />}
    </Box>
  )
}

export const Inner = (props: { crossCheck: CrossCheckDetail }) => {
  const {
    crossCheck: { rules, events, reviewerGroups },
  } = props
  return (
    <Widget pt="s-8" pb="s-8">
      {rules.map((rule, idx) => (
        <CrossCheckRule rule={rule} groups={reviewerGroups} events={events} key={idx} />
      ))}
    </Widget>
  )
}
