import { useQuery } from '@tanstack/react-query'
import { FetchEntities } from 'view/XChecks/XCheck/lib/hooks'
import { useQueryData } from 'view/XChecks/XCheck/lib/utils'

const ENTITY_FETCHER_KEY = 'ENTITY_FETCHER_KEY'

export const useEntityFetcher = (fetcher: FetchEntities, entityType: string) =>
  useQueryData(
    useQuery({
      queryKey: [ENTITY_FETCHER_KEY, entityType],
      queryFn: fetcher,
    }),
  )
