import { CopyableText } from 'components/CopyableText'
import { getBackofficeBusinessUrl, getBackofficeUserUrl } from 'utils/url'
import { ExternalLink } from 'components/Links/ExternalLink'
import { EntityComponentType, EntityDescriptor } from 'view/XChecks/XCheck/lib/types'
import { Param, TextParam } from 'api/types/xChecks'
import { getContextTextStyle, searchParam } from 'view/XChecks/XCheck/lib/utils'
import { DefaultEntity } from './DefaultEntity'

export const RegisteredIdentity: EntityComponentType = (props) => {
  const { descriptor, context, attributes } = props

  const textStyle = getContextTextStyle(context)
  const href = getRegisteredIdentityUrl(descriptor, attributes)

  if (href) {
    return (
      <ExternalLink textProps={textStyle} href={href} target="_blank">
        {descriptor.name}
      </ExternalLink>
    )
  }

  return descriptor.id ? (
    <CopyableText value={descriptor.id} textProps={textStyle} />
  ) : (
    <DefaultEntity {...props} />
  )
}

enum IDENTITY_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  BUSINESS = 'BUSINESS',
  IDENTITY_GROUP = 'IDENTITY_GROUP',
}

const getRegisteredIdentityUrl = (
  entity: EntityDescriptor,
  entityAttributes: Param[] = [],
) => {
  const { id } = entity
  const identityType = searchParam<TextParam>(entityAttributes, 'identityType', 'text')
  const identityTypeValue = identityType?.value.value

  if (!id || !identityTypeValue) {
    return undefined
  }

  switch (identityTypeValue) {
    case IDENTITY_TYPE.INDIVIDUAL:
      return getBackofficeUserUrl(id)
    case IDENTITY_TYPE.BUSINESS:
      return getBackofficeBusinessUrl(id)
    default:
      return undefined
  }
}
