import { ArrayParam, EntityParam, Param } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { EntityToEntityViewFn } from 'view/XChecks/XCheck/lib/types'
import { valueTypeMatcher } from 'view/XChecks/XCheck/lib/utils/params'
import {
  extendContexts,
  extendDetails,
  extendTables,
  extendWidgets,
  isComplexEntity,
} from '../../../utils'

export type ParamsView = {
  details: Param[]
  tables: ArrayParam[]
  entityContexts: EntityParam[]
  widgets: EntityParam[]
}
type GetParamsView = {
  params: Param[]
  entityToEntityView: EntityToEntityViewFn
  isInContext: boolean
}
export const getParamsView = ({
  entityToEntityView,
  params,
  isInContext,
}: GetParamsView) => {
  return params.reduce<ParamsView>(
    (result, param) => {
      return match(param)
        .with(valueTypeMatcher('array'), addArrayToParamsView(result))
        .with(
          valueTypeMatcher('entity'),
          addEntityToParamsView(result, entityToEntityView, isInContext),
        )
        .otherwise(() => extendDetails(result, param))
    },
    {
      details: [],
      tables: [],
      entityContexts: [],
      widgets: [],
    },
  )
}

const addEntityToParamsView =
  (
    paramsView: ParamsView,
    entityToEntityView: EntityToEntityViewFn,
    isInContext: boolean,
  ) =>
  (param: EntityParam) =>
    match({
      entityView: entityToEntityView(param.value),
      isInContext,
    })
      .with({ entityView: 'context', isInContext: true }, () =>
        extendDetails<Param, ParamsView>(paramsView, param),
      )
      .with({ entityView: 'context', isInContext: false }, () =>
        extendContexts(paramsView, param),
      )
      .with({ entityView: 'widget' }, () => extendWidgets(paramsView, param))
      .otherwise(() => extendDetails<Param, ParamsView>(paramsView, param))

const addArrayToParamsView = (paramsView: ParamsView) => (param: ArrayParam) =>
  match(param)
    .when(isComplexEntityArray, (array) => extendTables(paramsView, array))
    .otherwise(() => extendDetails<Param, ParamsView>(paramsView, param))

const isComplexEntityArray = (array: ArrayParam) => {
  const items = array.value.value
  return items?.some(isComplexEntity)
}
