import { Action, HStack, Text, TextProps, copyToClipboard } from '@revolut/ui-kit'
import { useErrorToast } from 'hooks/useErrorToast'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'

type Props = {
  children?: React.ReactNode
  value: string
  textProps?: TextProps
}
export const CopyableText = ({ children, value, textProps }: Props) => {
  const { showSuccessToast } = useToasts()
  const handler = useErrorToast()

  const onClick = useCallback(
    () =>
      copyToClipboard(value)
        .then(() => showSuccessToast('Copied'))
        .catch((err) => handler(err)),
    [showSuccessToast, handler, value],
  )

  return (
    <HStack space="s-8">
      <Action useIcon="Copy" onClick={onClick} />
      {children || <Text {...textProps}>{value}</Text>}
    </HStack>
  )
}
