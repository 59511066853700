import { Box, Subheader } from '@revolut/ui-kit'
import { CopyableText } from 'components/CopyableText'
import { Client } from 'api/idave/clients'
import { DetailsGroup } from 'components/DetailsGroup'
import { EntityDescription } from 'components/EntityDescription'
import { GroupName } from 'components/GroupName'
import { TowerComponentLink } from 'components/Links/TowerComponentLink'
import { notNullable } from 'utils/common'
import { capitalizeFirst } from 'utils/string'
import { StatusText } from 'components/StatusText'

export const ClientInfo = (props: { client: Client }) => {
  const { client } = props
  const ownerGroupId = client.ownerGroupId

  return (
    <>
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <DetailsGroup
          hideEmpty
          rows={[
            ['Name', client.name],
            ['ID', <CopyableText value={client.id} key={client.id} />],
            ['Scope', client.scope],
            ownerGroupId
              ? ['Owners', <GroupName id={ownerGroupId} key={ownerGroupId} />]
              : undefined,
            ['Source', client.source],
            [
              'Reference',
              client.externalRef?.origin === 'tower' ? (
                <TowerComponentLink component={client.externalRef.reference} />
              ) : null,
            ],
            [
              'SA auth enabled',
              notNullable(client.enableSAAuthentication) ? (
                <StatusText
                  fontSize="caption"
                  level={client.enableSAAuthentication ? 'success' : 'warning'}
                >
                  {capitalizeFirst(client.enableSAAuthentication.toString())}
                </StatusText>
              ) : null,
            ],
          ]}
        />
      </Box>
      <EntityDescription description={client.description} />
    </>
  )
}
