import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useEditPopup } from 'hooks/useEditPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { pluralForm } from 'utils/string'
import { AddAccessPayload, RevokeAccessPayload } from 'api/dart/types'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { DART_PERMISSIONS } from 'security'
import { JustificationPopup } from 'components/JustificationPopup'
import { AddCompanyPopup, AddCompanyPopupData } from './components'

export const useAddCompanies = (employeeId: string) => {
  const { setPopup, closePopup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_ADD_COMPANY_ACCESS,
    DART_PERMISSIONS.EMPLOYEE_COMPANIES_ADD_COMPANY_ACCESS_CROSS_CHECK,
  )

  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { mutate: add } = useMutation({
    mutationFn: (payload: AddAccessPayload) =>
      dartApi.employee.addAccess(employeeId, {
        payload,
        crossCheck: isCrossCheck,
      }),
    onMutate(payload) {
      showLoadingPopup({
        title: pluralForm(payload.companyIds.length, [
          'Access adding',
          'Accesses adding',
        ]),
      })
    },
    onSuccess(response, payload) {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        showSuccessToast(
          pluralForm(payload.companyIds.length, ['Access added', 'Accesses added']),
        )
        queryClient.invalidateQueries([QueryKey.EmployeeDetails, employeeId])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Access addition failed',
        error,
      })
    },
  })

  const addModifiers = useCallback(
    (params: AddCompanyPopupData) => {
      add(params)
    },
    [add],
  )

  const onAddCompanies = useCallback(() => {
    setPopup({
      title: 'Add companies',
      content: <AddCompanyPopup employeeId={employeeId} add={addModifiers} />,
      focusTrap: false,
    })
  }, [addModifiers, employeeId, setPopup])

  return { onAddCompanies }
}

export const useDeleteCompany = (employeeId: string) => {
  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()
  const { showSuccessToast } = useToasts()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const queryClient = useQueryClient()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    DART_PERMISSIONS.EMPLOYEES_REVOKE_COMPANY_ACCESS,
    DART_PERMISSIONS.EMPLOYEES_REVOKE_COMPANY_ACCESS_CROSS_CHECK,
  )
  const { mutate: revoke } = useMutation({
    mutationFn: (payload: RevokeAccessPayload) =>
      dartApi.employee.revokeAccess(
        employeeId,

        {
          payload,
          crossCheck: isCrossCheck,
        },
      ),
    onMutate(payload) {
      showLoadingPopup({
        title: pluralForm(payload.companyIds.length, [
          'Access deleting',
          'Accesses deleting',
        ]),
      })
    },
    onSuccess(response) {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closeEditPopup()
      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        showSuccessToast('Access revoked')
        queryClient.invalidateQueries([QueryKey.EmployeeDetails, employeeId])
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Access revocation failed',
        error,
      })
      closeEditPopup()
    },
  })

  const onDeleteCompany = useCallback(
    (companyId: string) => {
      setEditPopup(
        getJustificationPopup({
          onSubmit: revoke,
          companyId,
        }),
      )
    },
    [revoke, setEditPopup],
  )

  return { onDeleteCompany }
}

const getJustificationPopup = (params: {
  onSubmit: (payload: RevokeAccessPayload) => void
  companyId: string
}) => {
  const { companyId, onSubmit } = params
  const onSubmitClick = (justification?: string) =>
    onSubmit({
      companyIds: [companyId],
      justification,
    })
  return {
    title: 'Are you sure you want to revoke access to customer company?',
    description: 'User will no longer have access to the customer company data.',
    isBottomSheet: true,
    content: <JustificationPopup onSubmit={onSubmitClick} submitButtonLabel="Revoke" />,
  }
}
