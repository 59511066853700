import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { EntityParam } from 'api/types/xChecks'
import { useMemo } from 'react'
import { InputProps } from '../../../../../type'
import { unknownEntityUpdate } from '../../../../../utils'
import {
  getStandalonePath,
  getStandaloneLabel,
  useStandaloneValidate,
} from '../../../../../utils'
import { FieldInput } from '../../../../../../Fields'

export const EntityIdInput = ({ param, prefix, index }: InputProps<EntityParam>) => {
  const setValueAs = useMemo(() => unknownEntityUpdate(param.value), [param])

  return (
    <FieldInput
      path={getStandalonePath(prefix, index)}
      label={getStandaloneLabel(param)}
      defaultValue={getEntityDescriptor(param.value).id}
      disabled={!param.value.editConfig}
      setValueAs={setValueAs}
      validate={useStandaloneValidate(param)}
    />
  )
}
