import { State, Decision, RuleTypeDescriptor } from 'api/types/xChecks'
import { getRulesByType, isProdDomain } from 'view/XChecks/XCheck/lib/utils'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GetActionsParams, XCheckActionSettings } from '../types'

export const getApproveAction = ({
  xCheck,
  xCheckId,
  userId,
  hasXCheckPermission,
  onSubmit,
}: GetActionsParams): XCheckActionSettings | undefined => {
  if (
    !xCheck ||
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_REVIEW) ||
    xCheck.state !== State.AwaitingReview ||
    !userId
  ) {
    return undefined
  }

  const isRequester = xCheck.requester.id === userId
  if (isRequester && isProdDomain()) {
    return undefined
  }

  const alreadyApproved = xCheck.reviews?.some(
    (review) => review.author.id === userId && review.decision === Decision.approve,
  )
  if (alreadyApproved) {
    return undefined
  }

  const hasReviewCountRule = !!getRulesByType(RuleTypeDescriptor.counting, xCheck).length
  const reviewGroups = getRulesByType(RuleTypeDescriptor.group, xCheck)
  const isUserInGroup = reviewGroups.some((groupRule) =>
    groupRule.config.reviewers.some((reviewer) => reviewer.id === userId),
  )

  if (!hasReviewCountRule && !isUserInGroup) {
    return undefined
  }

  return {
    onSubmit,
    xCheckId,
    actionType: 'approve',
    popupSettings: {
      title: 'Approve request',
      primaryActionLabel: 'Approve',
      description:
        'Are you sure you want to approve this request? Once approved, the request will move forward in the process.',
      primaryActionVariant: 'primary',
      textAreaLabel: 'Justification (optional)',
      isTextRequired: false,
    },
    actionSettings: {
      label: 'Approve',
      variant: 'accent',
      useIcon: 'Check',
    },
  }
}
