import { Box, Subheader } from '@revolut/ui-kit'
import { Role } from 'api/idave/roles'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { GroupName } from 'components/GroupName'
import { GroupOwners } from 'components/GroupOwners'
import { RoleLink } from 'components/Links/RoleLink'
import { RoleDataClassification } from 'components/RoleClassification'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const Details = (props: { role: Role }) => {
  const { role } = props
  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          [
            'ID',
            <CopyableText value={role.id} key="id">
              {role.id}
            </CopyableText>,
          ],
          [
            'Owner group',
            role.ownerGroupId ? (
              <GroupName id={role.ownerGroupId} key="groupName" />
            ) : (
              EMPTY_VALUE_FALLBACK
            ),
          ],
          role.ownerGroupId
            ? ['Owner', <GroupOwners groupId={role.ownerGroupId} key="groupOwner" />]
            : undefined,
          [
            'Parent',
            role?.parent ? (
              <RoleLink id={role.parent} key="parentRole" />
            ) : (
              EMPTY_VALUE_FALLBACK
            ),
          ],
          ['Classification', <RoleDataClassification role={role} key="classification" />],
        ]}
      />
    </Box>
  )
}
