import { QueryResult } from 'utils/query'
import { XChecksFilters } from './types'

export const isEmptyFilters = (filters: XChecksFilters) => {
  const filterKeys = Object.keys(filters) as Array<keyof typeof filters>

  return filterKeys.every((key) => {
    const filter = filters[key]
    return Array.isArray(filter) ? !filter.length : !filter
  })
}

export const getSelectLoadingState = (loadingStatus: QueryResult) => {
  switch (loadingStatus) {
    case 'loading':
      return 'pending'
    case 'success':
      return 'ready'
    default:
      return 'failed'
  }
}
