import { TableColumn } from '@revolut/ui-kit'
import { XChecksListItem } from 'api/types/xChecks'
import { ENTITY_TABLE_CELL } from './components/EntityTableColumn'
import { EXECUTABLE_TABLE_CELL } from './components/ExecutableTableColumn'
import { STATE_TABLE_CELL } from './components/StateTableColumn'
import { SOURCE_TABLE_CELL } from './components/SourceTableColumn'
import { DateTimeTableCell } from './components/DateTimeTableCell'
import { REQUESTER_TABLE_CELL } from './components/RequesterTableColumn'

export const COLUMNS: TableColumn<XChecksListItem>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    // xChecks API does not provide sorting order functionality
    disableSortBy: true,
  },
  STATE_TABLE_CELL,
  SOURCE_TABLE_CELL,
  EXECUTABLE_TABLE_CELL,
  REQUESTER_TABLE_CELL,
  ENTITY_TABLE_CELL,
  {
    Header: 'Created date',
    accessor: 'createdDate',
    Cell: DateTimeTableCell,
  },

  {
    Header: 'Updated date',
    accessor: 'updatedDate',
    Cell: DateTimeTableCell,
  },
]
