import { createContext, useContext } from 'react'
import { useToggle } from '@revolut/ui-kit'
import {
  EntityComponentType,
  EntityToEntityViewFn,
  GetEntityUrlFn,
  PrincipalComponentType,
  WidgetComponentType,
} from '../../types'
import { entityToEntityView } from './utils'
import { Principal, Entity, Widget } from './components'
import { noop } from '../utils'
import { ViewSettings } from './types'

type TViewsContext = {
  Principal: PrincipalComponentType
  Entity: EntityComponentType
  Widget: WidgetComponentType
  entityToEntityView: EntityToEntityViewFn
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  getEntityUrl: GetEntityUrlFn
}

const VIEWS_CONTEXT = {
  Principal,
  Entity,
  Widget,
  entityToEntityView,
  isEditing: false,
  setIsEditing: noop,
  getEntityUrl: noop,
}

const ViewsContext = createContext<TViewsContext>(VIEWS_CONTEXT)

type ViewsProviderProps = ViewSettings & {
  children?: React.ReactNode
}

export const ViewsProvider = ({ children, ...views }: ViewsProviderProps) => {
  const [isEditing, setIsEditing] = useToggle({ defaultState: false })
  const value = { ...VIEWS_CONTEXT, ...views, isEditing, setIsEditing }

  return <ViewsContext.Provider value={value}>{children}</ViewsContext.Provider>
}

export const useViews = () => useContext(ViewsContext)
