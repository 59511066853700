import { Text, Token } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { ToxicPair } from 'api/idave/toxic'
import { UserStates } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { capitalizeFirst } from 'utils/string'

type State = UserStates | Client['state'] | ToxicPair['state']

type Props = {
  state?: State
}

const stateToColor = (state: State) => {
  switch (state) {
    case 'ACTIVE':
      return Token.color.green
    case 'DELETED':
    case 'TERMINATED':
    default:
      return Token.color.red
  }
}

export const StateText = ({ state }: Props) => {
  if (!state) {
    return <Text>{EMPTY_VALUE_FALLBACK}</Text>
  }

  return <Text color={stateToColor(state)}>{capitalizeFirst(state)}</Text>
}
