import { VStack } from '@revolut/ui-kit'
import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { Param } from 'api/types/xChecks'
import { Details } from '../common/Details'
import { Tables } from '../common/Tables'
import { Widgets } from '../common/Widgets/Widgets'
import { getParamsView } from './utils'
import { EntitiesParams } from './components/EntitiesParams'
import { EmptyParams } from './EmptyParams'

type Props = {
  params: Param[]
}
export const Params = ({ params }: Props) => {
  if (!params.length) {
    return <EmptyParams />
  }
  return <Inner params={params} />
}

const Inner = ({ params }: Props) => {
  const { entityToEntityView } = useViews()
  const { details, tables, widgets, entityContexts } = getParamsView({
    params,
    entityToEntityView,
    isInContext: false,
  })

  return (
    <VStack gap="s-16">
      <Details title="Details" details={details} />
      <Tables tables={tables} />
      <EntitiesParams entityContexts={entityContexts} />
      <Widgets widgets={widgets} />
    </VStack>
  )
}
