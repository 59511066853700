/* eslint-disable prettier/prettier */
export const IDAVE_PERMISSIONS = {
  AUDIT_LOG_VIEW_LIST: {
    endpoints: [
      'GET /audit-log'
    ],
    service: 'idave',
    value: 'audit_log.view.list'
  },
  AUTH_CREATE: {
    endpoints: [
      'POST /auth'
    ],
    service: 'idave',
    value: 'auth.create'
  },
  AUTH_CREATE_2FA_RESEND: {
    endpoints: [
      'POST /auth/2fa/resend'
    ],
    service: 'idave',
    value: 'auth.create.2fa.resend'
  },
  AUTH_CREATE_2FA_VERIFY: {
    endpoints: [
      'POST /auth/2fa/verify'
    ],
    service: 'idave',
    value: 'auth.create.2fa.verify'
  },
  AUTH_CREATE_CREDENTIALS: {
    endpoints: [
      'POST /auth/credentials'
    ],
    service: 'idave',
    value: 'auth.create.credentials'
  },
  AUTH_CREATE_GOOGLE_TOKEN: {
    endpoints: [
      'POST /auth/google-token'
    ],
    service: 'idave',
    value: 'auth.create.google_token'
  },
  AUTH_CREATE_LOGOUT: {
    endpoints: [
      'POST /auth/logout'
    ],
    service: 'idave',
    value: 'auth.create.logout'
  },
  AUTH_CREATE_SESSION: {
    endpoints: [
      'POST /auth/session'
    ],
    service: 'idave',
    value: 'auth.create.session'
  },
  AUTH_VIEW_2FA_VERIFY_LIST: {
    endpoints: [
      'GET /auth/2fa/verify'
    ],
    service: 'idave',
    value: 'auth.view.2fa.verify.list'
  },
  AUTH_VIEW_COMPLETE_LIST: {
    endpoints: [
      'GET /auth/complete'
    ],
    service: 'idave',
    value: 'auth.view.complete.list'
  },
  AUTH_VIEW_LIST: {
    endpoints: [
      'GET /auth'
    ],
    service: 'idave',
    value: 'auth.view.list'
  },
  AUTH_VIEW_SESSION_LIST: {
    endpoints: [
      'GET /auth/session'
    ],
    service: 'idave',
    value: 'auth.view.session.list'
  },
  BLOCK_LISTS_VIEW_LIST: {
    endpoints: [
      'GET /block-lists',
      'GET /service-api/block-lists'
    ],
    service: 'idave',
    value: 'block_lists.view.list'
  },
  CLIENTS_CREATE: {
    endpoints: [
      'POST /client',
      'PUT /clients'
    ],
    service: 'idave',
    value: 'clients.create'
  },
  CLIENTS_DELETE: {
    endpoints: [
      'DELETE /client/:clientId'
    ],
    service: 'idave',
    value: 'clients.delete'
  },
  CLIENTS_UPDATE: {
    endpoints: [
      'PUT /client/:clientId'
    ],
    service: 'idave',
    value: 'clients.update'
  },
  CLIENTS_VIEW_CURRENT_ROLES_LIST: {
    endpoints: [
      'GET /service-api/clients/current/roles'
    ],
    service: 'idave',
    value: 'clients.view.current.roles.list'
  },
  CLIENTS_VIEW_DETAILS: {
    endpoints: [
      'GET /clients/:clientId'
    ],
    service: 'idave',
    value: 'clients.view.details'
  },
  CLIENTS_VIEW_LIST: {
    endpoints: [
      'GET /clients',
      'GET /service-api/clients'
    ],
    service: 'idave',
    value: 'clients.view.list'
  },
  CLIENTS_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /service-api/clients/:clientIdOrName/roles'
    ],
    service: 'idave',
    value: 'clients.view.roles.list'
  },
  CLIENT_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /client/:clientIdOrName/roles'
    ],
    service: 'idave',
    value: 'client.view.roles.list'
  },
  CROSS_CHECKS_COMMENT: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/comment'
    ],
    service: 'idave',
    value: 'cross_checks.comment'
  },
  CROSS_CHECKS_CREATE_GROUPS: {
    endpoints: [
      'POST /cross-checks/groups'
    ],
    service: 'idave',
    value: 'cross_checks.create.groups'
  },
  CROSS_CHECKS_DECLINE: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/decline'
    ],
    service: 'idave',
    value: 'cross_checks.decline'
  },
  CROSS_CHECKS_JUSTIFY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/justify'
    ],
    service: 'idave',
    value: 'cross_checks.justify'
  },
  CROSS_CHECKS_REFRESH: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/refresh'
    ],
    service: 'idave',
    value: 'cross_checks.refresh'
  },
  CROSS_CHECKS_RETRY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/retry'
    ],
    service: 'idave',
    value: 'cross_checks.retry'
  },
  CROSS_CHECKS_REVIEW: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/review'
    ],
    service: 'idave',
    value: 'cross_checks.review'
  },
  CROSS_CHECKS_RUN_CLIENT_CREATE_ACTION: {
    endpoints: [
      'POST /client',
      'PUT /clients'
    ],
    service: 'idave',
    value: 'cross_checks.run.client_create_action'
  },
  CROSS_CHECKS_RUN_CLIENT_DELETE_ACTION: {
    endpoints: [
      'DELETE /client/:clientId'
    ],
    service: 'idave',
    value: 'cross_checks.run.client_delete_action'
  },
  CROSS_CHECKS_RUN_CLIENT_UPDATE_ACTION: {
    endpoints: [
      'PUT /client/:clientId'
    ],
    service: 'idave',
    value: 'cross_checks.run.client_update_action'
  },
  CROSS_CHECKS_RUN_ROLE_ASSIGN_ACTION: {
    endpoints: [
      'POST /users/:userId/roles'
    ],
    service: 'idave',
    value: 'cross_checks.run.role_assign_action'
  },
  CROSS_CHECKS_RUN_ROLE_CREATE_ACTION: {
    endpoints: [
      'POST /roles'
    ],
    service: 'idave',
    value: 'cross_checks.run.role_create_action'
  },
  CROSS_CHECKS_RUN_ROLE_UPDATE_ACTION: {
    endpoints: [
      'PUT /roles/:roleId'
    ],
    service: 'idave',
    value: 'cross_checks.run.role_update_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_ACTIVATE_ACTION: {
    endpoints: [
      'POST /service-accounts/:saId/activate'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_activate_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_CREATE_ACTION: {
    endpoints: [
      'POST /service-accounts'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_create_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_DELETE_ACTION: {
    endpoints: [
      'DELETE /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_delete_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_TERMINATE_ACTION: {
    endpoints: [
      'POST /service-accounts/:saId/terminate'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_terminate_action'
  },
  CROSS_CHECKS_RUN_SERVICE_ACCOUNT_UPDATE_ACTION: {
    endpoints: [
      'PUT /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'cross_checks.run.service_account_update_action'
  },
  CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS: {
    endpoints: [
      'POST /cross-checks/groups/:crossCheckGroupId/reviewers'
    ],
    service: 'idave',
    value: 'cross_checks.update.groups.reviewers'
  },
  CROSS_CHECKS_UPDATE_PARAMS: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/params'
    ],
    service: 'idave',
    value: 'cross_checks.update.params'
  },
  CROSS_CHECKS_VIEW_CHANGES_LIST: {
    endpoints: [
      'GET /cross-checks/:crossCheckId/changes'
    ],
    service: 'idave',
    value: 'cross_checks.view.changes.list'
  },
  CROSS_CHECKS_VIEW_DETAILS: {
    endpoints: [
      'GET /cross-checks/:crossCheckId'
    ],
    service: 'idave',
    value: 'cross_checks.view.details'
  },
  CROSS_CHECKS_VIEW_GROUPS_DETAILS: {
    endpoints: [
      'GET /cross-checks/groups/:crossCheckGroupId'
    ],
    service: 'idave',
    value: 'cross_checks.view.groups.details'
  },
  CROSS_CHECKS_VIEW_LIST: {
    endpoints: [
      'GET /cross-checks'
    ],
    service: 'idave',
    value: 'cross_checks.view.list'
  },
  CROSS_CHECKS_VIEW_PARAMS_LIST: {
    endpoints: [
      'GET /cross-checks/:crossCheckId/params'
    ],
    service: 'idave',
    value: 'cross_checks.view.params.list'
  },
  CUSTOMIZATIONS_DELETE_CLIENTS: {
    endpoints: [
      'DELETE /customizations/clients/:clientId'
    ],
    service: 'idave',
    value: 'customizations.delete.clients'
  },
  CUSTOMIZATIONS_DELETE_SERVICE_ACCOUNTS: {
    endpoints: [
      'DELETE /customizations/service-accounts/:saId'
    ],
    service: 'idave',
    value: 'customizations.delete.service_accounts'
  },
  CUSTOMIZATIONS_UPDATE_CLIENTS: {
    endpoints: [],
    service: 'idave',
    value: 'customizations.update.clients'
  },
  CUSTOMIZATIONS_UPDATE_SERVICE_ACCOUNTS: {
    endpoints: [],
    service: 'idave',
    value: 'customizations.update.service_accounts'
  },
  CUSTOMIZATIONS_VIEW_CLIENTS_DETAILS: {
    endpoints: [
      'GET /customizations/clients/:clientId'
    ],
    service: 'idave',
    value: 'customizations.view.clients.details'
  },
  CUSTOMIZATIONS_VIEW_CLIENTS_LIST: {
    endpoints: [
      'GET /customizations/clients'
    ],
    service: 'idave',
    value: 'customizations.view.clients.list'
  },
  CUSTOMIZATIONS_VIEW_SERVICE_ACCOUNTS_DETAILS: {
    endpoints: [
      'GET /customizations/service-accounts/:saId'
    ],
    service: 'idave',
    value: 'customizations.view.service_accounts.details'
  },
  CUSTOMIZATIONS_VIEW_SERVICE_ACCOUNTS_LIST: {
    endpoints: [
      'GET /customizations/service-accounts'
    ],
    service: 'idave',
    value: 'customizations.view.service_accounts.list'
  },
  GROUPS_CREATE: {
    endpoints: [
      'POST /groups',
      'POST /service-api/groups'
    ],
    service: 'idave',
    value: 'groups.create'
  },
  GROUPS_DELETE: {
    endpoints: [
      'DELETE /groups/:groupId',
      'DELETE /service-api/groups/:groupId'
    ],
    service: 'idave',
    value: 'groups.delete'
  },
  GROUPS_UPDATE: {
    endpoints: [
      'PUT /groups/:groupId',
      'PUT /service-api/groups/:groupId'
    ],
    service: 'idave',
    value: 'groups.update'
  },
  GROUPS_VIEW_DETAILS: {
    endpoints: [
      'GET /groups/:groupId',
      'GET /service-api/groups/:groupId'
    ],
    service: 'idave',
    value: 'groups.view.details'
  },
  GROUPS_VIEW_LIST: {
    endpoints: [
      'GET /groups',
      'GET /service-api/groups'
    ],
    service: 'idave',
    value: 'groups.view.list'
  },
  HEALTHCHECK_VIEW_LIST: {
    endpoints: [
      'GET /healthcheck'
    ],
    service: 'idave',
    value: 'healthcheck.view.list'
  },
  JOBS_RUN: {
    endpoints: [
      'GET /jobs/:name'
    ],
    service: 'idave',
    value: 'jobs.run'
  },
  MIGRATIONS_CREATE_CLIENT_ROLES: {
    endpoints: [
      'POST /migrations/client-roles'
    ],
    service: 'idave',
    value: 'migrations.create.client_roles'
  },
  OAUTH2_CREATE_LOGOUT: {
    endpoints: [
      'POST /oauth2/logout'
    ],
    service: 'idave',
    value: 'oauth2.create.logout'
  },
  OAUTH2_VIEW_BACKUP_DETAILS: {
    endpoints: [
      'GET /oauth2/backup'
    ],
    service: 'idave',
    value: 'oauth2.view.backup.details'
  },
  OAUTH2_VIEW_CODE_LIST: {
    endpoints: [
      'GET /oauth2/code'
    ],
    service: 'idave',
    value: 'oauth2.view.code.list'
  },
  OIDC_RESET_KEYS: {
    endpoints: [
      'POST /oidc/keys/reset'
    ],
    service: 'idave',
    value: 'oidc.reset.keys'
  },
  OIDC_ROTATE_KEYS: {
    endpoints: [
      'POST /oidc/keys/rotate'
    ],
    service: 'idave',
    value: 'oidc.rotate.keys'
  },
  OIDC_VIEW_KEYS_LIST: {
    endpoints: [
      'GET /oidc/keys',
      'GET /service-api/oidc/keys'
    ],
    service: 'idave',
    value: 'oidc.view.keys.list'
  },
  ORGANISATIONS_CREATE: {
    endpoints: [
      'POST /organisations'
    ],
    service: 'idave',
    value: 'organisations.create'
  },
  ORGANISATIONS_DELETE: {
    endpoints: [
      'DELETE /organisations/:organisationId'
    ],
    service: 'idave',
    value: 'organisations.delete'
  },
  ORGANISATIONS_UPDATE: {
    endpoints: [
      'PUT /organisations/:organisationId'
    ],
    service: 'idave',
    value: 'organisations.update'
  },
  ORGANISATIONS_VIEW_DETAILS: {
    endpoints: [
      'GET /organisations/:organisationId'
    ],
    service: 'idave',
    value: 'organisations.view.details'
  },
  ORGANISATIONS_VIEW_LIST: {
    endpoints: [
      'GET /organisations',
      'GET /service-api/organisations'
    ],
    service: 'idave',
    value: 'organisations.view.list'
  },
  ORPHANED_PERMISSIONS_VIEW_LIST: {
    endpoints: [
      'GET /orphaned-permissions'
    ],
    service: 'idave',
    value: 'orphaned_permissions.view.list'
  },
  PERMISSIONS_CREATE_SNAPSHOT: {
    endpoints: [
      'POST /permissions/snapshot',
      'POST /service-api/permissions/snapshot'
    ],
    service: 'idave',
    value: 'permissions.create.snapshot'
  },
  PERMISSIONS_UPDATE_DESCRIPTION: {
    endpoints: [
      'PATCH /permissions/:permissionId/description'
    ],
    service: 'idave',
    value: 'permissions.update.description'
  },
  PERMISSIONS_UPLOAD: {
    endpoints: [],
    service: 'idave',
    value: 'permissions.upload'
  },
  PERMISSIONS_VIEW_ACL_LIST: {
    endpoints: [
      'GET /permissions/acl'
    ],
    service: 'idave',
    value: 'permissions.view.acl.list'
  },
  PERMISSIONS_VIEW_DETAILS: {
    endpoints: [
      'GET /permissions/:permissionId'
    ],
    service: 'idave',
    value: 'permissions.view.details'
  },
  PERMISSIONS_VIEW_LIST: {
    endpoints: [
      'GET /permissions',
      'GET /service-api/permissions'
    ],
    service: 'idave',
    value: 'permissions.view.list'
  },
  PERMISSIONS_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /permissions/:permissionId/roles'
    ],
    service: 'idave',
    value: 'permissions.view.roles.list'
  },
  PERMISSIONS_VIEW_SNAPSHOT_LIST: {
    endpoints: [
      'GET /service-api/permissions/snapshot'
    ],
    service: 'idave',
    value: 'permissions.view.snapshot.list'
  },
  RECERTIFICATIONS_CREATE_CLIENT_ROLES: {
    endpoints: [
      'POST /recertifications/client-roles'
    ],
    service: 'idave',
    value: 'recertifications.create.client_roles'
  },
  REVOKE_CREATE: {
    endpoints: [
      'POST /revoke'
    ],
    service: 'idave',
    value: 'revoke.create'
  },
  ROLES_CREATE: {
    endpoints: [
      'POST /roles'
    ],
    service: 'idave',
    value: 'roles.create'
  },
  ROLES_DELETE: {
    endpoints: [
      'DELETE /roles/:roleId'
    ],
    service: 'idave',
    value: 'roles.delete'
  },
  ROLES_UPDATE: {
    endpoints: [
      'PUT /roles/:roleId'
    ],
    service: 'idave',
    value: 'roles.update'
  },
  ROLES_VIEW_DETAILS: {
    endpoints: [
      'GET /roles/:roleId'
    ],
    service: 'idave',
    value: 'roles.view.details'
  },
  ROLES_VIEW_LIST: {
    endpoints: [
      'GET /roles',
      'GET /service-api/roles'
    ],
    service: 'idave',
    value: 'roles.view.list'
  },
  SERVICE_ACCOUNTS_ACTIVATE: {
    endpoints: [
      'POST /service-accounts/:saId/activate'
    ],
    service: 'idave',
    value: 'service_accounts.activate'
  },
  SERVICE_ACCOUNTS_CREATE: {
    endpoints: [
      'POST /service-accounts'
    ],
    service: 'idave',
    value: 'service_accounts.create'
  },
  SERVICE_ACCOUNTS_DELETE: {
    endpoints: [
      'DELETE /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.delete'
  },
  SERVICE_ACCOUNTS_TERMINATE: {
    endpoints: [
      'POST /service-accounts/:saId/terminate'
    ],
    service: 'idave',
    value: 'service_accounts.terminate'
  },
  SERVICE_ACCOUNTS_UPDATE: {
    endpoints: [
      'PUT /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.update'
  },
  SERVICE_ACCOUNTS_UPDATE_PERMISSIONS: {
    endpoints: [
      'PATCH /service-api/service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.update.permissions'
  },
  SERVICE_ACCOUNTS_VIEW_DETAILS: {
    endpoints: [
      'GET /service-accounts/:saId'
    ],
    service: 'idave',
    value: 'service_accounts.view.details'
  },
  SERVICE_ACCOUNTS_VIEW_LIST: {
    endpoints: [
      'GET /service-accounts'
    ],
    service: 'idave',
    value: 'service_accounts.view.list'
  },
  SESSIONS_ROTATE: {
    endpoints: [
      'POST /sessions/rotate'
    ],
    service: 'idave',
    value: 'sessions.rotate'
  },
  TOKEN_CREATE: {
    endpoints: [
      'POST /token'
    ],
    service: 'idave',
    value: 'token.create'
  },
  TOKEN_INFO_CREATE: {
    endpoints: [
      'POST /token_info'
    ],
    service: 'idave',
    value: 'token_info.create'
  },
  TOXIC_PERMISSIONS_CREATE: {
    endpoints: [
      'POST /toxic-permissions'
    ],
    service: 'idave',
    value: 'toxic_permissions.create'
  },
  TOXIC_PERMISSIONS_DELETE: {
    endpoints: [
      'DELETE /toxic-permissions/:toxicPermissionId'
    ],
    service: 'idave',
    value: 'toxic_permissions.delete'
  },
  TOXIC_PERMISSIONS_VIEW_LIST: {
    endpoints: [
      'GET /toxic-permissions'
    ],
    service: 'idave',
    value: 'toxic_permissions.view.list'
  },
  TOXIC_PERMISSION_ALERTS_VIEW_LIST: {
    endpoints: [
      'GET /toxic-permission-alerts'
    ],
    service: 'idave',
    value: 'toxic_permission_alerts.view.list'
  },
  USERS_ASSIGN_ROLES: {
    endpoints: [
      'POST /service-api/users/:userId/roles',
      'POST /users/:userId/roles'
    ],
    service: 'idave',
    value: 'users.assign.roles'
  },
  USERS_CLEAR_ROLES: {
    endpoints: [
      'POST /service-api/users/:userId/roles/clear',
      'POST /users/:userId/roles/clear'
    ],
    service: 'idave',
    value: 'users.clear.roles'
  },
  USERS_CREATE: {
    endpoints: [
      'POST /service-api/users',
      'POST /users'
    ],
    service: 'idave',
    value: 'users.create'
  },
  USERS_CURRENT_CHANGE_PASSWORD: {
    endpoints: [
      'POST /users/password'
    ],
    service: 'idave',
    value: 'users.current.change.password'
  },
  USERS_CURRENT_VIEW_DETAILS: {
    endpoints: [
      'GET /users/current'
    ],
    service: 'idave',
    value: 'users.current.view.details'
  },
  USERS_CURRENT_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /users/current/roles'
    ],
    service: 'idave',
    value: 'users.current.view.roles.list'
  },
  USERS_NOTIFY: {
    endpoints: [
      'POST /users/notify'
    ],
    service: 'idave',
    value: 'users.notify'
  },
  USERS_RESET_PASSWORD: {
    endpoints: [
      'PUT /users/reset'
    ],
    service: 'idave',
    value: 'users.reset.password'
  },
  USERS_TERMINATE: {
    endpoints: [
      'PUT /service-api/users/terminate',
      'PUT /users/terminate'
    ],
    service: 'idave',
    value: 'users.terminate'
  },
  USERS_UNASSIGN_ROLES: {
    endpoints: [
      'DELETE /service-api/users/:userId/roles/:roleId',
      'DELETE /users/:userId/roles/:roleId',
      'POST /users/roles/unassign'
    ],
    service: 'idave',
    value: 'users.unassign.roles'
  },
  USERS_UNTERMINATE: {
    endpoints: [
      'POST /users/unterminate',
      'PUT /users/activate'
    ],
    service: 'idave',
    value: 'users.unterminate'
  },
  USERS_UPDATE: {
    endpoints: [
      'PUT /users/update'
    ],
    service: 'idave',
    value: 'users.update'
  },
  USERS_UPDATE_EXTERNAL_REF: {
    endpoints: [
      'PUT /users/:userId/external-ref'
    ],
    service: 'idave',
    value: 'users.update.external_ref'
  },
  USERS_VIEW_DETAILS: {
    endpoints: [
      'GET /service-api/users/:userId',
      'GET /users/:userId',
      'GET /users/info'
    ],
    service: 'idave',
    value: 'users.view.details'
  },
  USERS_VIEW_LIST: {
    endpoints: [
      'GET /service-api/users',
      'GET /users',
      'GET /users/all'
    ],
    service: 'idave',
    value: 'users.view.list'
  },
  USERS_VIEW_LOGS_ACCESS_LIST: {
    endpoints: [
      'GET /users/:userId/logs/access'
    ],
    service: 'idave',
    value: 'users.view.logs.access.list'
  },
  USERS_VIEW_ROLES_LIST: {
    endpoints: [
      'GET /service-api/users/:userId/roles',
      'GET /users/:userId/roles'
    ],
    service: 'idave',
    value: 'users.view.roles.list'
  },
  USERS_WITH_ROLES_VIEW_DETAILS: {
    endpoints: [
      'GET /users-with-roles/:userId'
    ],
    service: 'idave',
    value: 'users_with_roles.view.details'
  },
  WHOAMI_VIEW_DETAILS: {
    endpoints: [
      'GET /whoami'
    ],
    service: 'idave',
    value: 'whoami.view.details'
  }
} as const