import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ClientPayload } from 'api/idave/clients'
import idaveApi from 'api/idave'
import { IDAVE_PERMISSIONS } from 'security'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useToasts } from 'hooks/useToasts'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { UrlUpdateParams } from '../../../CommonClientUrls'
import { getUpdateSuccessToast } from './utils'

type Params = {
  client: ClientPayload
  update: UrlUpdateParams
}

export const useEditClientUrls = ({
  onMutate,
  onSuccess,
  onError,
}: {
  onMutate: (update: UrlUpdateParams) => void
  onSuccess: () => void
  onError: (error: unknown, update: UrlUpdateParams) => void
}) => {
  const queryClient = useQueryClient()
  const { toCrosscheck, isCrossCheck } = useCrossCheck(
    IDAVE_PERMISSIONS.CLIENTS_UPDATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_UPDATE_ACTION,
  )
  const { showSuccessToast } = useToasts()

  return useMutation({
    mutationFn: ({ client, update }: Params) => {
      onMutate(update)
      return idaveApi.clients.editClient({
        payload: client,
        crossCheck: isCrossCheck,
      })
    },
    onSuccess: (response, { update, client }) => {
      onSuccess()

      const crosscheck = getCrossCheckDescriptor(response)
      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        showSuccessToast(getUpdateSuccessToast(update.type))
        queryClient.invalidateQueries({
          queryKey: [QueryKey.Clients],
          refetchType: 'all',
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKey.Client, client.id],
          refetchType: 'all',
        })
        queryClient.invalidateQueries({
          queryKey: [QueryKey.ClientCustomization, client.id],
          refetchType: 'all',
        })
        queryClient.resetQueries({ queryKey: [QueryKey.AuditLogs, client.id] })
      }
    },
    onError: (error, { update }) => onError(error, update),
  })
}
