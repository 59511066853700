import { Flex, Icon, Table, Text, Tooltip, useTooltip } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { formatDateTime } from 'utils/date'
import { pluralForm } from 'utils/string'

type Props = {
  row: { original: { createdDate?: number; deletedDate?: number } }
}

const DAY_MS = 24 * 60 * 60 * 1000

export const LifetimeCell = (props: Props) => {
  const tooltip = useTooltip()

  const { createdDate, deletedDate } = props.row.original

  if (!createdDate) {
    return <Table.Cell>{EMPTY_VALUE_FALLBACK}</Table.Cell>
  }

  const now = Date.now()
  const days = Math.ceil(((deletedDate || now) - createdDate) / DAY_MS)

  return (
    <Table.Cell>
      <Flex alignItems="center">
        <Text mr="s-4">
          {days} {pluralForm(days, ['day', 'days'])}
        </Text>
        <Icon
          name="16/ExclamationMarkOutline"
          size={16}
          color="grey-tone-50"
          {...tooltip.getAnchorProps()}
        />
        <Tooltip {...tooltip.getTargetProps()}>
          From {formatDateTime(createdDate)}
          {deletedDate && <> to {formatDateTime(deletedDate)}</>}
        </Tooltip>
      </Flex>
    </Table.Cell>
  )
}
