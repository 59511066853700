import { UpdateTab } from './types'

export const REQUEST_INPUT_TAB = {
  type: UpdateTab.params,
  label: 'Request input',
}

export const CHANGES_TAB = {
  type: UpdateTab.changes,
  label: 'Changes',
}
