import { useCallback } from 'react'

export const useInputStringChange = (handler: (value: string) => void) =>
  useCallback(
    <Event extends { currentTarget: { value: string } } | { target: { value: string } }>(
      event: Event,
    ) => {
      const value = 'target' in event ? event.target.value : event.currentTarget.value
      return handler(value)
    },
    [handler],
  )
