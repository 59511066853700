import { PEOPLE_URL } from 'constants/urls'
import { useQueryRoleMap } from 'queries/sam/revoluters'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { QuerySwitch } from 'components/QuerySwitch'
import { TextSkeleton } from '@revolut/ui-kit'
import { ExternalTextLink } from './ExternalTextLink'

type Props = {
  id: string | null | undefined
  withId?: boolean
}

export const getRevolutersRoleLink = (id: string) => `${PEOPLE_URL}/role/preview/${id}`

export const RevolutersRoleLink = ({ id, withId }: Props) => {
  const { data, status: qs, fetchStatus: fs } = useQueryRoleMap()

  if (!id) {
    return <>{EMPTY_VALUE_FALLBACK}</>
  }

  const href = getRevolutersRoleLink(id)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderError={() => <ExternalTextLink href={href} text={id} />}
      renderIdle={() => <ExternalTextLink href={href} text={id} />}
      renderLoading={() => <TextSkeleton size={15} />}
      renderSuccess={({ data: roles }) => {
        const role = roles?.get(id)
        const text = withId && role ? `${role.name} (${id})` : role?.name
        return <ExternalTextLink href={href} text={text || id} />
      }}
    />
  )
}
