import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { IDAVE_PERMISSIONS } from 'security'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { QueryKey } from 'helpers/configQuery'
import { generatePath } from 'react-router'
import { useToasts } from 'hooks/useToasts'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { AccountFormValues } from './types'

export const useAddAccount = () => {
  const qClient = useQueryClient()
  const navigate = useNavigate()
  const { showSuccessToast } = useToasts()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_CREATE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_SERVICE_ACCOUNT_CREATE_ACTION,
  )

  return useMutation({
    mutationFn: ({ clientId, permissionIds, gcpSA }: AccountFormValues) => {
      if (!clientId) {
        return Promise.reject(new Error('Client not provided'))
      }
      const payload = {
        clientId,
        authentication: { gcpSA },
        permissionIds,
      }
      return idaveApi.accounts.addAccount({ payload, crossCheck: isCrossCheck })
    },
    onMutate: () => showLoadingPopup({ title: 'Creating...' }),
    onSuccess: (response) => {
      hideLoadingPopup()

      const crosscheck = getCrossCheckDescriptor(response)
      if (isCrossCheck) {
        return toCrosscheck(crosscheck)
      }

      showSuccessToast('Account created')

      qClient.resetQueries({
        queryKey: [QueryKey.Accounts],
      })

      if (typeof response.data === 'object' && 'id' in response.data) {
        const accountId = response.data.id

        return navigate(generatePath(Url.Account, { accountId }))
      }
      return navigate(Url.Accounts)
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Application account creation failed',
        error: err,
      })
    },
  })
}
