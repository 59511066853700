import { Text } from '@revolut/ui-kit'
import { TextParam } from 'api/types/xChecks'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { getVariantTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { ItemValueProps } from './types'

export const TextValue = ({ value, variant }: ItemValueProps<TextParam>) => {
  const textValue = value?.value
  return (
    <Text {...getVariantTextStyle(variant)}>
      {textValue || EMPTY_VALUE_FALLBACK_TEXT}
    </Text>
  )
}
