import { EntityParam } from 'api/types/xChecks'
import { useApi } from 'view/XChecks/XCheck/lib/hooks'
import { match, P } from 'ts-pattern'
import { InputProps } from '../../types'
import { EntityIdItem } from './components/EntityIdItem'
import { EntitySelectItem } from './components/EntitySelectItem'

export const EntityItem = (props: InputProps<EntityParam>) => {
  const entityType = props.value.type.entityType
  const { entitiesFetchers } = useApi()
  const fetcher = entitiesFetchers?.[entityType]

  return match(fetcher)
    .with(P.nonNullable, (fetchFn) => <EntitySelectItem {...props} fetcher={fetchFn} />)
    .otherwise(() => <EntityIdItem {...props} />)
}
