import { hashItem } from './hashItem'

export const uniq = <T>(items: T[]): T[] => {
  const seen: Record<string, true> = {}

  return items.filter((item) => {
    const hash = hashItem(item)
    if (seen[hash]) {
      return false
    }

    seen[hash] = true
    return true
  })
}
