import { useCallback } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { PageHeader } from 'components/PageHeader'

export const Header = ({ accountId }: { accountId: string }) => {
  const navigate = useNavigate()
  const toAccount = useCallback(
    () => navigate(generatePath(Url.Account, { accountId })),
    [accountId, navigate],
  )
  return <PageHeader onBack={toAccount} pageTitle="Edit Account" />
}
