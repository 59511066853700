import { useXCheckData } from '../../../../hooks'
import { useParams } from '../../../../hooks/useParams'
import { getDataValue } from '../../../../utils'
import { EmptyEditLayout } from './components/EmptyEditLayout'
import { EditForm } from './components/EditForm'

export const EditLayout = ({ onBackClick }: { onBackClick: () => void }) => {
  const paramsData = useParams()
  const { xCheckData } = useXCheckData()

  const xCheck = getDataValue(xCheckData)
  const params = getDataValue(paramsData)

  return params?.length && xCheck ? (
    <EditForm params={params} xCheck={xCheck} onBackClick={onBackClick} />
  ) : (
    <EmptyEditLayout onBackClick={onBackClick} />
  )
}
