import { Page, PageMainSize } from '@revolut/ui-kit'
import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import { AuditLogsList } from './AuditLogsList'

export const AuditLogs = () => {
  const HeaderActions = useHeaderActions()
  return (
    <>
      <PageHeader actions={HeaderActions && <HeaderActions />} pageTitle="Audit Logs" />
      <Page.Main size={PageMainSize.FULL}>
        <AuditLogsList />
      </Page.Main>
    </>
  )
}
