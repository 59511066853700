import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { generatePath, useNavigate } from 'react-router'
import { useCallback } from 'react'
import { Url } from 'routing'
import { Account, AccountCustomization } from 'api/idave/accounts'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Caption, List, Paragraph, Token, Text, Flex } from '@revolut/ui-kit'
import { useTerminateAccount } from './useTerminateAccount'
import { useActivateAccount } from './useActivateAccount'
import { useDeleteAccount } from './useDeleteAccount'
import { getAccountActions } from './utils'
import { useResetAccountAction } from './useResetAccountAction'
import { useRotateTokens } from './useRotateTokens'

export const useAccountActions = ({
  account,
  customization,
}: {
  account: Account
  customization?: AccountCustomization
}) => {
  const accountId = account.id
  const { setPopup } = useConfirmationPopup()
  const { mutate: removeAccount } = useDeleteAccount()
  const { mutate: activateAccount } = useActivateAccount()
  const { mutate: terminateAccount } = useTerminateAccount()
  const { mutate: resetAccount } = useResetAccountAction()
  const { mutate: rotateTokens } = useRotateTokens()

  const navigate = useNavigate()

  const onEditClick = useCallback(() => {
    navigate(generatePath(Url.AccountEdit, { accountId }))
  }, [accountId, navigate])

  const onResetClick = useCallback(
    () =>
      setPopup({
        title: 'Service account customization reset confirmation',
        confirmButtonText: 'Reset',
        message: 'Are you sure you want to reset account customization?',
        onConfirmClick: () => {
          resetAccount(accountId)
        },
      }),
    [resetAccount, setPopup, accountId],
  )
  const onDeleteClick = useCallback(
    () =>
      setPopup({
        title: 'Service account deletion confirmation',
        confirmButtonText: 'Delete',
        message: 'Are you sure you want to delete the service account?',
        onConfirmClick: () => {
          removeAccount(accountId)
        },
      }),
    [removeAccount, accountId, setPopup],
  )

  const onActivateClick = useCallback(
    () =>
      setPopup({
        title: 'Service account activation confirmation',
        confirmButtonText: 'Activate',
        message: 'Are you sure you want to activate the service account?',
        onConfirmClick: () => {
          activateAccount(accountId)
        },
      }),
    [accountId, activateAccount, setPopup],
  )

  const onTerminateClick = useCallback(
    () =>
      setPopup({
        title: 'Service account termination confirmation',
        confirmButtonText: 'Terminate',
        confirmButtonVariant: 'negative',
        message: (
          <>
            <Paragraph color={Token.color.foreground}>
              Are you sure you want to terminate the service account?
            </Paragraph>
            <List>
              <List.Item useIcon="16/Bullet">
                <Caption>
                  Any outgoing requests will be blocked termination of service account.
                  Application will loose connectivity with each consumed service.
                </Caption>
              </List.Item>
              <List.Item useIcon="16/Bullet">
                <Caption>
                  Re-enabling of service account will require re-deployment of the
                  application.
                </Caption>
              </List.Item>
              <List.Item useIcon="16/Bullet">
                <Caption>
                  Enabling of another authentication strategy will require re-deployment
                  or configuration adjustments of the app.
                </Caption>
              </List.Item>
            </List>
          </>
        ),
        onConfirmClick: () => {
          terminateAccount(accountId)
        },
      }),
    [accountId, setPopup, terminateAccount],
  )

  const onRotateTokensClick = useCallback(
    () =>
      setPopup({
        title: 'Rotate tokens confirmation',
        confirmButtonText: 'Rotate tokens',
        confirmButtonVariant: 'negative',
        message: (
          <Flex gap="s-8" flexDirection="column">
            <Text>Are you sure you want to rotate all the tokens?</Text>
            <Text>
              Be aware that all active sessions will be blocked <b>immediately</b>. This
              will have a short-term impact on the SLO metric.
            </Text>
          </Flex>
        ),
        onConfirmClick: () => {
          rotateTokens(account.clientId)
        },
      }),
    [setPopup, rotateTokens, account.clientId],
  )

  const permissionUtils = usePermissions()

  return getAccountActions({
    permissionUtils,
    customization,
    account,
    onDeleteClick,
    onEditClick,
    onTerminateClick,
    onActivateClick,
    onResetClick,
    onRotateTokensClick,
  })
}
