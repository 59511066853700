import { Table } from '@revolut/ui-kit'
import { Client } from 'api/idave/clients'
import { UserStates } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { capitalizeFirst } from 'utils/string'
import { StateText } from './StateText'

type State = UserStates | Client['state']

type Props = {
  row: { original: { state?: State } }
}

export const stateCellAccessor = ({ state }: { state?: State }) =>
  state ? capitalizeFirst(state) : EMPTY_VALUE_FALLBACK

export const StateCell = (props: Props) => {
  const { state } = props.row.original

  return (
    <Table.Cell>
      <StateText state={state} />
    </Table.Cell>
  )
}
