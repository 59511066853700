import { useToasts } from 'react-toast-notifications'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuerySsoUser } from 'queries/idave/auth'
import { Box, VStack, Header, Item, Link, Page, PageMainSize } from '@revolut/ui-kit'
import { isBrowserSupported } from 'helpers/utils'
import { IdaveLogin } from './IdaveLogin'
import { IdaveSso } from './IdaveSso'

const UnsupportedBrowserWarning = styled.h1`
  text-align: center;
  color: red;
`

export const SignIn = () => {
  const { addToast } = useToasts()
  useEffect(() => {
    const errorMessage = new URLSearchParams(window.location.search).get('error_message')

    if (errorMessage) {
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }, [addToast])

  const [useSsoButton, setUseSsoButton] = useState(true)
  const { isError, isLoading, data: ssoUser } = useQuerySsoUser()

  useEffect(() => {
    if (isError) {
      setUseSsoButton(false)
    }
  }, [isError])

  if (!isBrowserSupported()) {
    return (
      <UnsupportedBrowserWarning>
        Unsupported or legacy browser version
      </UnsupportedBrowserWarning>
    )
  }

  return (
    <Page variant="focused">
      <Page.Main size={PageMainSize.NARROW}>
        <Box height="100%" width="100%" maxWidth="375px" margin="auto">
          <VStack pt={{ lg: 'calc(50vh - 330px)' }}>
            <Header variant="main" alignSelf="center">
              <Header.Title>Log in to Revolut&nbsp;ID</Header.Title>
            </Header>
            <Box mt="s-24">
              {useSsoButton && (isLoading || ssoUser?.user) ? (
                <IdaveSso user={ssoUser?.user} onCancel={() => setUseSsoButton(false)} />
              ) : (
                <IdaveLogin />
              )}
            </Box>
          </VStack>
          <Box mt="s-32">
            <Item>
              <Item.Content>
                <Item.Title>Disclaimer</Item.Title>
                <Item.Description>
                  Your activities on Revolut systems are monitored, in accordance with{' '}
                  <Link href="https://backoffice.revolut.com/docs/policies/1785">
                    Information Security Awareness and Acceptable Use Policy
                  </Link>
                  , to ensure the security of our customers and company, and our
                  compliance with applicable regulations. Unauthorised or improper use of
                  Revolut systems may result in disciplinary action including termination
                  of employment
                </Item.Description>
              </Item.Content>
            </Item>
          </Box>
        </Box>
      </Page.Main>
    </Page>
  )
}
