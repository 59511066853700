import { Action, Table, TableColumn, Text } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { compareDesc } from 'date-fns'
import { formatDateTime } from 'utils/date'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'
import { capitalizeFirst } from 'utils/string'
import { notNullable } from 'utils/common'
import { useCallback, useMemo } from 'react'
import { AccessState } from 'api/sam/types'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { SAM_PERMISSIONS } from 'security'
import { getStatusLevel } from './utils'
import { Row } from './types'
import { useResourceRevoke } from './hooks'

export const useColumns = (entities: Row[], idaveUserId: string): TableColumn<Row>[] => {
  const { hasPermission } = usePermissions()
  const revoke = useResourceRevoke(idaveUserId)
  const hasActive = entities.some(({ access }) => access.state === AccessState.ACTIVE)
  const isAbleToRevoke = hasPermission(
    SAM_PERMISSIONS.TEMPORARY_RESOURCE_ACCESS_UPDATE_REVOKE,
  )
  const isRevokable = hasActive && isAbleToRevoke

  return useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: (value: Row) => value.resource.resourceName,
        },
        {
          Header: 'Type',
          accessor: (value: Row) => getResourceTypeLabel(value.resource.resourceType),
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        },
        {
          Header: 'Granted until',
          accessor: (value: Row) => formatDateTime(new Date(value.access.expirationDate)),
          sortType: (rowA: { original: Row }, rowB: { original: Row }) =>
            compareDesc(
              new Date(rowA.original.access.expirationDate),
              new Date(rowB.original.access.expirationDate),
            ),
        },
        {
          Header: 'Status',
          accessor: (value: Row) => capitalizeFirst(value.access.state),
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,

          Cell: (params: { row: { original: Row } }) => {
            const status = params.row.original.access.state
            return (
              <Table.StatusCell level={getStatusLevel(status)}>
                <Text fontSize="14" fontWeight="500">
                  {capitalizeFirst(status)}
                </Text>
              </Table.StatusCell>
            )
          },
        },
        isRevokable
          ? {
              Header: 'Action',
              align: 'center' as const,
              Cell: (params: { row: { original: Row } }) => {
                const row = params.row.original
                const onClick = useCallback(() => revoke(row), [row])
                if (row.access.state !== AccessState.ACTIVE) {
                  return <Table.EmptyCell />
                }

                return (
                  <Table.ActionsCell justifyContent="center">
                    <Action onClick={onClick}>Revoke</Action>
                  </Table.ActionsCell>
                )
              },
            }
          : null,
      ].filter(notNullable),
    [isRevokable, revoke],
  )
}
