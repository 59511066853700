import { DetailRow } from 'components/DetailsGroup'
import { notNullableMap } from 'utils/array'
import { ChangeViewType } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { DetailRowValue } from './components/DetailRowValue'
import { DetailItem } from './types'

type Params = {
  details: DetailItem[]
  view?: ChangeViewType
}
export const getDetailParamsRows = ({ details, view }: Params) => {
  return notNullableMap<DetailItem, DetailRow>(details, (item, idx) => [
    item.name,
    <DetailRowValue item={item} key={idx} view={view} />,
  ])
}
