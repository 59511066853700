import { Update as TUpdate } from 'api/types/xChecks'
import { Update } from './components/Update'

type Props = {
  updated?: TUpdate[]
  firstItemExpanded: boolean
}
export const Updated = ({ updated, firstItemExpanded }: Props) => {
  if (!updated?.length) {
    return null
  }

  return (
    <>
      {updated.map((update, idx) => (
        <Update
          update={update}
          defaultExpanded={idx === 0 && firstItemExpanded}
          key={idx}
        />
      ))}
    </>
  )
}
