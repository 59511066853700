import { useInfiniteQuery } from '@tanstack/react-query'
import { getParamsKey } from 'queries/utils'
import { omit } from 'lodash'
import { useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { useApi } from '../../../hooks'
import { ListParams } from '../../../hooks/useAPI/service'

const X_CHECKS_QUERY_KEY = 'X_CHECKS_QUERY_KEY'

export const useXChecks = (params: ListParams = {}) => {
  const { hasXCheckPermission } = useXCheckSecurity()

  const { fetchXChecks } = useApi()

  const keyParams =
    params.query && params.query.length >= 4 ? params : omit(params, 'query')

  return useInfiniteQuery(
    [X_CHECKS_QUERY_KEY, getParamsKey(keyParams)],
    ({ pageParam }) =>
      fetchXChecks({
        ...keyParams,
        offset: pageParam?.offset || params.offset,
      }),
    {
      enabled: hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_LIST),
      getNextPageParam: (lastPage, allPages) => {
        const hasMorePages = Boolean(lastPage?.length)

        if (!hasMorePages) {
          return undefined
        }

        const initialOffset = params.offset || 0
        const loadedItems = allPages.flatMap((data = []) => data).length

        return {
          offset: initialOffset + loadedItems,
        }
      },
    },
  )
}
