import { IDAVE_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { Permission } from './Permission'
import { Permissions } from './Permissions'
import { PermissionEdit } from './PermissionEdit'
import { ToxicPair } from './ToxicPair'
import { ToxicPairCreate } from './ToxicPairCreate/ToxicPairCreate'

export const routes = ({ hasPermission }: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Permissions]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Permissions tab="permissions" />,
  },

  [Url.PermissionsAuditLogs]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.Permissions,
    Component: () => <Permissions tab="auditLogs" />,
  },

  [Url.ToxicPairs]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_VIEW_LIST),
    fallbackRoute: Url.Permissions,
    Component: () => <Permissions tab="toxicPairs" />,
  },
  [Url.ToxicPair]: {
    type: 'redirect',
    newUrl: Url.ToxicPairDetails,
    params: ['toxicPairId'],
  },
  [Url.ToxicPairDetails]: {
    withParams: true,
    type: 'component',
    params: ['toxicPairId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_VIEW_LIST),
    fallbackRoute: Url.ToxicPairs,
    Component: (props: { toxicPairId: string }) => (
      <ToxicPair id={props.toxicPairId} tab="details" />
    ),
  },
  [Url.ToxicPairAlerts]: {
    withParams: true,
    type: 'component',
    params: ['toxicPairId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST),
    fallbackRoute: Url.ToxicPairs,
    Component: (props: { toxicPairId: string }) => (
      <ToxicPair id={props.toxicPairId} tab="alerts" />
    ),
  },
  [Url.ToxicPairAuditLogs]: {
    withParams: true,
    type: 'component',
    params: ['toxicPairId'],
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.ToxicPairs,
    Component: (props: { toxicPairId: string }) => (
      <ToxicPair id={props.toxicPairId} tab="auditLogs" />
    ),
  },

  [Url.ToxicPairAdd]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_CREATE),
    fallbackRoute: Url.ToxicPairs,
    Component: ToxicPairCreate,
  },

  [Url.ToxicAlerts]: {
    withParams: false,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST),
    fallbackRoute: Url.Permissions,
    Component: () => <Permissions tab="toxicAlerts" />,
  },

  [Url.Permission]: {
    type: 'redirect',
    params: ['permissionId'],
    newUrl: Url.PermissionDetails,
  },

  [Url.PermissionDetails]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_VIEW_DETAILS),
    fallbackRoute: Url.Permissions,
    params: ['permissionId'],
    Component: (props: { permissionId: string }) => (
      <Permission tab="details" permissionId={props.permissionId} />
    ),
  },

  [Url.PermissionAuditLogs]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    fallbackRoute: Url.Permissions,
    params: ['permissionId'],
    Component: (props: { permissionId: string }) => (
      <Permission tab="auditLog" permissionId={props.permissionId} />
    ),
  },

  [Url.PermissionRoles]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_LIST),
    fallbackRoute: Url.Permissions,
    params: ['permissionId'],
    Component: (props: { permissionId: string }) => (
      <Permission tab="roles" permissionId={props.permissionId} />
    ),
  },

  [Url.PermissionUsers]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_LIST),
    fallbackRoute: Url.Permissions,
    params: ['permissionId'],
    Component: (props: { permissionId: string }) => (
      <Permission tab="users" permissionId={props.permissionId} />
    ),
  },

  [Url.PermissionEdit]: {
    withParams: true,
    type: 'component',
    allowed: hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_UPDATE_DESCRIPTION),
    fallbackRoute: Url.Permissions,
    params: ['permissionId'],
    Component: PermissionEdit,
  },
})
