import { useToasts } from 'hooks/useToasts'
import idaveApi from 'api/idave'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { EditPermissionPayload } from 'api/idave/permissions'

export const useEditPermission = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  return useMutation({
    mutationFn: ({
      permissionId,
      payload,
    }: {
      permissionId: string
      payload: EditPermissionPayload
    }) => {
      return idaveApi.permissions.editDescription(permissionId, payload)
    },

    onMutate: () => showLoadingPopup({ title: 'Updating...' }),
    onSuccess: (_result, { permissionId }) => {
      hideLoadingPopup()
      showSuccessToast('Description updated')
      queryClient.invalidateQueries([QueryKey.Permission, permissionId])
      queryClient.invalidateQueries([QueryKey.Permissions], { refetchType: 'all' })
      onSuccess()
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Permission update failed',
        error: err,
      })
    },
  })
}
