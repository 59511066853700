import { Box, Color, HStack, Icon, IconName, Token } from '@revolut/ui-kit'
import { ReviewRule, ReviewRuleState } from 'api/types/xChecks'

type Props = {
  rule: ReviewRule
  children?: React.ReactNode
}

export const RuleItem = ({ rule, children }: Props) => {
  return (
    <Box py="s-4">
      <HStack space="s-8" align="center">
        <Icon
          name={getStateIcon(rule.state)}
          color={getRuleStateColor(rule.state)}
          size="s-16"
        />
        {children}
      </HStack>
    </Box>
  )
}

const getStateIcon = (ruleState: ReviewRuleState): IconName => {
  switch (ruleState) {
    case ReviewRuleState.approved:
      return '16/CheckCircle'
    case ReviewRuleState.pending:
      return '16/Time'
    case ReviewRuleState.rejected:
      return '16/CrossCircle'
    case ReviewRuleState.active:
    default:
      return 'RadiobuttonOff'
  }
}

const getRuleStateColor = (ruleState: ReviewRuleState): Color => {
  switch (ruleState) {
    case ReviewRuleState.approved:
      return Token.color.success
    case ReviewRuleState.pending:
      return Token.color.deepGrey
    case ReviewRuleState.rejected:
      return Token.color.danger
    case ReviewRuleState.active:
    default:
      return Token.color.foreground
  }
}
