import { XCheckDetails, Log } from 'api/types/xChecks'
import { Data } from 'view/XChecks/XCheck/lib/types'
import { getDataValue } from 'view/XChecks/XCheck/lib/utils'
import { EventTab } from '../../types'

export const getEventsTabs = ({
  xCheck,
  log,
}: {
  xCheck: Data<XCheckDetails>
  log: Data<Log[]>
}): EventTab[] => {
  const logCounter = getDataValue(log)?.length || 0
  const commentCounter = getDataValue(xCheck)?.comments.length || 0

  return [
    {
      tab: 'comment' as const,
      title: 'Comments',
      counter: commentCounter,
      available: xCheck.status !== 'error',
    },
    {
      tab: 'log' as const,
      title: 'Logs',
      counter: logCounter,
      available: xCheck.status !== 'error',
    },
  ]
}
