import { useController, useFormContext } from 'react-hook-form'
import {
  PermissionClassificationSelectProps,
  PermissionClassificationSelect,
} from 'components/Selects/PermissionClassificationSelect'
import { Fielded } from 'utils/types/fielded'

export const FieldPermissionClassificationSelect = (
  props: Fielded<PermissionClassificationSelectProps>,
) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return <PermissionClassificationSelect {...rest} {...field} ref={undefined} />
}
