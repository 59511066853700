import { VStack } from '@revolut/ui-kit'
import { CustomerCompany } from 'api/dart/customerCompanies'
import { CustomerApprovers } from './components/CustomerApprovers'
import { CustomerOwners } from './components/CustomerOwners'

type Props = {
  customerCompany: CustomerCompany
}

export const CustomerCompanyManagers = ({ customerCompany }: Props) => {
  return (
    <VStack space="s-24">
      <CustomerOwners
        ids={customerCompany.owners}
        customerCompanyId={customerCompany.id}
      />
      <CustomerApprovers
        ids={customerCompany.approvers}
        customerCompanyId={customerCompany.id}
      />
    </VStack>
  )
}
