import { NoRejectionsReviewRule, ReviewRule } from 'api/types/xChecks'
import { Text } from '@revolut/ui-kit'
import { RuleItem } from './RuleItem'
import { getParentRulePrefix } from '../utils'

type Props = {
  rule: NoRejectionsReviewRule
  parentRule?: ReviewRule
  isFirst?: boolean
}

export const NoRejectionsRule = ({ rule, parentRule, isFirst }: Props) => {
  return (
    <RuleItem rule={rule}>
      <Text variant="secondary">
        {getParentRulePrefix(parentRule, isFirst)} has no rejections
      </Text>
    </RuleItem>
  )
}
