import { Table, TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { StatusText } from 'components/StatusText'
import { getName } from 'utils/string/getName'
import { UserLinkBase } from 'components/Links/common/UserLinkBase'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { formatDateTime } from 'utils/date'
import {
  SYSTEM_REQUESTERS,
  getRequestStateLevel,
  getRequestStateText,
} from 'view/CrossChecks/utils'
import { SystemRequester } from 'view/CrossChecks/components/SystemRequester'
import { Row } from './types'

export const getColumns = (
  avatars?: Map<string, RevolutersAvatar>,
): TableColumn<Row>[] => [
  {
    Header: 'Requester',
    accessor: ({ requesterUser, requesterId }) => {
      const system = SYSTEM_REQUESTERS[requesterId]
      const name = requesterUser ? getName(requesterUser) : requesterId

      return system?.name || name
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: Row } }) => {
      const { requesterId, requesterUser, requesterReady } = params.row.original

      const system = SYSTEM_REQUESTERS[requesterId]
      if (system) {
        return (
          <Table.Cell>
            <SystemRequester system={system} />
          </Table.Cell>
        )
      }

      if (!requesterReady) {
        return (
          <Table.Cell>
            <TextSkeleton size={12} />
          </Table.Cell>
        )
      }

      if (requesterUser) {
        return (
          <Table.Cell>
            <UserLinkBase user={requesterUser} avatars={avatars} target="_blank" />
          </Table.Cell>
        )
      }

      return <Table.Cell>{requesterId}</Table.Cell>
    },
  },
  {
    Header: 'State',
    id: 'state',
    accessor: (row) => getRequestStateText(row),
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: Row } }) => {
      const row = params.row.original

      return (
        <Table.Cell>
          <StatusText level={getRequestStateLevel(row.state)}>
            {getRequestStateText(row)}
          </StatusText>
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Requested at',
    id: 'requested',
    accessor: (row) => row.createdDate,
    Cell: (params: { row: { original: Row } }) => {
      return <Table.Cell>{formatDateTime(params.row.original.createdDate)}</Table.Cell>
    },
  },
]
