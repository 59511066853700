import { createEnumMap } from 'utils/types/createEnumMap'
import { ValueOf } from 'utils/types/valueOf'

export const PrincipalType = createEnumMap('EMPLOYEE', 'APPLICATION')
export type PrincipalType = ValueOf<typeof PrincipalType>

export type Principal = {
  id: string
  type: PrincipalType
}

export type PrincipalOption = {
  id: string
  label: string
  type: PrincipalType
}

export enum State {
  Requested = 'REQUESTED',
  AwaitingReview = 'AWAITING_REVIEW',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Executed = 'EXECUTED',
  Declined = 'DECLINED',
  RequestedInfo = 'REQUESTED_INFO',
}
export type Entity = {
  /**
   * Entity id in crosscheck app
   */
  id: string
  /**
   * Entity id in source system, might be absent when the entity is created after crosscheck execution
   */
  entityId?: string
  entityName: string
  entityType: EntityType
}

export enum ReviewRuleState {
  pending = 'PENDING',
  active = 'ACTIVE',
  approved = 'APPROVED',
  rejected = 'REJECTED',
}

export enum Decision {
  approve = 'APPROVE',
  reject = 'REJECT',
}

export type Executable = {
  /** executable in xCheck app */
  id: string
  /** executable in source app */
  executableName: ExecutableName
  executableId: string
  source: Source
}

export type Review = {
  id: string
  author: Principal
  createdDate: number
  decision: Decision
}

/**
 * ↓↓↓ Types below should be configured according to x-check provider services
 * ↓↓↓ in general approach they are strings
 */
export type ExecutableName = string
export type EntityType = string
export type Source = 'dart' | 'idave' | 'idave-admin' | 'sam'
export type ReviewStage = string
