import { Page, PageMainSize, StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { PageHeader } from './PageHeader'

type NoAccessWidgetProps = {
  title?: string
}

export const NoAccessWidget = ({
  title = 'Looks like you don’t have sufficient permissions to view this page',
}: {
  title?: string
}) => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D231', 'v2')} />
    <StatusWidget.Title>Not allowed</StatusWidget.Title>
    <StatusWidget.Description>{title}</StatusWidget.Description>
  </StatusWidget>
)

type PageProps = NoAccessWidgetProps & {
  pageTitle?: string
  onBackClick?: () => void
}
export const NoAccessPage = (props: PageProps) => {
  const { pageTitle, onBackClick, ...widgetProps } = props
  return (
    <>
      {pageTitle && <PageHeader onBack={onBackClick} pageTitle={pageTitle} />}
      <Page.Main size={PageMainSize.WIDE}>
        <NoAccessWidget {...widgetProps} />
      </Page.Main>
    </>
  )
}
