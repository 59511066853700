import {
  Principal,
  PrincipalType,
  ReviewRule,
  RuleTypeDescriptor,
  XCheckDetails,
} from 'api/types/xChecks'
import { uniqBy } from 'lodash'
import { Data } from '../types'
import { getDataValue } from '../utils'

export const noop = () => undefined
export const makeErrorAsyncNoop = (name: string) => () =>
  Promise.reject(`API call ${name} wasn't defined`)

export const getParticipantIds = (xCheckData: Data<XCheckDetails>): string[] => {
  const participantIds = new Set<string>()
  const data = getDataValue(xCheckData)

  if (!data) {
    return []
  }

  data.reviews?.forEach((review) => {
    if (review.author.type === PrincipalType.EMPLOYEE) {
      participantIds.add(review.author.id)
    }
  })

  const reviewPrincipals = getReviewerPrincipals(data.reviewRule)
  reviewPrincipals.forEach((reviewer) => {
    if (reviewer.type === PrincipalType.EMPLOYEE) {
      participantIds.add(reviewer.id)
    }
  }, [])

  if (data.requester.type === PrincipalType.EMPLOYEE) {
    participantIds.add(data.requester.id)
  }

  data.comments.forEach(({ author }) => {
    if (author.type === PrincipalType.EMPLOYEE) {
      participantIds.add(author.id)
    }
  })

  return Array.from(participantIds)
}

const getReviewerPrincipals = (reviewRule: ReviewRule) => {
  const rulesToTraverse: ReviewRule[] = []
  const reviewPrincipal: Principal[] = []
  rulesToTraverse.push(reviewRule)

  while (rulesToTraverse.length) {
    const rule = rulesToTraverse.pop()

    if (rule?.type === RuleTypeDescriptor.group) {
      reviewPrincipal.push(...rule.config.reviewers)
    }

    if (
      rule?.type === RuleTypeDescriptor.and ||
      rule?.type === RuleTypeDescriptor.or ||
      rule?.type === RuleTypeDescriptor.multiStage
    ) {
      rulesToTraverse.push(...rule.reviewRules)
    }
  }

  return uniqBy(reviewPrincipal, ({ id }) => id)
}
