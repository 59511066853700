import { StatusWidget, Action } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { Url } from 'routing'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyList = (props: { userId: string }) => {
  const { userId } = props

  return (
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D299', 'v2')} size={104} />
      <StatusWidget.Title role="heading">
        No temporary resources request
      </StatusWidget.Title>
      <StatusWidget.Description>
        There are currently no temporary resources was requested. Request resources
        accesses to see them listed here.
      </StatusWidget.Description>
      <StatusWidget.Actions>
        <Action
          use={Link}
          to={generatePath(Url.UserResourceAccess, { userId })}
          useIcon="BackButtonArrow"
        >
          Back to user resources
        </Action>
      </StatusWidget.Actions>
    </StatusWidget>
  )
}
