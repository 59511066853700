import { UserListItem } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { EmployeeAccesses } from 'api/dart/employee'
import { AccessModifierLimited } from 'api/dart/types'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { sortBy } from 'lodash'
import { PermissionUtils } from 'services/permissions'
import { DART_PERMISSIONS } from 'security'
import { EmployeeModifier, UserRestrictionRow, EmployeeModifierType } from './types'

export const mapLimitationTypeToLabel = (type: EmployeeModifierType) => {
  switch (type) {
    case 'blockedEmployee':
      return 'Banned Employee'
    case 'restrictedRelation':
      return 'Banned Relation'
    default:
      return 'Unknown'
  }
}

export const getRows = (params: {
  directRelations: EmployeeAccesses[]
  userMap: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
}) => {
  const { directRelations, userMap, avatars } = params

  return sortBy(
    directRelations.reduce<UserRestrictionRow[]>(
      (acc, employee) => {
        const user = userMap.get(employee.id)
        const name = user ? getName(user) : employee.id
        const avatarUrl = getAvatarUrl({ avatars, user })

        const restrictions = employee.accessModifiers.reduce<UserRestrictionRow[]>(
          (result, modifier) => {
            if (modifier.type !== 'DENIED') {
              return result
            }
            const employeeModifier = getEmployeeModifier(employee.id, modifier)

            return [
              ...result,
              {
                id: modifier.id,
                employeeId: employee.id,
                name,
                avatarUrl,
                state: user?.state,
                justification: employeeModifier.modifier.justification,
                employeeModifier,
                employeeModifierType: employeeModifier.type,
              },
            ]
          },
          [],
        )

        if (!restrictions.length) {
          return acc
        }

        return acc.concat(restrictions)
      },

      [],
    ),
    'name',
  )
}

export const stringifyLimitationObject = (value: UserRestrictionRow) => {
  switch (value.employeeModifier.type) {
    case 'restrictedRelation':
      return value.employeeModifier.customerId || EMPTY_VALUE_FALLBACK
    default:
      return EMPTY_VALUE_FALLBACK
  }
}

export const getEmployeeModifier = (
  employeeId: string,
  modifier: AccessModifierLimited,
): EmployeeModifier => {
  if (modifier.type === 'DENIED' && modifier.entityId?.type === 'CUSTOMER') {
    return {
      type: 'restrictedRelation',
      employeeId,
      customerId: modifier.entityId.id,
      modifier,
    }
  }

  if (modifier.type === 'DENIED' && !modifier.entityId) {
    return {
      type: 'blockedEmployee',
      employeeId,
      modifier,
    }
  }

  return {
    type: 'unknown',
    modifier,
  }
}

export const getModifierDeleteDisabled = (
  employeeModifier: EmployeeModifier,
  permissionUtils: PermissionUtils,
) => {
  const { hasPermission, hasSomePermissions } = permissionUtils
  if (employeeModifier.type === 'blockedEmployee') {
    return !hasPermission(DART_PERMISSIONS.EMPLOYEES_UNBLOCK)
  }

  if (employeeModifier.type === 'restrictedRelation') {
    return !hasSomePermissions(
      DART_PERMISSIONS.EMPLOYEES_DELETE_RESTRICTIONS,
      DART_PERMISSIONS.EMPLOYEES_DELETE_RESTRICTIONS_CROSS_CHECK,
    )
  }

  return true
}
