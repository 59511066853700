import api from 'api/sam'
import { SamPolicy } from 'api/sam/policies'
import { useToasts } from 'hooks/useToasts'
import { useMutation } from '@tanstack/react-query'
import { PolicyDetails } from 'view/Sam/components/SamEditPolicyDetails'
import { SAM_PERMISSIONS } from 'security'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useNavigateToPolicy } from 'hooks/useNavigateToPolicy'
import { getCrossCheckDescriptor } from 'helpers/utils'

export const usePolicyUpdate = (policy: SamPolicy, policyDetails: PolicyDetails) => {
  const { showSuccessToast } = useToasts()
  const { toCrosscheck } = useCrossCheck(SAM_PERMISSIONS.POLICIES_UPDATE, [
    SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
    SAM_PERMISSIONS.POLICIES_UPDATE_MULTI_SUBJECT_WITH_CROSS_CHECK,
  ])
  const reloadAndNavigateToPolicy = useNavigateToPolicy()
  const crossCheckMutation = useMutation({
    mutationFn: () =>
      api.policies.crossCheckUpdatePolicy({
        policyId: policy.policyId,
        data: {
          mandatoryTrainings: policy.mandatoryTrainings || [],
          resourceIds: policy.resourceIds,
          businessReason: policyDetails.businessReason,
          policyName: policyDetails.policyName,
          subject: policyDetails.subject.subjectType ? policyDetails.subject : undefined,
        },
      }),
    onSuccess: (response) => {
      const crosscheck = getCrossCheckDescriptor(response)
      const data = response.data
      const crossCheckDescriptor = {
        id: data.crossCheckId || crosscheck.id,
        version: crosscheck.version,
      }
      if (crossCheckDescriptor.id) {
        toCrosscheck(crossCheckDescriptor)
      } else {
        reloadAndNavigateToPolicy(policy.policyId)
        showSuccessToast('Policy edited')
      }
    },
  })

  return {
    update: () => crossCheckMutation.mutate(),
    isUpdating: crossCheckMutation.isLoading,
  }
}
