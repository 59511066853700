import { IconName, TextButton, Token, VStack } from '@revolut/ui-kit'
import { Comment, GroupReviewRule, Log, LogType, XCheckDetails } from 'api/types/xChecks'
import { DetailRow } from 'components/DetailsGroup'
import { PrincipalComponentType, PrincipalContext } from 'view/XChecks/XCheck/lib/types'
import { getReviewGroup } from 'view/XChecks/XCheck/lib/utils'

export const getLogIcon = (log: Log): { iconName: IconName; iconColor: string } => {
  switch (log.type) {
    case LogType.approved:
      return { iconName: 'CheckSuccess', iconColor: Token.color.success }
    case LogType.rejected:
      return { iconName: 'CrossVoid', iconColor: Token.color.danger }
    case LogType.declined:
      return { iconName: 'CrossCircle', iconColor: Token.color.danger }
    case LogType.commented:
      return { iconName: 'Message', iconColor: Token.color.accent }
    case LogType.executed:
      return { iconName: 'VerifiedService', iconColor: Token.color.success }
    case LogType.groupReviewersAdded:
      return { iconName: 'AddContact', iconColor: Token.color.accent }
    case LogType.infoProvided:
      return { iconName: 'Info', iconColor: Token.color.success }
    case LogType.infoRequested:
      return { iconName: 'Question', iconColor: Token.color.warning }
    case LogType.justified:
      return { iconName: 'Statement', iconColor: Token.color.accent }
    case LogType.requested:
      return { iconName: 'PlayOutline', iconColor: Token.color.success }
    case LogType.reviewStageChanged:
      return { iconName: 'Gear', iconColor: Token.color.accent }
    case LogType.reviewed:
      return log.decision === 'APPROVE'
        ? { iconName: 'SocialLike', iconColor: Token.color.success }
        : { iconName: 'SocialDislike', iconColor: Token.color.danger }

    case LogType.executionFailed:
      return {
        iconName: 'Bug',
        iconColor: Token.color.danger,
      }
    case LogType.commentEdited: {
      return {
        iconName: '20/Draft',
        iconColor: Token.color.accent,
      }
    }
    default:
      return {
        iconName: 'Issue' as IconName,
        iconColor: Token.color.neutral,
      }
  }
}

export const getLogHeader = (log: Log) => {
  switch (log.type) {
    case LogType.approved:
      return { title: 'Request approved' }
    case LogType.rejected:
      return { title: 'Request rejected' }
    case LogType.declined:
      return { title: 'Request declined by ', withAuthor: true }
    case LogType.commented:
      return { title: 'Commented by ', withAuthor: true }
    case LogType.executed:
      return { title: 'Action executed' }
    case LogType.groupReviewersAdded:
      return { title: 'Reviewers extended by ', withAuthor: true }
    case LogType.infoProvided:
      return { title: 'Info provided by ', withAuthor: true }
    case LogType.infoRequested:
      return { title: 'Info requested by ', withAuthor: true }
    case LogType.justified:
      return { title: 'Request justified by ', withAuthor: true }
    case LogType.requested:
      return { title: 'Request created by ', withAuthor: true }
    case LogType.reviewStageChanged:
      return { title: 'Review stage changed' }
    case LogType.reviewed:
      return {
        title: log.decision === 'APPROVE' ? 'Approval added by ' : 'Rejection added by ',
        withAuthor: true,
      }
    case LogType.executionFailed:
      return { title: 'Execution failed' }
    case LogType.commentEdited:
      return { title: 'Comment edited by ', withAuthor: true }
    default:
      return { title: 'Something happened' }
  }
}

type LogDetails = {
  textWidgets?: Array<{ title: string; value: string }>
  infoRows?: DetailRow[]
}

type GetLogDetailsParams = {
  log: Log
  PrincipalComponent: PrincipalComponentType
  xCheck?: XCheckDetails
  onGroupClick: (group: GroupReviewRule) => void
  commentMap: Map<string, Comment>
}
export const getLogDetails = ({
  log,
  PrincipalComponent,
  xCheck,
  onGroupClick,
  commentMap,
}: GetLogDetailsParams): LogDetails => {
  const defaultText =
    'commentId' in log && log.commentId ? commentMap.get(log.commentId)?.text : undefined
  const defaultTitle = getTextFieldTitle(log)

  switch (log.type) {
    case LogType.reviewStageChanged:
      return {
        infoRows: [
          ['Stage', log.reviewStageName],
          ['Stage ID', log.reviewStageId],
          ['Old state', log.oldState],
          ['New state', log.newState],
        ],
      }

    case LogType.groupReviewersAdded:
      const group = xCheck ? getReviewGroup(xCheck, log.groupId) : undefined

      return {
        textWidgets: defaultText
          ? [{ title: defaultTitle, value: defaultText }]
          : undefined,
        infoRows: [
          [
            'Group',
            group ? (
              <TextButton onClick={() => onGroupClick(group)}>{group.name}</TextButton>
            ) : (
              log.groupId
            ),
          ],
          [
            'Added reviewers',
            <VStack key="reviewers">
              {log.reviewers.map((reviewer, idx) => (
                <PrincipalComponent
                  key={idx}
                  principal={reviewer}
                  context={PrincipalContext.log}
                />
              ))}
            </VStack>,
          ],
        ],
      }

    case LogType.commentEdited: {
      return {
        infoRows: undefined,
        textWidgets: [
          {
            title: 'Old text',
            value: log.oldText,
          },
          {
            title: 'New text',
            value: log.newText,
          },
        ],
      }
    }

    default:
      return {
        textWidgets: defaultText
          ? [{ title: defaultTitle, value: defaultText }]
          : undefined,
        infoRows: undefined,
      }
  }
}

export const getTextFieldTitle = (log: Log) => {
  switch (log.type) {
    case LogType.declined:
    case LogType.approved:
    case LogType.rejected:
    case LogType.reviewed:
      return 'Justification'
    case LogType.groupReviewersAdded:
      return 'Reason'
    default:
      return 'Text'
  }
}
