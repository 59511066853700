import { PageMainSize } from '@revolut/ui-kit'
import { Tab } from './types'

export const generateCode = (name: string) =>
  `idave:team:${name.replace(/[^a-zA-Z0-9]+/g, '_')}`

export const tabToPageSize = (tab: Tab) => {
  switch (tab) {
    case 'users':
      return PageMainSize.FULL
    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
