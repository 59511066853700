import { generatePath } from 'react-router'
import { Url } from 'routing'
import { match, P } from 'ts-pattern'
import { EntityDescriptor, EntityToEntityViewFn, EntityView } from '../../lib/types'
import { entityToEntityView as libEntityToEntityView } from '../../lib/hooks'
import { getEntityDescriptor, entityParamsMatches, paramMatcher } from '../../lib/utils'
import { KNOWN_ENTITY_TYPE } from './consts'

export const getEntityUrl = (entity: EntityDescriptor) =>
  match(entity)
    .with({ entityType: KNOWN_ENTITY_TYPE.role, id: P.string }, ({ id }) =>
      generatePath(Url.Role, { roleId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.customerCompany, id: P.string }, ({ id }) =>
      generatePath(Url.DartCustomerCompany, { companyId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.employeeCompany, id: P.string }, ({ id }) =>
      generatePath(Url.DartEmployeeCompany, { companyId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.permission, id: P.string }, ({ id }) =>
      generatePath(Url.Permission, { permissionId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.iDaveUser, id: P.string }, ({ id }) =>
      generatePath(Url.User, { userId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.application, id: P.string }, ({ id }) =>
      generatePath(Url.Client, { clientId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.group, id: P.string }, ({ id }) =>
      generatePath(Url.SystemGroup, { groupId: id }),
    )
    .with({ entityType: KNOWN_ENTITY_TYPE.serviceAccount, id: P.string }, ({ id }) =>
      generatePath(Url.Account, { accountId: id }),
    )
    .otherwise(() => undefined)

export const entityToEntityView: EntityToEntityViewFn = (entity) =>
  match(getEntityDescriptor(entity))
    .with({ entityType: KNOWN_ENTITY_TYPE.registeredIdentity }, () =>
      entityParamsMatches(entity, paramMatcher('type', 'text')) ? 'details' : 'context',
    )
    .with(
      {
        entityType: P.union(
          KNOWN_ENTITY_TYPE.employeeCompany,
          KNOWN_ENTITY_TYPE.customerCompany,
        ),
      },
      () =>
        entityParamsMatches(entity, paramMatcher('Region', 'entity'))
          ? EntityView.details
          : EntityView.context,
    )
    .otherwise(() => libEntityToEntityView(entity))
