import { get } from 'lodash'

export const getErrorMessage = (error: unknown, fallback: string) => {
  const responseMessage = get(error, 'response.data.message')
  if (typeof responseMessage === 'string') {
    return responseMessage
  }
  if (error instanceof Error && error.message) {
    return error.message
  }
  return fallback
}
