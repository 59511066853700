import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryDirectRelations } from 'queries/dart/employees'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useMemo } from 'react'
import { waitForAll } from 'utils/query'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useLoadingToast } from 'hooks/useLoadingToast'
import { UserRestrictionList } from './components/UserRestrictionList'
import { getRows } from './utils'

const TIMEOUT = 1500

export const UserRestrictions = () => {
  const {
    data: userMap = new Map(),
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: avatars,
    status: avatarsStatus,
    fetchStatus: avatarsFS,
  } = useQueryMappedEmployeeIdAvatars()

  const {
    data: directRelations = [],
    status: directRelStatus,
    fetchStatus: directRelationsFetchStatus,
  } = useQueryDirectRelations()

  const items = useMemo(
    () =>
      getRows({
        directRelations,
        userMap,
        avatars,
      }),
    [directRelations, userMap, avatars],
  )

  const queryStatus = waitForAll(
    [
      {
        qs: directRelStatus,
        fs: directRelationsFetchStatus,
      },
    ],
    [
      { qs: userStatus, fs: userFetchStatus },
      { qs: avatarsStatus, fs: avatarsFS },
    ],
  )

  useLoadingToast(
    [
      {
        qs: directRelStatus,
        fs: directRelationsFetchStatus,
      },
      { qs: userStatus, fs: userFetchStatus },
      { qs: avatarsStatus, fs: avatarsFS },
    ],
    "Just a little longer, we're loading",
    TIMEOUT,
  )

  const loadingState = getLoadingState(queryStatus, items.length)

  return <UserRestrictionList items={items} loadingState={loadingState} />
}
