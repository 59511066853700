import { Param, Value } from 'api/types/xChecks'
import { useCallback, useMemo } from 'react'
import { ActionButton, Text, useToggle } from '@revolut/ui-kit'
import { notNullable } from 'utils/common'
import { stringifyParam } from './utils'
import { Delete } from './components/Delete'
import { ItemPopup } from '../ItemPopup'

type ArrayItemProps = {
  index: number
  param: Param
  itemValue: Value<Param>
  canDelete: boolean
  canEdit: boolean
  disabled: boolean
  replaceItem: (index: number, item: Value<Param>) => void
  deleteItem: (index: number) => void
}

export const ArrayItem = ({
  index,
  param,
  itemValue,
  canDelete,
  canEdit,
  deleteItem,
  disabled,
  replaceItem,
}: ArrayItemProps) => {
  const label = stringifyParam(itemValue)
  const [open, toggleOpen] = useToggle()
  const onClick = useMemo(
    () => (canEdit ? toggleOpen.on : undefined),
    [canEdit, toggleOpen],
  )

  const onDeleteClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()
      deleteItem(index)
    },
    [deleteItem, index],
  )

  const submit = useCallback(
    (value: Value<Param>) => {
      if (notNullable(value.value)) {
        return replaceItem(index, value)
      }

      if (canDelete) {
        return deleteItem(index)
      }

      return undefined
    },
    [replaceItem, canDelete, deleteItem, index],
  )

  return (
    <>
      <ActionButton
        variant="white"
        size="xs"
        disabled={disabled}
        onClick={onClick}
        useEndIcon={
          <Delete onClick={onDeleteClick} disabled={disabled} canDelete={canDelete} />
        }
      >
        <Text variant="caption">{label}</Text>
      </ActionButton>
      {open && (
        <ItemPopup
          disabled={disabled}
          name={param.name}
          defaultValue={itemValue}
          submit={submit}
          onClose={toggleOpen.off}
        />
      )}
    </>
  )
}
