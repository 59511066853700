import { FilterButton, RadioSelect, useDropdown } from '@revolut/ui-kit'
import { Entity } from 'api/idave/auditsLog'

export type ModelTypeFilterValue = Entity | null

type ModelTypeFilterProps = {
  value: ModelTypeFilterValue
  options: Entity[]
  onChange: (value: ModelTypeFilterValue) => void
}

const formatEntity = (entity: Entity) => entity.replace(/([a-z])([A-Z])/g, '$1 $2')

export const ModelTypeFilter = ({ options, value, onChange }: ModelTypeFilterProps) => {
  const dropdown = useDropdown()

  const formattedOptions = options.map((entity) => ({
    key: entity,
    label: formatEntity(entity),
    value: entity,
  }))

  return (
    <>
      <FilterButton
        {...dropdown.getAnchorProps()}
        useIcon="Filter"
        active={Boolean(value)}
      >
        {value ? formatEntity(value) : 'Model type'}
      </FilterButton>
      <RadioSelect
        {...dropdown.getTargetProps()}
        value={value}
        options={formattedOptions}
        onChange={onChange}
        labelList="Model types"
        autoClose
      />
    </>
  )
}
