import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEditPopup } from 'hooks/useEditPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import samApi from 'api/sam'
import { Resource, ResourceType } from 'api/sam/types/resources'
import { QueryKey } from 'helpers/configQuery'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ActionButtonProps } from '@revolut/ui-kit'
import { SAM_PERMISSIONS } from 'security'
import { ResourceOwnerSetPopup } from '../components/ResourceOwnerSetPopup'

export const useResourceActions = ({ resource }: { resource: Resource }) => {
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { setPopup: setEditPopup, closePopup: closeEditPopup } = useEditPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()

  const { mutate: mutateOwner } = useMutation({
    mutationFn: (ownerGroupId?: string) => {
      return samApi.resources.setResourceOwner(resource.resourceId, ownerGroupId)
    },
    onMutate: () => showLoadingPopup({ title: 'Updating resource...' }),
    onSuccess: () => {
      hideLoadingPopup()
      closeEditPopup()
      showSuccessToast('Resource updated')
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SamResources],
      })
      queryClient.invalidateQueries({
        queryKey: [QueryKey.SamResource, resource.resourceId],
        refetchType: 'all',
      })
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Update failed', error: err })
    },
  })

  const onSetResourceOwnerClick = useCallback(
    () =>
      setEditPopup({
        title: 'Set resource owner',
        content: <ResourceOwnerSetPopup submit={mutateOwner} resource={resource} />,
        isBottomSheet: true,
        headerVariant: 'bottom-sheet',
      }),
    [setEditPopup, mutateOwner, resource],
  )

  const permissionUtils = usePermissions()

  return getResourceActions({
    resource,
    permissionUtils,
    onSetResourceOwnerClick,
  })
}

type GetResourceActionsParams = {
  resource: Resource
  permissionUtils: ReturnType<typeof usePermissions>
  onSetResourceOwnerClick: () => void
}

type GetResourceActionsResult = Array<{
  variant: ActionButtonProps['variant']
  useIcon: ActionButtonProps['useIcon']
  onClick: () => void
  label: string
}>

export const getResourceActions = ({
  resource,
  permissionUtils: { hasPermission },
  onSetResourceOwnerClick,
}: GetResourceActionsParams) => {
  const actions: GetResourceActionsResult = []

  if (
    hasPermission(SAM_PERMISSIONS.RESOURCES_SET_OWNERS) &&
    resource.resourceType !== ResourceType.IdaveRole
  ) {
    actions.push({
      label: 'Edit owner',
      onClick: onSetResourceOwnerClick,
      useIcon: 'Pencil',
      variant: 'primary',
    })
  }

  return actions
}
