import { ArrayParam } from 'api/types/xChecks'
import { ArrayParamValue } from './types'

export const getEditInfo = (param: ArrayParam, value: ArrayParamValue) => {
  const isItemsArray = param.value.type.subType.type === 'array'
  const canAdd = !!param.value.editConfig?.actions.includes('ADD_ITEM') && !isItemsArray
  const canDelete =
    !!param.value.editConfig?.actions.includes('DELETE_ITEM') && !isItemsArray
  const canEdit = canAdd && canDelete && !isItemsArray
  const disabled = (!canAdd && !canDelete) || isItemsArray

  return {
    disabled,
    canAdd,
    canDelete,
    canEdit,
    empty: !value?.length,
  }
}
