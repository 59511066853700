import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react'
import { noop } from 'lodash'
import { HeaderVariant } from '@revolut/ui-kit'

type EditPopupProps = {
  content: React.ReactElement
  title: string
  description?: string
  headerVariant?: HeaderVariant
  isBottomSheet?: boolean
  focusTrap?: boolean
  onClose?: () => void
}

export type EditPopupContextData = {
  setPopup(popup: EditPopupProps): void
  closePopup(): void
  popup?: EditPopupProps
  scrollRef?: React.RefObject<HTMLDivElement>
}

const EditPopupContext = React.createContext<EditPopupContextData>({
  popup: undefined,
  scrollRef: undefined,
  setPopup: noop,
  closePopup: noop,
})

export function useEditPopup() {
  return useContext(EditPopupContext)
}

export const EditPopupProvider = ({ children }: PropsWithChildren) => {
  const [popup, setPopup] = useState<EditPopupProps | undefined>()

  const closePopup = useCallback(() => {
    popup?.onClose?.()
    setPopup(undefined)
  }, [setPopup, popup])

  const scrollRef = useRef(null)

  return (
    <EditPopupContext.Provider value={{ popup, setPopup, closePopup, scrollRef }}>
      {children}
    </EditPopupContext.Provider>
  )
}
