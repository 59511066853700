import { Cell, Checkbox, CheckboxProps } from '@revolut/ui-kit'
import { useCallback, useState } from 'react'
import { notNullable } from 'utils/common'
import { Fielded } from '../types'
import { useFormContext } from '../../../../FormProvider'
import { getFieldErrorState } from '../utils'

type Props = Fielded<boolean, CheckboxProps>

export const FieldCheckbox = (props: Props) => {
  const { path, validate, label, defaultChecked, ...rest } = props
  const { errors, setError, updateForm } = useFormContext()

  const [state, setState] = useState(defaultChecked)

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.checked

      setState(newValue)
      updateForm(path, newValue)
      setError(path, validate(newValue))
    },
    [setError, updateForm, path, validate],
  )

  return (
    <Cell use="label">
      <Checkbox
        {...rest}
        checked={state}
        indeterminate={!notNullable(state)}
        onChange={onChange}
        {...getFieldErrorState(errors, path)}
      >
        {label}
      </Checkbox>
    </Cell>
  )
}
