import { Log, XCheckDetails } from 'api/types/xChecks'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { Data } from '../types'
import { getParticipantIds, noop } from './utils'
import { useApi } from './useAPI'
import { useXCheckSecurity } from './useXCheckSecurity'
import { useQueryData } from '../utils'
import { CHANGES_QUERY_KEY } from './useChanges'
import { PARAMS_QUERY_KEY } from './useParams'

const X_CHECK_QUERY_KEY = 'X_CHECK_QUERY_KEY'
const X_CHECK_LOG_QUERY_KEY = 'X_CHECK_LOG_QUERY_KEY'

type TXCheckDataOuterContext = {
  xCheckId: string
  userIdData: Data<string>
  onXCheckUpdate: () => void
}

type TXCheckDataInnerContext = {
  xCheckData: Data<XCheckDetails>
  xCheckLogData: Data<Log[]>
  xCheckParticipantIds: string[]
}

type TXCheckDataContext = TXCheckDataOuterContext & TXCheckDataInnerContext

export const DEFAULT_DATA = {
  xCheckId: '',
  userIdData: { status: 'loading' as const },
  xCheckData: { status: 'loading' as const },
  xCheckLogData: { status: 'loading' as const },
  xCheckParticipantIds: [],
  onXCheckUpdate: noop,
}

export const XCheckDataContext = createContext<TXCheckDataContext>(DEFAULT_DATA)

export const XCheckDataProvider = ({
  children,
  ...outerParams
}: { children: React.ReactNode } & TXCheckDataOuterContext) => {
  const { userIdData, xCheckId } = outerParams

  const { fetchXCheckLog, fetchXCheck } = useApi()
  const { hasXCheckPermission } = useXCheckSecurity()
  const queryClient = useQueryClient()

  const xCheckQuery = useQuery({
    queryKey: [X_CHECK_QUERY_KEY, outerParams.xCheckId],
    queryFn: () => fetchXCheck({ xCheckId: outerParams.xCheckId }),
    enabled: hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
  })
  const xCheckData = useQueryData(xCheckQuery)

  const xCheckParticipantIds = getParticipantIds(xCheckData)

  const xCheckLogQuery = useQuery({
    queryKey: [X_CHECK_LOG_QUERY_KEY, outerParams.xCheckId],
    queryFn: () => fetchXCheckLog({ xCheckId: outerParams.xCheckId }),
    enabled: hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_LOG_LIST),
  })
  const xCheckLogData = useQueryData(xCheckLogQuery)

  const onXCheckUpdate = useCallback(() => {
    queryClient.invalidateQueries([X_CHECK_QUERY_KEY, xCheckId])
    queryClient.invalidateQueries([X_CHECK_LOG_QUERY_KEY, xCheckId])
    queryClient.invalidateQueries([CHANGES_QUERY_KEY, xCheckId])
    queryClient.invalidateQueries([PARAMS_QUERY_KEY, xCheckId])
    outerParams.onXCheckUpdate()
  }, [outerParams, queryClient, xCheckId])

  const value = useMemo(
    () => ({
      onXCheckUpdate,
      userIdData,
      xCheckId,
      xCheckData,
      xCheckLogData,
      xCheckParticipantIds,
    }),
    [
      onXCheckUpdate,
      userIdData,
      xCheckId,
      xCheckData,
      xCheckLogData,
      xCheckParticipantIds,
    ],
  )
  return <XCheckDataContext.Provider value={value}>{children}</XCheckDataContext.Provider>
}

export const useXCheckData = () => useContext(XCheckDataContext)
