import { ChangeItem } from '../../types'

export type Indexed<T> = {
  idx: number
  item: T
}

export const unpackIndexedChange = <T>(items: ChangeItem<Indexed<T>>[]) =>
  items.map(({ value, variant }) => ({
    variant,
    value: value.item,
  }))

export const indexItems = <T>(items: T[] = []) =>
  items.map((item, idx) => ({
    item,
    idx,
  }))
