import { ArrayChange } from 'api/types/xChecks'
import { Flex, Text, Token } from '@revolut/ui-kit'
import { ChangeViewType } from 'view/XChecks/XCheck/lib/components/Updates/types'
import {
  getArrayChangeViewItems,
  isChangePayloadBig,
} from 'view/XChecks/XCheck/lib/components/Updates/utils'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { DetailParamItem } from '../../../DetailParamItem'

export const ArrayChangeItem = ({
  change,
  view,
}: {
  change: ArrayChange
  view: ChangeViewType
}) => {
  const tooMuchItems = isChangePayloadBig(change)

  if (tooMuchItems) {
    return (
      <Text color={Token.color.danger}>
        Items amount too big to show, refer &quot;Request input&quot; to assess
      </Text>
    )
  }

  const items = getArrayChangeViewItems({ change, view })
  if (!items.length) {
    return <Text>{EMPTY_VALUE_FALLBACK_TEXT}</Text>
  }
  return (
    <Flex gap="s-8" flexWrap="wrap" justifyContent="end">
      {items.map(({ value, variant }, key) => (
        <DetailParamItem value={value} variant={variant} isArrayItem key={key} />
      ))}
    </Flex>
  )
}
