import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { generatePath, useNavigate } from 'react-router'
import samApi from 'api/sam'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { Url } from 'routing'
import { QueryKey } from 'helpers/configQuery'

type UseResourceRequestParams = {
  onSuccess: () => void
}
export const useResourceRequest = ({ onSuccess }: UseResourceRequestParams) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { showErrorPopup } = useErrorPopup()
  const { isLoading, mutate } = useMutation({
    mutationFn: samApi.resources.requestResource,
    onSuccess: (response, params) => {
      const crosscheck = getCrossCheckDescriptor(response)
      onSuccess()
      if (crosscheck.id) {
        navigate(
          generatePath(Url.CrossCheck, { source: 'sam', crossCheckId: crosscheck.id }),
        )
      }

      queryClient.resetQueries({
        queryKey: [QueryKey.ResourceRequests, params.idaveUserId],
        type: 'all',
      })
    },
    onError: (error) => {
      showErrorPopup({
        title: 'Request failed',
        error,
      })
    },
  })

  return { pending: isLoading, requestResources: mutate }
}
