import { Param } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { valueTypeMatcher } from 'view/XChecks/XCheck/lib/utils'
import {
  EntityInput,
  UuidInput,
  TextInput,
  BoolInput,
  NumberInput,
  TimestampInput,
  ArrayInput,
} from './components/Inputs'

export const ParamInput = ({
  param,
  index,
  prefix,
}: {
  param: Param
  index: number
  prefix: string
}) => {
  return match(param)
    .with(valueTypeMatcher('text'), (textParam) => (
      <TextInput param={textParam} index={index} prefix={prefix} />
    ))
    .with(valueTypeMatcher('entity'), (entityParam) => (
      <EntityInput param={entityParam} index={index} prefix={prefix} />
    ))
    .with(valueTypeMatcher('uuid'), (uuidParam) => (
      <UuidInput param={uuidParam} index={index} prefix={prefix} />
    ))
    .with(valueTypeMatcher('bool'), (boolParam) => (
      <BoolInput param={boolParam} index={index} prefix={prefix} />
    ))
    .with(valueTypeMatcher('number'), (numberParam) => (
      <NumberInput param={numberParam} index={index} prefix={prefix} />
    ))
    .with(valueTypeMatcher('timestamp'), (timestampParam) => (
      <TimestampInput param={timestampParam} index={index} prefix={prefix} />
    ))
    .with(valueTypeMatcher('array'), (arrayParam) => (
      <ArrayInput param={arrayParam} index={index} prefix={prefix} />
    ))
    .exhaustive()
}
