import { DetailsGroup } from 'components/DetailsGroup'
import { getDetailParamsRows } from './utils'
import { hasSomeChanges } from '../../../utils'
import { DetailItem } from './types'
import { ChangeSubheaderWrapper } from '../ChangeSubheaderWrapper'

type Props = {
  details: DetailItem[]
  title: string
}

export const Details = ({ details, title }: Props) => {
  if (!details?.length) {
    return null
  }

  return (
    <ChangeSubheaderWrapper
      title={title}
      showViewSwitcher={hasSomeChanges(...details)}
      renderComponent={({ view }) => (
        <DetailsGroup rows={getDetailParamsRows({ details, view })} />
      )}
    />
  )
}
