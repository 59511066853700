import { useCurrentUserId } from 'queries/idave/users'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { generatePath, useNavigate } from 'react-router'
import { useCallback } from 'react'
import { Url } from 'routing'
import { XChecksListItem } from 'api/types/xChecks'
import { XChecks as LibXChecks } from './lib'
import { useQueryData } from '../lib/utils'
import { useApiSettings } from '../hooks/useApiSettings'

export const XChecks = () => {
  const { hasPermission } = usePermissions()

  const userIdQuery = useCurrentUserId()
  const userIdData = useQueryData(userIdQuery)

  const navigate = useNavigate()

  const getRowLink = useCallback(
    (row: XChecksListItem) => generatePath(Url.XCheck, { xCheckId: row.id }),
    [],
  )

  const navigationSettings = {
    getRowLink,
    onRowClick: (row: XChecksListItem) => navigate(getRowLink(row)),
  }

  const apiSettings = useApiSettings()

  return (
    <LibXChecks
      hasPermission={hasPermission}
      userIdData={userIdData}
      apiSettings={apiSettings}
      navigationSettings={navigationSettings}
    />
  )
}
