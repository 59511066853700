import { Table, TableColumn, TableData, TableFilterProps, Text } from '@revolut/ui-kit'
import { Executable, XChecksListItem } from 'api/types/xChecks'
import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { CheckboxSelectTableFilter } from './CheckboxSelectTableFilter'
import { getSelectLoadingState } from '../utils'
import { useApi } from '../../../hooks'

const EXECUTABLES_QUERY_KEY = 'EXECUTABLES_QUERY_KEY'

const ExecutableTableFilter = <Entity extends TableData>({
  column,
}: Pick<TableFilterProps<Entity>, 'column'>) => {
  const { fetchExecutables } = useApi()
  const { hasXCheckPermission } = useXCheckSecurity()

  const { data: executables = [], status } = useQuery({
    queryKey: [EXECUTABLES_QUERY_KEY],
    queryFn: () => fetchExecutables(),
    enabled: hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_ENTITIES_LIST),
  })

  const loadingState = getSelectLoadingState(status)

  const options = uniqBy(
    executables.map((executable) => ({
      key: executable.executableId,
      label: executable.executableName,
      value: executable.executableId,
    })),
    'key',
  )

  return (
    <CheckboxSelectTableFilter
      column={column}
      options={options}
      loadingState={loadingState}
    />
  )
}

const ExecutableTableCell = ({ value }: { value: Executable }) => (
  <Table.Cell>
    <Text>{value.executableName}</Text>
  </Table.Cell>
)

export const EXECUTABLE_TABLE_CELL: TableColumn<XChecksListItem> = {
  Header: 'Executable',
  accessor: 'executable',
  Cell: ExecutableTableCell,
  Filter: ({ column }: Pick<TableFilterProps<XChecksListItem>, 'column'>) => (
    <ExecutableTableFilter column={column} />
  ),
  // xChecks API does not provide sorting order functionality
  disableSortBy: true,
}
