import { Box } from '@revolut/ui-kit'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { EmployeeCompany } from 'api/dart/types'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryCustomerCompanies } from 'queries/dart/companies'
import { useCallback, useState } from 'react'
import { randomKey } from 'utils/common/randomKey'
import { CustomerCompanyList } from './components/CustomerCompanyList'
import { EmployeeCompanySkeleton } from '../EmployeeCompanySkeleton'

type Props = {
  employeeCompany: EmployeeCompany
}

export const CustomerCompanies = ({ employeeCompany }: Props) => {
  const { data, status: qs, fetchStatus: fs } = useQueryCustomerCompanies()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={EmployeeCompanySkeleton}
      renderSuccess={({ data: customerCompanies }) => (
        <Inner employeeCompany={employeeCompany} customerCompanies={customerCompanies} />
      )}
    />
  )
}

type InnerProps = {
  employeeCompany: EmployeeCompany
  customerCompanies: CustomerCompanyItem[]
}
const Inner = ({ employeeCompany, customerCompanies }: InnerProps) => {
  const [resetKey, setResetKey] = useState(randomKey())

  const reset = useCallback(() => {
    setResetKey(randomKey())
  }, [setResetKey])

  return (
    <Box key={resetKey}>
      <CustomerCompanyList
        reset={reset}
        employeeCompany={employeeCompany}
        customerCompanies={customerCompanies}
      />
    </Box>
  )
}
