import { Input, VStack } from '@revolut/ui-kit'
import { times } from 'lodash'

export const RoleEditFormSkeleton = () => {
  return (
    <VStack space="s-24">
      {times(5, (idx) => (
        <Input ref={undefined} disabled key={idx} />
      ))}
    </VStack>
  )
}
