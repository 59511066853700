import { ArrayChange, Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { ARRAY_CHANGE_LIST_PATTERN } from 'view/XChecks/XCheck/lib/utils'
import { Indexed, indexItems, unpackIndexedChange } from './indexChanges'
import { getOldTabItems } from './getOldTabItems'
import { getNewTabItems } from './getNewTabItems'
import { getArrayDiffItems, getSetDiffItems } from './getDiffItems'
import { ParamChangeItem } from '../../types'
import { ChangeViewType } from '../../types'

export const getArrayChangeViewItems = ({
  change,
  view,
}: {
  change: ArrayChange
  view: ChangeViewType
}) =>
  match({ change, view })
    .with(ARRAY_CHANGE_LIST_PATTERN, getArrayListChanges)
    .otherwise(getArraySetChanges)

const getArraySetChanges = ({
  change,
  view,
}: {
  change: ArrayChange
  view: ChangeViewType
}): ParamChangeItem[] => {
  const oldValueItems = change.oldValue.value || []
  const newValueItems = change.newValue.value || []

  return match(view)
    .with(ChangeViewType.old, () => getOldTabItems<Value>(newValueItems, oldValueItems))
    .with(ChangeViewType.new, () => getNewTabItems<Value>(newValueItems, oldValueItems))
    .with(ChangeViewType.diff, () => getSetDiffItems<Value>(newValueItems, oldValueItems))
    .exhaustive()
}

const getArrayListChanges = ({
  change,
  view,
}: {
  change: ArrayChange
  view: ChangeViewType
}): ParamChangeItem[] => {
  const oldValueItems = indexItems<Value>(change.oldValue.value)
  const newValueItems = indexItems<Value>(change.newValue.value)

  return match(view)
    .with(ChangeViewType.old, () =>
      unpackIndexedChange(getOldTabItems<Indexed<Value>>(newValueItems, oldValueItems)),
    )
    .with(ChangeViewType.new, () =>
      unpackIndexedChange(getNewTabItems<Indexed<Value>>(newValueItems, oldValueItems)),
    )
    .with(ChangeViewType.diff, () => getArrayDiffItems(newValueItems, oldValueItems))
    .exhaustive()
}
