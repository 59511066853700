import { MoreBar } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { IDAVE_PERMISSIONS } from 'security'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { Link } from 'react-router-dom'

type Props = {
  permission: Permission
}
export const PermissionActions = ({ permission }: Props) => {
  return (
    <PermissionsCheck
      somePermissions={[IDAVE_PERMISSIONS.PERMISSIONS_UPDATE_DESCRIPTION]}
    >
      <MoreBar>
        <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_UPDATE_DESCRIPTION}>
          <MoreBar.Action
            useIcon="Pencil"
            use={Link}
            to={generatePath(Url.PermissionEdit, { permissionId: permission.id })}
          >
            Edit
          </MoreBar.Action>
        </PermissionsCheck>
      </MoreBar>
    </PermissionsCheck>
  )
}
