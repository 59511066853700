import { Flex } from '@revolut/ui-kit'
import { UserToxicAlertBanner } from './components/UserToxicAlertBanner/UserToxicAlertBanner'
import { UserIdaveProfile } from './components/UserIdaveProfile'
import { UserPeopleopsProfile } from './components/UserPeopleopsProfile'

export const MAXIMAL_BLOCK_WIDTH = 720
const COLUMN_GAP = 16
const MAX_WIDTH = MAXIMAL_BLOCK_WIDTH * 2 + COLUMN_GAP

export const UserProfile = ({ userId }: { userId: string }) => {
  return (
    <>
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        maxWidth={MAX_WIDTH}
        rowGap={0}
        columnGap={COLUMN_GAP}
      >
        <UserToxicAlertBanner userId={userId} flexBasis={MAX_WIDTH} />
        <UserIdaveProfile userId={userId} flexBasis={MAXIMAL_BLOCK_WIDTH / 2} />
        <UserPeopleopsProfile userId={userId} flexBasis={MAXIMAL_BLOCK_WIDTH / 2} />
      </Flex>
    </>
  )
}
