import { Icon, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

export const CollapseIcon = styled(Icon)`
  &[aria-expanded='true'] {
    transform: rotate(180deg);
  }

  transition: ${Token.transition.transform};
`
