import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'

export const useDeleteClient = () => {
  const { closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    IDAVE_PERMISSIONS.CLIENTS_DELETE,
    IDAVE_PERMISSIONS.CROSS_CHECKS_RUN_CLIENT_DELETE_ACTION,
  )
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  return useMutation({
    mutationFn: (clientId: string) =>
      idaveApi.clients.removeClient({
        payload: { clientId },
        crossCheck: isCrossCheck,
      }),
    onMutate: () => showLoadingPopup({ title: 'Deleting...' }),
    onSuccess: (response, clientId) => {
      hideLoadingPopup()
      closePopup()
      const crosscheck = getCrossCheckDescriptor(response)
      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        queryClient.resetQueries({
          queryKey: [QueryKey.Clients],
          type: 'all',
        })
        queryClient.removeQueries({
          queryKey: [QueryKey.Client, clientId],
        })
        showSuccessToast('Application deleted')
        navigate(Url.Clients)
      }
    },
    onError: (err) => {
      hideLoadingPopup()
      closePopup()
      showErrorPopup({
        title: 'Application deletion failed',
        error: err,
      })
    },
  })
}
