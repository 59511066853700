import { CustomerCompany } from 'api/dart/customerCompanies'
import { EmployeeCompany } from 'api/dart/types'
import { EntitiesTable } from 'components/EntitiesTable'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useCallback, useMemo, useState } from 'react'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { DART_PERMISSIONS } from 'security'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { EmployeeCompany as Row } from '../../types'
import { getRows } from './utils'
import { COLUMNS } from './columns'
import { EmployeeCompanyEditActions } from '../EmployeeCompanyEditActions'

type EmployeeCompanyListProps = {
  customerCompany: CustomerCompany
  employeeCompanies?: EmployeeCompany[]
  reset: () => void
}

export const EmployeeCompanyList = ({
  customerCompany,
  employeeCompanies,
  reset,
}: EmployeeCompanyListProps) => {
  const { hasSomePermissions } = usePermissions()
  const loadingStatus = customerCompany && employeeCompanies ? 'success' : 'loading'
  const entities = useMemo(
    () => getRows(employeeCompanies, customerCompany),
    [employeeCompanies, customerCompany],
  )

  const loadingState = getLoadingState(loadingStatus, entities.length)
  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities,
  })

  const getRowLink = useCallback(
    (row: Row) => generatePath(Url.DartEmployeeCompany, { companyId: row.id }),
    [],
  )

  const isEditable = hasSomePermissions(
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_COMPANY_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_COMPANY_ACCESS_CROSS_CHECK,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_COMPANY_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_REVOKE_COMPANY_ACCESS_CROSS_CHECK,
  )
  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((v) => !v),
    [setShowSelected],
  )
  const selectHash = useMemo(
    () => (isEditable ? setSelectedHash : undefined),
    [isEditable],
  )

  const dataLabels = useMemo(
    () => [
      {
        value: customerCompany?.allowedCompanies.length || 0,
        label: 'Allowed',
      },
    ],
    [customerCompany?.allowedCompanies.length],
  )

  return (
    <EntitiesTable
      entitiesTypeLabel="Companies"
      pluralForms={['company', 'companies']}
      totalCount={entities.length}
      data={searched}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      searchValue={searchValue}
      searchAutoFocus
      columns={COLUMNS}
      getRowLink={getRowLink}
      dataLabels={dataLabels}
      selectedHash={selectedHash}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={selectHash}
      showSelectedSwitcherForce={isEditable}
      renderActions={() => (
        <EmployeeCompanyEditActions
          reset={reset}
          selectedHash={selectedHash}
          companyId={customerCompany?.id}
          isEditable={isEditable}
          entities={entities}
        />
      )}
    />
  )
}
