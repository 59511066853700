import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyParams = () => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D289', 'v2')} />
    <StatusWidget.Title>No request input details available</StatusWidget.Title>
    <StatusWidget.Description>
      The request information appears to be empty.
      <br />
      This could be due to an unexpected issue or missing input from the requester
    </StatusWidget.Description>
  </StatusWidget>
)
