import { Text } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getActionLabel } from 'utils/permission/getActionLabel'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'

type Props = {
  permission: Permission
}
export const PermissionAction = ({ permission }: Props) => {
  const label = getSecurityLabel(permission.action, getActionLabel(permission.action))

  return <Text>{label || EMPTY_VALUE_FALLBACK}</Text>
}
