import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import {
  XCheckDataProvider,
  XCheckSecurityProvider,
  XCheckSideViewContextProvider,
  ApiProvider,
  ViewSettings,
  ViewsProvider,
} from '.'
import { Data, HasPermissionFn } from '../types'
import { ParamsProvider } from './useParams'
import { ChangesProvider } from './useChanges'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
})

export const QueryProvider = ({ children }: { children: React.ReactNode }) => {
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

type Props = {
  children: React.ReactNode
  xCheckId: string

  userIdData: Data<string>

  onXCheckUpdate: () => void

  apiSettings: ApiSettings
  viewSettings: ViewSettings
  hasPermission: HasPermissionFn
}

export const Provider = ({
  children,
  userIdData,
  xCheckId,
  onXCheckUpdate,

  apiSettings,
  viewSettings,
  hasPermission,
}: Props) => {
  return (
    <ApiProvider {...apiSettings}>
      <ViewsProvider {...viewSettings}>
        <XCheckSecurityProvider hasPermission={hasPermission} apiSettings={apiSettings}>
          <QueryProvider>
            <XCheckDataProvider
              userIdData={userIdData}
              xCheckId={xCheckId}
              onXCheckUpdate={onXCheckUpdate}
            >
              <XCheckSideViewContextProvider>
                <ParamsProvider>
                  <ChangesProvider>{children}</ChangesProvider>
                </ParamsProvider>
              </XCheckSideViewContextProvider>
            </XCheckDataProvider>
          </QueryProvider>
        </XCheckSecurityProvider>
      </ViewsProvider>
    </ApiProvider>
  )
}
