import { NumberParam } from 'api/types/xChecks'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { notNullable } from 'utils/common'
import { Text } from '@revolut/ui-kit'
import { getVariantTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { ItemValueProps } from './types'

export const NumberValue = ({ value, variant }: ItemValueProps<NumberParam>) => {
  const numberValue = value?.value

  return (
    <Text {...getVariantTextStyle(variant)}>
      {notNullable(numberValue) ? numberValue : EMPTY_VALUE_FALLBACK_TEXT}
    </Text>
  )
}
