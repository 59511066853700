import { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { useInvalidtePolicyCache } from './useInvalidatePolicyCache'

export function useNavigateToPolicy() {
  const invalidate = useInvalidtePolicyCache()
  const navigate = useNavigate()

  const navigateAndReload = useCallback(
    (id: string) => {
      invalidate(id)

      navigate(generatePath(Url.SamPolicies, { policyId: id }))
    },
    [invalidate, navigate],
  )

  return navigateAndReload
}
