import { EntitiesTable } from 'components/EntitiesTable'
import { TableLoadingState } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useCallback, useMemo } from 'react'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { useQueryClient } from '@tanstack/react-query'
import { QueryKey } from 'helpers/configQuery'
import { getColumns } from './columns'
import { UserRestrictionAction } from './components/UserRestrictionAction'
import { UserRestrictionRow } from '../../types'
import { useDeleteRestriction } from '../../hooks'

type Props = {
  items: UserRestrictionRow[]
  loadingState: TableLoadingState
}
export const UserRestrictionList = ({ items, loadingState }: Props) => {
  const permissionUtils = usePermissions()
  const { searched, searchValue, setSearchValue } = useSearchFilter({ entities: items })
  const queryClient = useQueryClient()
  const { onDeleteRestriction } = useDeleteRestriction(() => {
    queryClient.invalidateQueries([QueryKey.DirectRelations])
  })
  const columns = useMemo(
    () => getColumns({ permissionUtils, onDeleteClick: onDeleteRestriction }),
    [permissionUtils, onDeleteRestriction],
  )
  const getEmployeeLink = useCallback(({ employeeId: userId }: UserRestrictionRow) => {
    return generatePath(Url.UserProfile, { userId })
  }, [])
  return (
    <EntitiesTable
      enableNavigation
      totalCount={items.length}
      entitiesTypeLabel="Restrictions"
      pluralForms={['restriction', 'restrictions']}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      searchAutoFocus
      renderActions={UserRestrictionAction}
      loadingState={loadingState}
      onSearchChange={setSearchValue}
      getRowLink={getEmployeeLink}
    />
  )
}
