import { useMutation } from '@tanstack/react-query'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { extractErrorMsg } from 'view/XChecks/XCheck/lib/utils'
import { useApi, useXCheckData } from 'view/XChecks/XCheck/lib/hooks'

export const useSubmitComment = (onSuccess: () => void) => {
  const api = useApi()
  const { onXCheckUpdate } = useXCheckData()
  const statusPopup = useStatusPopup()

  return useMutation({
    mutationFn: api.comment,
    onSuccess: () => {
      onSuccess()
      onXCheckUpdate()
    },
    onError: (err: Error) => {
      statusPopup.hide()
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Comment adding failed</StatusPopup.Title>
          <StatusPopup.Description>{extractErrorMsg(err)}</StatusPopup.Description>
        </StatusPopup>,
      )
    },
  })
}

export const useSubmitInfo = (onSuccess: () => void) => {
  const api = useApi()
  const { onXCheckUpdate } = useXCheckData()
  const statusPopup = useStatusPopup()

  return useMutation({
    mutationFn: api.provideInfo,
    onSuccess: () => {
      onSuccess()
      onXCheckUpdate()
    },
    onError: (err: Error) => {
      statusPopup.hide()
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Request unpause failed</StatusPopup.Title>
          <StatusPopup.Description>{extractErrorMsg(err)}</StatusPopup.Description>
        </StatusPopup>,
      )
    },
  })
}
