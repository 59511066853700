import { Param, Change, Changes } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { CHANGE_PATTERN } from '../../../utils'

type Item = Change | Param

export const hasSomeChanges = (...items: Item[]) => {
  return items.some(isChange)
}
const isChange = (value: Item): value is Change =>
  match(value)
    .with(CHANGE_PATTERN, () => true)
    .otherwise(() => false)

export const isChangesEmpty = (changes?: Changes) => {
  return !changes?.created.length && !changes?.updated.length && !changes?.deleted.length
}
