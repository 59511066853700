import { XCheckDetails as TXCheckDetails } from 'api/types/xChecks'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { CopyableText } from 'components/CopyableText'
import { formatDateTimePrecise } from 'utils/date'
import { DetailsCell, Group } from '@revolut/ui-kit'
import { Data } from '../../../types'
import { MarkdownRenderer } from '../../common/MarkdownRenderer'

type Props = {
  xCheck: Data<TXCheckDetails>
}

export const XCheckDetails = ({ xCheck }: Props) => {
  if (xCheck.status === 'loading') {
    return <DetailsGroupSkeleton title="Details" />
  }

  if (xCheck.status === 'error') {
    return null
  }

  return (
    <>
      <Group>
        {xCheck.data.justification ? (
          <DetailsCell>
            <DetailsCell.Title>Justification</DetailsCell.Title>
            <DetailsCell.Note>
              <MarkdownRenderer>{xCheck.data.justification}</MarkdownRenderer>
            </DetailsCell.Note>
          </DetailsCell>
        ) : null}

        <DetailsCell>
          <DetailsCell.Title>Request ID</DetailsCell.Title>
          <DetailsCell.Note>
            <CopyableText value={xCheck.data.id}>{xCheck.data.id}</CopyableText>
          </DetailsCell.Note>
        </DetailsCell>

        <DetailsCell>
          <DetailsCell.Title>Executable ID</DetailsCell.Title>
          <DetailsCell.Note>
            <CopyableText value={xCheck.data.executable.executableId}>
              {xCheck.data.executable.executableId}
            </CopyableText>
          </DetailsCell.Note>
        </DetailsCell>

        <DetailsCell>
          <DetailsCell.Title>Executable name</DetailsCell.Title>
          <DetailsCell.Content>
            {xCheck.data.executable.executableName}
          </DetailsCell.Content>
        </DetailsCell>

        <DetailsCell>
          <DetailsCell.Title>Source</DetailsCell.Title>
          <DetailsCell.Content>{xCheck.data.source}</DetailsCell.Content>
        </DetailsCell>

        <DetailsCell>
          <DetailsCell.Title>Created date</DetailsCell.Title>
          <DetailsCell.Content>
            {formatDateTimePrecise(xCheck.data.createdDate)}
          </DetailsCell.Content>
        </DetailsCell>

        <DetailsCell>
          <DetailsCell.Title>Updated date</DetailsCell.Title>
          <DetailsCell.Content>
            {formatDateTimePrecise(xCheck.data.updatedDate)}
          </DetailsCell.Content>
        </DetailsCell>
      </Group>
    </>
  )
}
