import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { SamPolicy } from 'api/sam/policies'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { useCallback, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { SamEditPolicyDetails } from 'view/Sam/components/SamEditPolicyDetails'
import { mapPolicyDetails } from 'view/Sam/SamCreatePolicy/utils'
import { PageHeader } from 'components/PageHeader'
import { equals } from 'lodash/fp'
import { SamPolicyEditSkeleton } from './SamPolicyEditSkeleton'
import { usePolicyUpdate } from './usePolicyEdit'

export const SamPolicyEdit = (props: { policyId: string }) => {
  const { policyId } = props
  const { data: policy, status, refetch, fetchStatus } = useQuerySamPolicy(props.policyId)
  const navigate = useNavigate()

  return (
    <>
      <PageHeader
        onBack={() => navigate(generatePath(Url.SamPolicy, { policyId }))}
        pageTitle="Edit Policy"
      />

      <Page.Main size={PageMainSize.NARROW}>
        <QuerySwitch
          data={policy}
          required={[{ qs: status, fs: fetchStatus }]}
          renderSuccess={({ data }) => <InnerPolicyEdit policy={data} />}
          renderIdle={() => <RequestErrorWidget action={refetch} />}
          renderError={() => <RequestErrorWidget action={refetch} />}
          renderLoading={SamPolicyEditSkeleton}
        />
      </Page.Main>
    </>
  )
}

const InnerPolicyEdit = (props: { policy: SamPolicy }) => {
  const { policy } = props

  const { policyName, businessReason, subject } = policy
  const [policyDetails, setPolicyDetails] = useState({
    ...mapPolicyDetails(policy),
    policyName,
    businessReason,
  })
  const [hasErrors, setHasErrors] = useState(false)

  const { update, isUpdating } = usePolicyUpdate(policy, policyDetails)

  const isNothingChanged =
    policyName === policyDetails.policyName &&
    policyDetails.businessReason === businessReason &&
    equals(subject, policyDetails.subject)

  const onSubmit = useCallback(() => {
    update()
  }, [update])
  return (
    <>
      <SamEditPolicyDetails
        policyDetails={policyDetails}
        setHasErrors={setHasErrors}
        setPolicyDetails={setPolicyDetails}
        currentEditingPolicyId={policy.policyId}
      />
      <Page.MainActions>
        <Button
          id="policy-edit-save"
          disabled={isNothingChanged || hasErrors}
          onClick={onSubmit}
          pending={isUpdating}
        >
          Save changes
        </Button>
      </Page.MainActions>
    </>
  )
}
