import { getCookie } from 'utils/common/getCookie'
import { ThemeMode, ThemeBackground } from '@revolut/ui-kit'

export const OLD_THEME_COOKIE = 'BACKOFFICE_THEME'
export const NEW_THEME_COOKIE = 'revo_theme_mode'
export const MODES: NonNullable<ThemeMode>[] = ['auto', 'dark', 'light']

export const STORAGE_BACKGROUND_KEY = 'BACKOFFICE_THEME_BACKGROUND'

const isMode = (value?: string | null): value is ThemeMode => {
  return !!value && !!MODES.find((v) => v === value)
}

export const getCookieMode = () => {
  const modeValue = getCookie(NEW_THEME_COOKIE) || getCookie(OLD_THEME_COOKIE)
  return isMode(modeValue) ? modeValue : 'light'
}

const isBackground = (value?: string): value is ThemeBackground => {
  return !!value && Object.values(ThemeBackground).includes(value as ThemeBackground)
}

export const getCookieBackground = () => {
  const backgroundValue = getCookie(STORAGE_BACKGROUND_KEY)
  return isBackground(backgroundValue) ? backgroundValue : undefined
}
