import { PrincipalComponentType } from 'view/XChecks/XCheck/lib/types'
import { Log, XCheckDetails } from 'api/types/xChecks'
import { useCallback, useMemo, useState } from 'react'
import { Action, Box, Flex, Footnote, VStack } from '@revolut/ui-kit'
import { useMapify } from 'hooks/useMapify'
import { EmptyLogs } from './components/EmptyLogs'
import { LogItem } from './components/LogItem'
import { SLICE_AMOUNT, getEvents } from '../../../../utils'

type Props = {
  Principal: PrincipalComponentType
  logs: Log[]
  xCheck?: XCheckDetails
}

export const LogsTab = ({ Principal, logs, xCheck }: Props) => {
  const [sliceLast, setSliceLast] = useState(true)
  const onShowAllClick = useCallback(() => setSliceLast(false), [])
  const { items, isSliced } = useMemo(
    () => getEvents({ items: logs, sliceLast }),
    [logs, sliceLast],
  )

  const commentMap = useMapify(xCheck?.comments || [], (comment) => comment.id)

  if (!items.length) {
    return <EmptyLogs />
  }

  return (
    <>
      <VStack gap="s-32" mt="s-12">
        {items.map((log) => (
          <LogItem
            key={log.id}
            commentMap={commentMap}
            log={log}
            Principal={Principal}
            xCheck={xCheck}
          />
        ))}
      </VStack>
      {isSliced && sliceLast ? (
        <Box pt="s-12">
          <Footnote>Last {SLICE_AMOUNT} logs shown</Footnote>
          <Flex justifyContent="center" mb="s-8">
            <Action onClick={onShowAllClick}>Show all</Action>
          </Flex>
        </Box>
      ) : null}
    </>
  )
}
