export const isSubmitCombination = (event: React.KeyboardEvent) => {
  const isEnterPressed = event.key === 'Enter'
  const isMac =
    navigator?.platform.toLowerCase().includes('mac') ||
    navigator.userAgent.toLowerCase().includes('mac')
  const isAdditionalKeyPressed = isMac ? event.metaKey : event.ctrlKey

  return isEnterPressed && isAdditionalKeyPressed
}

export const stopPropagation = (e: React.MouseEvent) => {
  e.stopPropagation()
}
