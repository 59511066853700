import { PageMainSize } from '@revolut/ui-kit'
import { AccountTab } from '../types'

export const tabToPageSize = (tab: AccountTab) => {
  switch (tab) {
    case 'auditLog':
    case 'permissions':
      return PageMainSize.FULL
    default:
    case 'details':
      return PageMainSize.NARROW
  }
}
