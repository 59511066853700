import { match, P } from 'ts-pattern'
import { CHANGE_PATTERN } from 'view/XChecks/XCheck/lib/utils'
import { ChangeViewType } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { DetailItem } from '../../types'
import { DetailParamItem } from './components/DetailParamItem'
import { DetailChangeItem } from './components/DetailChangeItem'

type Props = {
  item: DetailItem
  view?: ChangeViewType
}

export const DetailRowValue = (props: Props) =>
  match(props)
    .with({ item: CHANGE_PATTERN }, ({ item }) => (
      <DetailChangeItem change={item} view={props.view} />
    ))
    .with({ item: { value: P.any } }, ({ item }) => (
      <DetailParamItem value={item.value} />
    ))
    .exhaustive()
