import {
  BottomSheet,
  Box,
  Button,
  Header,
  IconButton,
  Token,
  useToggle,
} from '@revolut/ui-kit'
import { State, XCheckDetails } from 'api/types/xChecks'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'
import { isFinished, isSubmitCombination } from 'view/XChecks/XCheck/lib/utils'
import { useXCheckData, useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { useSubmitComment, useSubmitInfo } from './hooks'
import { MentionsInput } from '../MentionsInput'

export const CommentForm = ({ xCheck }: { xCheck: XCheckDetails }) => {
  const { userIdData } = useXCheckData()
  const [comment, setComment] = useState('')
  const { hasXCheckPermission } = useXCheckSecurity()

  const onChange = useInputStringChange(setComment)
  const clearField = useCallback(() => setComment(''), [setComment])
  const [open, toggleOpen] = useToggle()

  const { mutate: sendComment, isLoading: isCommentLoading } =
    useSubmitComment(clearField)
  const { mutate: sendInfo, isLoading: isInfoLoading } = useSubmitInfo(clearField)

  const submitComment = useCallback(() => {
    sendComment({ comment, xCheckId: xCheck.id })
    toggleOpen.off()
  }, [sendComment, toggleOpen, comment, xCheck.id])
  const submitInfo = useCallback(() => {
    sendInfo({ comment, xCheckId: xCheck.id })
    toggleOpen.off()
  }, [sendInfo, toggleOpen, comment, xCheck.id])

  const onSubmitComment = useCallback(() => {
    if (
      userIdData.status === 'success' &&
      userIdData.data === xCheck.requester.id &&
      xCheck.state === State.RequestedInfo &&
      hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_PROVIDE_INFO)
    ) {
      toggleOpen.on()
    } else {
      submitComment()
    }
  }, [hasXCheckPermission, submitComment, xCheck, toggleOpen, userIdData])

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (isSubmitCombination(event) && comment) {
        onSubmitComment()
      }
    },
    [onSubmitComment, comment],
  )

  if (!hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_COMMENT)) {
    return null
  }

  if (isFinished(xCheck)) {
    return null
  }

  const disabled = !comment || isCommentLoading || isInfoLoading

  return (
    <>
      <Box mb="s-16">
        <MentionsInput
          onChange={onChange}
          value={comment}
          disabled={isCommentLoading || isInfoLoading}
          withPreview
          onKeyDown={onKeyDown}
          renderActions={() => (
            <IconButton
              useIcon="SendMessage"
              disabled={disabled}
              variant="icon"
              color={disabled ? Token.color.shimmer : Token.color.accent}
              onClick={onSubmitComment}
              size={22}
            >
              Comment
            </IconButton>
          )}
        />
      </Box>
      <BottomSheet open={open} onClose={toggleOpen.off}>
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Unpause or comment</Header.Title>
          <Header.Description>
            Do you wish to unpause request by sending info or just add a comment?
          </Header.Description>
        </Header>

        <BottomSheet.Actions horizontal>
          <Button onClick={submitComment} useIcon="Chat" variant="secondary">
            Comment
          </Button>
          <Button onClick={submitInfo} useIcon="Play">
            Unpause
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}
