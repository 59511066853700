import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyLogs = () => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D007', 'v2')} />
    <StatusWidget.Title>No logs for the request yet</StatusWidget.Title>
    <StatusWidget.Description>Wait until they appear</StatusWidget.Description>
  </StatusWidget>
)
