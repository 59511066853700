import { Input, Page, PageMainSize, VStack } from '@revolut/ui-kit'
import { PageHeader } from 'components/PageHeader'
import { useLinkNavigate } from 'hooks/useLinkNavigate'
import { times } from 'lodash'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const OrganisationEditSkeleton = (props: { organisationId: string }) => {
  const toOrg = useLinkNavigate(
    generatePath(Url.OrganisationDetails, { organisationId: props.organisationId }),
  )
  return (
    <>
      <PageHeader onBack={toOrg} pageTitle="Edit organisation" />
      <Page.Main size={PageMainSize.NARROW}>
        <VStack space="s-24">
          {times(4, (idx) => (
            <Input ref={undefined} disabled key={idx} />
          ))}
        </VStack>
      </Page.Main>
    </>
  )
}
