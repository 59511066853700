import { useCallback, useState } from 'react'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { EventTab, Tab } from '../../types'

export const useEventsTabs = (tabs: EventTab[]) => {
  const firstAvailable = tabs.find((t) => t.available)
  const [tab, setTab] = useState(firstAvailable?.tab)

  const onTabClick = useCallback(
    (newTab: Nullable<Tab>) => {
      if (!newTab) {
        return undefined
      }
      const newTabItem = tabs.find((tabItem) => tabItem.tab === newTab)

      if (newTabItem && newTabItem.available) {
        return setTab(newTab)
      }

      return undefined
    },
    [setTab, tabs],
  )

  return {
    tab,
    onTabClick,
  }
}
