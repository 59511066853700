import { XCheckDetails } from 'api/types/xChecks'
import { useEffect } from 'react'
import { UpdateTab } from './types'
import { isFinished } from '../../utils'

type Params = {
  xCheck?: XCheckDetails
  tab: UpdateTab
  setTab: (tab: UpdateTab) => void
}

export const useUpdateXCheckTabs = ({ xCheck, setTab, tab }: Params) => {
  useEffect(() => {
    if (tab === UpdateTab.params) {
      return
    }
    if (!xCheck || isFinished(xCheck)) {
      setTab(UpdateTab.params)
    }
  }, [setTab, xCheck, tab])
}
