import { sortBy } from 'lodash'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { EntityData } from 'view/XChecks/XCheck/lib/hooks'

type GetUserEntitiesParams = {
  users: EntityData[]
  bubbleUp?: string[]
  excludeHash: Record<string, boolean>
}
export const getUserEntities = ({
  users,
  bubbleUp = [],
  excludeHash,
}: GetUserEntitiesParams) => {
  const hoistIds = toPresenceMap(bubbleUp)

  const userItems = users.filter((user) => !excludeHash[user.id])

  return sortBy(userItems, [({ id }) => !hoistIds[id], 'state', 'name'])
}

type GetFilteredUsers = {
  users: EntityData[]
  showSelected: boolean
  selectedHash: Record<string, boolean>
}
export const getFilteredUsers = ({
  users,
  showSelected,
  selectedHash,
}: GetFilteredUsers) => {
  return users.filter((user) => !showSelected || selectedHash[user.id])
}
