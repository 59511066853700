import { UuidParam, Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { FieldInput, FieldSelect } from '../Fields'
import { InputProps } from './type'
import { PARAM_WITH_OPTION_PATTERN } from './constants'
import { getStandaloneLabel, getStandalonePath, useStandaloneValidate } from './utils'

const getUuidOptions = (options: Value<UuidParam>[] = []) =>
  options?.map((option, idx) => ({
    key: `${option.value}_${idx}`,
    value: option.value as string,
    label: option.value,
  }))

export const UuidInput = (props: InputProps<UuidParam>) => {
  const { param, prefix, index } = props
  const defaultValue = param.value.value

  const required = !!param.value?.editConfig?.required
  const validate = useStandaloneValidate(param)

  return match(param)
    .with(PARAM_WITH_OPTION_PATTERN, (optionedParam) => (
      <FieldSelect
        label={getStandaloneLabel(param)}
        path={getStandalonePath(prefix, index)}
        defaultValue={defaultValue}
        clearable={!required}
        options={getUuidOptions(optionedParam.value.editConfig.options)}
        validate={validate}
      />
    ))
    .otherwise(() => (
      <FieldInput
        label={getStandaloneLabel(param)}
        path={getStandalonePath(prefix, index)}
        defaultValue={defaultValue}
        disabled={!param.value.editConfig}
        validate={validate}
      />
    ))
}
