import { CustomerCompany } from 'api/dart/customerCompanies'
import { EmployeeAccesses } from 'api/dart/employee'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { UserListItem } from 'api/idave/user'
import { useGetEmployeeRevoDescription } from 'hooks/useGetEmployeeRevoDescription'
import { useCallback, useMemo, useState } from 'react'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { EntitiesTable } from 'components/EntitiesTable'
import { Row } from './types'
import { getRows } from './utils'
import { useColumns } from './columns'
import { UserListActions } from './components/UserListActions'

type AllowedUserListProps = {
  customerCompany: CustomerCompany
  userMap?: Map<string, UserListItem>
  avatars?: Map<string, RevolutersAvatar>
  employeesAccesses?: EmployeeAccesses[]
  reset: () => void
}

export const AllowedUserList = ({
  customerCompany,
  avatars,
  userMap = new Map(),
  employeesAccesses,
  reset,
}: AllowedUserListProps) => {
  const loadingStatus = userMap?.size ? 'success' : 'loading'
  const { status: revoDescriptionStatus, getEmployeeDescription } =
    useGetEmployeeRevoDescription()

  const rows = useMemo(
    () =>
      getRows({
        userMap,
        customerCompany,
        avatars,
        employeesAccesses,
        getEmployeeDescription,
      }),
    [userMap, customerCompany, avatars, getEmployeeDescription, employeesAccesses],
  )

  const loadingState = getLoadingState(loadingStatus, rows.length)
  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities: rows,
  })

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((showSelectedValue) => !showSelectedValue),
    [setShowSelected],
  )

  const getRowLink = useCallback(
    (row: Row) => generatePath(Url.User, { userId: row.id }),
    [],
  )

  const columns = useColumns({ isTeamsLoaded: revoDescriptionStatus !== 'loading' })

  return (
    <EntitiesTable
      totalCount={rows.length}
      entitiesTypeLabel="Employees"
      pluralForms={['employee', 'employees']}
      data={searched}
      onSearchChange={setSearchValue}
      loadingState={loadingState}
      searchValue={searchValue}
      searchAutoFocus
      columns={columns}
      getRowLink={getRowLink}
      selectedHash={selectedHash}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={setSelectedHash}
      renderActions={() => (
        <UserListActions
          selectedHash={selectedHash}
          reset={reset}
          companyId={customerCompany.id}
        />
      )}
    />
  )
}
