import { isProduction } from 'utils/common'
import { PageMainSize } from '@revolut/ui-kit'
import { CustomerCompanyTab } from './CustomerCompany'

export const getCustomerCompanyLink = (companyId: string) =>
  `https://core-platform.revolut.${
    isProduction() ? 'com' : 'codes'
  }/companies/${companyId}`

export const tabToPageSize = (tab: CustomerCompanyTab) => {
  switch (tab) {
    case 'employee':
    case 'employeeCompanies':
      return PageMainSize.FULL
    case 'approvers':
    default:
      return PageMainSize.NARROW
  }
}
