import { EntitiesTable } from 'components/EntitiesTable'
import { useSearchFilter } from 'hooks/useSearchFilter'
import {
  useQueryMappedEmployeeIdAvatars,
  useQueryEmployeePeopleOpsMap,
} from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback, useMemo } from 'react'
import { isAllSettled } from 'utils/query'
import { useNavigate, generatePath } from 'react-router'
import { Url } from 'routing'
import { CrossCheck } from 'api/types/crossCheck'
import { getColumns } from './columns'
import { getRows } from './utils'
import { Row } from './types'
import { EmptyList } from './components/EmptyList'

type ListProps = {
  crossChecks: CrossCheck[]
  userId: string
}

export const List = ({ crossChecks, userId }: ListProps) => {
  const navigate = useNavigate()

  const { data: userMap, status: usersQS, fetchStatus: usersFS } = useQueryUserIdMap()
  const {
    data: employeeMap,
    status: employeeQS,
    fetchStatus: employeeFS,
  } = useQueryEmployeePeopleOpsMap()
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()

  const isEmployeeSettled = isAllSettled(
    { qs: employeeQS, fs: employeeFS },
    { qs: usersQS, fs: usersFS },
  )

  const dataMaps = useMemo(
    () => ({
      userMap,
      employeeMap,
    }),
    [userMap, employeeMap],
  )

  const rows = useMemo(
    () =>
      getRows({
        crossChecks,
        dataMaps,
        isEmployeeSettled,
      }),
    [crossChecks, dataMaps, isEmployeeSettled],
  )

  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities: rows })
  const columns = useMemo(() => getColumns(avatars), [avatars])

  const getRowLink = useCallback(
    (row: Row) =>
      generatePath(Url.CrossCheckSamDetails, {
        crossCheckId: row.id,
      }),
    [],
  )

  const onClick = useCallback(
    (row: Row) => {
      return navigate(
        generatePath(Url.CrossCheckSamDetails, {
          crossCheckId: row.id,
        }),
      )
    },
    [navigate],
  )

  if (!rows.length) {
    return <EmptyList userId={userId} />
  }

  return (
    <EntitiesTable
      enableNavigation
      entitiesTypeLabel="Requests"
      pluralForms={['request', 'requests']}
      totalCount={rows.length}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      getRowLink={getRowLink}
      onRowClick={onClick}
    />
  )
}
