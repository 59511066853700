import { Action, Caption, HStack, Input, VStack } from '@revolut/ui-kit'
import { EmployeeType, SamPolicySubjectType } from 'api/sam/policies'
import { useCallback, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { get, isEmpty } from 'lodash'
import { useQueryPolicies } from 'queries/sam/policies'
import { useMapify } from 'hooks/useMapify'
import {
  useQueryDepartmentMap,
  useQueryFunctionMap,
  useQueryRoleMap,
  useQuerySpecMap,
  useQueryTeamMap,
} from 'queries/sam/revoluters'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { SAM_PERMISSIONS } from 'security'
import { PolicySubjectSelect } from './PolicySubjectSelect'
import { SamEditPolicySubjectField } from './SamEditPolicySubjectField'
import {
  getFieldRules,
  getPolicySubjectFields,
  getDefaultSubject,
  getPolicyNameRules,
  getNameSuggest,
  getPolicyDescriptionRules,
  isSingleField,
  getEmployeeTypeForContractType,
} from './utils'
import { PolicyDetails } from './types'

export const SamEditPolicyDetails = (props: {
  policyDetails: PolicyDetails
  setPolicyDetails: (details: PolicyDetails) => void
  setHasErrors: (hasErrors: boolean) => void
  currentEditingPolicyId?: string
}) => {
  const { policyDetails, currentEditingPolicyId, setHasErrors, setPolicyDetails } = props
  const { data: policies = [] } = useQueryPolicies()

  const { data: teamMap } = useQueryTeamMap()
  const { data: specMap } = useQuerySpecMap()
  const { data: departmentMap } = useQueryDepartmentMap()
  const { data: functionMap } = useQueryFunctionMap()
  const { data: roleMap } = useQueryRoleMap()

  const mappedPolicyNames = useMapify(policies, (policy) => policy.policyName)

  const { hasSomePermissions } = usePermissions()

  const enabledMultiSubject = currentEditingPolicyId
    ? hasSomePermissions(
        SAM_PERMISSIONS.POLICIES_UPDATE,
        SAM_PERMISSIONS.POLICIES_UPDATE_MULTI_SUBJECT_WITH_CROSS_CHECK,
      )
    : hasSomePermissions(
        SAM_PERMISSIONS.POLICIES_CREATE,
        SAM_PERMISSIONS.POLICIES_CREATE_MULTI_SUBJECT_WITH_CROSS_CHECK,
      )

  const { formState, control, setValue, getValues, watch } = useForm<PolicyDetails>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: policyDetails,
  })
  const { subject } = getValues()
  const fields = getPolicySubjectFields(subject.subjectType)

  useEffect(() => {
    return setHasErrors(!isEmpty(formState.errors))
  }, [formState, setHasErrors])

  useEffect(() => {
    const subscription = watch((state) => {
      setPolicyDetails(state as PolicyDetails)
    })
    return subscription.unsubscribe
  }, [setPolicyDetails, watch])

  const nameChangeHandler = useCallback(
    (value: string) => {
      setValue('policyName', value, { shouldValidate: true })
    },
    [setValue],
  )
  const onNameChange = useInputStringChange(nameChangeHandler)

  const businessReasonHandler = useCallback(
    (value: string) => {
      setValue('businessReason', value, { shouldValidate: true })
    },
    [setValue],
  )
  const onBusinessReasonChange = useInputStringChange(businessReasonHandler)

  const onSubjectTypeChange = useCallback(
    (subjectType?: SamPolicySubjectType | null) => {
      if (subjectType) {
        const newSubject = getDefaultSubject(subjectType)
        setValue('subject', newSubject, { shouldValidate: true })
      }
    },
    [setValue],
  )

  const suggestedName = getNameSuggest({
    subject,
    teamMap,
    specMap,
    departmentMap,
    roleMap,
    functionMap,
  })

  // `contractTypes` has different values for 'external' and 'internal' `employeeTypes`
  // also `contractTypes` can be set if only one `employeeTypes` is selected
  // so we reset `contractTypes` here if `employeeTypes` changes, has no value, has more than one value
  const employeeType = getEmployeeTypeForContractType(subject)
  const savedEmployeeType = useRef<EmployeeType | undefined>()
  useEffect(() => {
    if (savedEmployeeType.current !== employeeType) {
      // to skip the initial call when values are retrieved from the localStorage
      if (savedEmployeeType.current) {
        setValue('subject.contractTypes', [])
      }

      savedEmployeeType.current = employeeType
    }
  }, [employeeType, setValue])

  return (
    <VStack space="s-24">
      <Controller
        name="subject.subjectType"
        control={control}
        render={({ field: { onChange: _onChange, ref: _ref, ...rest } }) => (
          <PolicySubjectSelect {...rest} onChange={onSubjectTypeChange} />
        )}
      />

      {fields.map((field) => (
        <Controller
          key={`subject.${field}`}
          name={`subject.${field}`}
          control={control}
          rules={getFieldRules(field, subject.subjectType)}
          render={({ field: { onChange: _onChange, ref: _ref, ...rest } }) => (
            <SamEditPolicySubjectField
              {...rest}
              field={field}
              isSingle={isSingleField(field, enabledMultiSubject, subject.subjectType)}
              subjectType={subject.subjectType}
              subjectEmployeeType={employeeType}
              setValue={setValue}
              values={getValues(`subject.${field}`)}
              error={get(formState.errors, `subject.${field}.message`)}
            />
          )}
        />
      ))}

      <Controller
        control={control}
        name="policyName"
        rules={getPolicyNameRules(mappedPolicyNames, currentEditingPolicyId)}
        render={({ field }) => (
          <Input
            {...field}
            ref={undefined}
            onChange={onNameChange}
            onClear={() => nameChangeHandler('')}
            label="Policy name"
            invalid={!!formState.errors.policyName?.message}
            errorMessage={formState.errors.policyName?.message}
            description={
              suggestedName && field.value !== suggestedName ? (
                <HStack gap="s-8" align="center">
                  <Caption use="p">Suggested name: {suggestedName}</Caption>
                  <Action onClick={() => nameChangeHandler(suggestedName)}>Apply</Action>
                </HStack>
              ) : null
            }
          />
        )}
      />

      <Controller
        control={control}
        name="businessReason"
        rules={getPolicyDescriptionRules()}
        render={({ field }) => (
          <Input
            {...field}
            ref={undefined}
            onChange={onBusinessReasonChange}
            onClear={() => businessReasonHandler('')}
            label="Description"
            invalid={!!formState.errors.businessReason?.message}
            errorMessage={formState.errors.businessReason?.message}
          />
        )}
      />
    </VStack>
  )
}
