import { Button, Header, Popup } from '@revolut/ui-kit'
import { useCallback, memo } from 'react'
import { Popup as PopupProps, useConfirmationPopup } from 'hooks/useConfirmationPopup'

export const ConfirmationPopup = () => {
  const { popup, closePopup } = useConfirmationPopup()

  if (!popup) {
    return null
  }

  return <InnerPopup {...popup} closePopup={closePopup} />
}

const InnerPopup = memo((props: PopupProps & { closePopup(): void }) => {
  const {
    message,
    title,
    onConfirmClick,
    onCancelClick,
    onClose,
    closePopup,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    confirmButtonVariant = 'primary',
  } = props

  const onCancelClickHandler = useCallback(() => {
    onCancelClick?.()
    closePopup()
  }, [onCancelClick, closePopup])

  const onCloseHandler = useCallback(() => {
    onClose?.()
    closePopup()
  }, [onClose, closePopup])

  return (
    <Popup open onClose={onCloseHandler} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        <Header.Description>{message}</Header.Description>
      </Header>
      <Popup.Actions horizontal>
        <Button variant="secondary" elevated onClick={onCancelClickHandler}>
          {cancelButtonText}
        </Button>
        <Button variant={confirmButtonVariant} elevated onClick={onConfirmClick}>
          {confirmButtonText}
        </Button>
      </Popup.Actions>
    </Popup>
  )
})
