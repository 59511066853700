import { useState } from 'react'
import { Table, TableColumn, TableData, TableFilterProps } from '@revolut/ui-kit'
import { Entity, XChecksListItem } from 'api/types/xChecks'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { CheckboxSelectTableFilter } from './CheckboxSelectTableFilter'
import { getSelectLoadingState } from '../utils'
import { useApi } from '../../../hooks'

const ENTITIES_QUERY_KEY = 'ENTITIES_QUERY_KEY'

const EntityTableFilter = <TEntity extends TableData>({
  column,
}: Pick<TableFilterProps<TEntity>, 'column'>) => {
  const [search, setSearch] = useState<string>()

  const debouncedSearch = useDebouncedValue(search, 500)

  const invalidSearch =
    !!debouncedSearch && debouncedSearch.length > 0 && debouncedSearch.length < 4

  const { fetchEntities } = useApi()
  const { hasXCheckPermission } = useXCheckSecurity()

  const { data: entities = [], status } = useQuery({
    queryKey: [ENTITIES_QUERY_KEY, debouncedSearch],
    queryFn: () => fetchEntities({ query: debouncedSearch || undefined }),
    enabled: hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_ENTITIES_LIST),
  })

  const loadingState = getSelectLoadingState(status)

  const options = uniqBy(
    entities.map((entity) => ({
      value: entity.entityId || entity.id,
      label: entity.entityName,
      key: entity.entityId || entity.id,
    })),
    'key',
  )

  return (
    <CheckboxSelectTableFilter
      column={column}
      options={options}
      loadingState={loadingState}
      onSearchText={setSearch}
      labelFailedState={invalidSearch ? 'At least 4 characters' : undefined}
    />
  )
}

const EntityTableCell = ({ value }: { value: Entity[] }) => (
  <Table.Cell>
    {value.map((entity) => (
      <p key={entity.id}>{entity.entityName}</p>
    ))}
  </Table.Cell>
)

export const ENTITY_TABLE_CELL: TableColumn<XChecksListItem> = {
  Header: 'Entities',
  accessor: 'entities',
  Cell: EntityTableCell,
  Filter: ({ column }: Pick<TableFilterProps<XChecksListItem>, 'column'>) => (
    <EntityTableFilter column={column} />
  ),
  // xChecks API does not provide sorting order functionality
  disableSortBy: true,
}
