import { createContext, useContext, useMemo } from 'react'
import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { uniq } from 'lodash'
import { makeErrorAsyncNoop, noop } from '../../../../lib/hooks/utils'
import { makeService } from './service'
import { XCheckApi } from './types'

export type TApiContext = XCheckApi & ApiSettings & ApiAttributes

type ApiAttributes = {
  sources: string[]
}

const DEFAULT = {
  sourceServiceMap: {},
  sourcePrefixMap: {},
  apiPrefix: '',
  apiName: '',
  entitiesFetchers: {},
  sources: [],
  onXCheckApiError: noop,

  fetchUsers: makeErrorAsyncNoop('fetchUsers'),
  fetchXChecks: makeErrorAsyncNoop('fetchXChecks'),
  fetchEntities: makeErrorAsyncNoop('fetchEntities'),
  fetchExecutables: makeErrorAsyncNoop('fetchExecutables'),
  fetchPrincipals: makeErrorAsyncNoop('fetchPrincipals'),
}

export const ApiContext = createContext<TApiContext>(DEFAULT)

type ApiProviderProps = ApiSettings & {
  children?: React.ReactNode
}

export const ApiProvider = ({ children, ...apiSettings }: ApiProviderProps) => {
  const sources = useMemo(
    () =>
      uniq(
        Object.keys(apiSettings.sourcePrefixMap).concat(
          ...Object.keys(apiSettings.sourceServiceMap),
        ),
      ),
    [apiSettings],
  )

  const value = useMemo(
    () => ({
      ...DEFAULT,
      ...makeService(apiSettings),
      ...apiSettings,
      sources,
    }),
    [apiSettings, sources],
  )

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}
export const useApi = () => useContext(ApiContext)
