import { UserListItem } from 'api/idave/user'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getName } from 'utils/string/getName'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { EmployeeAccesses } from 'api/dart/employee'
import { AccessModifier } from 'api/dart/types'
import { sortBy } from 'lodash'
import { DirectViewTable } from './types'

export const mapLimitationTypeToLabel = (type: string) => {
  switch (type) {
    case 'bannedEmployee':
      return 'Banned Employee'
    case 'bannedRelations':
      return 'Banned Relation'
    case 'ignoredEmployees':
      return 'Ignored Employee'
    case 'allowedCompany':
      return 'Allow customer company'
    default:
      return 'Unknown'
  }
}

export const getRelationType = (access: AccessModifier) => {
  switch (access.type) {
    case 'ALLOWED': {
      if (!access.entityId) {
        return 'ignoredEmployees'
      }

      if (access.entityId.type === 'COMPANY') {
        return 'allowedCompany'
      }

      return undefined
    }

    default:
      return undefined
  }
}

export const mapDirectRelations = (params: {
  directRelations: EmployeeAccesses[]
  userMap: Map<string, UserListItem>
}) => {
  const { directRelations, userMap } = params

  return sortBy(
    directRelations.flatMap(({ id, accessModifiers }) =>
      accessModifiers.reduce<DirectViewTable[]>((acc, access) => {
        const type = getRelationType(access)

        if (type) {
          const user = userMap.get(id)
          return [
            ...acc,
            {
              peopleOpsId: user?.externalRef?.reference,
              employeeId: id,
              id: access.id,
              name: user ? getName(user) : id,
              email: user?.email || EMPTY_VALUE_FALLBACK,
              type,
              entityId: access?.entityId,
              state: user?.state,
            },
          ]
        }

        return acc
      }, []),
    ),
    'name',
  )
}

export const stringifyLimitationObject = (
  value: DirectViewTable,
  customerCompanyMap: Map<string, CustomerCompanyItem>,
) => {
  switch (value.type) {
    case 'allowedCompany':
      return value.entityId
        ? customerCompanyMap.get(value.entityId.id)?.name || value.entityId.id
        : EMPTY_VALUE_FALLBACK
    case 'bannedEmployee':
    case 'ignoredEmployees':
    case 'bannedRelations':
    default:
      return value.entityId?.id || EMPTY_VALUE_FALLBACK
  }
}
