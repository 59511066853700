/* eslint-disable prettier/prettier */
export const X_CHECKS_PERMISSIONS = {
  CROSS_CHECKS_UPDATE_COMMENT: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/comment',
      'POST /cross-checks/:crossCheckId/comment/:commentId'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.comment'
  },
  CROSS_CHECKS_UPDATE_DECLINE: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/decline'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.decline'
  },
  CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/groups/:groupId/reviewers'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.groups.reviewers'
  },
  CROSS_CHECKS_UPDATE_JUSTIFY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/justify'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.justify'
  },
  CROSS_CHECKS_UPDATE_PROVIDE_INFO: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/provide-info'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.provide_info'
  },
  CROSS_CHECKS_UPDATE_REFRESH: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/refresh'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.refresh'
  },
  CROSS_CHECKS_UPDATE_REQUEST_INFO: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/request-info'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.request_info'
  },
  CROSS_CHECKS_UPDATE_RETRY: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/retry'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.retry'
  },
  CROSS_CHECKS_UPDATE_REVIEW: {
    endpoints: [
      'POST /cross-checks/:crossCheckId/review'
    ],
    service: 'x_checks',
    value: 'cross_checks.update.review'
  },
  CROSS_CHECKS_VIEW_DETAILS: {
    endpoints: [
      'GET /cross-checks/:crossCheckId'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.details'
  },
  CROSS_CHECKS_VIEW_ENTITIES_LIST: {
    endpoints: [
      'GET /cross-checks/entities'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.entities.list'
  },
  CROSS_CHECKS_VIEW_EXECUTABLES_LIST: {
    endpoints: [
      'GET /cross-checks/executables'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.executables.list'
  },
  CROSS_CHECKS_VIEW_LIST: {
    endpoints: [
      'GET /cross-checks'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.list'
  },
  CROSS_CHECKS_VIEW_LOG_LIST: {
    endpoints: [
      'GET /cross-checks/:crossCheckId/log'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.log.list'
  },
  CROSS_CHECKS_VIEW_PRINCIPALS_DETAILS: {
    endpoints: [
      'GET /cross-checks/principals/:type/:id',
      'POST /cross-checks/principals'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.principals.details'
  },
  CROSS_CHECKS_VIEW_PRINCIPALS_LIST: {
    endpoints: [
      'GET /cross-checks/principals'
    ],
    service: 'x_checks',
    value: 'cross_checks.view.principals.list'
  },
  PERMISSIONS_VIEW_ACL_LIST: {
    endpoints: [
      'GET /permissions/acl'
    ],
    service: 'x_checks',
    value: 'permissions.view.acl.list'
  },
  WHOAMI_VIEW_DETAILS: {
    endpoints: [
      'GET /whoami'
    ],
    service: 'x_checks',
    value: 'whoami.view.details'
  }
} as const