import { Table, TableColumn, TextSkeleton } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { InternalLink } from 'components/Links/InternalLink'
import { StatusText } from 'components/StatusText'
import { getName } from 'utils/string/getName'
import { UserLinkBase } from 'components/Links/common/UserLinkBase'
import { RevolutersAvatar } from 'api/sam/revoluters'
import { formatDateTime } from 'utils/date'
import { SystemRequester } from 'view/CrossChecks/components/SystemRequester'
import { CrossCheckRow } from '../../types'
import {
  SYSTEM_REQUESTERS,
  getRequestStateLevel,
  getRequestStateText,
} from '../../../utils'

export const getColumns = (
  avatars?: Map<string, RevolutersAvatar>,
): TableColumn<CrossCheckRow>[] => [
  {
    Header: 'Request',
    accessor: 'request',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Object',
    accessor: 'entityName',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: CrossCheckRow } }) => {
      const { entityName, entityLink } = params.row.original

      return (
        <Table.Cell>
          {entityLink ? (
            <InternalLink href={entityLink} target="_blank">
              {entityName}
            </InternalLink>
          ) : (
            entityName
          )}
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Requester',
    accessor: ({ requesterUser, requesterId }) => {
      const system = SYSTEM_REQUESTERS[requesterId]
      const name = requesterUser ? getName(requesterUser) : requesterId

      return system?.name || name
    },
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: CrossCheckRow } }) => {
      const { requesterId, requesterUser, requesterReady } = params.row.original

      const system = SYSTEM_REQUESTERS[requesterId]
      if (system) {
        return (
          <Table.Cell>
            <SystemRequester system={system} />
          </Table.Cell>
        )
      }

      if (!requesterReady) {
        return (
          <Table.Cell>
            <TextSkeleton size={12} />
          </Table.Cell>
        )
      }

      if (requesterUser) {
        return (
          <Table.Cell>
            <UserLinkBase user={requesterUser} avatars={avatars} target="_blank" />
          </Table.Cell>
        )
      }

      return <Table.Cell>{requesterId}</Table.Cell>
    },
  },
  {
    Header: 'State',
    id: 'state',
    accessor: (row) => getRequestStateText(row.crossCheck),
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (params: { row: { original: CrossCheckRow } }) => {
      const crossCheck = params.row.original.crossCheck

      return (
        <Table.Cell>
          <StatusText level={getRequestStateLevel(crossCheck.state)}>
            {getRequestStateText(crossCheck)}
          </StatusText>
        </Table.Cell>
      )
    },
  },
  {
    Header: 'Updated at',
    id: 'updated',
    accessor: (row) => row.crossCheck.updatedDate,
    Cell: (params: { row: { original: CrossCheckRow } }) => {
      return (
        <Table.Cell>
          {formatDateTime(params.row.original.crossCheck.updatedDate)}
        </Table.Cell>
      )
    },
  },
]
