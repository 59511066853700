const DEV_HOST = 'id.revolut.codes'
const PROD_HOST = 'id.revolut.com'

export const isStaging = () => {
  return new RegExp(`.${DEV_HOST}`).test(window.location.hostname)
}

export const isProduction = () => {
  return window.location.hostname === PROD_HOST
}
