import { memo, useCallback, useEffect, useState } from 'react'
import { MoreBar, useToggle } from '@revolut/ui-kit'
import { WarningPopup } from './components/WarningPopup'
import { JustificationPopup } from './components/JustificationPopup'
import { XCheckActionSettings } from './useActions/types'

export const XCheckAction = memo(({ action }: { action: XCheckActionSettings }) => {
  const {
    onSubmit,
    popupSettings,
    actionType,
    xCheckId,
    actionSettings,
    warningSettings: confirmationSettings,
  } = action
  const [wasAutoTriggered, setAutoTriggered] = useState(false)
  const [popupOpen, togglePopup] = useToggle()
  const [warningOpen, toggleWarning] = useToggle()
  const [reasonText, setReasonText] = useState<string>('')

  useEffect(
    () => () => {
      togglePopup.off()
      toggleWarning.off()
    },
    [togglePopup, toggleWarning],
  )

  const onActionClick = useCallback(() => {
    if (popupSettings) {
      togglePopup.on()
    } else {
      onSubmit({ actionType, xCheckId })
    }
  }, [actionType, popupSettings, togglePopup, onSubmit, xCheckId])

  useEffect(() => {
    if (action.autoTrigger && !wasAutoTriggered) {
      setAutoTriggered(true)
      onActionClick()
    }
  }, [wasAutoTriggered, setAutoTriggered, action.autoTrigger, onActionClick])

  const onSubmitClick = useCallback(() => {
    if (confirmationSettings) {
      return toggleWarning.on()
    }
    onSubmit({
      actionType,
      reasonText,
      xCheckId,
    })
    return togglePopup.off()
  }, [
    onSubmit,
    actionType,
    reasonText,
    xCheckId,
    togglePopup,
    toggleWarning,
    confirmationSettings,
  ])

  const onConfirmClick = useCallback(() => {
    onSubmit({
      actionType,
      reasonText,
      xCheckId,
    })
    toggleWarning.off()
    togglePopup.off()
  }, [onSubmit, actionType, reasonText, xCheckId, togglePopup, toggleWarning])

  return (
    <>
      <JustificationPopup
        onClose={togglePopup.off}
        onSubmitClick={onSubmitClick}
        open={popupOpen}
        popupSettings={action.popupSettings}
        setReasonText={setReasonText}
        reasonText={reasonText}
      />

      <WarningPopup
        open={warningOpen}
        confirmation={action.warningSettings}
        onCancelClick={toggleWarning.off}
        onConfirmClick={onConfirmClick}
      />

      <MoreBar.Action
        onClick={onActionClick}
        variant={actionSettings.variant}
        useIcon={actionSettings.useIcon}
      >
        {actionSettings.label}
      </MoreBar.Action>
    </>
  )
})
