import { CrossCheck } from 'api/types/crossCheck'
import { UserListItem } from 'api/idave/user'
import { RevolutersEmployee } from 'api/sam/revoluters'
import { Row } from './types'

type DataMaps = {
  userMap?: Map<string, UserListItem>
  employeeMap?: Map<string, RevolutersEmployee>
}

type GetRowsParams = {
  crossChecks: CrossCheck[]
  dataMaps: DataMaps
  isEmployeeSettled: boolean
}

export const getRows = (params: GetRowsParams) => {
  const { crossChecks, dataMaps, isEmployeeSettled } = params
  return crossChecks.map<Row>((crossCheck) => {
    const { requesterUser, requesterId, requesterReady } = getRequester({
      crossCheck,
      dataMaps,
      isEmployeeSettled,
    })

    return {
      id: crossCheck.id,
      requesterUser,
      requesterId,
      requesterReady,

      status: crossCheck.status,
      state: crossCheck.state,

      createdDate: crossCheck.createdDate,
    }
  })
}

type GetRequesterParams = {
  crossCheck: CrossCheck
  dataMaps: DataMaps
  isEmployeeSettled: boolean
}
const getRequester = ({
  isEmployeeSettled,
  dataMaps,
  crossCheck,
}: GetRequesterParams) => {
  if (!isEmployeeSettled) {
    return {
      requesterUser: undefined,
      requesterReady: false,
      requesterId: crossCheck.requester,
    }
  }

  const employee = dataMaps.employeeMap?.get(crossCheck.requester)
  if (!employee) {
    return {
      requesterUser: undefined,
      requesterReady: true,
      requesterId: crossCheck.requester,
    }
  }

  const idaveId = employee.iDaveUserId
  const user = idaveId ? dataMaps.userMap?.get(idaveId) : undefined

  return {
    requesterUser: user,
    requesterReady: true,
    requesterId: crossCheck.requester,
  }
}
