import { InputProps } from '@revolut/ui-kit'
import { Input } from 'components/Inputs'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback } from 'react'
import { identity } from 'lodash'
import { getFieldErrorState } from '../utils'
import { Fielded } from '../types'
import { useFormContext } from '../../../../FormProvider'

type Props = Fielded<string, InputProps>

export const FieldInput = (props: Props) => {
  const { path, validate, setValueAs = identity, ...rest } = props
  const { errors, setError, updateForm } = useFormContext()

  const processChange = useCallback(
    (value: string) => {
      const newValue = setValueAs(value || undefined)
      updateForm(path, newValue)
      setError(path, validate(newValue))
    },
    [setError, updateForm, path, validate, setValueAs],
  )

  const onChange = useInputStringChange(processChange)

  return <Input {...rest} onChange={onChange} {...getFieldErrorState(errors, path)} />
}
