import { startCase } from 'lodash'
import { SelectLoadingState, SelectOptionItemType } from '@revolut/ui-kit'
import { QueryResult } from 'utils/query'
import { notNullableMap } from 'utils/array'

export function toStringOption<T extends string>(value: T): SelectOptionItemType<T> {
  return {
    key: value,
    label: startCase(value),
    value,
  }
}
export function toOption<T extends { id: string; name: string }>(
  item: T,
): SelectOptionItemType<T> {
  return {
    key: item.id,
    label: item.name,
    value: item,
  }
}
export const getLoadingState = (qr: QueryResult): SelectLoadingState | undefined => {
  switch (qr) {
    case 'success':
      return 'ready'
    case 'loading':
      return 'pending'
    case 'error':
    case 'forbidden':
      return 'failed'
    default:
      return undefined
  }
}

type MakeRenderValueParams<Data extends { id: string; name: string }> = {
  getOption: (item: Data) => SelectOptionItemType<Data>
  itemId?: string
  itemMap: Map<string, Data>
}
export const makeRenderValue =
  <Data extends { id: string; name: string }>({
    itemId,
    getOption,
    itemMap,
  }: MakeRenderValueParams<Data>) =>
  () => {
    if (!itemId) {
      return ''
    }

    const value = itemMap.get(itemId)

    if (!value) {
      return itemId
    }

    const { id, name } = value

    const itemOption = getOption(value)
    const itemLabel = typeof itemOption?.label === 'string' ? itemOption.label : undefined

    return itemLabel || name || id
  }

export const stringEnumToOptions = (
  stringEnum: Partial<Record<string, string>>,
  getLabel: (itemKey: string) => string | undefined,
): SelectOptionItemType<string>[] =>
  notNullableMap(Object.keys(stringEnum), (itemKey) => {
    const value = stringEnum[itemKey]
    if (!value) {
      return undefined
    }

    return {
      key: value,
      value,
      label: getLabel(itemKey) || value,
    }
  })
