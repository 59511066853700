import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'
import qs from 'qs'

type UUID = string

type ToxicPairState = 'ACTIVE' | 'DELETED'

export type ToxicPair = {
  id: UUID // permission pair id
  firstPermission: UUID // permission id
  secondPermission: UUID // permission id
  name: string
  justification: string // "Reason for toxic combo",
  owner: UUID // user id
  reviewDate?: number
  state: ToxicPairState
  createdDate: number
  deletedDate?: number
}

export type ToxicPairPayload = {
  name: string
  firstId: string
  secondId: string
  justification: string // 'Reason for toxic combo'
  owner?: UUID // optional, user id, (current user by default)
  reviewDate?: number // optional, by default (now + reviewPeriod from BE) will be used
}

export type ToxicAlertParams = {
  userId?: string
  toxicPermissionId?: string
}

// firstRoleIds and secondRoleIds produce cartesian product of participating role pairs
export type ToxicAlert = {
  firstRoleIds: string[]
  secondRoleIds: string[]
  toxicPermissionId: string
  userId: string
  state: 'ACTIVE' | 'DELETED'
  createdDate: number
  deletedDate?: number
}

export type ToxicPairsParams = {
  state?: ToxicPairState[]
}

const toxic = {
  getToxicPairs: (params?: ToxicPairsParams): AxiosPromise<ToxicPair[]> =>
    axios.get('/api/toxic-permissions', {
      params,
      paramsSerializer: (paramValues) =>
        qs.stringify(paramValues, {
          arrayFormat: 'repeat',
        }),
    }),

  addToxicPair: (
    payload: ToxicPairPayload,
  ): AxiosPromise<ActionResponse | { id: string }> =>
    axios.post('/api/toxic-permissions', payload),

  deleteToxicPair: (id: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/toxic-permissions/${id}`),

  getToxicAlerts: (params?: ToxicAlertParams): AxiosPromise<ToxicAlert[]> =>
    axios.get('/api/toxic-permission-alerts', { params }),
}

export default toxic
