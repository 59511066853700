import { Page, PageMainSize } from '@revolut/ui-kit'
import { memo, useCallback } from 'react'
import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import { useResourceRequests } from 'queries/sam/resources'
import { generatePath, useNavigate } from 'react-router'
import { QuerySwitch } from 'components/QuerySwitch'
import { Url } from 'routing'
import { UserLink } from 'components/Links/UserLink'
import { List } from './components/List'
import { CrossChecksSkeleton } from '../../../CrossChecks/CrossCheckList/components/CrossChecksSkeleton'

type Props = {
  userId: string
}
export const UserResourceRequests = memo(({ userId }: Props) => {
  const HeaderActions = useHeaderActions()
  const navigate = useNavigate()
  const onBack = useCallback(
    () => navigate(generatePath(Url.UserResourceAccess, { userId })),
    [navigate, userId],
  )

  return (
    <>
      <PageHeader
        actions={HeaderActions && <HeaderActions />}
        pageTitle="Resource requests"
        subtitle={<UserLink id={userId} />}
        onBack={onBack}
      />
      <Page.Main size={PageMainSize.FULL}>
        <Inner userId={userId} />
      </Page.Main>
    </>
  )
})

type InnerProps = {
  userId: string
}
export const Inner = ({ userId }: InnerProps) => {
  const { data, status: qs, fetchStatus: fs } = useResourceRequests(userId)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={() => <CrossChecksSkeleton />}
      renderSuccess={({ data: crossChecks }) => (
        <List crossChecks={crossChecks} userId={userId} />
      )}
    />
  )
}
