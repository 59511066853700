import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Url } from 'routing'
import { useEditPopup } from 'hooks/useEditPopup'
import { useStorageItem } from 'hooks/useStorageItem'
import { FormProvider, useForm } from 'react-hook-form'
import { useHeaderActions } from 'components/HeaderActions'
import { PageHeader } from 'components/PageHeader'
import { CommonClientUrls, UrlUpdateParams } from '../components/CommonClientUrls'
import { CommonClientEdit } from '../components/CommonClientEdit'
import { useNewClient } from './useNewClient'
import { CommonClientEditFormValues } from '../components/CommonClientEdit/types'

export const ClientNew = () => {
  const { closePopup } = useEditPopup()

  const { clearItem, setItem, item } =
    useStorageItem<Partial<CommonClientEditFormValues>>('clientCreate')

  const {
    clearItem: clearStoredUrls,
    setItem: setStoredUrls,
    item: storedUrls,
  } = useStorageItem<{ urls: string[] }>('clientCreateUrls')

  const [urls, setUrls] = useState<string[]>(storedUrls?.urls || [])
  const navigate = useNavigate()
  const onSuccess = useCallback(() => {
    clearItem()
    clearStoredUrls()
  }, [clearItem, clearStoredUrls])

  const clientForm = useForm<CommonClientEditFormValues>({
    reValidateMode: 'onBlur',
    mode: 'all',
    // 'scope' is mentioned here, because otherwise it's not changing on 'setValue' after 'name' changed
    defaultValues: { scope: '', ...item },
  })

  const { mutate, isLoading } = useNewClient({ onSuccess })

  const updateUrls = useCallback(
    (update: UrlUpdateParams) => {
      switch (update.type) {
        case 'add': {
          const newUrls = [...urls, update.newUrl]
          closePopup()
          setUrls(newUrls)
          setStoredUrls({ urls: newUrls })
          break
        }
        case 'delete': {
          const newUrls = urls.filter((v) => v !== update.deleteUrl)
          setUrls(newUrls)
          setStoredUrls({ urls: newUrls })
          break
        }
        case 'deleteAll':
        default:
          setUrls([])
          setStoredUrls({ urls: [] })
      }
    },
    [setUrls, closePopup, setStoredUrls, urls],
  )
  const onBackClick = useCallback(() => navigate(Url.Clients), [navigate])

  const onSubmit = useCallback(() => {
    const values = clientForm.getValues()
    mutate({
      name: values.name.trim(),
      scope: values.scope.trim(),
      description: values.description?.trim(),
      ownerGroupId: values.ownerGroupId,
      redirectUris: urls.filter((url) => !!url),
    })
  }, [clientForm, mutate, urls])

  const disabledSubmit = !clientForm.formState.isValid

  useEffect(() => {
    const subscription = clientForm.watch(setItem)
    return () => subscription.unsubscribe()
  }, [clientForm, setItem])

  const HeaderActions = useHeaderActions()

  return (
    <>
      <PageHeader
        onBack={onBackClick}
        actions={HeaderActions && <HeaderActions />}
        pageTitle="Create application"
      />
      <FormProvider {...clientForm}>
        <Page.Main size={PageMainSize.NARROW}>
          <CommonClientEdit isLoading={isLoading} isCreate isOuterSource={false} />
          <CommonClientUrls allowUpdate urls={urls} updateUrls={updateUrls} />
          <Page.MainActions>
            <Button
              id="client-new-create"
              variant="primary"
              elevated
              disabled={disabledSubmit}
              onClick={onSubmit}
              pending={isLoading}
            >
              Create
            </Button>
          </Page.MainActions>
        </Page.Main>
      </FormProvider>
    </>
  )
}
