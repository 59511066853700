import { Avatar, Header, Side as KitSide } from '@revolut/ui-kit'
import { useEffect } from 'react'
import { isEqual } from 'lodash'
import { SideBody } from './SideBody'
import { SideActions } from './SideActions'
import { getReviewGroup } from '../../utils'
import { useXCheckData, useXCheckSide } from '../../hooks'
import { getGroupRuleSide } from './utils'

export const Side = () => {
  const { xCheckId, xCheckData } = useXCheckData()
  const { side, setSide } = useXCheckSide()

  useEffect(() => {
    if (side?.type === 'reviewGroup' && xCheckData.status === 'success') {
      const dataGroup = getReviewGroup(xCheckData.data, side.group.id)

      if (dataGroup && !isEqual(side.group, dataGroup)) {
        setSide(getGroupRuleSide({ groupRule: dataGroup, setSideView: setSide }))
      }
    }
  }, [side, xCheckData, setSide])

  if (!side) {
    return null
  }
  return (
    <KitSide open={!!side} onClose={side.onClose} appear variant="wide">
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Avatar>
          <Avatar variant="brand" useIcon={side.iconName} />
        </Header.Avatar>
        <Header.Title>{side.title}</Header.Title>
        <Header.Description>{side.description}</Header.Description>
        <Header.Bar>
          <SideActions side={side} xCheckId={xCheckId} />
        </Header.Bar>
      </Header>
      <SideBody side={side} />
    </KitSide>
  )
}
