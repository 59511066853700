import { useTooltip, Text, Tooltip } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { EntityComponentType } from '../../../types'
import { getContextTextStyle } from '../../../utils'

export const Entity: EntityComponentType = ({ descriptor, context }) => {
  const tooltip = useTooltip()
  const { name, entityType, id } = descriptor

  return (
    <>
      <Text {...tooltip.getAnchorProps()} {...getContextTextStyle(context)}>
        {name}
      </Text>
      <Tooltip {...tooltip.getTargetProps()}>{`${entityType}:${
        id || EMPTY_VALUE_FALLBACK_TEXT
      }`}</Tooltip>
    </>
  )
}
