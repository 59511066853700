import {
  CheckboxSelect,
  CheckboxSelectProps,
  Table,
  TableData,
  TableFilterProps,
} from '@revolut/ui-kit'

type Option = {
  key: string
  value: string
  label: string
}

export const CheckboxSelectTableFilter = <Entity extends TableData>({
  column,
  options,
  loadingState,
  onSearchText,
  labelFailedState,
}: Pick<TableFilterProps<Entity>, 'column'> &
  Pick<
    CheckboxSelectProps<unknown, Option>,
    'options' | 'loadingState' | 'onSearchText' | 'labelFailedState'
  >) => {
  return (
    <Table.Filter column={column} aria-label="Open filter" aria-haspopup="listbox">
      {(filter) => (
        <CheckboxSelect
          type="confirm"
          {...filter.getCheckboxSelectProps({
            options,
            searchable: true,
            labelList: 'Select',
            labelApply: 'Apply',
            labelClear: 'Clear',
            labelNoResults: 'No results found',
            labelFailedState,
            loadingState,
            onSearchText,
          })}
        />
      )}
    </Table.Filter>
  )
}
