import axios from 'axios'
import { ActionResponse } from 'api/types/actionResponse'
import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { AddAccessPayload, EmployeeCompany, RevokeAccessPayload } from './types'

export const employeeCompany = {
  getEmployeeCompanies: () =>
    axios.get<EmployeeCompany[]>('/dart-api/dart/employee-companies'),

  addAccess: (
    employeeCompanyId: string,
    payload: CrossCheckedPayload<AddAccessPayload>,
  ) => {
    const { body, headers } = splitPayloadHeader(payload)
    return axios.post<ActionResponse>(
      `/dart-api/employee-companies/${employeeCompanyId}/company-access/add`,
      body,
      { headers },
    )
  },

  revokeAccess: (
    employeeCompanyId: string,
    payload: CrossCheckedPayload<RevokeAccessPayload>,
  ) => {
    const { body, headers } = splitPayloadHeader(payload)
    return axios.post<ActionResponse>(
      `/dart-api/employee-companies/${employeeCompanyId}/company-access/revoke`,
      body,
      { headers },
    )
  },
}
