import { Table } from '@revolut/ui-kit'
import { EntityParam, Value } from 'api/types/xChecks'
import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { EntityContext } from 'view/XChecks/XCheck/lib/types'
import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils/params'

export const EntityValueCell = ({ entity }: { entity: Value<EntityParam> }) => {
  const { Entity } = useViews()

  return (
    <Table.Cell>
      <Entity
        descriptor={getEntityDescriptor(entity)}
        attributes={entity.value}
        context={EntityContext.tableRow}
      />
    </Table.Cell>
  )
}
