import { Principal, ReviewRuleState } from './common'

export type Log =
  | ApprovedLog
  | CommentedLog
  | CommentEditedLog
  | DeclinedLog
  | ExecutedLog
  | ExecutionFailedLog
  | InfoProvidedLog
  | InfoRequestedLog
  | JustifiedLog
  | RejectedLog
  | RequestedLog
  | ReviewedLog
  | ReviewStageStateChangedLog
  | ReviewGroupReviewersAddedLog

export enum LogType {
  approved = 'CrossCheckApprovedEvent',
  commented = 'CrossCheckCommentedEvent',
  declined = 'CrossCheckDeclinedEvent',
  executed = 'CrossCheckExecutedEvent',
  executionFailed = 'CrossCheckExecutionFailedEvent',
  infoProvided = 'CrossCheckInfoProvidedEvent',
  infoRequested = 'CrossCheckInfoRequestedEvent',
  justified = 'CrossCheckJustifiedEvent',
  rejected = 'CrossCheckRejectedEvent',
  requested = 'CrossCheckRequestedEvent',
  reviewed = 'CrossCheckReviewedEvent',
  groupReviewersAdded = 'CrossCheckReviewGroupReviewersAddedEvent',
  reviewStageChanged = 'CrossCheckReviewStageStateChangedEvent',
  commentEdited = 'CrossCheckCommentEditedEvent',
}

type BaseLog = {
  id: string
  author: Principal
  createdDate: number
}

type TypedLog<T extends LogType> = BaseLog & {
  type: T
}
type TextLog<T extends LogType> = TypedLog<T> & {
  commentId?: string
}

/** Crosscheck review is finished and xCheck approved */
export type ApprovedLog = TypedLog<LogType.approved>
/** Crosscheck review is declined by requester and xCheck rejected */
export type DeclinedLog = TypedLog<LogType.declined>
/** Crosscheck review is finished and xCheck rejected */
export type RejectedLog = TypedLog<LogType.rejected>
/** Crosscheck action is executed */
export type ExecutedLog = TypedLog<LogType.executed>
/** Additional info requested  */
export type RequestedLog = TypedLog<LogType.requested>
/** Changing xCheck stage for multistage rules  */
export type ReviewStageStateChangedLog = TypedLog<LogType.reviewStageChanged> & {
  reviewStageId: string
  reviewStageName: string
  oldState: ReviewRuleState
  newState: ReviewRuleState
}

/** A user added to a review group */
export type ReviewGroupReviewersAddedLog = TextLog<LogType.groupReviewersAdded> & {
  groupId: string
  reviewers: Principal[]
}
/** A user commented xCheck */
export type CommentedLog = TextLog<LogType.commented>
/** A user requested additional info for a xCheck */
export type InfoRequestedLog = TextLog<LogType.infoRequested>
/** A user provided additional info for a xCheck */
export type InfoProvidedLog = TextLog<LogType.infoProvided>
/** Requester added justification info for a xCheck */
export type JustifiedLog = TextLog<LogType.justified>
/** Execution of action failed on side of BE owning xCheck */
export type ExecutionFailedLog = TextLog<LogType.executionFailed>
/** A user reviewed a crosscheck */
export type ReviewedLog = TextLog<LogType.reviewed> & {
  decision?: 'APPROVE' | 'REJECT'
}
export type CommentEditedLog = TextLog<LogType.commentEdited> & {
  newText: string
  oldText: string
}
