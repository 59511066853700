import { MoreBar } from '@revolut/ui-kit'
import { useActions } from './components/Action/useActions'
import { XCheckAction } from './components/Action/Action'
import { useXCheckData } from '../../hooks'
import { ActionsSkeleton } from './components/ActionsSkeleton'

export const Actions = () => {
  const { xCheckData } = useXCheckData()

  if (xCheckData.status === 'error') {
    return null
  }

  if (xCheckData.status === 'loading') {
    return <ActionsSkeleton />
  }

  return <Inner />
}

const Inner = () => {
  const actions = useActions()

  if (!actions.length) {
    return null
  }
  return (
    <MoreBar>
      {actions.map((action) => (
        <XCheckAction action={action} key={action.actionType} />
      ))}
    </MoreBar>
  )
}
