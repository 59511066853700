import { VStack } from '@revolut/ui-kit'
import { FieldClientSelect } from 'components/fields/FieldClientSelect'
import { FieldInput } from 'components/fields/FieldInput'
import { RULE_REQUIRED, getEmailRule } from 'utils/form/rules'

type Props = {
  isCreating: boolean
  excludeClients?: string[]
}

export const DetailFields = ({ isCreating, excludeClients }: Props) => {
  return (
    <VStack gap="s-24">
      <FieldClientSelect
        name="clientId"
        rules={RULE_REQUIRED}
        disabled={!isCreating}
        excludeIds={excludeClients}
        label="Client"
      />

      <FieldInput
        name="gcpSA"
        label="GCP Service agent (Optional)"
        rules={getEmailRule('GCP SA must be an email')}
      />
    </VStack>
  )
}
