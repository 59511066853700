import { Page, PageMainSize, TabBar } from '@revolut/ui-kit'
import { TabBarLink } from 'components/TabBarLink'
import { IDAVE_PERMISSIONS } from 'security'
import { Url } from 'routing'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useHeaderActions } from 'components/HeaderActions'
import { Title } from 'components/Title'
import { PageHeader } from 'components/PageHeader'
import { ToxicPairs } from './ToxicPairs'
import { PermissionList } from './PermissionList'
import { ToxicAlerts } from './ToxicAlerts'
import { AuditLogs } from './AuditLogs'

type PermissionsProps = {
  tab: 'permissions' | 'toxicPairs' | 'toxicAlerts' | 'auditLogs'
}

export const Permissions = ({ tab }: PermissionsProps) => {
  const HeaderActions = useHeaderActions()
  return (
    <>
      <Title value="Permissions" />
      <PageHeader actions={HeaderActions && <HeaderActions />} pageTitle="Permissions" />
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={Url.Permissions}>Permissions</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck permission={IDAVE_PERMISSIONS.TOXIC_PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={Url.ToxicPairs}>Toxic pairs</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck
            permission={IDAVE_PERMISSIONS.TOXIC_PERMISSION_ALERTS_VIEW_LIST}
          >
            <TabBarLink to={Url.ToxicAlerts}>Alerts</TabBarLink>
          </PermissionsCheck>
          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={Url.PermissionsAuditLogs}>Audit logs</TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main size={PageMainSize.FULL}>
        {tab === 'permissions' && <PermissionList />}

        {tab === 'toxicPairs' && <ToxicPairs />}

        {tab === 'toxicAlerts' && <ToxicAlerts />}

        {tab === 'auditLogs' && <AuditLogs />}
      </Page.Main>
    </>
  )
}
