import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { WidgetItem } from 'view/XChecks/XCheck/lib/types'

type Props = {
  widgets: WidgetItem[]
}

export const Widgets = ({ widgets }: Props) => {
  const { Widget } = useViews()
  return (
    <>
      {widgets.map((widget, idx) => (
        <Widget widget={widget} key={idx} />
      ))}
    </>
  )
}
