import { SamPolicySubjectType } from 'api/sam/policies'
import { SamPolicySubjectField } from '../types'

export const getSpecialisationOptionality = (subjectType: SamPolicySubjectType) => {
  switch (subjectType) {
    case SamPolicySubjectType.Team:
    case SamPolicySubjectType.Department:
      return true
    default:
      return false
  }
}

export const isFieldOptional = (
  field: SamPolicySubjectField,
  subjectType?: SamPolicySubjectType,
) => {
  if (!subjectType) {
    return true
  }

  switch (field) {
    case 'employeeTypes':
      return subjectType !== SamPolicySubjectType.EmployeeType

    case 'seniorityIds':
    case 'contractTypes':
      return true
    case 'specialisationIds':
      return getSpecialisationOptionality(subjectType)
    default:
      return false
  }
}

export const getLabel = (field: SamPolicySubjectField, optional: boolean) => {
  const nameMap: Record<SamPolicySubjectField, string> = {
    employeeIds: 'Employees',
    employeeTypes: 'Employee Types',
    seniorityIds: 'Seniorities',
    specialisationIds: 'Specialisations',
    teamIds: 'Teams',
    departmentIds: 'Departments',
    positionIds: 'Roles',
    functionIds: 'Functions',
    contractTypes: 'Contract types',
  }

  const name = nameMap[field]

  return optional ? `${name} (optional)` : name
}
