import { SideboxQueryParamType } from 'view/SideBox/types'

export const AUDIT_LOG_TYPE: SideboxQueryParamType = 'auditLog'
export const USER_POLICY_TYPE: SideboxQueryParamType = 'userPolicy'
export const GROUP_SIDE: SideboxQueryParamType = 'group'
export const ROLE_SIDE: SideboxQueryParamType = 'role'
export const TOXIC_ALERT_SIDE: SideboxQueryParamType = 'toxicAlert'
export const PERMISSION_SIDE: SideboxQueryParamType = 'permission'
export const RESOURCE_SIDE: SideboxQueryParamType = 'resource'
export const RESOURCE_ACCESS_SIDE: SideboxQueryParamType = 'resourceAccess'

export const SIDEBOX_TYPE_PARAM_NAME = 'sb.type'
export const SIDEBOX_OBJECT_ID_PARAM_NAME = 'sb.objId'
export const SIDEBOX_SUBJECT_ID_PARAM_NAME = 'sb.subjId'
