import { Box, Page, PageMainSize, StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { PageHeader } from './PageHeader'

type RequestErrorWidgetProps = {
  action?: () => void
  actionText?: string
  title?: string
  description?: React.ReactNode
}

export const RequestErrorWidget = ({
  action,
  actionText = 'Retry',
  title = 'Something went wrong',
  description,
}: RequestErrorWidgetProps) => (
  <Box maxWidth="1272px" data-testid="error-banner">
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D018', 'v2')} />
      <StatusWidget.Title>{title}</StatusWidget.Title>
      {description && <StatusWidget.Description>{description}</StatusWidget.Description>}
      {action && <StatusWidget.Action onClick={action}>{actionText}</StatusWidget.Action>}
    </StatusWidget>
  </Box>
)

type PageProps = RequestErrorWidgetProps & {
  pageTitle?: string
  onBackClick?: () => void
}
export const RequestErrorPage = (props: PageProps) => {
  const { pageTitle, onBackClick, ...widgetProps } = props
  return (
    <>
      {pageTitle && <PageHeader onBack={onBackClick} pageTitle={pageTitle} />}
      <Page.Main size={PageMainSize.WIDE}>
        <RequestErrorWidget {...widgetProps} />
      </Page.Main>
    </>
  )
}
