import { EditPermissionPayload, Permission } from 'api/idave/permissions'
import { isEqual, pick } from 'lodash'
import { DeepPartial } from 'react-hook-form'
import { notNullable } from 'utils/common'
import { PermissionEditForm } from './types'

export const permissionToFormState = (
  storedItem?: DeepPartial<PermissionEditForm>,
  permission?: Permission,
): EditPermissionPayload => ({
  description: '',
  ...pick(permission, ['action', 'description', 'classification', 'scope', 'domain']),
  ...storedItem,
  oversight: permission?.oversight || storedItem?.oversight?.filter(notNullable),
})

export const isValid = (
  formState: DeepPartial<PermissionEditForm>,
): formState is EditPermissionPayload => {
  const { description, action, classification, domain, oversight, scope } = formState
  return Boolean(
    description && action && classification && scope && domain && oversight?.length,
  )
}

const isChanged = (
  formState: DeepPartial<PermissionEditForm>,
  permission?: Permission,
) => {
  if (!permission) {
    return false
  }
  return !isEqual(
    formState,
    pick(permission, [
      'description',
      'action',
      'classification',
      'domain',
      'oversight',
      'scope',
    ]),
  )
}

export const checkSubmitEnabled = (
  formState: DeepPartial<PermissionEditForm>,
  permission?: Permission,
) => isValid(formState) && isChanged(formState, permission)
