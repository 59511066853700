import axios, { AxiosPromise } from 'axios'
import { CrossCheckedPayload } from 'utils/types/crossChecked'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { ActionResponse } from 'api/types/actionResponse'

/** @deprecated: client based roles deprecated and should be used only for client role result until be removed */
export type DeprecatedRoles = {
  [key: string]: DeprecatedRole[]
}

/** @deprecated: client based role deprecated and should be used only for client role result until be removed */
export type DeprecatedRole = {
  client: string
  description: string
  role: string
  roleId: string
}

export type Role = {
  id: string
  name: string
  description: string
  permissions: string[]
  parent?: string
  client?: string
  ownerGroupId?: string
  approvers?: [string]
  classification?: RoleClassification
}

export enum RoleClassification {
  unclassified = 'X',
  confidential = 'C',
  private = 'P',
  internal = 'I',
  sensitive = 'S',
}

export type RolePayloadMVP = Omit<Role, 'id'>

const roles = {
  getRoles: (): AxiosPromise<DeprecatedRoles> => axios.get('/api/roles'),

  getRolesMVP: (): AxiosPromise<Role[]> =>
    axios.get(`/api/roles`, {
      headers: {
        'X-Version': 'future',
      },
    }),

  getRoleMVP: (roleId: string): AxiosPromise<Role> =>
    axios.get(`/api/roles/${roleId}`, {
      headers: {
        'X-Version': 'future',
      },
    }),

  addRole: (
    params: CrossCheckedPayload<RolePayloadMVP>,
  ): AxiosPromise<ActionResponse | { id: string }> => {
    const { body, headers } = splitPayloadHeader(params)

    return axios.post('/api/roles', body, { headers })
  },

  editRole: (params: CrossCheckedPayload<Role>): AxiosPromise<ActionResponse> => {
    const {
      body: { id, ...role },
      headers,
    } = splitPayloadHeader(params)

    return axios.put(`/api/roles/${id}`, role, { headers })
  },

  getRole: (roleId: string): AxiosPromise<DeprecatedRole> =>
    axios.get(`/api/roles/${roleId}`),

  removeRole: (roleId: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/roles/${roleId}`),
}

export default roles
