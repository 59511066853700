import { Table, TableColumn, TableData, TableFilterProps } from '@revolut/ui-kit'
import { XChecksListItem } from 'api/types/xChecks'
import { Principal } from 'view/XChecks/XCheck/components/Principal'
import { useState } from 'react'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { useQuery } from '@tanstack/react-query'
import { PrincipalContext } from 'view/XChecks/XCheck/lib/types'
import { useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { CheckboxSelectTableFilter } from './CheckboxSelectTableFilter'
import { getSelectLoadingState } from '../utils'
import { useApi } from '../../../hooks'

const REQUESTERS_QUERY_KEY = 'REQUESTERS_QUERY_KEY'

const RequesterTableFilter = <Entity extends TableData>({
  column,
}: Pick<TableFilterProps<Entity>, 'column'>) => {
  const [search, setSearch] = useState<string>()

  const debouncedSearch = useDebouncedValue(search, 500)

  const { fetchPrincipals } = useApi()
  const { hasXCheckPermission } = useXCheckSecurity()

  const { data: requesters = [], status } = useQuery({
    queryKey: [REQUESTERS_QUERY_KEY, debouncedSearch],
    queryFn: () => fetchPrincipals({ query: debouncedSearch }),
    enabled: hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_ENTITIES_LIST),
  })

  const loadingState = getSelectLoadingState(status)

  const options = requesters.map((requester) => {
    return {
      key: requester.id,
      label: requester.name,
      value: requester.id,
    }
  })

  return (
    <CheckboxSelectTableFilter
      column={column}
      options={options}
      loadingState={loadingState}
      onSearchText={setSearch}
    />
  )
}

const RequesterTableCell = ({
  row: {
    original: { requester },
  },
}: {
  row: { original: XChecksListItem }
}) => (
  <Table.Cell>
    <Principal principal={requester} context={PrincipalContext.requesterTableCell} />
  </Table.Cell>
)

export const REQUESTER_TABLE_CELL: TableColumn<XChecksListItem> = {
  Header: 'Requester',
  id: 'requester',
  accessor: () => '',
  Cell: RequesterTableCell,
  Filter: ({ column }: Pick<TableFilterProps<XChecksListItem>, 'column'>) => (
    <RequesterTableFilter column={column} />
  ),
  // xChecks API does not provide sorting order functionality
  disableSortBy: true,
}
