import { EmployeeType, SamPolicySubjectType } from 'api/sam/policies'
import { UseFormSetValue } from 'react-hook-form'
import {
  SpecialisationsSelect,
  SpecialisationSelect,
} from 'components/Selects/Specialisation'
import { TeamsSelect, TeamSelect } from 'components/Selects/Team'
import { DepartmentsSelect, DepartmentSelect } from 'components/Selects/Department'
import { useSetValue } from 'hooks/useSetValue'
import { memo } from 'react'
import { EmployeeTypesSelect, EmployeeTypeSelect } from 'components/Selects/EmployeeType'
import { SeniorityIdSelect, SeniorityIdsSelect } from 'components/Selects/Seniority'
import { UserSelect } from 'components/Selects/UserSelect'
import { NamedUser } from 'components/Selects/UserSelect/types'
import { FunctionSelect, FunctionsSelect } from 'components/Selects/Function'
import { JobRoleSelect, JobRolesSelect } from 'components/Selects/JobRole'
import { ContractTypeSelect, ContractTypesSelect } from 'components/Selects/ContractType'
import { SamPolicySubjectField, PolicyDetails } from '../types'
import { getLabel, isFieldOptional } from './utils'
import { UsersSelect } from './UsersSelect'

export const SamEditPolicySubjectField = memo(
  (props: {
    subjectType?: SamPolicySubjectType
    subjectEmployeeType?: EmployeeType
    field: SamPolicySubjectField
    isSingle: boolean
    disabled?: boolean
    setValue: UseFormSetValue<PolicyDetails>
    values: string[] | EmployeeType[] | undefined
    error: string | undefined
  }) => {
    const {
      subjectType,
      subjectEmployeeType,
      field,
      disabled = false,
      values,
      setValue,
      isSingle,
    } = props

    const handleEntitiesChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: Array<{ id: string }> | null) => newValue?.map((i) => i.id),
    )

    const handleUserChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: NamedUser | null) =>
        newValue?.externalRef?.reference ? [newValue?.externalRef?.reference] : undefined,
    )

    const handleEntityChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: { id: string } | null) => (newValue?.id ? [newValue.id] : undefined),
    )

    const handleValuesChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: string[] | null) => newValue || undefined,
    )

    const handleValueChange = useSetValue(
      setValue,
      `subject.${field}`,
      (newValue?: string | null) => (newValue ? [newValue] : undefined),
    )

    const isOptional = isFieldOptional(field, subjectType)
    const label = getLabel(field, isOptional)

    switch (field) {
      case 'employeeTypes': {
        return isSingle ? (
          <EmployeeTypeSelect
            label={label}
            clearable={isOptional}
            disabled={disabled}
            value={values?.[0]}
            onChange={handleValueChange}
            searchable={false}
          />
        ) : (
          <EmployeeTypesSelect
            onChange={handleValuesChange}
            label={label}
            clearable={isOptional}
            disabled={disabled}
            value={values}
            valueAsTags
          />
        )
      }

      case 'seniorityIds':
        return isSingle ? (
          <SeniorityIdSelect
            label={label}
            clearable={isOptional}
            disabled={disabled}
            value={values?.[0]}
            onChange={handleValueChange}
            searchable={false}
          />
        ) : (
          <SeniorityIdsSelect
            onChange={handleValuesChange}
            label={label}
            clearable={isOptional}
            disabled={disabled}
            value={values}
            valueAsTags
          />
        )

      case 'specialisationIds':
        return isSingle ? (
          <SpecialisationSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemId={values?.[0] || undefined}
          />
        ) : (
          <SpecialisationsSelect
            label={label}
            onChange={handleEntitiesChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'teamIds':
        return isSingle ? (
          <TeamSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemId={values?.[0] || undefined}
          />
        ) : (
          <TeamsSelect
            label={label}
            onChange={handleEntitiesChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'departmentIds':
        return isSingle ? (
          <DepartmentSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemId={values?.[0] || undefined}
          />
        ) : (
          <DepartmentsSelect
            label={label}
            onChange={handleEntitiesChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'employeeIds':
        return isSingle ? (
          <UserSelect
            usePeopleOpsId
            onlyActive
            label={label}
            itemId={values?.[0] || undefined}
            clearable={isOptional}
            disabled={disabled}
            onChange={handleUserChange}
          />
        ) : (
          <UsersSelect
            disabled={disabled}
            itemIds={values || []}
            onChange={handleValuesChange}
            label={label}
            clearable={isOptional}
          />
        )
      case 'positionIds':
        return isSingle ? (
          <JobRoleSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemId={values?.[0] || undefined}
          />
        ) : (
          <JobRolesSelect
            label={label}
            onChange={handleEntitiesChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'functionIds':
        return isSingle ? (
          <FunctionSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemId={values?.[0] || undefined}
          />
        ) : (
          <FunctionsSelect
            label={label}
            onChange={handleEntitiesChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
          />
        )
      case 'contractTypes':
        if (!subjectEmployeeType) {
          return null
        }
        return isSingle ? (
          <ContractTypeSelect
            label={label}
            onChange={handleEntityChange}
            clearable={isOptional}
            disabled={disabled}
            itemId={values?.[0] || undefined}
            employeeType={subjectEmployeeType}
          />
        ) : (
          <ContractTypesSelect
            label={label}
            onChange={handleEntitiesChange}
            clearable={isOptional}
            disabled={disabled}
            itemIds={values}
            valueAsTags
            employeeType={subjectEmployeeType}
          />
        )
      default:
        return null
    }
  },
)
