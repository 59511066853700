import { EntityChange, Principal } from 'api/types/xChecks'
import { EntityParam, Param, Value } from 'api/types/xChecks/params'
import { ValueOf } from 'utils/types/valueOf'
import { createEnumMap } from 'utils/types/createEnumMap'

export type DataStatus = 'error' | 'loading' | 'ok'
export type Data<DataType> = DataError | DataLoading | DataOk<DataType>

type DataError = {
  status: 'error'
  error?: Error
}

type DataLoading = {
  status: 'loading'
}

type DataOk<DataType> = {
  status: 'success'
  data: DataType
}

export const PrincipalContext = createEnumMap(
  'requester',
  'log',
  'requesterTableCell',
  'reviewGroupAvatarBar',
  'comment',
  'reviewGroup',
)
export type PrincipalContext = ValueOf<typeof PrincipalContext>

type PrincipalComponentProps = {
  principal: Principal
  context: PrincipalContext
}
export type PrincipalComponentType = (props: PrincipalComponentProps) => JSX.Element

export const EntityContext = createEnumMap(
  'entityList',
  'details',
  'detailsAdded',
  'detailsRemoved',
  'tableRow',
  'array',
  'arrayAdded',
  'arrayRemoved',
)
export type EntityContext = ValueOf<typeof EntityContext>

export type EntityDescriptor = {
  id?: string
  name: string
  entityType: string
  isComplexEntity: boolean
}
export type EntityComponentProps = {
  descriptor: EntityDescriptor
  attributes?: Param[]
  context: EntityContext
}

export const EntityView = createEnumMap('widget', 'context', 'details')
export type EntityView = ValueOf<typeof EntityView>

export type EntityComponentType = (props: EntityComponentProps) => JSX.Element
export type EntityToEntityViewFn = (entity: Value<EntityParam>) => EntityView

export type GetEntityUrlFn = (descriptor: EntityDescriptor) => string | undefined

export type WidgetItem = EntityParam | EntityChange
export type WidgetComponentType = (props: { widget: WidgetItem }) => JSX.Element | null

export type XCheckActionType =
  | 'approve'
  | 'reject'
  | 'decline'
  | 'justify'
  | 'retry'
  | 'requestInfo'
  | 'provideInfo'
  | 'edit'

export type XCheckAction = {
  pending?: boolean
  action: XCheckActionType
  onClick: (action: XCheckActionType) => void
}

export type XCheckActionSubmitHandlerParams = {
  actionType: XCheckActionType
  reasonText?: string
  xCheckId: string
}
export type XCheckActionSubmitHandler = (params: XCheckActionSubmitHandlerParams) => void
export type XCheckCommentParams = {
  comment: string
  xCheckId: string
}
export type XCheckEditCommentParams = {
  actionType: 'edit'
  comment: string
  commentId: string
  xCheckId: string
}

export type XCheckCommentSubmitHandlerParams =
  | XCheckCommentParams
  | XCheckEditCommentParams

export type XCheckCommentSubmitHandler = (
  params: XCheckCommentSubmitHandlerParams,
) => void

export type Permission = { service: string; value: string }
export type HasPermissionFn = (permission: Permission) => boolean
export type HasXCheckPermissionFn = (permissionName: string) => boolean
export type Nullable<T> = null | undefined | T
