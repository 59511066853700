export enum QueryKey {
  SsoUser = 'SsoUser',

  Users = 'Users',
  User = 'User',
  CurrentUserId = 'CurrentUserId',
  CurrentUser = 'CurrentUser',

  Organisations = 'Organisations',
  Organisation = 'Organisation',

  Clients = 'Clients',
  ClientCustomization = 'ClientCustomization',
  ClientsCustomization = 'ClientsCustomization',
  Client = 'Client',
  Account = 'Account',
  AccountCustomization = 'AccountCustomization',
  AccountsCustomization = 'AccountsCustomization',
  Accounts = 'Accounts',
  RolesDeprecated = 'RolesDeprecated',
  Role = 'Role',
  Roles = 'Roles',
  AuditLogs = 'AuditLogs',
  AuditLog = 'AuditLog',
  UserAccessLogs = 'UserAccessLogs',

  SignIn = 'SignIn',
  SignInWithGoogle = 'SignInWithGoogle',

  Permission = 'Permission',
  Permissions = 'Permissions',
  OrphanedPermissions = 'OrphanedPermissions',
  RolesByPermission = 'RolesByPermission',

  ToxicPairs = 'ToxicPairs',
  ToxicAlerts = 'ToxicAlerts',

  CrossChecksList = 'CrossChecksList',
  CrossChecksListMy = 'CrossChecksListMy',
  CrossCheckDetails = 'CrossCheckDetails',
  SamCrossChecksList = 'SamCrossChecksList',
  ResourceRequests = 'ResourceRequests',
  ResourceAccesses = 'ResourceAccesses',
  AvailableResources = 'AvailableResources',

  SamCrossChecksListMy = 'SamCrossChecksListMy',
  SamMyCrossChecksList = 'SamMyCrossChecksList',
  SamCrossCheckDetails = 'SamCrossCheckDetails',
  SamReviewGroupDetails = 'SamReviewGroupDetails',

  DartCrossChecksList = 'DartCrossChecksList',
  DartCrossCheckDetails = 'DartCrossCheckDetails',

  XChecks = 'XChecks',
  XChecksEntities = 'XChecksEntities',
  XChecksExecutables = 'XChecksExecutables',
  XCheckCompatibilityList = 'XCheckCompatibilityList',
  XCheck = 'XCheck',
  XCheckLog = 'XCheckLog',
  XCheckParams = 'XCheckParams',

  WhoAmI = 'WhoAmI',
  WhoAmISam = 'WhoAmISam',
  WhoAmIDart = 'WhoAmIDart',

  SamPolicies = 'SamPolicies',
  SamPolicy = 'SamPolicy',
  SamResources = 'SamResources',
  SamResource = 'SamResource',
  SamTrainings = 'SamTrainings',
  SamUserTrainings = 'SamUserTrainings',
  SamAuditLogs = 'SamAuditLogs',
  SamPolicyEmployees = 'SamPolicyEmployees',
  SamEmployees = 'SamEmployees',
  SamRecertification = 'SamRecertification',
  SamRecertificationPolicyMap = 'SamRecertificationPolicyMap',

  CustomerCompanies = 'CustomerCompanies',
  CustomerCompany = 'CustomerCompany',
  EmployeeDetails = 'EmployeeDetails',
  EmployeeDetailsNotLimited = 'EmployeeDetailsNotLimited',
  EmployeeCompanies = 'EmployeeCompanies',
  DirectRelations = 'DirectRelations',
  IgnoredEmployees = 'IgnoredEmployees',
  DartAuditLogs = 'DartAuditLogs',

  RevolutersDictionaries = 'RevolutersDictionaries',
  RevolutersAvatars = 'RevolutersAvatars',

  Groups = 'Groups',
  Group = 'Group',
}
