import { AddRestrictionPayload, EmployeeAccesses } from 'api/dart/employee'
import { isUuid } from 'utils/string/isUuid'
import { FormState } from './UserRestrictionCreate'

export type CreateRestrictionsPayloadResult = {
  payload?: AddRestrictionPayload[]
  error?: string
}

export const getRestrictionErrors = (params: {
  formState: FormState
  accesses: EmployeeAccesses[]
}) => {
  const { formState, accesses } = params
  const { type, employeeId } = formState
  const employeeAccesses = employeeId
    ? accesses.find((i) => i.id === employeeId)
    : undefined

  switch (type) {
    case 'blockedEmployee': {
      return getBlockError({ employeeAccesses })
    }

    case 'restrictedRelation': {
      return getBanRelationError({ formState, employeeAccesses })
    }

    default:
      return 'Unknown type'
  }
}

const getBanRelationError = (params: {
  formState: FormState
  employeeAccesses?: EmployeeAccesses
}) => {
  const { formState, employeeAccesses } = params
  const { subjectId } = formState

  const isAlreadyBanned = !!employeeAccesses?.accessModifiers.find(
    ({ type, entityId }) => type === 'DENIED' && entityId?.id === subjectId,
  )
  if (isAlreadyBanned) {
    return 'User already blocked to access the customer'
  }

  if (subjectId && !isUuid(subjectId)) {
    return 'Customer ID must be an UUID'
  }

  return undefined
}

const getBlockError = (params: { employeeAccesses?: EmployeeAccesses }) => {
  const { employeeAccesses } = params

  const isAllowedAll = !!employeeAccesses?.accessModifiers.find(
    (modifier) => modifier.type === 'ALLOWED' && !modifier.entityId,
  )
  if (isAllowedAll) {
    return 'User has modificator providing access to all companies, remove it first'
  }

  const isAlreadyBanned = !!employeeAccesses?.accessModifiers.find(
    (modifier) => modifier.type === 'DENIED' && !modifier.entityId,
  )
  if (isAlreadyBanned) {
    return 'User already banned'
  }

  return undefined
}
