import { TextParam } from 'api/types/xChecks'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { Input } from '@revolut/ui-kit'
import { InputProps } from '../types'

export const TextItem = ({ value, onChange }: InputProps<TextParam>) => {
  const onStringChange = useInputStringChange((newValue) =>
    onChange({
      ...value,
      value: newValue || undefined,
    }),
  )
  return <Input label="Text value" value={value.value} onChange={onStringChange} />
}
