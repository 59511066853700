import { Variant } from '../components/Updates/types'
import { TEXT_ADDED_STYLE, TEXT_REMOVED_STYLE } from '../consts'
import { EntityContext } from '../types'

export const getVariantTextStyle = (variant?: Variant) => {
  switch (variant) {
    case Variant.added:
      return TEXT_ADDED_STYLE
    case Variant.removed:
      return TEXT_REMOVED_STYLE
    default:
      return {}
  }
}

export const getContextTextStyle = (context: EntityContext) => {
  switch (context) {
    case EntityContext.arrayAdded:
    case EntityContext.detailsAdded:
      return TEXT_ADDED_STYLE
    case EntityContext.arrayRemoved:
    case EntityContext.detailsRemoved:
      return TEXT_REMOVED_STYLE
    default:
      return {}
  }
}
