import { State } from 'api/types/xChecks'
import { GetActionsParams, XCheckActionSettings } from '../types'

const EDIT_STATUS: State[] = [State.Requested, State.AwaitingReview, State.RequestedInfo]
const UPDATE_PARAMS_PERMISSION = 'cross_checks.update.params'

export const getEditParamsAction = ({
  xCheck,
  xCheckId,
  userId,
  sourceServiceMap,
  hasPermission,
  setIsEditing,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const isRequester = xCheck && 'id' in xCheck.requester && xCheck.requester.id === userId
  const allowed =
    xCheck?.source &&
    hasPermission({
      service: sourceServiceMap[xCheck.source],
      value: UPDATE_PARAMS_PERMISSION,
    })

  if (!isRequester || !allowed || !EDIT_STATUS.includes(xCheck.state)) {
    return undefined
  }

  return {
    onSubmit: () => setIsEditing(true),
    actionType: 'edit',
    xCheckId,
    actionSettings: {
      label: 'Edit',
      useIcon: 'Pencil',
    },
  }
}
