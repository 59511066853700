import { chain, HStack, Text, TextSkeleton } from '@revolut/ui-kit'
import { XCheckState } from './State'
import { useXCheckData } from '../../../hooks'
import { useViews } from '../../../hooks/useViews'
import { PrincipalContext } from '../../../types'

export const Description = () => {
  const { xCheckData } = useXCheckData()
  const { Principal } = useViews()

  if (xCheckData.status === 'loading') {
    return <TextSkeleton size={40} />
  }
  if (xCheckData.status !== 'success') {
    return null
  }

  return (
    <HStack align="center">
      {chain(
        <XCheckState state={xCheckData.data.state} />,

        <HStack space="s-8" align="center">
          <Text color="grey-tone-50" variant="secondary">
            requested by
          </Text>
          <Principal
            principal={xCheckData.data.requester}
            context={PrincipalContext.requester}
          />
        </HStack>,
      )}
    </HStack>
  )
}
