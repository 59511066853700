import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { notNullable } from 'utils/common'
import { useMemo } from 'react'
import { useRegionsColumn } from 'components/RegionsTableCell'
import { ResourceRow } from './types'

type GetColumnsParams = {
  rows: ResourceRow[]
}

export const useColumns = ({ rows }: GetColumnsParams): TableColumn<ResourceRow>[] => {
  const regionsColumn = useRegionsColumn(rows)

  return useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'resourceName' as const,
        },
        {
          Header: 'Type',
          accessor: 'resourceType' as const,
          filter: 'includesValue' as const,
          Filter: SelectItemFilter,
        },
        regionsColumn,
        {
          Header: 'Duration',
          accessor: 'duration' as const,
        },
      ].filter(notNullable),
    [regionsColumn],
  )
}
