import { Token } from '@revolut/ui-kit'
import { createEnumMap } from 'utils/types/createEnumMap'
import { ValueOf } from 'utils/types/valueOf'

const KEY_PREFIX = 'XCheck'

export enum QueryKey {
  Users = `${KEY_PREFIX}Users`,
}

export const TEXT_REMOVED_STYLE = {
  textDecoration: 'line-through',
  color: Token.color.greyTone50,
}

export const TEXT_ADDED_STYLE = {
  color: Token.color.warning,
}

export const TAG_REMOVED_STYLE = {
  color: Token.color.greyTone50,
}

export const TAG_ADDED_STYLE = {
  color: Token.color.warning,
}

export const EntityWellKnownAttrs = createEnumMap('id', 'name', 'entityType')
type EntityWellKnownAttrs = ValueOf<typeof EntityWellKnownAttrs>

export const TIMESTAMP_FORMAT = 'dd / MM / yyyy, HH:mm:ss.SSS'
