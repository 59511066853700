import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SamPolicy } from 'api/sam/policies'
import api from 'api/sam'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { useCallback } from 'react'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useNavigateToPolicy } from 'hooks/useNavigateToPolicy'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { MandatoryTrainings } from './type'
import { getToastText } from './utils'

type TrainingsSetParams = {
  mandatoryTrainings: MandatoryTrainings
  type: 'add' | 'delete'
  changedCount: number
}

export const useSetPolicyTrainings = (params: {
  onSuccess: () => void
  policy?: SamPolicy
}) => {
  const { onSuccess, policy } = params
  const { closePopup } = useConfirmationPopup()
  const queryClient = useQueryClient()

  const reloadAndNavigateToPolicy = useNavigateToPolicy()
  const { showSuccessToast } = useToasts()
  const { showErrorPopup } = useErrorPopup()
  const { hideLoadingPopup, showLoadingPopup } = useLoadingPopup()
  const onError = useCallback(
    (error: unknown) => {
      hideLoadingPopup()
      showErrorPopup({ title: 'Training update failed', error })
      closePopup()
    },
    [hideLoadingPopup, showErrorPopup, closePopup],
  )
  const { isDirect, toCrosscheck } = useCrossCheck(SAM_PERMISSIONS.POLICIES_UPDATE, [
    SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
    SAM_PERMISSIONS.POLICIES_UPDATE_MULTI_SUBJECT_WITH_CROSS_CHECK,
  ])

  const setTrainingsMutation = useMutation({
    mutationFn: ({ mandatoryTrainings }: TrainingsSetParams) =>
      policy
        ? api.policies.updatePolicy({
            policyId: policy.policyId,
            data: {
              policyName: policy.policyName,
              businessReason: policy.businessReason,
              mandatoryTrainings,
            },
          })
        : Promise.reject('Policy not found'),
    onSuccess: (_data, { type, changedCount }) => {
      hideLoadingPopup()
      onSuccess()
      showSuccessToast(getToastText(changedCount, type))
      queryClient.invalidateQueries([QueryKey.SamPolicy, policy?.policyId])
      queryClient.resetQueries([QueryKey.SamAuditLogs, 'access-policy', policy?.policyId])
    },
    onError,
    onMutate: () => showLoadingPopup({ title: 'Updating policy trainings' }),
  })

  const setTrainingCrosscheckMutation = useMutation({
    mutationFn: ({ mandatoryTrainings }: TrainingsSetParams) =>
      policy
        ? api.policies.crossCheckUpdatePolicy({
            policyId: policy.policyId,
            data: {
              policyName: policy.policyName,
              businessReason: policy.businessReason,
              mandatoryTrainings: mandatoryTrainings?.length ? mandatoryTrainings : [],
              resourceIds: policy.resourceIds,
            },
          })
        : Promise.reject('Policy not found'),
    onSuccess: (response) => {
      const baseDescriptor = getCrossCheckDescriptor(response)
      const data = response.data
      const crossCheckDescriptor = {
        id: data.crossCheckId || baseDescriptor.id,
        version: baseDescriptor.version,
      }
      if (crossCheckDescriptor.id) {
        hideLoadingPopup()
        onSuccess()
        toCrosscheck(crossCheckDescriptor)
      } else if (policy) {
        reloadAndNavigateToPolicy(policy.policyId)
        showSuccessToast('Policy edited')
      }
    },
    onError,
    onMutate: () =>
      showLoadingPopup({ title: 'Creating policy training update cross-check' }),
  })

  return isDirect
    ? {
        setTrainings: (setParams: TrainingsSetParams) =>
          setTrainingsMutation.mutate(setParams),
        isSetting: setTrainingsMutation.isLoading,
      }
    : {
        setTrainings: (setParams: TrainingsSetParams) =>
          setTrainingCrosscheckMutation.mutate(setParams),
        isSetting: setTrainingCrosscheckMutation.isLoading,
      }
}
