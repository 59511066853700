import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { StatusPopup, Toast, useStatusPopup } from '@revolut/ui-kit'
import { useToast } from '@revolut/ui-kit'
import { Check } from '@revolut/icons'
import { useApi, useXCheckData } from 'view/XChecks/XCheck/lib/hooks'
import { extractErrorMsg } from 'view/XChecks/XCheck/lib/utils'
import {
  XCheckActionSubmitHandler,
  XCheckActionSubmitHandlerParams,
} from '../../../../../types'
import {
  actionMethod,
  getErrorPopupTitle,
  getLoadingPopupTitle,
  getSuccessToastTitle,
} from './utils'

export const useActionSubmit = () => {
  const api = useApi()
  const { onXCheckUpdate } = useXCheckData()
  const statusPopup = useStatusPopup()
  const toast = useToast()

  const { mutate } = useMutation({
    mutationFn: (params: XCheckActionSubmitHandlerParams) => actionMethod(params, api),
    onMutate: (params: XCheckActionSubmitHandlerParams) => {
      statusPopup.show(
        <StatusPopup variant="loading">
          <StatusPopup.Title>{getLoadingPopupTitle(params.actionType)}</StatusPopup.Title>
        </StatusPopup>,
      )
    },
    onSuccess: (_result: unknown, params: XCheckActionSubmitHandlerParams) => {
      statusPopup.hide()
      toast.show(
        <Toast>
          <Toast.Indicator>
            <Check />
          </Toast.Indicator>
          <Toast.Label>{getSuccessToastTitle(params.actionType)}</Toast.Label>
        </Toast>,
        'short',
      )
      onXCheckUpdate()
    },
    onError: (err: Error, params: XCheckActionSubmitHandlerParams) => {
      statusPopup.hide()
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>{getErrorPopupTitle(params.actionType)}</StatusPopup.Title>
          <StatusPopup.Description>{extractErrorMsg(err)}</StatusPopup.Description>
        </StatusPopup>,
      )
    },
  })

  return useCallback<XCheckActionSubmitHandler>((params) => mutate(params), [mutate])
}
