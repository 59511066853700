import { Button, Page, PageMainSize } from '@revolut/ui-kit'
import { OrganisationData } from 'api/idave/organisations'
import { useCallback, useMemo, useState } from 'react'
import { useLinkNavigate } from 'hooks/useLinkNavigate'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { QueryKey } from 'helpers/configQuery'
import { useToasts } from 'hooks/useToasts'
import { isEqual } from 'lodash'
import { QuerySwitch } from 'components/QuerySwitch'
import { useQueryOrg } from 'queries/idave/orgs'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { PageHeader } from 'components/PageHeader'
import { OrganisationEditForm, OrganisationEditFormState } from '../OrganisationEditForm'
import { getOrgFormState, getOrgPayload, isOrgValid } from '../OrganisationEditForm/utils'
import { OrganisationEditSkeleton } from './OrganisationEditSkeleton'

export const OrganisationEdit = (props: { organisationId: string }) => {
  const { organisationId } = props
  const { data: organisation, status, fetchStatus } = useQueryOrg(organisationId)

  const organisationsRequestStatus = useMemo(
    () => [{ qs: status, fs: fetchStatus }],
    [status, fetchStatus],
  )

  return (
    <QuerySwitch
      data={organisation}
      required={organisationsRequestStatus}
      renderLoading={() => <OrganisationEditSkeleton organisationId={organisationId} />}
      renderSuccess={({ data: org }) => <Inner organisation={org} />}
    />
  )
}

const Inner = (props: { organisation: OrganisationData }) => {
  const { organisation } = props

  const toOrg = useLinkNavigate(
    generatePath(Url.OrganisationDetails, { organisationId: organisation.id }),
  )

  const [orgData, setOrgData] = useState<OrganisationEditFormState>(
    getOrgFormState(organisation),
  )
  const queryClient = useQueryClient()
  const { showSuccessToast } = useToasts()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { mutate, isLoading } = useMutation({
    mutationFn: idaveApi.organisations.edit,
    onMutate: () => showLoadingPopup({ title: 'Updating...' }),
    onSuccess: () => {
      hideLoadingPopup()
      queryClient.resetQueries({
        queryKey: [QueryKey.Organisations],
      })
      showSuccessToast('Organisation updated')
      return toOrg()
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Organisation update failed',
        error: err,
      })
    },
  })

  const onSubmit = useCallback(() => {
    mutate({
      orgId: organisation.id,
      details: getOrgPayload(orgData),
    })
  }, [mutate, orgData, organisation])

  const disabled = isEqual(orgData, getOrgFormState(organisation)) || !isOrgValid(orgData)

  return (
    <>
      <PageHeader onBack={toOrg} pageTitle="Edit organisation" />
      <Page.Main size={PageMainSize.NARROW}>
        <OrganisationEditForm
          initialValue={orgData}
          isLoading={isLoading}
          onChange={setOrgData}
        />
        <Page.MainActions>
          <Button
            id="org-edit-save"
            variant="primary"
            elevated
            disabled={isLoading || disabled}
            onClick={onSubmit}
            pending={isLoading}
          >
            Save
          </Button>
        </Page.MainActions>
      </Page.Main>
    </>
  )
}
