import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { Permission } from './types'

export const COLUMNS: TableColumn<Permission>[] = [
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'includesValue',
    width: 300,
  },
  {
    Header: 'App',
    id: 'clientName',
    accessor: ({ client, clientName }: Permission) => clientName || client,
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 200,
  },
  {
    Header: 'Description',
    accessor: 'description',
    width: 500,
  },
]
