import { GroupReviewRule, ReviewRule } from 'api/types/xChecks'
import { AvatarBar, Box, Link, Text } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useXCheckSide } from 'view/XChecks/XCheck/lib/hooks'
import { pluralForm } from 'utils/string'
import { Principal } from 'view/XChecks/XCheck/components'
import { getGroupRuleSide } from 'view/XChecks/XCheck/lib/components/Side/utils'
import { PrincipalContext } from 'view/XChecks/XCheck/lib/types'
import { RuleItem } from './RuleItem'
import { getParentRulePrefix } from '../utils'

type Props = {
  rule: GroupReviewRule
  parentRule?: ReviewRule
  isFirst?: boolean
}

export const GroupRule = ({ rule, parentRule, isFirst }: Props) => {
  const { setSide } = useXCheckSide()

  const onClick = useCallback(
    () => setSide(getGroupRuleSide({ groupRule: rule, setSideView: setSide })),
    [setSide, rule],
  )

  return (
    <RuleItem rule={rule}>
      <Text variant="secondary">
        {getParentRulePrefix(parentRule, isFirst)}{' '}
        {pluralForm(rule.config.approvals, [
          `at least ${rule.config.approvals} approval`,
          `at least ${rule.config.approvals} approvals`,
        ])}{' '}
        from{' '}
        <Link onClick={onClick} style={{ cursor: 'pointer' }}>
          {rule.name}
          {'  '}
          <Box display="inline-block">
            <AvatarBar variant="compact" maxCount={4} display="inline" size={20}>
              {rule.config.reviewers.map((principal) => (
                <Principal
                  context={PrincipalContext.reviewGroupAvatarBar}
                  principal={principal}
                  key={principal.id}
                />
              ))}
            </AvatarBar>
          </Box>
        </Link>
      </Text>
    </RuleItem>
  )
}
