import { Param, Value } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { stringifyBoolean } from 'utils/boolean'
import { notNullable } from 'utils/common'
import {
  formatTimestamp,
  getEntityDescriptor,
  typeMatcher,
} from 'view/XChecks/XCheck/lib/utils'

export const stringifyParam = (param: Value<Param>) =>
  match(param)
    .with(typeMatcher('bool'), (boolParam) =>
      notNullable(boolParam.value)
        ? stringifyBoolean(boolParam.value)
        : 'Empty boolean parameter',
    )
    .with(typeMatcher('number'), (numberParam) =>
      notNullable(numberParam.value)
        ? String(numberParam.value)
        : 'Empty number parameter',
    )
    .with(typeMatcher('text'), (textParam) =>
      notNullable(textParam.value) ? textParam.value : 'Empty text parameter',
    )
    .with(typeMatcher('uuid'), (uuidParam) =>
      notNullable(uuidParam.value) ? uuidParam.value : 'Empty UUID parameter',
    )
    .with(typeMatcher('timestamp'), (timestampParam) =>
      notNullable(timestampParam.value)
        ? formatTimestamp(timestampParam.value)
        : 'Empty timestamp parameter',
    )
    .with(typeMatcher('entity'), (entityParam) => {
      const descriptor = getEntityDescriptor(entityParam)
      return descriptor.name || descriptor.id
    })
    .with(
      typeMatcher('array'),
      (arrayParam) => `Array of items ${arrayParam.value?.length || 0}`,
    )
    .exhaustive()
