import { Text } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { getClassificationLabel } from 'utils/permission/getClassificationLabel'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'

type Props = {
  permission: Permission
}
export const PermissionDataClassification = ({ permission }: Props) => {
  const label = getSecurityLabel(
    permission.classification,
    getClassificationLabel(permission.classification),
  )

  return <Text>{label || EMPTY_VALUE_FALLBACK}</Text>
}
