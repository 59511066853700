import { Button, Input, Page, TextArea, VStack } from '@revolut/ui-kit'
import { useToasts } from 'react-toast-notifications'
import { useForm } from 'react-hook-form'
import { useMutation } from '@tanstack/react-query'
import samApi from 'api/sam'
import { SamTaskPayload } from 'api/sam/tasks'

type FormState = { taskName: string; args: string }

export const SamTasks = () => {
  const { addToast } = useToasts()
  const { register, handleSubmit } = useForm<FormState>()

  const { mutate: sendTask, isLoading } = useMutation({
    mutationFn: (payload: SamTaskPayload) => {
      return samApi.tasks.sendTask(payload)
    },
    onSuccess: () => {
      addToast('Schedule Task: Success', { appearance: 'success', autoDismiss: true })
    },
  })

  const onSubmit = (data: FormState) => {
    sendTask({
      taskName: data.taskName,
      args: JSON.parse(`[${data.args}]`),
    })
  }

  return (
    <>
      <VStack gap="s-16">
        <Input {...register('taskName')} label="Task name" />
        <TextArea {...register('args')} label="Args" />
      </VStack>

      <Page.MainActions>
        <Button
          elevated
          pending={isLoading}
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Send
        </Button>
      </Page.MainActions>
    </>
  )
}
