import { Source } from 'api/types/xChecks'
import { auth } from 'services/auth'
import { useMemo } from 'react'
import { XCheckApiErrorHandler } from '../lib/types'
import { useEntitiesFetchers } from './useEntitiesFetchers'

const API_PREFIX = '/crosschecks-api'
const API_NAME = 'x_checks'
const SOURCE_PREFIX_MAP: Record<Source, string> = {
  dart: '/dart-api',
  idave: '/api',
  sam: '/sam-api',
  'idave-admin': '/api',
}

const SOURCE_SERVICE_MAP: Record<Source, string> = {
  dart: 'dart',
  idave: 'idave',
  sam: 'sam',
  'idave-admin': 'idave',
}

const onXCheckApiError: XCheckApiErrorHandler = ({ response }) => {
  if (!response?.ok && response?.status === 401) {
    auth.redirectToSignIn()
  }
}

export const useApiSettings = () => {
  const { entitiesFetchers, fetchUsers } = useEntitiesFetchers()

  return useMemo(
    () => ({
      apiPrefix: API_PREFIX,
      apiName: API_NAME,
      onXCheckApiError,
      sourcePrefixMap: SOURCE_PREFIX_MAP,
      sourceServiceMap: SOURCE_SERVICE_MAP,
      entitiesFetchers,
      fetchUsers,
    }),
    [fetchUsers, entitiesFetchers],
  )
}
