import { EntityParam } from 'api/types/xChecks'
import { useCallback, useMemo } from 'react'
import { Input } from '@revolut/ui-kit'
import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { InputProps } from '../../../types'
import { unknownEntityUpdate } from '../../../../../../utils'

export const EntityIdItem = ({
  onChange: originalOnChange,
  value,
}: InputProps<EntityParam>) => {
  const entityType = value.type.entityType
  const idValue = getEntityDescriptor(value).id
  const update = useMemo(() => unknownEntityUpdate(value), [value])

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const attrs = update(event.target.value)
      return originalOnChange({
        ...value,
        value: attrs,
      })
    },
    [originalOnChange, update, value],
  )

  return <Input label={`${entityType} id`} value={idValue} onChange={onChange} />
}
