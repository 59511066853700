import { notNullable } from 'utils/common'
import {
  useViews,
  useApi,
  useXCheckData,
  useXCheckSecurity,
} from 'view/XChecks/XCheck/lib/hooks'
import { getDataValue } from 'view/XChecks/XCheck/lib/utils'
import { useActionSubmit } from '../useActionSubmit'
import {
  getJustifyAction,
  getEditParamsAction,
  getApproveAction,
  getDeclineAction,
  getProvideInfoAction,
  getRejectAction,
  getRequestInfoAction,
  getRetryAction,
} from './utils'

export const useActions = () => {
  const { xCheckData, xCheckId, userIdData } = useXCheckData()
  const { hasXCheckPermission, hasPermission } = useXCheckSecurity()
  const { sourceServiceMap } = useApi()
  const onSubmit = useActionSubmit()
  const { setIsEditing } = useViews()

  const params = {
    xCheck: getDataValue(xCheckData),
    userId: getDataValue(userIdData),
    xCheckId,
    sourceServiceMap,
    setIsEditing,
    hasXCheckPermission,
    hasPermission,
    onSubmit,
  }
  return [
    getJustifyAction(params),
    getDeclineAction(params),
    getApproveAction(params),
    getRejectAction(params),
    getRetryAction(params),
    getRequestInfoAction(params),
    getProvideInfoAction(params),
    getEditParamsAction(params),
  ].filter(notNullable)
}
