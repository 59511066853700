import { useCallback, useMemo, useState } from 'react'
import { generatePath } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  CheckboxSelect,
  FilterButton,
  MoreBar,
  useDropdown,
  SelectOptionItemType,
} from '@revolut/ui-kit'
import { useQueryPolicies } from 'queries/sam/policies'
import { Url } from 'routing'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { SAM_PERMISSIONS } from 'security'
import { EntitiesTable } from 'components/EntitiesTable'
import { filterActiveIds, getLoadingState } from 'components/EntitiesTable/utils'
import { useSetAtom } from 'jotai'
import { SamPolicy } from 'api/sam/policies'
import { mergeQueryStatuses } from 'utils/query'
import { PermissionsCheck, usePermissions } from '@revolut-internal/idave-web-auth'
import { policyIdsAtom as policyAddResourceIdsAtom } from './SamPoliciesAddResources'
import { policyIdsAtom as policyAddTrainingIdsAtom } from './SamPoliciesAddTrainings'
import { getColumns, ColumnsType } from './columns'
import { CREATE } from '../permissions'

const columnsOptions: SelectOptionItemType<ColumnsType>[] = [
  {
    key: 'subject',
    label: 'Subject',
    value: 'subject',
  },
  {
    key: 'team',
    label: 'Team',
    value: 'team',
  },
  {
    key: 'department',
    label: 'Department',
    value: 'department',
  },
  {
    key: 'specialisation',
    label: 'Specialisation',
    value: 'specialisation',
  },
  {
    key: 'role',
    label: 'Role',
    value: 'role',
  },
  {
    key: 'function',
    label: 'Function',
    value: 'function',
  },
  {
    key: 'employee',
    label: 'Employee',
    value: 'employee',
  },
  {
    key: 'employee_type',
    label: 'Employee type',
    value: 'employee_type',
  },
  {
    key: 'updated',
    label: 'Updated',
    value: 'updated',
  },
]

const INITIAL_VALUE: ColumnsType[] = [
  'subject',
  'team',
  'department',
  'specialisation',
  'employee',
  'employee_type',
  'updated',
]

export const SamPolicies = () => {
  const navigate = useNavigate()
  const { data: policies = [], status, fetchStatus } = useQueryPolicies()
  const setPolicyResourcesIds = useSetAtom(policyAddResourceIdsAtom)
  const setPolicyTrainingIds = useSetAtom(policyAddTrainingIdsAtom)

  const entities = useMemo(
    () => policies.map((p) => ({ ...p, id: p.policyId })),
    [policies],
  )
  const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })
  const dropdown = useDropdown()
  const [activeColumns, setActiveColumns] = useState<ColumnsType[]>(INITIAL_VALUE)
  const anchorProps = dropdown.getAnchorProps<HTMLButtonElement>()
  const targetProps = dropdown.getTargetProps<HTMLElement>()

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({})
  const [showSelected, setShowSelected] = useState(false)
  const switchShowSelected = useCallback(
    () => setShowSelected((v) => !v),
    [setShowSelected],
  )

  const selectedIds = filterActiveIds(selectedHash)
  const { hasSomePermissions } = usePermissions()
  const onAddResourcesClick = useCallback(() => {
    setPolicyResourcesIds(selectedIds)
    navigate(Url.SamPoliciesAddResources)
  }, [setPolicyResourcesIds, navigate, selectedIds])

  const onAddTrainingsClick = useCallback(() => {
    setPolicyTrainingIds(selectedIds)

    navigate(Url.SamPoliciesAddTrainings)
  }, [setPolicyTrainingIds, navigate, selectedIds])

  const onCreateClick = useCallback(() => {
    navigate(Url.SamPoliciesCreate)
  }, [navigate])

  const onRowClick = useCallback(
    ({ policyId }: SamPolicy) => {
      navigate(generatePath(Url.SamPolicy, { policyId }))
    },
    [navigate],
  )

  const getRowLink = useCallback(
    ({ policyId }: SamPolicy) => generatePath(Url.SamPolicy, { policyId }),
    [],
  )

  return (
    <EntitiesTable
      enableNavigation
      totalCount={entities.length}
      entitiesTypeLabel="Policies"
      pluralForms={['policy', 'policies']}
      columns={getColumns(activeColumns)}
      data={searched}
      loadingState={getLoadingState(
        mergeQueryStatuses({ qs: status, fs: fetchStatus }),
        entities.length,
      )}
      onRowClick={onRowClick}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      selectedHash={selectedHash}
      switchShowSelected={switchShowSelected}
      showSelected={showSelected}
      setSelectedHash={setSelectedHash}
      getRowLink={getRowLink}
      showSelectedSwitcherForce={!hasSomePermissions(...CREATE)}
      renderActions={() => (
        <>
          {!selectedIds.length && (
            <PermissionsCheck somePermissions={CREATE}>
              <MoreBar.Action useIcon="Plus" onClick={onCreateClick}>
                Create policy
              </MoreBar.Action>
            </PermissionsCheck>
          )}

          {!!selectedIds.length && (
            <>
              <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_UPDATE_RESOURCES}>
                <MoreBar.Action useIcon="Plus" onClick={onAddResourcesClick}>
                  Add resources
                </MoreBar.Action>
              </PermissionsCheck>
              <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_UPDATE}>
                <MoreBar.Action useIcon="Plus" onClick={onAddTrainingsClick}>
                  Add trainings
                </MoreBar.Action>
              </PermissionsCheck>
            </>
          )}
        </>
      )}
      renderFiltersRight={() => (
        <>
          <FilterButton {...anchorProps} useIcon="Controls" active={targetProps.open}>
            Columns
          </FilterButton>
          <CheckboxSelect
            {...targetProps}
            options={columnsOptions}
            value={activeColumns}
            onChange={setActiveColumns}
            labelList="activeColumns"
          />
        </>
      )}
    />
  )
}
