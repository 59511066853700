import { ActionButton, useToggle } from '@revolut/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { FetchEntities, useApi, useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GroupUpdatePopup } from './components/GroupUpdatePopup'

type Props = {
  groupId: string
  xCheckId: string
}
export const GroupRuleActions = ({ groupId, xCheckId }: Props) => {
  const { hasXCheckPermission } = useXCheckSecurity()
  const { fetchUsers } = useApi()

  if (
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_GROUPS_REVIEWERS) ||
    !fetchUsers
  ) {
    return null
  }

  return <Inner groupId={groupId} xCheckId={xCheckId} fetchUsers={fetchUsers} />
}

type InnerProps = {
  groupId: string
  xCheckId: string
  fetchUsers: FetchEntities
}
const Inner = ({ groupId, xCheckId, fetchUsers }: InnerProps) => {
  const [isOpen, toggleOpen] = useToggle()
  const [reasonText, setReasonText] = useState<string>('')

  useEffect(() => toggleOpen.off, [toggleOpen.off])

  const onActionClick = useCallback(() => {
    setReasonText('')
    toggleOpen.on()
  }, [toggleOpen])

  return (
    <>
      {isOpen ? (
        <GroupUpdatePopup
          reasonText={reasonText}
          onReasonChange={setReasonText}
          toggleOpen={toggleOpen}
          groupId={groupId}
          xCheckId={xCheckId}
          fetchUsers={fetchUsers}
        />
      ) : null}
      <ActionButton useIcon="AddContact" onClick={onActionClick}>
        Extend review group
      </ActionButton>
    </>
  )
}
