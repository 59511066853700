import { UuidParam } from 'api/types/xChecks'
import { Input, Token } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { isUuid } from 'utils/string/isUuid'
import { InputProps } from '../types'

export const UuidItem = ({
  onChange,
  value,
  isValid,
  setIsValid,
}: InputProps<UuidParam>) => {
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setIsValid(isUuid(newValue))
      return onChange({
        ...value,
        value: newValue || undefined,
      })
    },
    [onChange, value, setIsValid],
  )
  const errorMessage = !isValid ? "Value isn't a UUID" : undefined
  return (
    <Input
      style={{ fontSize: Token.fontSize.body2 }}
      label="UUID"
      value={value.value}
      onChange={onInputChange}
      invalid={!isValid}
      errorMessage={errorMessage}
    />
  )
}
