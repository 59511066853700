import axios, { AxiosPromise } from 'axios'
import { CrossCheck } from 'api/types/crossCheck'
import { AvailableResource, Resource, ResourceAccess } from './types/resources'
import { DataResponse } from './types'

export type GetAvailableResourcesParams = {
  idaveUserId: string
  justification: string
  jiraTicket?: string
}

export type ResourceRequest = {
  resourceId: string
  daysRequested: number
}
export type RequestResourceParams = {
  idaveUserId: string
  jiraTicket?: string
  justification: string
  resources: ResourceRequest[]
}

export const resources = {
  getResources: () => axios.get<DataResponse<Resource[]>>('/sam-api/api/resources/'),

  getResource: (id: string) => axios.get<Resource>(`/sam-api/api/resources/${id}`),

  setResourceOwner: (resourceId: string, ownerGroupId?: string): AxiosPromise<void> =>
    axios.post('/sam-api/api/resources/set-owners', {
      data: [
        {
          resourceId,
          ownerGroupId: ownerGroupId || null,
        },
      ],
    }),

  getResourceRequests: (userId: string) =>
    axios.get<DataResponse<CrossCheck[]>>(
      `/sam-api/api/cross-checks-v2/temporary-resource-access-requests/${userId}`,
    ),

  getAvailableResources: ({ idaveUserId, ...params }: GetAvailableResourcesParams) =>
    axios.get<DataResponse<AvailableResource[]>>(
      `/sam-api/api/temporary-resource-access/available-resources/${idaveUserId}`,
      { params },
    ),

  getTemporaryResourcesAccesses: (idaveUserId: string) =>
    axios.get<DataResponse<ResourceAccess[]>>(
      `/sam-api/api/temporary-resource-access/${idaveUserId}`,
    ),

  requestResource: (params: RequestResourceParams) =>
    axios.post('/sam-api/api/temporary-resource-access/', params),

  revokeResource: (accessId: string) =>
    axios.post(`/sam-api/api/temporary-resource-access/${accessId}/revoke`),
}
