import { Icon } from '@revolut/ui-kit'

export const Delete = ({
  onClick,
  disabled,
  canDelete,
}: {
  canDelete: boolean
  onClick: (e: React.MouseEvent) => void
  disabled: boolean
}) => {
  return canDelete && !disabled ? (
    <Icon name="Cross" onClick={onClick} size={14} title="Delete">
      Action
    </Icon>
  ) : null
}
