import { PermissionClassification } from 'api/idave/permissions'
import { getSecurityLabel } from 'utils/string/getSecurityLabel'
import { SelectInput, SelectInputProps, SelectOptionItemType } from '@revolut/ui-kit'
import { getClassificationLabel } from 'utils/permission/getClassificationLabel'
import { stringEnumToOptions } from './utils'

const values = PermissionClassification as Record<string, PermissionClassification>
export const OPTIONS: SelectOptionItemType<string>[] = stringEnumToOptions(
  PermissionClassification,
  (item) => getSecurityLabel(values[item], getClassificationLabel(values[item])),
)

type StringSelect = SelectInputProps<string, SelectOptionItemType<string>>

export type PermissionClassificationSelectProps = Omit<StringSelect, 'options'>

export const PermissionClassificationSelect = (
  props: PermissionClassificationSelectProps,
) => <SelectInput searchable={false} {...props} options={OPTIONS} />
