import { ActionButton, MoreBar } from '@revolut/ui-kit'
import { QuerySwitch } from 'components/QuerySwitch'
import { useResourceRequests } from 'queries/sam/resources'
import { useCallback } from 'react'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'

type Variant = 'action' | 'bar'

type Props = {
  variant: Variant
  idaveUserId: string
}
export const NavigateToRequests = ({ idaveUserId, variant }: Props) => {
  const navigate = useNavigate()
  const onClick = useCallback(
    () => navigate(generatePath(Url.UserResourceRequests, { userId: idaveUserId })),
    [navigate, idaveUserId],
  )

  const { data, status: qs, fetchStatus: fs } = useResourceRequests(idaveUserId)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={data}
      renderLoading={() => <Inner onClick={onClick} pending variant={variant} />}
      renderError={() => null}
      renderIdle={() => null}
      renderSuccess={({ data: xChecks }) =>
        xChecks?.length ? <Inner onClick={onClick} variant={variant} /> : null
      }
    />
  )
}

type InnerProps = {
  pending?: boolean
  onClick: () => void
  variant: Variant
}

const LINK_TEXT = 'View existing requests'
const Inner = ({ onClick, pending, variant }: InnerProps) => {
  const props = {
    pending,
    useIcon: 'Link',
    variant: 'accent',
    onClick,
  } as const

  return variant === 'bar' ? (
    <MoreBar.Action {...props}>{LINK_TEXT}</MoreBar.Action>
  ) : (
    <ActionButton {...props}>{LINK_TEXT}</ActionButton>
  )
}
