import { AddAction } from './components/AddAction'
import { RevokeAction } from './components/RevokeAction'

export const UserListActions = ({
  selectedHash,
  reset,
  companyId,
}: {
  selectedHash: Record<string, boolean>
  reset: () => void
  companyId: string
}) => {
  return (
    <>
      <RevokeAction selectedHash={selectedHash} companyId={companyId} reset={reset} />
      <AddAction companyId={companyId} selectedHash={selectedHash} />
    </>
  )
}
