import { PageMainSize } from '@revolut/ui-kit'
import { UserTab } from './User'

export const tabToPageSize = (tab: UserTab) => {
  switch (tab) {
    case UserTab.accessLog:
    case UserTab.auditLog:
    case UserTab.dataLogs:
    case UserTab.permissions:
    case UserTab.policies:
    case UserTab.roles:
    case UserTab.toxicAlerts:
    case UserTab.resourceAccess:
      return PageMainSize.FULL
    case UserTab.profile:
      return PageMainSize.WIDE
    case UserTab.dataAccess:
    default:
      return PageMainSize.NARROW
  }
}
