import { mapify } from 'utils/array'
import { pluralForm } from 'utils/string'
import { JustificationPopup } from 'components/JustificationPopup'
import { EmployeeCompany } from '../../types'

export const getActionDisability = (activeIds: string[], entities: EmployeeCompany[]) => {
  if (!activeIds.length) {
    return { disableAdd: true, disableDelete: true }
  }

  const entityMap = mapify(entities, (e) => e.id)
  const isAllowed = !!entityMap.get(activeIds[0])?.accessModifierId
  const isAllSame = activeIds.every(
    (id) => !!entityMap.get(id)?.accessModifierId === isAllowed,
  )

  return {
    disableAdd: !isAllSame || isAllowed,
    disableDelete: !isAllSame || !isAllowed,
  }
}

type ActionType = 'revoke' | 'add'
const getPopupSettings = (action: ActionType, companiesAmount: number) => {
  switch (action) {
    case 'revoke':
      return {
        title: 'Are you sure you want to revoke access to this customer company? ',
        description: pluralForm(companiesAmount, [
          'Users of revoked employee company will not have access to selected customer company data.',
          'Users of revoked employee companies will not have access to selected customer company data.',
        ]),

        submitButtonLabel: 'Revoke',
      }
    default:
    case 'add':
      return {
        title: 'Are you sure you want to allow access to this customer company?',
        description: pluralForm(companiesAmount, [
          'Users of added employee company will have access to this customer company data.',
          'Users of added employee companies will have access to this customer company data.',
        ]),
        submitButtonLabel: 'Add',
      }
  }
}

type GetJustificationPopupParams = {
  action: ActionType
  onSubmit: (justification?: string) => void
  companiesAmount: number
}
export const getJustificationPopup = ({
  action,
  companiesAmount,
  onSubmit,
}: GetJustificationPopupParams) => {
  const { submitButtonLabel, ...popupSettings } = getPopupSettings(
    action,
    companiesAmount,
  )
  return {
    ...popupSettings,
    isBottomSheet: true,
    content: (
      <JustificationPopup onSubmit={onSubmit} submitButtonLabel={submitButtonLabel} />
    ),
  }
}
