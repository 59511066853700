import { PageMainSize } from '@revolut/ui-kit'
import { SamPolicySubject, SamPolicySubjectType } from 'api/sam/policies'
import { Resource } from 'api/sam/types/resources'
import { toPresenceMap } from 'utils/array/toPresenceMap'

export const stringifySubjectType = (subjectType: SamPolicySubjectType) => {
  const mapping: Record<SamPolicySubjectType, string> = {
    [SamPolicySubjectType.Employee]: 'Employee',
    [SamPolicySubjectType.EmployeeType]: 'Employee type',
    [SamPolicySubjectType.Team]: 'Team',
    [SamPolicySubjectType.Specialisation]: 'Specialisation',
    [SamPolicySubjectType.Department]: 'Department',
    [SamPolicySubjectType.Role]: 'Role',
    [SamPolicySubjectType.Function]: 'Function',
  }

  return mapping[subjectType]
}

export const normalizeResources = (resourceIds: string[], resources: Resource[]) => {
  const resourcePresence = toPresenceMap(resources.map((r) => r.resourceId))
  return resourceIds.filter((id) => resourcePresence[id])
}

export const getSpecIds = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Department ||
    subject.subjectType === SamPolicySubjectType.Specialisation
  ) {
    return subject.specialisationIds || []
  }

  return []
}

export const getSeniorityIds = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Department ||
    subject.subjectType === SamPolicySubjectType.Specialisation ||
    subject.subjectType === SamPolicySubjectType.Role ||
    subject.subjectType === SamPolicySubjectType.Function
  ) {
    return subject.seniorityIds || []
  }

  return []
}

export const getTeamIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Team) {
    return subject.teamIds
  }

  return []
}

export const getDepartmentIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Department) {
    return subject.departmentIds
  }

  return []
}

export const getRoleIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Role) {
    return subject.positionIds
  }

  return []
}

export const getFunctionIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Function) {
    return subject.functionIds
  }

  return []
}

export const getEmployeeTypes = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Department ||
    subject.subjectType === SamPolicySubjectType.Specialisation ||
    subject.subjectType === SamPolicySubjectType.EmployeeType ||
    subject.subjectType === SamPolicySubjectType.Role ||
    subject.subjectType === SamPolicySubjectType.Function
  ) {
    return subject.employeeTypes
  }

  return []
}

export const getContractTypes = (subject: SamPolicySubject) => {
  if (
    subject.subjectType === SamPolicySubjectType.Team ||
    subject.subjectType === SamPolicySubjectType.Specialisation ||
    subject.subjectType === SamPolicySubjectType.EmployeeType
  ) {
    return subject.contractTypes
  }

  return []
}

export const getEmployeeIds = (subject: SamPolicySubject) => {
  if (subject.subjectType === SamPolicySubjectType.Employee) {
    return subject.employeeIds
  }

  return []
}

export const tabToPageSize = (tab: string) => {
  switch (tab) {
    case 'policies':
    case 'resources':
      return PageMainSize.FULL
    case 'tasks':
    default:
      return PageMainSize.NARROW
  }
}

export const getIsMultiSubject = (subject?: SamPolicySubject) => {
  if (!subject) return false

  return (
    (subject.subjectType === SamPolicySubjectType.Team && subject.teamIds.length > 1) ||
    (subject.subjectType === SamPolicySubjectType.Department &&
      subject.departmentIds.length > 1) ||
    (subject.subjectType === SamPolicySubjectType.Specialisation &&
      subject.specialisationIds.length > 1) ||
    (subject.subjectType === SamPolicySubjectType.Employee &&
      subject.employeeIds.length > 1) ||
    (subject.subjectType === SamPolicySubjectType.EmployeeType &&
      subject.employeeTypes.length > 1) ||
    (subject.subjectType === SamPolicySubjectType.Role &&
      subject.positionIds.length > 1) ||
    (subject.subjectType === SamPolicySubjectType.Function &&
      subject.functionIds.length > 1)
  )
}
