import { useQueryAccounts } from 'queries/idave/accounts'
import { useNavigate } from 'react-router'
import { useCallback, useEffect, useMemo } from 'react'
import { Url } from 'routing'
import { FormMultiStep, Tab } from 'components/FormMultiStep'
import { FormProvider, useForm } from 'react-hook-form'
import { useAddAccount } from './useAddAccount'
import { AccountFormValues, FormTabs } from './types'
import { DetailFields } from '../components/DetailFields'
import { Permissions } from './components/Permissions'
import { tabToPageSize } from './utils'

const TABS: Tab<FormTabs>[] = [
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'permissions',
    label: 'Permissions',
  },
]

export const AccountAdd = () => {
  const { data: accounts = [] } = useQueryAccounts()
  const excludeClients = useMemo(
    () => accounts.map(({ clientId }) => clientId),
    [accounts],
  )
  const navigate = useNavigate()
  const onCloseClick = useCallback(() => navigate(Url.Accounts), [navigate])

  const { mutate, isLoading } = useAddAccount()

  const accountForm = useForm<AccountFormValues>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: {
      permissionIds: [],
    },
  })

  const onSubmit = useCallback(() => {
    const { clientId, gcpSA, permissionIds } = accountForm.getValues()
    if (clientId) {
      mutate({ clientId, gcpSA, permissionIds })
    }
  }, [mutate, accountForm])

  const checkValid = useCallback(
    () => accountForm.formState.isValid,
    [accountForm.formState.isValid],
  )

  useEffect(() => {
    /**
     * triggers initial form invalidation
     * it doesn't run itself ¯\_(ツ)_/¯
     */
    accountForm.trigger()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...accountForm}>
      <FormMultiStep
        title="Create application account"
        onCloseClick={onCloseClick}
        tabs={TABS}
        onSubmit={onSubmit}
        submitting={isLoading}
        checkTabValid={checkValid}
        checkTabFilled={checkValid}
        tabToPageSize={tabToPageSize}
        renderTab={(currentTab) => (
          <>
            {currentTab === 'details' && (
              <DetailFields excludeClients={excludeClients} isCreating />
            )}
            {currentTab === 'permissions' && <Permissions />}
          </>
        )}
      />
    </FormProvider>
  )
}
