import { createContext, useContext, useMemo } from 'react'
import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { HasPermissionFn } from '../types'

type TXCheckSecurityContext = {
  hasPermission: HasPermissionFn
  hasXCheckPermission: (permissionName: string) => boolean
}

type TXCheckSecurityProps = {
  children: React.ReactNode
  hasPermission: HasPermissionFn
  apiSettings: ApiSettings
}

export const XCheckSecurityContext = createContext<TXCheckSecurityContext>({
  hasPermission: () => false,
  hasXCheckPermission: () => false,
})

export const XCheckSecurityProvider = ({
  children,
  hasPermission,
  apiSettings: { apiName },
}: TXCheckSecurityProps) => {
  const value = useMemo(
    () => ({
      hasPermission,
      hasXCheckPermission: (permissionName: string) =>
        hasPermission({ service: apiName, value: permissionName }),
    }),
    [hasPermission, apiName],
  )

  return (
    <XCheckSecurityContext.Provider value={value}>
      {children}
    </XCheckSecurityContext.Provider>
  )
}

export const useXCheckSecurity = () => useContext(XCheckSecurityContext)
