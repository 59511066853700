import { CollapseIcon } from 'components/CollapseIcon'
import {
  chain,
  Flex,
  Group,
  HStack,
  Icon,
  Item,
  Link,
  Token,
  TransitionCollapse,
  useToggle,
  Widget,
} from '@revolut/ui-kit'
import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils/params'
import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { stopPropagation } from 'view/XChecks/XCheck/lib/utils'
import { useCallback } from 'react'
import { EntityContextProps } from './types'
import { EntityContextContent } from './components/EntityContextContent'
import { isContextEmpty } from './utils'

export const EntityContext = ({
  entity,
  defaultExpanded,
  children,
  descriptionLabel,
}: EntityContextProps) => {
  const [isExpanded, toggle] = useToggle({
    defaultState: defaultExpanded,
  })
  const { getEntityUrl } = useViews()
  const descriptor = getEntityDescriptor(entity)
  const href = getEntityUrl(descriptor)

  const isExpandable = !isContextEmpty({ entity, children })
  const toggleExpand = useCallback(() => {
    if (isExpandable) {
      toggle()
    }
  }, [isExpandable, toggle])

  return (
    <Group>
      <Widget>
        <Item
          variant="header"
          use={isExpandable ? 'button' : undefined}
          aria-expanded={isExpanded}
          onClick={toggleExpand}
        >
          <Item.Content>
            <Item.Title>
              <Flex alignItems="center" gap="s-4">
                {descriptor.name}
                {href ? (
                  <Icon
                    use={Link}
                    target="_blank"
                    name="LinkExternalSimple"
                    href={href}
                    size="s-20"
                    color={Token.color.accent}
                    onClick={stopPropagation}
                  />
                ) : null}
              </Flex>
            </Item.Title>

            <Item.Description>
              <HStack>{chain(descriptor.entityType, descriptionLabel)}</HStack>
            </Item.Description>
          </Item.Content>

          {isExpandable ? (
            <Item.Side>
              <Item.Value paddingTop="s-6">
                <HStack space="s-12" alignSelf="center" align="center">
                  <CollapseIcon
                    color={Token.color.greyTone20}
                    name="ChevronDown"
                    aria-expanded={isExpanded}
                  />
                </HStack>
              </Item.Value>
            </Item.Side>
          ) : null}
        </Item>

        <TransitionCollapse in={isExpanded}>
          <Widget padding="s-8" borderTopLeftRadius={0} borderTopRightRadius={0}>
            <EntityContextContent entity={entity}>{children}</EntityContextContent>
          </Widget>
        </TransitionCollapse>
      </Widget>
    </Group>
  )
}
