import idaveApi from 'api/idave'
import dartApi from 'api/dart'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { DART_PERMISSIONS, IDAVE_PERMISSIONS, SAM_PERMISSIONS } from 'security'

import { capitalizeFirst } from 'utils/string'
import { useMemo } from 'react'
import { KNOWN_ENTITY_TYPE } from 'view/XChecks/XCheck/components/Entity/consts'

import {
  getOrgFlagUrl,
  makeEntitiesFetcher,
  makePermissionsFetcher,
  makeServiceAccountFetcher,
  makeUsersFetcher,
} from './util'
import { makeErrorAsyncNoop } from '../../lib/hooks/utils'

export const useEntitiesFetchers = () => {
  const { hasPermission, hasEveryPermission } = usePermissions()
  const fetchUsers = makeUsersFetcher(
    hasPermission(IDAVE_PERMISSIONS.USERS_VIEW_LIST),
    hasPermission(SAM_PERMISSIONS.REVOLUTERS_VIEW_AVATARS_LIST),
  )
  const fetchUsersWithFallback = fetchUsers || makeErrorAsyncNoop('fetchUsers')

  return useMemo(
    () => ({
      fetchUsers: fetchUsersWithFallback,
      entitiesFetchers: {
        [KNOWN_ENTITY_TYPE.iDaveUser]: fetchUsers,
        [KNOWN_ENTITY_TYPE.serviceAccount]: makeServiceAccountFetcher(
          hasEveryPermission(
            IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST,
            IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_VIEW_LIST,
          ),
        ),
        [KNOWN_ENTITY_TYPE.application]: makeEntitiesFetcher({
          allowed: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
          fetchItems: idaveApi.clients.getClients,
          itemToEntity: ({ id, name }) => ({ id, name }),
        }),

        [KNOWN_ENTITY_TYPE.customerCompany]: makeEntitiesFetcher({
          allowed: hasPermission(DART_PERMISSIONS.CUSTOMER_COMPANIES_VIEW_LIST),
          fetchItems: dartApi.customerCompany.getCustomerCompanies,
          itemToEntity: ({ id, name, serviceType, region }) => ({
            id,
            name,
            description: capitalizeFirst(serviceType),
            avatarUrl: getOrgFlagUrl(region),
          }),
        }),

        [KNOWN_ENTITY_TYPE.employeeCompany]: makeEntitiesFetcher({
          allowed: hasPermission(DART_PERMISSIONS.EMPLOYEE_COMPANIES_VIEW_LIST),
          fetchItems: dartApi.employeeCompany.getEmployeeCompanies,
          itemToEntity: ({ id, name, region, externalId }) => ({
            id,
            name,
            description: externalId,
            avatarUrl: getOrgFlagUrl(region),
          }),
        }),

        [KNOWN_ENTITY_TYPE.group]: makeEntitiesFetcher({
          allowed: hasPermission(IDAVE_PERMISSIONS.GROUPS_VIEW_LIST),
          fetchItems: idaveApi.groups.groups,
          itemToEntity: ({ id, name, type }) => ({ id, name, description: type }),
        }),

        [KNOWN_ENTITY_TYPE.permission]: makePermissionsFetcher(
          hasPermission(IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST),
          hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
        ),

        [KNOWN_ENTITY_TYPE.role]: makeEntitiesFetcher({
          allowed: hasPermission(IDAVE_PERMISSIONS.ROLES_VIEW_LIST),
          fetchItems: idaveApi.roles.getRolesMVP,
          itemToEntity: ({ id, name }) => ({ id, name }),
        }),
      },
    }),
    [hasPermission, hasEveryPermission, fetchUsers, fetchUsersWithFallback],
  )
}
