import { createContext, useContext, useMemo } from 'react'
import { Data } from '../../../lib/types'

type TXChecksDataOuterContext = {
  userIdData: Data<string>
}

type TXChecksDataContext = TXChecksDataOuterContext

export const DEFAULT_DATA = {
  userIdData: { status: 'loading' as const },
  sources: [],
}

export const XChecksDataContext = createContext<TXChecksDataContext>(DEFAULT_DATA)

export const XChecksDataProvider = ({
  children,
  ...outerParams
}: { children: React.ReactNode } & TXChecksDataOuterContext) => {
  const { userIdData } = outerParams

  const value = useMemo(
    () => ({
      userIdData,
    }),
    [userIdData],
  )
  return (
    <XChecksDataContext.Provider value={value}>{children}</XChecksDataContext.Provider>
  )
}

export const useXChecksData = () => useContext(XChecksDataContext)
