import { useCallback, useMemo, useState } from 'react'
import { SelectInput } from '@revolut/ui-kit'
import { EntityData, FetchEntities } from 'view/XChecks/XCheck/lib/hooks'
import { EntityParam } from 'api/types/xChecks'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { InputProps } from '../../../../../type'
import { useEntityFetcher } from '../../../../../hooks'
import { EntityIdInput } from '../EntityIdInput'
import {
  dataToParam,
  getDefaultValue,
  getOptions,
  getStandaloneLabel,
  getStandalonePath,
  makeEntityDataOptions,
  makeRenderPrefix,
  useStandaloneValidate,
} from '../../../../../utils'
import { FieldSelect } from '../../../../../../Fields'

type Props = InputProps<EntityParam> & { fetcher: FetchEntities }

export const EntitySelect = (props: Props) => {
  const { param, fetcher } = props
  const result = useEntityFetcher(fetcher, param.value.type.entityType)
  const label = getStandaloneLabel(param)

  if (result.status === 'error') {
    return <EntityIdInput {...props} />
  }

  if (result.status === 'loading') {
    return <SelectInput pending options={[]} disabled label={label} />
  }

  return <Inner data={result.data} label={label} {...props} />
}

type InnerProps = InputProps<EntityParam> & {
  data: EntityData[]
  label: string
  defaultValue?: string
}

const Inner = ({ data, label, param, index, prefix }: InnerProps) => {
  const options = useMemo(() => getOptions(data), [data])
  const required = !!param.value?.editConfig?.required
  const defaultValue = useMemo(
    () => getDefaultValue(options, param.value),
    [options, param],
  )

  const validate = useStandaloneValidate(param)

  const [localValue, setLocalValue] = useState<Nullable<EntityData>>(defaultValue)

  const renderOption = useMemo(() => makeEntityDataOptions(data), [data])
  const renderPrefix = useMemo(
    () => makeRenderPrefix(data, localValue),
    [data, localValue],
  )
  const setValueAs = useCallback(
    (value: Nullable<EntityData>) => dataToParam(value, param.value.type.entityType),
    [param],
  )

  return (
    <FieldSelect
      path={getStandalonePath(prefix, index)}
      label={label}
      setValueAs={setValueAs}
      defaultValue={defaultValue}
      options={options}
      clearable={!required}
      validate={validate}
      onChange={setLocalValue}
      renderOption={renderOption}
      renderPrefix={renderPrefix}
    />
  )
}
