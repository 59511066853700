import { EntityComponentType } from 'view/XChecks/XCheck/lib/types'
import { Link } from '@revolut/ui-kit'
import { getContextTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { Entity } from 'view/XChecks/XCheck/lib/hooks'
import { getEntityUrl } from '../utils'

export const DefaultEntity: EntityComponentType = (props) => {
  const { descriptor, context } = props
  const href = getEntityUrl(descriptor)
  const contextTextProps = getContextTextStyle(context)

  if (href) {
    return (
      <Link href={href} target="_blank" {...contextTextProps}>
        {descriptor.name}
      </Link>
    )
  }

  return <Entity {...props} />
}
