import { abbreviate, AvatarBar, AvatarSize, TextProps } from '@revolut/ui-kit'
import { ClientScopeLink } from 'components/Links/ClientLink'
import { UserLink } from 'components/Links/UserLink'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { getAvatarUrl } from 'utils/url/avatarUrl'
import { useQueryMappedEmployeeIdAvatars } from 'queries/sam/users'
import { useQueryUserIdMap } from 'queries/idave/users'
import { getName } from 'utils/string/getName'
import { match } from 'ts-pattern'
import { PrincipalComponentType, PrincipalContext } from '../lib/types'

export const Principal: PrincipalComponentType = (props) =>
  match(props)
    .with(
      {
        principal: { type: 'APPLICATION' },
        context: PrincipalContext.reviewGroupAvatarBar,
      },
      ({ principal }) => <AvatarBar.Item uuid={principal.id} useIcon="Apps" />,
    )
    .with(
      {
        principal: { type: 'EMPLOYEE' },
        context: PrincipalContext.reviewGroupAvatarBar,
      },
      ({ principal }) => <UserAvatarItem userId={principal.id} />,
    )
    .with({ principal: { type: 'APPLICATION' } }, ({ principal }) => (
      <ClientScopeLink scope={principal.id} />
    ))
    .with({ principal: { type: 'EMPLOYEE' } }, ({ principal, context }) => (
      <UserLink id={principal.id} {...getPrincipalContextView(context)} />
    ))
    .exhaustive()

const REQUESTER_VIEW: PrincipalViewParams = {
  textProps: { fontSize: 'body1' },
  isReversed: true,
}
const LOG_VIEW: PrincipalViewParams = {
  textProps: { fontSize: 'caption' },
  avatarSize: 20,
  isReversed: true,
}

const REVIEW_GROUP_VIEW: PrincipalViewParams = {
  textProps: { fontSize: 'primary' },
  avatarSize: 32,
  isReversed: false,
}

type PrincipalViewParams = {
  isReversed?: boolean
  textProps?: TextProps
  avatarSize?: AvatarSize
}

const getPrincipalContextView = (
  context: PrincipalContext,
): PrincipalViewParams | undefined => {
  switch (context) {
    case 'requester':
      return REQUESTER_VIEW
    case 'log':
      return LOG_VIEW
    case 'reviewGroup':
      return REVIEW_GROUP_VIEW
    default:
      return undefined
  }
}

const UserAvatarItem = ({ userId }: { userId: string }) => {
  const { data: avatars } = useQueryMappedEmployeeIdAvatars()
  const { data: users } = useQueryUserIdMap()

  const user = users?.get(userId)
  const avatar = getAvatarUrl({ user, type: 'imageMedium', avatars })
  const label = abbreviate(user ? getName(user) : userId)
  return (
    <AvatarBar.Item
      {...getAvatarProps({
        label,
        id: userId,
        avatar,
      })}
    />
  )
}
