import { Button, Page, VStack } from '@revolut/ui-kit'
import React, { useCallback } from 'react'
import { Param, XCheckDetails } from 'api/types/xChecks'
import { Header } from 'view/XChecks/XCheck/lib/components/Header'
import { useForm, useMutateParams } from './hooks'
import { ParamInput } from './components/ParamInput/ParamInput'
import { FormProvider } from './components/FormProvider'

export const EditForm = ({
  xCheck,
  params,
  onBackClick,
}: {
  params: Param[]
  xCheck: XCheckDetails
  onBackClick: () => void
}) => {
  const { mutate, isLoading } = useMutateParams({ xCheck })
  const { formState, updateForm, errors, setError, dirty, hasErrors } = useForm({
    params,
  })

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      mutate(formState.params)
    },
    [formState, mutate],
  )

  return (
    <>
      <Header onBackClick={onBackClick} />
      <FormProvider updateForm={updateForm} errors={errors} setError={setError}>
        <Page.Main size="narrow" use="form" onSubmit={onSubmit}>
          <VStack gap="s-12">
            {params.map((param, idx) => (
              <ParamInput param={param} index={idx} key={idx} prefix="params" />
            ))}
          </VStack>

          <Page.MainActions>
            <Button
              type="submit"
              elevated
              pending={isLoading}
              disabled={isLoading || !dirty || hasErrors}
            >
              Update
            </Button>
          </Page.MainActions>
        </Page.Main>
      </FormProvider>
    </>
  )
}
