import { notNullableMap } from 'utils/array'
import { MutableRefObject } from 'react'
import { ResourceSelectRow } from './types'

export const getDuration = (
  row: ResourceSelectRow,
  durationsRef: MutableRefObject<Record<string, number>>,
) => {
  return durationsRef.current?.[row.id] || row.maximalDuration
}

export const setDuration = (
  id: string,
  value: number,
  durationsRef: MutableRefObject<Record<string, number>>,
) => {
  const oldValue = durationsRef.current
  const newValue = { [id]: value }
  durationsRef.current = oldValue ? { ...oldValue, ...newValue } : newValue
}

export const getResources = (
  rows: ResourceSelectRow[],
  durationsRef: MutableRefObject<Record<string, number>>,
) => {
  return notNullableMap(rows, (row) =>
    row.available
      ? {
          resourceId: row.id,
          daysRequested: getDuration(row, durationsRef),
        }
      : undefined,
  )
}
