import { Permission } from 'api/idave/permissions'
import { ToxicPair } from 'api/idave/toxic'
import { UserListItem } from 'api/idave/user'
import { ToxicPairRow } from './types'

type Params = {
  toxicPairs: ToxicPair[]
  permissionMap: Map<string, Permission>
  userMap?: Map<string, UserListItem>
}

export const getRows = ({ toxicPairs, permissionMap, userMap }: Params): ToxicPairRow[] =>
  toxicPairs.map((pair) => {
    const firstPermission = permissionMap.get(pair.firstPermission)
    const secondPermission = permissionMap.get(pair.secondPermission)

    const user = userMap?.get(pair.owner)

    return {
      name: pair.name,
      firstPermission,
      secondPermission,
      firstPermissionId: pair.firstPermission,
      secondPermissionId: pair.secondPermission,
      id: pair.id,
      justification: pair.justification,
      owner: pair.owner,
      ownerUser: user,
      reviewTS: pair.reviewDate,
      state: pair.state,
      createdDate: pair.createdDate,
      deletedDate: pair.deletedDate,
    }
  })
