import { SelectItemFilter } from 'components/SelectItemFilter'
import { UserTableCell } from 'components/UserTableCell'
import { TableColumn } from '@revolut/ui-kit'
import { CustomerCompanyItem } from 'api/dart/customerCompanies'
import { DirectViewTable } from './types'
import { mapLimitationTypeToLabel, stringifyLimitationObject } from './utils'

export const getColumns = (params: {
  customerCompanyMap: Map<string, CustomerCompanyItem>
}): TableColumn<DirectViewTable>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    Cell: (cellParams: { row: { original: DirectViewTable } }) => {
      const { employeeId } = cellParams.row.original
      return <UserTableCell userId={employeeId} />
    },
    width: 200,
  },
  {
    Header: 'Email',
    accessor: 'email',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 200,
  },
  {
    Header: 'Limitation',
    accessor: (value) => mapLimitationTypeToLabel(value.type),
    id: 'type',
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 200,
  },
  {
    Header: 'Subject',
    accessor: (value) => stringifyLimitationObject(value, params.customerCompanyMap),
    filter: 'includesValue',
    Filter: SelectItemFilter,
    width: 300,
  },
]
