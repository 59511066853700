import { VStack } from '@revolut/ui-kit'
import { useEffect, useMemo } from 'react'
import { GroupType } from 'api/idave/groups'
import { FieldInput } from 'components/fields/FieldInput'
import { FieldTextArea } from 'components/fields/FieldTextArea'
import { FieldSelectUser } from 'components/fields/FieldSelectUser'
import { FieldRoleSelect } from 'components/fields/FieldRoleSelect'
import { FieldGroupSelect } from 'components/fields/FieldGroupSelect'
import { RULE_REQUIRED } from 'utils/form/rules'
import { useFormContext } from 'react-hook-form'
import { useGroupOwnerId } from 'hooks/useGroupOwnerId'
import { traverseRoleChildren } from 'utils/role/traverseRolesChildren'
import { Role } from 'api/idave/roles'

const GROUP_TYPES: GroupType[] = ['peopleops:team', 'perfectionist']

export type RoleEditFormProps = {
  isDisabled?: boolean
  roleId?: string
  roles: Role[]
}

export type RoleEditFormState = {
  name: string
  parent?: string
  description: string
  ownerGroupId: string
  approverId?: string
}

export const RoleEditForm = ({ isDisabled, roleId, roles }: RoleEditFormProps) => {
  const disabledRoles = useMemo(
    () => (roleId ? [roleId, ...traverseRoleChildren({ roleId, roles })] : undefined),
    [roleId, roles],
  )

  const getGroupOwnerId = useGroupOwnerId()

  const { watch, setValue, formState } = useFormContext()
  const ownerGroupId = watch('ownerGroupId')
  useEffect(() => {
    // defaultValues check is to prevent calling setValue on unrelated deps update
    if (ownerGroupId && ownerGroupId !== formState.defaultValues?.ownerGroupId) {
      const groupOwnerId = getGroupOwnerId(ownerGroupId)
      if (groupOwnerId) {
        setValue('approverId', groupOwnerId)
      }
    }
  }, [formState.defaultValues?.ownerGroupId, getGroupOwnerId, ownerGroupId, setValue])

  return (
    <VStack space="s-24">
      <FieldInput
        name="name"
        label="Name"
        disabled={isDisabled}
        autoFocus
        maxLength={50}
        rules={RULE_REQUIRED}
      />
      <FieldTextArea
        name="description"
        label="Description"
        disabled={isDisabled}
        rules={RULE_REQUIRED}
      />
      <FieldGroupSelect
        name="ownerGroupId"
        label="Role Owner Group"
        disabled={isDisabled}
        useGroupTypes={GROUP_TYPES}
        rules={RULE_REQUIRED}
      />
      <FieldSelectUser
        name="approverId"
        label="Approver (Optional)"
        disabled={isDisabled}
        onlyActive
        clearable
      />
      <FieldRoleSelect
        name="parent"
        label="Role parent (Optional)"
        disabled={isDisabled}
        excludeRoles={disabledRoles}
        clearable
      />
    </VStack>
  )
}
