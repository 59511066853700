import { CustomerCompany } from 'api/dart/customerCompanies'
import { EmployeeCompany } from 'api/dart/types'
import { mapify } from 'utils/array'

export const getRows = (
  employeeCompanies: EmployeeCompany[] = [],
  customerCompany?: CustomerCompany,
) => {
  const allowedCompanies = customerCompany?.allowedCompanies || []
  const allowedCompanyMap = mapify(allowedCompanies, (a) => a.id)
  const allowedCompanyIds = allowedCompanies.map((i) => i.id)

  const employeeCompanyMap = mapify(employeeCompanies, (e) => e.id)
  const employeeCompanyIds = employeeCompanies.map((i) => i.id)

  /**
   * Removed company can still be in allowed list, so showing them as id
   */
  const companiesIds = new Set(allowedCompanyIds.concat(employeeCompanyIds))

  return [...companiesIds].map((companyId) => ({
    id: companyId,
    name: employeeCompanyMap.get(companyId)?.name || companyId,
    region: employeeCompanyMap.get(companyId)?.region,
    accessModifierId: allowedCompanyMap.get(companyId)?.allowedAccessModifier.id,
  }))
}
