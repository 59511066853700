import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'api/sam'
import { useToasts } from 'hooks/useToasts'
import { useStorageItem } from 'hooks/useStorageItem'
import { useNavigate } from 'react-router'
import { QueryKey } from 'helpers/configQuery'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { SAM_PERMISSIONS } from 'security'
import { useCallback } from 'react'
import { SamCreatePolicyPayload } from 'api/sam/policies'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { mapPolicyDetailsSubjectPolicySubject } from './utils'
import { FormState } from './types'

export const useCreatePolicy = (storageName?: string) => {
  const { showSuccessToast } = useToasts()
  const { clearItem } = useStorageItem<FormState>(storageName)
  const navigate = useNavigate()
  const { toCrosscheck, isDirect } = useCrossCheck(SAM_PERMISSIONS.POLICIES_CREATE, [
    SAM_PERMISSIONS.POLICIES_CREATE_WITH_CROSS_CHECK,
    SAM_PERMISSIONS.POLICIES_CREATE_MULTI_SUBJECT_WITH_CROSS_CHECK,
  ])
  const queryClient = useQueryClient()

  const createMutation = useMutation({
    mutationFn: api.policies.createPolicy,
    onSuccess: (response) => {
      clearItem()
      showSuccessToast('Policy created')
      queryClient.refetchQueries([QueryKey.SamPolicies])
      navigate(generatePath(Url.SamPolicy, { policyId: response.data.policyId }))
    },
  })

  const crossCheckMutation = useMutation({
    mutationFn: api.policies.crossCheckCreatePolicy,
    onSuccess: (response) => {
      clearItem()
      const crosscheck = getCrossCheckDescriptor(response)
      const data = response.data
      const crossCheckDescriptor = {
        id: data.crossCheckId || crosscheck.id,
        version: crosscheck.version,
      }
      toCrosscheck(crossCheckDescriptor)
    },
  })

  const mutate = useCallback(
    (payload: SamCreatePolicyPayload) => {
      isDirect ? createMutation.mutate(payload) : crossCheckMutation.mutate(payload)
    },
    [createMutation, crossCheckMutation, isDirect],
  )

  const isCreating = isDirect ? createMutation.isLoading : crossCheckMutation.isLoading

  return {
    isCreating,
    create: (policy: FormState) => {
      const { policyDetails, resources, mandatoryTrainings } = policy
      const subject = mapPolicyDetailsSubjectPolicySubject(policyDetails.subject)
      if (subject) {
        mutate({
          resourceIds: resources,
          mandatoryTrainings: mandatoryTrainings?.length ? mandatoryTrainings : null,
          policyName: policyDetails.policyName,
          businessReason: policyDetails.businessReason,
          subject,
        })
      }
    },
  }
}
