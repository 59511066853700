import { sortBy } from 'lodash'

export const SLICE_AMOUNT = 100
export const getEvents = <T extends { createdDate: number }>({
  items,
  sliceLast,
}: {
  items: T[]
  sliceLast: boolean
}) => {
  if (!items) {
    return { items: [], isSliced: false }
  }

  const sorted = sortBy(items, (i) => -i.createdDate)
  const sliced = sliceLast ? sorted.slice(0, SLICE_AMOUNT) : sorted

  return {
    items: sliced,
    isSliced: items.length > SLICE_AMOUNT,
  }
}
