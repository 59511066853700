import { TableColumn, Action, Table } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { SamPolicy } from 'api/sam/policies'
import { stringifySubjectType } from 'view/Sam/utils'
import { WithId } from 'utils/types/withId'
import {
  POLICY_UPDATE_PERMISSIONS,
  useRemoveRoleFromPolicy,
} from './useRemoveRoleFromPolicy'

const BASE_COLUMNS: TableColumn<WithId<SamPolicy>>[] = [
  {
    Header: 'Policy name',
    accessor: 'policyName',
  },
  {
    Header: 'Subject type',
    accessor: (value) => stringifySubjectType(value.subject.subjectType),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]

export const useColumns = (roleId: string) => {
  const { hasSomePermissions } = usePermissions()

  const removeRoleFromPolicy = useRemoveRoleFromPolicy(roleId)

  if (
    hasSomePermissions(
      POLICY_UPDATE_PERMISSIONS.actionPermission,
      ...POLICY_UPDATE_PERMISSIONS.xCheckPermission,
    )
  ) {
    return [
      ...BASE_COLUMNS,
      {
        Header: 'Action',
        id: 'action',
        Cell: ({ row }: { row: { original: SamPolicy } }) => (
          <Table.Cell>
            <Action
              variant="text"
              onClick={(e) => {
                e.stopPropagation()
                removeRoleFromPolicy(row.original)
              }}
            >
              Remove
            </Action>
          </Table.Cell>
        ),
      },
    ]
  }

  return BASE_COLUMNS
}
