import { MoreBar } from '@revolut/ui-kit'
import { EntitiesTable } from 'components/EntitiesTable'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { useCallback, useMemo } from 'react'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { SAM_PERMISSIONS } from 'security'
import { useResourcePreview } from 'components/previews/ResourcePreview'
import { ResourceAccess } from 'api/sam/types'
import { useColumns } from './columns'
import { getResourceAccesses } from './utils'
import { Row } from './types'
import { EmptyUserResources } from '../EmptyUserResources'
import { NavigateToRequests } from '../NavigateToRequests'

type Props = {
  accesses: ResourceAccess[]
  idaveUserId: string
  onRequestResourceClick: () => void
}
export const ResourcesList = ({
  accesses,
  idaveUserId,
  onRequestResourceClick,
}: Props) => {
  const entities = useMemo(() => getResourceAccesses(accesses), [accesses])

  const { searchValue, searched, setSearchValue } = useSearchFilter({
    entities,
  })
  const renderActions = useCallback(
    () => (
      <>
        <PermissionsCheck permission={SAM_PERMISSIONS.TEMPORARY_RESOURCE_ACCESS_CREATE}>
          <MoreBar.Action useIcon="Plus" onClick={onRequestResourceClick}>
            Request Resource Access
          </MoreBar.Action>
        </PermissionsCheck>
        <PermissionsCheck permission={SAM_PERMISSIONS.CROSS_CHECKS_VIEW_LIST}>
          <NavigateToRequests idaveUserId={idaveUserId} variant="bar" />
        </PermissionsCheck>
      </>
    ),
    [idaveUserId, onRequestResourceClick],
  )

  const { openResourceSide } = useResourcePreview()
  const onRowClick = useCallback(
    (row: Row) => openResourceSide(row.resource.resourceId, row.access.crossCheckId),
    [openResourceSide],
  )

  const columns = useColumns(entities, idaveUserId)

  return !entities.length ? (
    <EmptyUserResources
      idaveUserId={idaveUserId}
      onRequestClick={onRequestResourceClick}
      requestAllowed
    />
  ) : (
    <EntitiesTable
      entitiesTypeLabel="Resources"
      pluralForms={['resource', 'resources']}
      totalCount={entities.length}
      data={searched}
      columns={columns}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      searchAutoFocus
      renderActions={renderActions}
      onRowClick={onRowClick}
    />
  )
}
