import { notNullableMap } from './notNullableMap'

export const mapify = <Entity>(
  entities: Entity[] = [],
  matcher: (entity: Entity) => string | undefined,
) => {
  return new Map(
    notNullableMap(entities, (e) => {
      const key = matcher(e)

      return key ? [key, e] : undefined
    }),
  )
}
