import { GroupReviewRule } from 'api/types/xChecks'
import { Side } from '../../hooks/useXCheckSide/types'

export const getGroupRuleSide = (params: {
  groupRule?: GroupReviewRule
  setSideView: (side?: Side) => void
}) => {
  const { groupRule: group, setSideView } = params
  if (group) {
    return {
      type: 'reviewGroup' as const,
      group,
      onClose: () => setSideView(undefined),
      title: group.name,
      description: 'Review group',
      iconName: 'People' as const,
    }
  }

  return undefined
}
