import { MutableRefObject, useCallback } from 'react'
import { Action, Table } from '@revolut/ui-kit'
import { stopPropagation } from 'view/XChecks/XCheck/lib'
import { Row } from '../types'
import { getDuration } from '../../../utils'
import { useDurationPopup } from './DurationPopup'

export const useEditDurationColumn = (
  durationsRef: MutableRefObject<Record<string, number>>,
) => {
  const Cell = (params: { row: { original: Row } }) => {
    const row = params.row.original

    return row.isDisabled ? (
      <Table.EmptyCell />
    ) : (
      <EditDuration row={row} durationsRef={durationsRef} />
    )
  }

  return {
    Header: 'Duration (days)',
    id: 'duration',
    align: 'center' as const,
    Cell,
  }
}

type Props = {
  row: Row
  durationsRef: MutableRefObject<Record<string, number>>
}

const EditDuration = ({ row, durationsRef }: Props) => {
  const openPopup = useDurationPopup({ row, durationsRef })
  const duration = getDuration(row, durationsRef)
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      stopPropagation(event)
      openPopup()
    },
    [openPopup],
  )

  return (
    <Table.Cell align="center">
      <Action useEndIcon="Pencil" onClick={onClick}>
        {duration}
      </Action>
    </Table.Cell>
  )
}
