import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { SelectOptionItemType, Text, Token, VStack } from '@revolut/ui-kit'
import { EntityParam, Value } from 'api/types/xChecks'
import { notNullableMap } from 'utils/array'
import { Nullable } from 'view/XChecks/XCheck/lib/types'

export const renderOption = (option: SelectOptionItemType<string>) => (
  <VStack gap="s-2">
    <Text>{option.label}</Text>
    <Text variant="caption" color={Token.color.neutral}>
      {option.value}
    </Text>
  </VStack>
)

export const makeValueToEntity =
  (entities: Value<EntityParam>[]) => (value: Nullable<string>) => {
    return value
      ? entities.find((entity) => getEntityDescriptor(entity).id === value)?.value
      : undefined
  }

export const makeEntityOptions = (
  entities: Value<EntityParam>[],
): SelectOptionItemType<string>[] =>
  notNullableMap<Value<EntityParam>, SelectOptionItemType<string>>(entities, (item) => {
    const descriptor = getEntityDescriptor(item)

    if (!descriptor.id) {
      return undefined
    }

    return {
      key: descriptor.id,
      label: descriptor.name,
      value: descriptor.id,
      keywords: [descriptor.id, descriptor.name],
    }
  })
