import { Skeleton, VStack } from '@revolut/ui-kit'

export const SamPolicyEditSkeleton = () => {
  return (
    <VStack space="s-24" pt="s-24">
      <Skeleton height="56px" />
      <Skeleton height="56px" />
      <Skeleton height="56px" />
      <Skeleton height="56px" />
      <Skeleton height="56px" />
    </VStack>
  )
}
