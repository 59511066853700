import { useCallback } from 'react'
import { isUuid as checkUuid } from 'utils/string/isUuid'
import { notNullable } from 'utils/common'
import { format } from 'date-fns'
import { Value, EntityParam, Param } from 'api/types/xChecks'
import { match, P } from 'ts-pattern'
import { EntityWellKnownAttrs } from 'view/XChecks/XCheck/lib/consts'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { getEntityDescriptor, paramMatcher } from 'view/XChecks/XCheck/lib/utils'
import { EntityData } from 'view/XChecks/XCheck/lib/hooks'
import {
  Avatar,
  HStack,
  SelectOptionItemType,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { FieldValue } from '../../../../types'
import { StandaloneParam } from './type'

export const getStandaloneLabel = (param: StandaloneParam) => {
  const required = !!param.value?.editConfig?.required
  return `${param.name}${!required ? ' (Optional)' : ''}`
}

export const getStandalonePath = (prefix: string, index: number) =>
  `${prefix}.${index}.value.value`

export const useStandaloneValidate = (param: StandaloneParam) => {
  const required = !!param.value?.editConfig?.required

  return useCallback(
    (value: FieldValue) => {
      if (required && !notNullable(value)) {
        return 'Required value'
      }

      const isUuid = param.value.type.type === 'uuid'
      if (value && isUuid && (typeof value !== 'string' || !checkUuid(value))) {
        return 'Value should contain UUID'
      }

      return undefined
    },
    [required, param],
  )
}

const HTML_TIMESTAMP_VALUE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS"
export const timestampToDatetimeLocalWithMs = (timestamp?: number) =>
  notNullable(timestamp) ? format(timestamp, HTML_TIMESTAMP_VALUE_FORMAT) : undefined

const createTextParam = (name: string, value: string) => ({
  name,
  value: {
    type: { type: 'text' as const },
    value,
  },
})

export const unknownEntityUpdate =
  (initialValue: Value<EntityParam>) =>
  (value: Nullable<string>): Param[] | undefined => {
    const entityType = initialValue.type.entityType
    const attrs = initialValue.value
    const idValue = value || ''

    return (
      match({ idValue, attrs })
        // Delete
        .with({ idValue: '' }, () => undefined)
        // Create
        .with({ attrs: P.nullish }, ({ idValue: id }) => [
          createTextParam(EntityWellKnownAttrs.id, id),
          createTextParam(EntityWellKnownAttrs.entityType, entityType),
          createTextParam(EntityWellKnownAttrs.name, id),
        ])
        // Update: keep all params the same except name + id the same
        .otherwise(({ attrs: oldAttrs = [], idValue: id }) =>
          oldAttrs.reduce<Param[]>(
            (acc, param) =>
              match(param)
                .with(paramMatcher(EntityWellKnownAttrs.id, 'text'), () => [
                  ...acc,
                  createTextParam(EntityWellKnownAttrs.id, id),
                ])
                .with(paramMatcher(EntityWellKnownAttrs.name, 'text'), () => [
                  ...acc,
                  createTextParam(EntityWellKnownAttrs.name, id),
                ])
                .otherwise(() => [...acc, param]),
            [],
          ),
        )
    )
  }

export const dataToParam = (
  item: Nullable<EntityData>,
  entityType: string,
): Param[] | undefined => {
  if (!item) {
    return undefined
  }

  return [
    {
      name: 'id',
      value: {
        type: { type: 'text' as const },
        value: item.id,
      },
    },
    {
      name: 'entityType',
      value: { type: { type: 'text' as const }, value: entityType },
    },
    { name: 'name', value: { type: { type: 'text' as const }, value: item.name } },
  ]
}

export const getOptions = (items: EntityData[]): SelectOptionItemType<EntityData>[] =>
  items.map((item) => ({
    key: item.id,
    label: item.name,
    value: item,
    keywords: [item.id, item.name, item.description].filter(notNullable),
  }))

export const getDefaultValue = (
  options: SelectOptionItemType<EntityData>[],
  entity: Value<EntityParam>,
) => {
  const id = getEntityDescriptor(entity).id
  return id ? options.find((item) => item.key === id)?.value : undefined
}

export const makeRenderPrefix = (
  data: EntityData[],
  currentValue?: null | EntityData,
) => {
  const withAvatars = currentValue && data.some((item) => !!item.avatarUrl)
  return () => {
    const avatarProps = currentValue?.avatarUrl
      ? { image: currentValue.avatarUrl }
      : { useIcon: 'Image' as const }
    return withAvatars ? <Avatar variant="default" {...avatarProps} /> : null
  }
}

export const makeEntityDataOptions = (data: EntityData[]) => {
  const withAvatars = data.some((item) => !!item.avatarUrl)
  return (option: SelectOptionItemType<EntityData>) => {
    const avatarProps = option.value.avatarUrl
      ? { image: option.value.avatarUrl }
      : { useIcon: 'Image' as const }
    return (
      <HStack gap="s-8">
        {withAvatars && <Avatar variant="default" {...avatarProps} />}
        <VStack gap="s-2">
          <Text>{option.label}</Text>
          <Text variant="caption" color={Token.color.neutral}>
            {option.value.description || option.value.id}
          </Text>
        </VStack>
      </HStack>
    )
  }
}
