import { Value, Param } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { getEntityDescriptor } from '../../../utils'

export const isComplexEntity = (item: Value<Param>) =>
  match(item)
    .with(
      { type: { type: 'entity' } },
      (entity) => getEntityDescriptor(entity).isComplexEntity,
    )
    .otherwise(() => false)
