import { textChain } from '@revolut/ui-kit'
import { format } from 'date-fns'

export const DATE_FORMAT = 'yyyy-MM-dd'
export const LONG_DATE_FORMAT = 'dd MMMM yyy'
export const LONG_DATE_TIME_FORMAT = 'dd MMMM yyy HH:mm'
export const DATE_TIME_FORMAT = textChain(DATE_FORMAT, 'HH:mm')
export const TIME_PRECISE_FORMAT = 'HH:mm:ss'
export const DATE_TIME_PRECISE_FORMAT = textChain(DATE_FORMAT, TIME_PRECISE_FORMAT)

const makeFormatter = (formatSchema: string) => (date: Date | number) =>
  format(date, formatSchema)

export const formatDate = makeFormatter(DATE_FORMAT)
export const formatLongDate = makeFormatter(LONG_DATE_FORMAT)
export const formatLongDateTime = makeFormatter(LONG_DATE_TIME_FORMAT)
export const formatDateTime = makeFormatter(DATE_TIME_FORMAT)
export const formatTimePrecise = makeFormatter(TIME_PRECISE_FORMAT)
export const formatDateTimePrecise = makeFormatter(DATE_TIME_PRECISE_FORMAT)
