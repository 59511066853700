import { CreatedEntity as TCreatedEntity } from 'api/types/xChecks'
import { entityAttrsToDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { Text, Token } from '@revolut/ui-kit'
import { EntityContext } from '../../../../../common/EntityContext'

type Props = {
  entity: TCreatedEntity
  defaultExpanded?: boolean
}
export const CreatedEntity = ({ entity, defaultExpanded }: Props) => {
  const descriptor = entityAttrsToDescriptor(entity)

  return (
    <EntityContext
      entity={{
        type: { type: 'entity', entityType: descriptor.entityType },
        value: entity,
      }}
      defaultExpanded={defaultExpanded}
      descriptionLabel={<Text color={Token.color.success}>Created</Text>}
    />
  )
}
