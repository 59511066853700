import { createContext, useContext, useMemo, useState } from 'react'
import { Side } from './types'
import { noop } from '../utils'

type XCheckSideViewContextType = {
  side?: Side
  setSide: (side: Side | undefined) => void
}

export const XCheckSideViewContext = createContext<XCheckSideViewContextType>({
  side: undefined,
  setSide: noop,
})

export const XCheckSideViewContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [side, setSide] = useState<Side>()
  const value = useMemo(() => ({ side, setSide }), [side, setSide])

  return (
    <XCheckSideViewContext.Provider value={value}>
      {children}
    </XCheckSideViewContext.Provider>
  )
}
export const useXCheckSide = () => useContext(XCheckSideViewContext)
