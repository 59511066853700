import { Token } from '@revolut/ui-kit'

import styled from 'styled-components'
import { isXMasDates } from 'utils/common/getAvatarProps/utils'

const Hat = styled.div`
  @media screen and (max-width: ${Token.breakpoint.md}) {
    display: none;
  }
  width: 40px;
  height: 40px;
  position: fixed;
  background-color: red;
  top: 3px;
  right: 27px;
  z-index: 1;
  transform: rotate(12deg);
  background: center no-repeat url('${process.env.PUBLIC_URL}/img/santa_hat.svg');
  pointer-events: none;
`

export const SantaHat = () => {
  if (!isXMasDates()) {
    return null
  }
  return <Hat />
}
