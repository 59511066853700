import { FormMultiStep } from 'components/FormMultiStep'
import { generatePath, useNavigate } from 'react-router'
import { useCallback } from 'react'
import { Url } from 'routing'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import idaveApi from 'api/idave'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useToasts } from 'hooks/useToasts'
import { QueryKey } from 'helpers/configQuery'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { GroupFormValues } from './types'
import { TABS } from './constants'
import { Details } from './components/Details'
import { Users } from './components/Users'
import { generateCode, tabToPageSize } from './utils'

export const GroupAdd = () => {
  const navigate = useNavigate()

  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showSuccessToast } = useToasts()

  const queryClient = useQueryClient()

  const onCloseClick = useCallback(() => navigate(Url.SystemGroups), [navigate])

  const { mutate } = useMutation({
    mutationFn: idaveApi.groups.addGroup,
    onMutate: () => showLoadingPopup({ title: 'Adding...' }),
    onSuccess: (response) => {
      hideLoadingPopup()
      queryClient.resetQueries({ queryKey: [QueryKey.Groups] })
      showSuccessToast('Group added')
      navigate(generatePath(Url.SystemGroupDetails, { groupId: response.data.id }))
    },
    onError: (err) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Adding a group failed',
        error: err,
      })
    },
  })

  const groupForm = useForm<GroupFormValues>({ reValidateMode: 'onBlur', mode: 'all' })

  const onSubmit = useCallback(() => {
    const { ownerId, ...rest } = groupForm.getValues()
    return mutate({
      ...rest,
      ownerIds: [ownerId],
      type: 'idave:team',
      code: generateCode(rest.name),
    })
  }, [groupForm, mutate])

  const checkTabValid = useCallback(
    () => groupForm.formState.isValid,
    [groupForm.formState.isValid],
  )

  return (
    <FormProvider {...groupForm}>
      <FormMultiStep
        onCloseClick={onCloseClick}
        title="Add group"
        tabs={TABS}
        onSubmit={onSubmit}
        checkTabValid={checkTabValid}
        checkTabFilled={checkTabValid}
        tabToPageSize={tabToPageSize}
        renderTab={(currentTab) => (
          <>
            {currentTab === 'details' && <Details />}
            {currentTab === 'users' && <Users />}
          </>
        )}
      />
    </FormProvider>
  )
}
