import { Action, Box, Subheader } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { useEditPopup } from 'hooks/useEditPopup'
import { countedItems } from 'utils/string'
import { ClientAddUrlPopup } from './CommonClientAddUrlPopup'
import { ClientUrlList } from './CommonClientUrlList'
import { UrlUpdateParams } from './types'

export const CommonClientUrls = (props: {
  urls: string[]
  updateUrls: (update: UrlUpdateParams) => void
  allowUpdate: boolean
}) => {
  const { urls, updateUrls, allowUpdate } = props

  const { setPopup } = useEditPopup()

  const deleteAll = useCallback(() => updateUrls({ type: 'deleteAll' }), [updateUrls])
  const deleteUrl = useCallback(
    (url: string) => updateUrls({ type: 'delete', deleteUrl: url }),
    [updateUrls],
  )
  const addNewUrl = useCallback(
    (newUrl: string) => updateUrls({ type: 'add', newUrl }),
    [updateUrls],
  )

  const openAddPopup = useCallback(
    () =>
      setPopup({
        title: 'Add URL',
        content: <ClientAddUrlPopup onSubmit={addNewUrl} />,
        isBottomSheet: true,
        headerVariant: 'bottom-sheet',
      }),
    [setPopup, addNewUrl],
  )

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{countedItems('URLs', urls.length)}</Subheader.Title>
        {!!urls.length && allowUpdate && (
          <Subheader.Side>
            <Action onClick={deleteAll}>Delete all</Action>
          </Subheader.Side>
        )}
      </Subheader>
      <ClientUrlList
        allowUpdate={allowUpdate}
        urls={urls}
        onAddClick={openAddPopup}
        deleteUrl={deleteUrl}
      />
    </Box>
  )
}
