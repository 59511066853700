import { AuditLogData } from 'api/types/auditLog'
import { AuditLogEntityParam } from 'api/idave/auditsLog'
import { LogAuthorProps } from 'components/LogAuthor'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const getAuthor = <T extends string>(
  author: AuditLogData<T>['Author'],
): LogAuthorProps => {
  if (author.type === 'string') {
    return { entity: 'unknown', name: author.value.replace(/^client:/, 'application:') }
  }

  const entity = author.value.entity
  const id = author.value.id

  return entity === 'User'
    ? { entity: 'user', id }
    : { name: entity, description: id, entity: 'unknown' }
}

export const getEntityHref = (entityValue: AuditLogEntityParam['value']) => {
  const { entity, id } = entityValue

  switch (entity) {
    case 'Permission':
    case 'OrphanedPermission':
      return generatePath(Url.Permission, { permissionId: id })
    case 'Role':
      return generatePath(Url.Role, { roleId: id })
    case 'User':
      return generatePath(Url.User, { userId: id })
    case 'Client':
      return generatePath(Url.Client, { clientId: id })
    case 'ToxicPermissionCombination':
      return generatePath(Url.ToxicPair, { toxicPairId: id })
    case 'Group':
      return generatePath(Url.SystemGroup, { groupId: id })
    case 'Organisation':
    case 'AuthCode':
    case 'Device':
    case 'MigrationUnitExecution':
    case 'RefreshToken':
    case 'ToxicPermissionAlert':
    case 'UserAccessLogEntry':
    case 'VerifyToken':
    default:
      return undefined
  }
}

export const trimUnknown = (maybeString: unknown) =>
  typeof maybeString === 'string' ? maybeString.trim() : undefined
