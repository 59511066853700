import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { EntityParam } from 'api/types/xChecks'
import { useMemo } from 'react'
import { InputProps } from '../../../type'
import { makeEntityOptions, makeValueToEntity, renderOption } from './utils'
import {
  getStandaloneLabel,
  getStandalonePath,
  useStandaloneValidate,
} from '../../../utils'
import { FieldSelect } from '../../../../Fields'

export const EntityOptions = ({ param, prefix, index }: InputProps<EntityParam>) => {
  const editConfig = param.value.editConfig

  const defaultValue = getEntityDescriptor(param.value).id
  const required = !!editConfig?.required

  const entityOptions = useMemo(() => editConfig?.options || [], [editConfig?.options])
  const options = useMemo(() => makeEntityOptions(entityOptions), [entityOptions])
  const setValueAs = useMemo(() => makeValueToEntity(entityOptions), [entityOptions])
  const validate = useStandaloneValidate(param)

  return (
    <FieldSelect
      path={getStandalonePath(prefix, index)}
      label={getStandaloneLabel(param)}
      defaultValue={defaultValue}
      options={options}
      clearable={!required}
      renderOption={renderOption}
      setValueAs={setValueAs}
      validate={validate}
    />
  )
}
