import { StatusCellLevels } from '@revolut/ui-kit'
import { AccessState, ResourceAccess } from 'api/sam/types'
import { match } from 'ts-pattern'

export const getStatusLevel = (status: AccessState): StatusCellLevels =>
  match(status)
    .with(AccessState.ACTIVE, () => StatusCellLevels.SUCCESS)
    .with(AccessState.EXPIRED, () => StatusCellLevels.ALERT)
    .with(AccessState.REVOKED, () => StatusCellLevels.ALERT)
    .otherwise(() => StatusCellLevels.ALERT)

export const getResourceAccesses = (resourceAccesses: ResourceAccess[] = []) =>
  resourceAccesses.map((item) => ({
    id: `${item.resource.resourceId}_${item.access.crossCheckId}`,
    ...item,
  }))
