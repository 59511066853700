import { EntityToEntityViewFn } from 'view/XChecks/XCheck/lib/types'
import { EntityParam, Value } from 'api/types/xChecks'
import { getEntityDescriptor, isEntityWellKnownAttr } from 'view/XChecks/XCheck/lib/utils'
import { getParamsView } from '../../Params/utils'
import { EntityContextProps } from './types'

export const getEntityContextParams = (
  entity: Value<EntityParam>,
  getEntityView: EntityToEntityViewFn,
) => {
  const params = entity.value?.filter((param) => !isEntityWellKnownAttr(param.name)) || []
  return getParamsView({
    params,
    entityToEntityView: getEntityView,
    isInContext: true,
  })
}

export const isContextEmpty = ({ entity, children }: EntityContextProps) =>
  !children && !getEntityDescriptor(entity).isComplexEntity
