import { TimestampParam } from 'api/types/xChecks'
import { Text } from '@revolut/ui-kit'
import { notNullable } from 'utils/common'
import { formatDateTimePrecise } from 'utils/date'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { getVariantTextStyle } from 'view/XChecks/XCheck/lib/utils'
import { ItemValueProps } from './types'

export const TimeStampValue = ({ value, variant }: ItemValueProps<TimestampParam>) => {
  const timestampValue = value?.value

  return (
    <Text {...getVariantTextStyle(variant)}>
      {notNullable(timestampValue)
        ? formatDateTimePrecise(timestampValue)
        : EMPTY_VALUE_FALLBACK_TEXT}
    </Text>
  )
}
