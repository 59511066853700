import { PageMainSize } from '@revolut/ui-kit'
import { GroupTab } from './types'

export const tabToPageSize = (tab: GroupTab) => {
  switch (tab) {
    case 'users':
      return PageMainSize.FULL
    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
