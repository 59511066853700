import { TableColumn } from '@revolut/ui-kit'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { capitalizeFirst } from 'utils/string'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { isFinished } from 'view/CrossChecks/CrossCheck/components/CrossCheckRules/utils'
import { PermissionRow } from './types'
import { CrossCheckValueTab } from '../../../../types'
import { isComparable } from '../../../ChangeSwitcher/utils'

export const getColumns = (
  status: CrossCheckValueTab,
  crossCheck: CrossCheckDetail,
): TableColumn<PermissionRow>[] =>
  status === 'requested' && isComparable(crossCheck) && !isFinished(crossCheck)
    ? [...BASE_COLUMNS, ...STATE_COLUMNS]
    : BASE_COLUMNS

const BASE_COLUMNS: TableColumn<PermissionRow>[] = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Service',
    accessor: 'client',
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]

const STATE_COLUMNS: TableColumn<PermissionRow>[] = [
  {
    Header: 'Change',
    accessor: ({ status }: PermissionRow) =>
      status ? capitalizeFirst(status) : EMPTY_VALUE_FALLBACK,
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
]
