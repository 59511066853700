import { useFormContext, useController } from 'react-hook-form'

import { DateInput, InputProps } from '@revolut/ui-kit'
import { Fielded } from 'utils/types/fielded'
import { CalendarDateProps } from '@revolut/ui-kit/types/dist/components/Calendar/CalendarDate'

interface DateInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'>,
    Pick<
      CalendarDateProps,
      | 'defaultValue'
      | 'value'
      | 'onChange'
      | 'disabledDays'
      | 'labelNext'
      | 'labelPrev'
      | 'initialMonth'
    > {
  clearable?: boolean
}

export const FieldDateInput = (props: Fielded<DateInputProps>) => {
  const { name, rules, ...rest } = props

  const { control } = useFormContext()
  const { field } = useController({ name, control, rules })

  return <DateInput {...rest} {...field} />
}
