import {
  Source,
  State,
  EntityType,
  ExecutableName,
  ReviewStage,
  XChecksListItem,
  Executable,
  Entity,
} from 'api/types/xChecks'
import qs from 'qs'
import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { defaultApiErrorHandler } from 'view/XChecks/XCheck/lib/hooks/useAPI/utils'
import { ApiMethod } from './types'
import { get } from '../../../../lib/hooks/useAPI/utils'

/**
 * Params for getting crosscheck list
 */
export type ListParams = {
  /**
   * Search term for crosschecks full text search by name.
   * Case insensitive. Minimum 4 symbols
   */
  query?: string
  /**
   * Reviewer iDave ID. Multiple values are supported.
   * Filters crosschecks that have one of these reviewers in any review stage
   */
  reviewer?: string[]
  /**
   * Reviewer iDave ID or application scope name. Multiple values are supported.
   * Filters crosschecks where one of the specified reviewers is in the active review stage but has not yet provided a review.
   */
  currentReviewer?: string | string[]
  /**
   * Requester iDave ID. Multiple values are supported.
   * Filters crosschecks that has been created by one of these requesters.
   */
  requester?: string[]
  /**
   * Executable name. Multiple values are supported.
   * Filters crosschecks that have one of these executable names
   */
  executableName?: ExecutableName[]
  /**
   * Executable ID. Multiple values are supported.
   * Filters crosschecks that have one of these executable id.
   * This might be useful when an executable has been renamed, and now multiple executable names correspond to the same executable ID.
   */
  executableId?: string[]
  /**
   * Entity type. Multiple values are supported.
   * Filters crosschecks that have one of these entity types
   */
  entityType?: EntityType[]
  /**
   * Entity ID. Multiple values are supported.
   * Filters crosschecks that have one of these entity IDs
   */
  entityId?: string[]
  /**
   * XCheck state. Multiple values are supported.
   * Filters crosschecks that have one of these states
   */
  state?: State[]
  /**
   * XCheck source. Multiple values are supported.
   * Filters crosschecks that have one of these sources.
   * By default BE will return XChecks from all supported sources
   */
  source?: Source[]
  /**
   * Active review stage name. Multiple values are supported.
   * Filters crosschecks that have one of these review stages in active stage
   */
  activeReviewStage?: ReviewStage[]
  /**
   * Page size (default 50)
   */
  limit?: number
  /**
   * Page offset (amount x-check entities to skip)
   */
  offset?: number
  /**
   * Order by column (default CREATED_DATE)
   */
  order?: XCheckResultOrder
  /**
   * Order direction (default DESC)
   */
  direction?: XCheckResultDirection
}

type XCheckResultOrder = 'CREATED_DATE' | 'UPDATED_DATE'
type XCheckResultDirection = 'ASC' | 'DESC'

export type EntitiesParams = {
  /** Entity full name. Multiple values are supported. Case sensitive */
  name?: string[]
  /** Search term for entity full text search by name. Case insensitive. Minimum 4 symbols */
  query?: string
  source?: Source[]
  limit?: number
}

export type ExecutablesParams = {
  executableName?: string[]
  executableId?: string[]
  source?: Source[]
  limit?: number
}

type PrincipalType = 'EMPLOYEE' | 'APPLICATION'
type PrincipalState = 'ACTIVE' | 'TERMINATED'

export type PrincipalParams = {
  type?: PrincipalType[]
  state?: PrincipalState[]
  query?: string
  limit?: number
  offset?: number
}

export type PrincipalListItem = PrincipalApplication | PrincipalEmployee

type PrincipalApplication = {
  id: string
  type: 'APPLICATION'
  state: 'ACTIVE'
  name: string
}

type PrincipalEmployee = {
  id: string
  type: 'EMPLOYEE'
  state: 'ACTIVE' | 'TERMINATED'
  name: string
  email: string
  peopleOpsId?: string
}

export const makeService = ({
  apiPrefix,
  onXCheckApiError = defaultApiErrorHandler,
}: ApiSettings) => ({
  fetchXChecks: (params: ListParams) =>
    get<ApiMethod, XChecksListItem[]>({
      apiMethod: 'fetchXChecks',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    }),

  fetchEntities: (params?: EntitiesParams) =>
    get<ApiMethod, Entity[]>({
      apiMethod: 'fetchEntities',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/entities?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    }),

  fetchExecutables: (params?: ExecutablesParams) =>
    get<ApiMethod, Executable[]>({
      apiMethod: 'fetchExecutables',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/executables?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    }),

  fetchPrincipals: (params?: PrincipalParams) =>
    get<ApiMethod, PrincipalListItem[]>({
      apiMethod: 'fetchPrincipals',
      onXCheckApiError,
      path: `${apiPrefix}/cross-checks/principals?${qs.stringify(params, {
        arrayFormat: 'repeat',
      })}`,
    }),
})
