import { ArrayParam } from 'api/types/xChecks'
import { Flex, Group, Item, Widget } from '@revolut/ui-kit'
import { useState } from 'react'
import { InputProps } from '../type'
import { getEditInfo } from './utils'
import { useAdd, useArrayFormSubscription, useDelete, useReplace } from './hooks'
import { ArrayItem, AddItem } from './components'

export const ArrayInput = ({ index, param, prefix }: InputProps<ArrayParam>) => {
  const [value, setValue] = useState(param.value.value)
  const { disabled, canAdd, canDelete, canEdit } = getEditInfo(param, value)
  const addItem = useAdd(setValue)
  const replaceItem = useReplace(setValue)
  const deleteItem = useDelete(setValue)

  useArrayFormSubscription({ index, prefix, value })

  return (
    <Group>
      <Widget>
        <Item variant="header">
          <Item.Content>
            <Item.Title>{param.name}</Item.Title>
          </Item.Content>
        </Item>

        <Widget padding="s-8" borderTopLeftRadius={0} borderTopRightRadius={0}>
          <Flex gap="s-4" flexWrap="wrap">
            {value?.map((item, idx) => (
              <ArrayItem
                key={idx}
                index={idx}
                itemValue={item}
                param={param}
                canEdit={canEdit}
                canDelete={canDelete}
                deleteItem={deleteItem}
                replaceItem={replaceItem}
                disabled={disabled}
              />
            ))}
            <AddItem
              addItem={addItem}
              canAdd={canAdd}
              param={param}
              disabled={disabled}
            />
          </Flex>
        </Widget>
      </Widget>
    </Group>
  )
}
