import { clamp } from 'lodash'
import { useCallback } from 'react'

/**
 * returns input onChange handler
 * witch process clamped integer value within given range
 */
export const useClampedIntChange = (
  onChange: (value: number) => void,
  min: number,
  max: number,
  defaultValue?: number,
) => {
  const defaultNumberValue = typeof defaultValue === 'number' ? defaultValue : min

  return useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const eventValue = event.currentTarget.valueAsNumber
      const numberValue = Number.isNaN(eventValue) ? defaultNumberValue : eventValue
      const newValue = clamp(Math.round(numberValue), min, max)

      if (newValue !== eventValue) {
        event.currentTarget.value = String(newValue)
      }

      onChange(newValue)
    },
    [min, max, defaultNumberValue, onChange],
  )
}
