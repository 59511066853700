import { Avatar, Checkbox, Ellipsis, Item, abbreviate } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { VirtualItem } from 'react-virtual'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { EntityData } from 'view/XChecks/XCheck/lib/hooks'

type UserListItemProps = {
  user: EntityData
  virtualItem: VirtualItem
  selectedHash: Record<string, boolean | undefined>
  onChange: (id: string) => void
}

export const UserListItem = ({
  user,
  virtualItem,
  selectedHash,
  onChange,
}: UserListItemProps) => {
  const checked = !!selectedHash[user.id]

  const onItemChange = useCallback(() => {
    onChange(user.id)
  }, [onChange, user])

  const label = abbreviate(user.name)
  return (
    <Item use="label" ref={virtualItem.measureRef} style={getItemStyle(virtualItem)}>
      <Item.Prefix>
        <Checkbox
          aria-labelledby="item-title"
          aria-describedby="item-description"
          onChange={onItemChange}
          checked={checked}
          align="center"
        />
      </Item.Prefix>
      <Item.Avatar>
        <Avatar
          size={40}
          {...getAvatarProps({ id: user.id, label, avatar: user.avatarUrl })}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{user.name}</Item.Title>
        <Item.Description>
          <Ellipsis>{user.description}</Ellipsis>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

const getItemStyle = (virtualItem: VirtualItem) => ({
  position: 'absolute' as const,
  top: 0,
  left: 0,
  width: '100%',
  minHeight: `${virtualItem.size}px`,
  transform: `translateY(${virtualItem.start}px)`,
})
