import { Page, PageMainSize } from '@revolut/ui-kit'
import { Header, Actions, Side, Updates, Rules, Details } from '../..'

export const ViewLayout = ({ onBackClick }: { onBackClick: () => void }) => (
  <>
    <Header onBackClick={onBackClick} />

    <Page.Tabs>
      <Actions />
    </Page.Tabs>

    <Page.Feed>
      <Details />
    </Page.Feed>

    <Page.Main size={PageMainSize.WIDE}>
      <Updates />
      <Rules />
    </Page.Main>

    <Page.Side>
      <Side />
    </Page.Side>
  </>
)
