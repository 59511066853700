import { RadiobuttonOff } from '@revolut/icons'
import { Cell, Group, HStack, TextSkeleton } from '@revolut/ui-kit'

const SKELETON_SIZES = [17, 10, 15]

export const CrossCheckRulesSkeleton = () => {
  return (
    <Group>
      {SKELETON_SIZES.map((size, idx) => (
        <Cell key={idx}>
          <HStack space="s-8">
            <RadiobuttonOff size={16} />
            <TextSkeleton size={size} />
          </HStack>
        </Cell>
      ))}
    </Group>
  )
}
