import { ActionButton, HStack } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'

type Props = {
  resourceId: string
  crossCheckId?: string
}

export const Actions = ({ resourceId, crossCheckId }: Props) => {
  return (
    <HStack gap="s-12">
      <ActionButton
        use="a"
        href={generatePath(Url.SamResource, { resourceId })}
        useEndIcon="LinkExternal"
        target="_blank"
      >
        To resource
      </ActionButton>
      {crossCheckId ? (
        <ActionButton
          use="a"
          href={generatePath(Url.CrossCheckSamDetails, { crossCheckId })}
          useEndIcon="LinkExternal"
          target="_blank"
        >
          To request
        </ActionButton>
      ) : null}
    </HStack>
  )
}
