import { Changes, XCheckDetails } from 'api/types/xChecks'
import { match } from 'ts-pattern'
import { Tab } from '../types'
import { isChangesEmpty } from './hasChanges'
import { isFinished } from '../../../utils'
import { CHANGES_TAB, REQUEST_INPUT_TAB } from '../consts'

/**
 * returns list of tabs for x-check
 *
 * if x-check finished - params only
 * if x-check changes empty - params only
 * otherwise - changes + params
 */
export const getTabs = ({
  xCheck,
  changes,
}: {
  xCheck?: XCheckDetails
  changes?: Changes
}) => {
  const tabs: Tab[] = [REQUEST_INPUT_TAB]

  return match({ xCheck, changes })
    .when(
      () => !xCheck || isFinished(xCheck),
      () => tabs,
    )
    .when(
      () => changes && isChangesEmpty(changes),
      () => tabs,
    )
    .otherwise(() => [CHANGES_TAB, ...tabs])
}
