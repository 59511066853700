import { State, Decision, RuleTypeDescriptor } from 'api/types/xChecks'
import { getRulesByType, isProdDomain } from 'view/XChecks/XCheck/lib/utils'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GetActionsParams, XCheckActionSettings } from '../types'

export const getRejectAction = ({
  xCheck,
  xCheckId,
  userId,
  hasXCheckPermission,
  onSubmit,
}: GetActionsParams): XCheckActionSettings | undefined => {
  if (
    !xCheck ||
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_REVIEW) ||
    xCheck.state !== State.AwaitingReview
  ) {
    return undefined
  }

  const isRequester = xCheck.requester.id === userId
  if (isRequester && isProdDomain()) {
    return undefined
  }

  const alreadyRejected = xCheck.reviews?.some(
    (review) => review.author.id === userId && review.decision === Decision.reject,
  )

  if (alreadyRejected) {
    return undefined
  }

  const hasNoRejectionsRule = !!getRulesByType(RuleTypeDescriptor.noRejections, xCheck)
    .length
  const reviewGroups = getRulesByType(RuleTypeDescriptor.group, xCheck)
  const isUserInGroup = reviewGroups.some((groupRule) =>
    groupRule.config.reviewers.some((reviewer) => reviewer.id === userId),
  )

  if (!hasNoRejectionsRule && !isUserInGroup) {
    return undefined
  }

  return {
    onSubmit,
    xCheckId,
    actionType: 'reject',
    popupSettings: {
      title: 'Reject request',
      description:
        'This action is likely irreversible and can change the request status to Rejected permanently. Please provide a reason below before confirming.',
      primaryActionLabel: 'Reject',
      primaryActionVariant: 'negative',
      textAreaLabel: 'Justification (optional)',
      isTextRequired: false,
    },
    actionSettings: {
      label: 'Reject',
      variant: 'negative',
      useIcon: 'Cross',
    },
    warningSettings: {
      proceedActionLabel: 'Reject',
      description: 'This action might be irreversible and will stop review process',
      title: 'Do you really want to reject the request?',
      proceedActionButtonVariant: 'negative',
    },
  }
}
