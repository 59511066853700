import { Box, IconButton, Token } from '@revolut/ui-kit'
import { Comment, XCheckDetails } from 'api/types/xChecks'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback, useState } from 'react'
import { XCheckEditCommentParams } from 'view/XChecks/XCheck/lib/types'
import { isSubmitCombination } from 'view/XChecks/XCheck/lib/utils'
import { MentionsInput } from '../../MentionsInput'

export const EditCommentForm = ({
  comment,
  onCancelClick,
  onCommentSubmit,
  xCheck,
  isLoading,
}: {
  isLoading: boolean
  comment: Comment
  onCancelClick: () => void
  onCommentSubmit: (params: XCheckEditCommentParams) => void
  xCheck: XCheckDetails
}) => {
  const [value, setValue] = useState(comment.text)
  const onChange = useInputStringChange(setValue)
  const onSubmit = useCallback(() => {
    onCommentSubmit({
      comment: value,
      xCheckId: xCheck.id,
      actionType: 'edit',
      commentId: comment.id,
    })
  }, [comment, onCommentSubmit, xCheck.id, value])

  const updateDisabled = !comment || comment.text === value || isLoading

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (isSubmitCombination(event) && !updateDisabled) {
        return onSubmit()
      }

      if (event.key === 'Escape') {
        return onCancelClick()
      }

      return undefined
    },
    [onSubmit, onCancelClick, updateDisabled],
  )

  return (
    <Box>
      <MentionsInput
        onChange={onChange}
        value={value}
        disabled={isLoading}
        onCloseClick={onCancelClick}
        onKeyDown={onKeyDown}
        withPreview
        renderActions={() => (
          <IconButton
            useIcon="SendMessage"
            variant="icon"
            disabled={updateDisabled}
            onClick={onSubmit}
            key="update"
            color={updateDisabled ? Token.color.shimmer : Token.color.accent}
            size={22}
          >
            Update
          </IconButton>
        )}
      />
    </Box>
  )
}
