import { Log, XCheckDetails } from 'api/types/xChecks'
import { Data } from 'view/XChecks/XCheck/lib/types'
import { Box } from '@revolut/ui-kit'
import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { getDataValue } from 'view/XChecks/XCheck/lib/utils'
import { Tab } from '../../types'
import { CommentTab } from './components/CommentTab'
import { ErrorTab } from './components/ErrorTab'
import { LoadingTab } from './components/LoadingTab'
import { LogsTab } from './components/LogsTab'

type Props = {
  xCheck: Data<XCheckDetails>
  log: Data<Log[]>
  tab?: Tab
  currentUserId: Data<string>
}

export const EventsContent = (props: Props) => {
  return (
    <Box py="s-16" px="s-12">
      <Inner {...props} />
    </Box>
  )
}

const Inner = ({ tab, log, xCheck, currentUserId }: Props) => {
  const { Principal } = useViews()
  switch (tab) {
    case 'comment':
      if (xCheck.status === 'loading') {
        return <LoadingTab />
      }
      if (xCheck.status === 'error') {
        return <ErrorTab tab={tab} />
      }

      return (
        <CommentTab
          Principal={Principal}
          xCheck={xCheck.data}
          currentUserId={currentUserId}
        />
      )

    case 'log':
      if (log.status === 'loading') {
        return <LoadingTab />
      }
      if (log.status === 'error') {
        return <ErrorTab tab={tab} />
      }
      return (
        <LogsTab Principal={Principal} logs={log.data} xCheck={getDataValue(xCheck)} />
      )
    default:
      return <ErrorTab tab={tab} />
  }
}
