import { ReviewRule, RuleTypeDescriptor } from 'api/types/xChecks'
import { GroupRule } from './component/GroupRule'
import { CountingRule } from './component/CountingRule'
import { NoRejectionsRule } from './component/NoRejectionsRule'
import { OrRule } from './component/OrRule'
import { AndRule } from './component/AndRule'
import { MultistageRule } from './component/MultistageRule'

type Props = {
  rule: ReviewRule
  parentRule?: ReviewRule
  isFirst?: boolean
}

export const Rule = (props: Props) => {
  return <Inner {...props} />
}

const Inner = ({ rule, parentRule, isFirst }: Props) => {
  switch (rule.type) {
    case RuleTypeDescriptor.group:
      return <GroupRule rule={rule} parentRule={parentRule} isFirst={isFirst} />
    case RuleTypeDescriptor.counting:
      return <CountingRule rule={rule} parentRule={parentRule} isFirst={isFirst} />
    case RuleTypeDescriptor.noRejections:
      return <NoRejectionsRule rule={rule} parentRule={parentRule} isFirst={isFirst} />
    case RuleTypeDescriptor.or:
      return <OrRule rule={rule} parentRule={parentRule} isFirst={isFirst} />
    case RuleTypeDescriptor.and:
      return <AndRule rule={rule} parentRule={parentRule} isFirst={isFirst} />
    case RuleTypeDescriptor.multiStage:
      return <MultistageRule rule={rule} parentRule={parentRule} isFirst={isFirst} />
    default:
      return null
  }
}
