import { PermissionClassification as TPermissionClassification } from 'api/idave/permissions'

export const getClassificationLabel = (classification?: TPermissionClassification) => {
  switch (classification) {
    case TPermissionClassification.highlyConfidential:
      return 'Highly Confidential'
    case TPermissionClassification.confidential:
      return 'Confidential'
    case TPermissionClassification.monetary:
      return 'Monetary'
    case TPermissionClassification.sensitive:
      return 'Sensitive'
    case TPermissionClassification.internal:
      return 'Internal'
    case TPermissionClassification.public:
      return 'Public'
    default:
      return 'Unknown'
  }
}
