import { Action, Box, chain, Flex, Spacer, Text, Token } from '@revolut/ui-kit'
import { Comment, PrincipalType } from 'api/types/xChecks'
import { memo, useCallback } from 'react'
import { formatDateTimePrecise } from 'utils/date'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { MarkdownRenderer } from 'view/XChecks/XCheck/lib/components/common/MarkdownRenderer'
import { useXCheckData, useXCheckSecurity } from 'view/XChecks/XCheck/lib/hooks'
import {
  Data,
  PrincipalComponentType,
  PrincipalContext,
} from 'view/XChecks/XCheck/lib/types'
import { isFinished } from 'view/XChecks/XCheck/lib/utils'

export const CommentItem = memo(
  ({
    comment,
    Principal,
    currentUserId,
    onEditClick,
  }: {
    comment: Comment
    Principal: PrincipalComponentType
    currentUserId: Data<string>
    onEditClick: (comment: Comment) => void
  }) => {
    const { hasXCheckPermission } = useXCheckSecurity()
    const { xCheckData } = useXCheckData()
    const authorId =
      comment.author.type === PrincipalType.EMPLOYEE ? comment.author.id : undefined
    const isEditable =
      currentUserId.status === 'success' &&
      currentUserId.data === authorId &&
      hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_COMMENT) &&
      xCheckData.status === 'success' &&
      !isFinished(xCheckData.data)

    const onEditClickHandler = useCallback(() => {
      if (isEditable) {
        onEditClick(comment)
      }
    }, [onEditClick, isEditable, comment])

    return (
      <Box>
        <Flex
          alignItems="center"
          color={Token.color.greyTone50}
          flexWrap="wrap"
          overflow="hidden"
        >
          {chain(
            <Principal context={PrincipalContext.comment} principal={comment.author} />,
            <Text variant="caption">{formatDateTimePrecise(comment.createdDate)}</Text>,
          )}
          {isEditable ? (
            <>
              <Spacer />
              <Action iconOnly useIcon="Pencil" onClick={onEditClickHandler} />
            </>
          ) : null}
        </Flex>
        <Box mt="s-8">
          <MarkdownRenderer>{comment.text}</MarkdownRenderer>
        </Box>
      </Box>
    )
  },
)
