import { ActionButtonProps } from '@revolut/ui-kit'
import { SAM_PERMISSIONS } from 'security'
import {
  CREATE,
  CREATE_MULTI_SUBJECT,
  DELETE,
  EDIT,
  EDIT_MULTI_SUBJECT,
} from 'view/Sam/permissions'
import { usePermissions } from '@revolut-internal/idave-web-auth'

type GetPolicyActionsParams = {
  permissionUtils: ReturnType<typeof usePermissions>
  isLoading: boolean
  isMultiSubject: boolean
  onApplyPolicyClick: () => void
  onCopyClick: () => void
  onDeleteClick: () => void
  onEditClick: () => void
}

type GetPolicyActionsResult = Array<{
  variant: ActionButtonProps['variant']
  useIcon: ActionButtonProps['useIcon']
  onClick: () => void
  label: string
  disabled?: boolean
}>

export const getPolicyActions = ({
  permissionUtils: { hasPermission, hasSomePermissions },
  isLoading,
  isMultiSubject,
  onEditClick,
  onApplyPolicyClick,
  onCopyClick,
  onDeleteClick,
}: GetPolicyActionsParams) => {
  const actions: GetPolicyActionsResult = []

  if (
    isMultiSubject
      ? hasSomePermissions(...EDIT_MULTI_SUBJECT)
      : hasSomePermissions(...EDIT)
  ) {
    actions.push({
      label: 'Edit',
      onClick: onEditClick,
      useIcon: 'Pencil',
      variant: 'primary',
      disabled: isLoading,
    })
  }

  if (hasPermission(SAM_PERMISSIONS.POLICIES_UPDATE_APPLY)) {
    actions.push({
      label: 'Force apply',
      onClick: onApplyPolicyClick,
      useIcon: 'Play',
      variant: 'primary',
      disabled: isLoading,
    })
  }

  if (
    isMultiSubject
      ? hasSomePermissions(...CREATE_MULTI_SUBJECT)
      : hasSomePermissions(...CREATE)
  ) {
    actions.push({
      label: 'Duplicate policy',
      onClick: onCopyClick,
      useIcon: 'Copy',
      variant: 'primary',
      disabled: isLoading,
    })
  }

  if (hasSomePermissions(...DELETE)) {
    actions.push({
      label: 'Delete policy',
      onClick: onDeleteClick,
      useIcon: 'Delete',
      variant: 'negative',
      disabled: isLoading,
    })
  }

  return actions
}
