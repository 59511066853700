import { Resource } from 'api/sam/types/resources'
import { getResourceLink } from 'utils/resource/getResourceLink'
import { ExternalTextLink } from './ExternalTextLink'
import { RoleLink } from './RoleLink'
import { CustomerCompanyLink } from './CustomerCompanyLink'

export const ResourceLink = (props: { resource: Resource }) => {
  const { resource } = props
  const link = getResourceLink(resource)
  if (!link) {
    return null
  }
  switch (link.type) {
    case 'link':
      return <ExternalTextLink href={link.href} text={link.label} title={link.label} />
    case 'role':
      return <RoleLink id={link.id} />
    case 'customerCompany':
      return <CustomerCompanyLink id={link.id} />
    default:
      return null
  }
}
