import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { EntityParam, Value } from 'api/types/xChecks'
import { StatusWidget, VStack } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { getEntityContextParams, isContextEmpty } from '../utils'
import { Details } from '../../Details'
import { Tables } from '../../Tables'
import { Widgets } from '../../Widgets/Widgets'

type Props = {
  entity: Value<EntityParam>
  children?: React.ReactNode
}
export const EntityContextContent = ({ entity, children }: Props) => {
  const { entityToEntityView } = useViews()
  const name = getEntityDescriptor(entity).name

  if (isContextEmpty({ entity, children })) {
    return <EmptyContent name={name} />
  }

  const { details, tables, widgets } = getEntityContextParams(entity, entityToEntityView)

  return (
    <VStack gap="s-20" padding="s-16">
      <Details details={details} title="Details" />
      <Tables tables={tables} />
      <Widgets widgets={widgets} />
      {children}
    </VStack>
  )
}

const EmptyContent = ({ name }: { name: string }) => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D231', 'v2')} />
    <StatusWidget.Title>Lack of details</StatusWidget.Title>
    <StatusWidget.Description>
      Any additional attribute wasn&apos;t provided for {name}
    </StatusWidget.Description>
  </StatusWidget>
)
