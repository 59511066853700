import { Action, Box, Flex, Footnote, VStack } from '@revolut/ui-kit'
import { Comment, XCheckDetails } from 'api/types/xChecks'
import { useCallback, useMemo, useState } from 'react'
import {
  Data,
  PrincipalComponentType,
  XCheckEditCommentParams,
} from 'view/XChecks/XCheck/lib/types'
import { CommentItem } from './components/CommentItem'
import { EmptyComments } from './components/EmptyComments'
import { EditCommentForm } from './components/EditCommentForm'
import { getEvents, SLICE_AMOUNT } from '../../../../../../utils'
import { useEditComment } from './useEditComment'

type Props = {
  Principal: PrincipalComponentType
  xCheck: XCheckDetails
  currentUserId: Data<string>
}

export const Comments = ({ Principal, xCheck, currentUserId }: Props) => {
  const [sliceLast, setSliceLast] = useState(true)
  const [activeCommentId, setActiveCommentId] = useState<string | undefined>()
  const resetActive = useCallback(() => {
    setActiveCommentId(undefined)
  }, [setActiveCommentId])
  const onShowAllClick = useCallback(() => setSliceLast(false), [])
  const { items, isSliced } = useMemo(
    () => getEvents({ items: xCheck.comments, sliceLast }),
    [xCheck.comments, sliceLast],
  )

  const { mutate, isLoading } = useEditComment(resetActive)

  const onEditClick = useCallback(
    ({ id }: Comment) => {
      setActiveCommentId(id)
    },
    [setActiveCommentId],
  )

  const onCommentSubmit = useCallback(
    (params: XCheckEditCommentParams) => mutate(params),
    [mutate],
  )

  if (!items.length) {
    return <EmptyComments />
  }

  return (
    <>
      <VStack gap="s-16">
        {items.map((comment) => {
          return comment.id === activeCommentId ? (
            <EditCommentForm
              isLoading={isLoading}
              onCancelClick={resetActive}
              comment={comment}
              onCommentSubmit={onCommentSubmit}
              xCheck={xCheck}
              key={comment.id}
            />
          ) : (
            <CommentItem
              onEditClick={onEditClick}
              key={comment.id}
              comment={comment}
              Principal={Principal}
              currentUserId={currentUserId}
            />
          )
        })}
      </VStack>

      {isSliced && sliceLast ? (
        <Box pt="s-12">
          <Footnote>Last {SLICE_AMOUNT} comments shown</Footnote>
          <Flex justifyContent="center" mb="s-8">
            <Action onClick={onShowAllClick}>Show all</Action>
          </Flex>
        </Box>
      ) : null}
    </>
  )
}
