import { FetchStatus, QueryStatus, UseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { notNullable } from 'utils/common'
import { mergeQueryStatuses } from 'utils/query'
import { Data } from '../types'

export const getQueryData = <DataType>(params: {
  data: DataType
  fetchStatus: FetchStatus
  status: QueryStatus
  error?: unknown
}): Data<NonNullable<DataType>> => {
  const { data, status, fetchStatus, error } = params
  const resultStatus = mergeQueryStatuses({ qs: status, fs: fetchStatus })

  switch (resultStatus) {
    case 'loading':
      return { status: 'loading' }
    case 'forbidden':
    case 'error': {
      const resultError = error && error instanceof Error ? error : undefined
      return { status: 'error', error: resultError }
    }
    case 'success':
      return notNullable(data) ? { data, status: 'success' } : { status: 'error' }
    default:
      return { status: 'error' }
  }
}

export const useQueryData = <DataType>(query: UseQueryResult<DataType>) => {
  const { data, fetchStatus, status, error } = query

  return useMemo(
    () =>
      getQueryData({
        data,
        fetchStatus,
        status,
        error,
      }),
    [data, fetchStatus, status, error],
  )
}

export const getDataValue = <T>(dataContainer: Data<T>) =>
  dataContainer.status === 'success' ? dataContainer.data : undefined
