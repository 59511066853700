import { useQueryEmployeeCompanies } from 'queries/dart/companies'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { DetailsGroupSkeleton } from 'components/DetailsGroupSkeleton'
import { findEntityById } from 'utils/array'
import { DataWrapperProps, InnerProps } from './types'

export const EmployeeCompanyDataWrapper = ({
  Component,
  crossCheck,
  id,
  source,
}: DataWrapperProps) => {
  if (!id) {
    const entity = {
      type: 'EmployeeCompany' as const,
      value: undefined,
    }
    return <Component crossCheck={crossCheck} entity={entity} source={source} />
  }

  return <Inner id={id} crossCheck={crossCheck} Component={Component} source={source} />
}

const Inner = ({ Component, crossCheck, id, source }: InnerProps) => {
  const {
    data: employeeCompanies = [],
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryEmployeeCompanies()
  const employeeCompany = findEntityById(employeeCompanies, id)

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={employeeCompany}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view clients" />
      )}
      renderLoading={() => <DetailsGroupSkeleton />}
      renderSuccess={({ data }) => (
        <Component
          crossCheck={crossCheck}
          entity={{
            type: 'EmployeeCompany',
            value: data,
          }}
          source={source}
        />
      )}
    />
  )
}
