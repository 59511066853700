import { Avatar, HStack } from '@revolut/ui-kit'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { InternalLink } from '../InternalLink'

type Props = {
  scope: string
}

export const ClientScopeLink = ({ scope }: Props) => {
  const href = generatePath(Url.ClientScope, { scope })

  return (
    <HStack space="s-8">
      <Avatar size={20} useIcon="Apps" />
      <InternalLink href={href} title={scope}>
        {scope}
      </InternalLink>
    </HStack>
  )
}
