import { useQueryRevolutersDictionaries } from 'queries/sam/revoluters'
import { useMemo } from 'react'
import { mergeQueryStatuses } from 'utils/query'
import { RevolutersFunction } from 'api/sam/revoluters'
import { SAM_PERMISSIONS } from 'security'
import { BaseMultiSelect, BaseMultiSelectWrapperProps } from '../BaseMultiSelect'
import { functionToOption } from './utils'

export const FunctionsSelect = (
  props: BaseMultiSelectWrapperProps<RevolutersFunction>,
) => {
  const {
    data: revoluters,
    status: qs,
    fetchStatus: fs,
  } = useQueryRevolutersDictionaries()
  const queryResult = mergeQueryStatuses({ qs, fs })
  const items = useMemo(() => revoluters?.functions || [], [revoluters])

  return (
    <BaseMultiSelect
      items={items}
      queryResult={queryResult}
      itemToOption={functionToOption}
      requiredPermission={SAM_PERMISSIONS.REVOLUTERS_STRUCTURE_VIEW_LIST}
      {...props}
    />
  )
}
