import { SelectEntitiesTableOverlay } from 'components/EntitiesTable'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mergeQueryStatuses } from 'utils/query'
import { useFormContext } from 'react-hook-form'
import { MutableRefObject, useMemo } from 'react'
import { useAvailableResources } from 'queries/sam/resources'
import { useColumns } from './columns'
import { Row } from './types'
import { getSubmitLabel, makeErrorWidget } from './utils'
import { useUnavailableResourcesBanner } from './hooks'
import { ResourceRequestForm } from '../../types'

type Props = {
  onClose: () => void
  onSelect: (rows: Row[]) => void
  pending?: boolean
  durationsRef: MutableRefObject<Record<string, number>>
}

export const AvailableResourceList = ({
  onClose,
  pending,
  onSelect,
  durationsRef,
}: Props) => {
  const { getValues } = useFormContext<ResourceRequestForm>()
  const { jiraTicket, justification, idaveUserId } = getValues()

  const {
    data = [],
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useAvailableResources({
    idaveUserId,
    justification,
    jiraTicket,
  })

  const entities = useMemo(
    () =>
      data.map((resourceAvailability) => ({
        ...resourceAvailability,
        id: resourceAvailability.resource.resourceId,
        isDisabled: !resourceAvailability.available,
        regions: resourceAvailability.resource.regions,
      })),
    [data],
  )

  const loadingState = getLoadingState(mergeQueryStatuses({ qs, fs }), data.length)

  const columns = useColumns(entities, durationsRef)
  const Banner = useUnavailableResourcesBanner(entities)
  const showErrorWidget = loadingState === 'no-results' || loadingState === 'failed'

  return (
    <SelectEntitiesTableOverlay
      entitiesTypeLabel="Resources"
      pluralForms={['resource', 'resources']}
      entities={entities}
      columns={columns}
      loadingState={loadingState}
      title="Request resources"
      pending={pending}
      onSelect={onSelect}
      onClose={onClose}
      BannerComponent={Banner}
      getSubmitLabel={getSubmitLabel}
      showErrorWidget={showErrorWidget}
      ErrorWidget={makeErrorWidget({ refetch })}
    />
  )
}
