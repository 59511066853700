import { Avatar, Banner, useToggle } from '@revolut/ui-kit'
import { getCategoriesImageProps } from 'utils/url'
import { Row } from './types'

export const useUnavailableResourcesBanner = (rows: Row[]) => {
  if (rows.some((i) => !i.available)) {
    return UnavailableResourceBanner
  }

  return undefined
}

export const UnavailableResourceBanner = () => {
  const [open, toggleOpen] = useToggle({ defaultState: true })

  return open ? (
    <Banner labelButtonClear="Clear" onClear={toggleOpen.off} mb="s-12">
      <Banner.Avatar>
        <Avatar variant="brand" image={getCategoriesImageProps('Admin')} size={56} />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>Some resources cannot be selected</Banner.Title>
        <Banner.Description>
          One or more resources are visible but cannot be requested with the provided Jira
          ticket and/or justification
        </Banner.Description>
      </Banner.Content>
    </Banner>
  ) : (
    <></>
  )
}
