import { Absolute, Box, Relative, Subheader, Widget } from '@revolut/ui-kit'
import { useXCheckData } from 'view/XChecks/XCheck/lib/hooks'
import { RulesSkeleton } from './components/RulesSkeleton'
import { Rule } from './components/Rule'
import { RefreshAction } from './components/RefreshAction'

export const Rules = () => {
  const { xCheckData } = useXCheckData()
  if (xCheckData.status === 'error') {
    return null
  }

  if (xCheckData.status === 'loading') {
    return <RulesSkeleton />
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>Approval requirements</Subheader.Title>
      </Subheader>

      <Relative>
        <Widget py="s-8" px="s-16" overflow="hidden">
          <Rule rule={xCheckData.data.reviewRule} />
          <Absolute top="16px" right="16px">
            <RefreshAction />
          </Absolute>
        </Widget>
      </Relative>
    </Box>
  )
}
