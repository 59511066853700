import { SamPolicy } from 'api/sam/policies'
import { Resource } from 'api/sam/types/resources'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { isString } from 'lodash'
import { isFinished as getIsFinished } from '../../../../../CrossCheckRules/utils'
import { CrossCheckValueTab } from '../../../../types'
import {
  getChangeRowsInfo,
  getStateTabRowsInfo,
  getStateValues,
  isStatePolicyChange,
} from '../../utils'
import { ResourceRow } from './types'
import { CREATE_NAMES } from '../../../ChangeSwitcher/utils'

type GetResourceRowsInfoParams = {
  crossCheck: CrossCheckDetail
  policy?: SamPolicy
  resourceMap: Map<string, Resource>
  tabs: CrossCheckValueTab[]
}
export const getResourceRowsInfo = (params: GetResourceRowsInfoParams) => {
  const { tabs, crossCheck, resourceMap, policy } = params

  if (isStatePolicyChange(params)) {
    return getStateResourceRowsInfo(params)
  }

  const { executableParams } = crossCheck

  const current = policy?.resourceIds || []
  const values = CREATE_NAMES.includes(crossCheck.executableName)
    ? getResourceIds(crossCheck)
    : []

  const added = executableParams?.resources_diff?.added || []
  const deleted = executableParams?.resources_diff?.removed || []
  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    resourceMap,
  })
}

type MakeGetResourceRowParams = {
  resourceMap: Map<string, Resource>
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
}

const makeMapValue =
  ({ deletedHash = {}, addedHash = {}, resourceMap }: MakeGetResourceRowParams) =>
  (id: string): ResourceRow => {
    const removedStatus = deletedHash[id] ? 'removed' : undefined
    const addedStatus = addedHash[id] ? 'added' : undefined

    return {
      id,
      resource: resourceMap.get(id),
      status: addedStatus || removedStatus,
    }
  }

export const getStateResourceRowsInfo = ({
  tabs,
  crossCheck,
  resourceMap,
  policy,
}: GetResourceRowsInfoParams) => {
  const values = policy?.resourceIds || []
  const newValues = getResourceIds(crossCheck)

  const { addedValues, deletedValues } = getStateValues({ newValues, values, crossCheck })

  const addedHash = toPresenceMap(addedValues)
  const deletedHash = toPresenceMap(deletedValues)

  const params = {
    newValues,
    values,
    addedHash,
    deletedHash,
    resourceMap,
    isFinished: getIsFinished(crossCheck),
  }

  return getStateTabRowsInfo({
    tabs,
    makeMapValue: makeStateMapValue,
    ...params,
  })
}

type MakeGetStateResourceRowParams = {
  resourceMap: Map<string, Resource>
  deletedHash?: Record<string, boolean>
  addedHash?: Record<string, boolean>
  isFinished?: boolean
}

const makeStateMapValue =
  ({
    deletedHash = {},
    addedHash = {},
    resourceMap,
    isFinished,
  }: MakeGetStateResourceRowParams) =>
  (id: string): ResourceRow => {
    const removedStatus = deletedHash[id] ? 'removed' : undefined
    const addedStatus = addedHash[id] ? 'added' : undefined

    return {
      id,
      resource: resourceMap.get(id),
      status: isFinished ? undefined : addedStatus || removedStatus,
    }
  }

export const getEmptyResourceLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? "There aren't added or deleted resources"
    : 'No resources for the policy'

const getResourceIds = (crossCheck: CrossCheckDetail) =>
  crossCheck.executableParams.resources?.filter<string>(isString) || []
