export const KNOWN_ENTITY_TYPE = {
  role: 'Role',
  permission: 'Permission',
  registeredIdentity: 'Registered Identity',
  iDaveUser: 'IDave User',
  employeeCompany: 'Employee Company',
  customerCompany: 'Customer Company',
  application: 'Application',
  region: 'Region',
  group: 'Group',
  serviceAccount: 'Service Account',
} as const
