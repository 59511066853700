import { useEffect } from 'react'

const DEFAULT_TITLE = 'Revolut IDave'

interface TitleProps {
  value?: string
}

export const Title = ({ value }: TitleProps) => {
  useEffect(() => {
    document.title =
      typeof value === 'string' ? `${value} | ${DEFAULT_TITLE}` : DEFAULT_TITLE
    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [value])

  return null
}
