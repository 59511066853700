import { State } from 'api/types/xChecks'
import { isProdDomain } from 'view/XChecks/XCheck/lib/utils/'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GetActionsParams, XCheckActionSettings } from '../types'

export const getRequestInfoAction = ({
  xCheck,
  xCheckId,
  userId,
  hasXCheckPermission,
  onSubmit,
}: GetActionsParams): XCheckActionSettings | undefined => {
  if (
    !xCheck ||
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_REQUEST_INFO) ||
    xCheck.state !== State.AwaitingReview
  ) {
    return undefined
  }

  const isRequester = xCheck.requester.id === userId
  if (isRequester && isProdDomain()) {
    return undefined
  }

  return {
    onSubmit,
    xCheckId,
    actionType: 'requestInfo',
    popupSettings: {
      title: 'Request info',
      description:
        'This action will pause the review process until further clarification or changes are provided. Please specify your concern below.',
      primaryActionLabel: 'Request',
      primaryActionVariant: 'primary',
      textAreaLabel: 'What updates or clarifications are required?',
      isTextRequired: true,
    },
    actionSettings: {
      label: 'Request info',
      variant: 'primary',
      useIcon: 'HelpChatOutline',
    },
  }
}
