import { Button, Popup, TextArea, VStack } from '@revolut/ui-kit'
import { UserList } from 'components/UserList'
import { useEditPopup } from 'hooks/useEditPopup'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { AssignmentTimer } from 'components/AssignmentTimer'
import { useCallback, useState } from 'react'
import { AssignTimer } from 'components/AssignmentTimer/types'
import { CustomerEmployeesAccessPayload } from 'api/dart/types'

export const AddEmployeePopup = (props: {
  add: (payload: CustomerEmployeesAccessPayload) => void
}) => {
  const { add } = props

  const [userIds, setUserIds] = useState<string[]>([])
  const [justification, setJustification] = useState<string>()

  const handleJustificationChange = useInputStringChange(setJustification)
  const [timer, setTimer] = useState<AssignTimer>()
  const onAddClick = useCallback(
    () =>
      add({
        employeeIds: userIds,
        justification,
        expirationDate: timer?.value,
      }),
    [add, userIds, justification, timer],
  )

  const { scrollRef } = useEditPopup()

  const count = userIds.length
  const disabled = !count || (timer?.type === 'temporary' && !timer.value)

  return (
    <>
      <VStack space="s-16">
        <AssignmentTimer onChange={setTimer} />
        <TextArea
          label="Justification"
          onChange={handleJustificationChange}
          value={justification}
        />
        <UserList onChange={setUserIds} variant="checkbox" scrollRef={scrollRef} />
      </VStack>

      <Popup.Actions>
        <Button elevated onClick={onAddClick} disabled={disabled}>
          {count ? `Add ${count} selected` : 'Add'}
        </Button>
      </Popup.Actions>
    </>
  )
}
