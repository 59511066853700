import { Navigate } from 'react-router-dom'
import { useCurrentUserId } from 'queries/idave/users'
import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { NoAccessPage } from 'components/NoAccessWidget'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { Page } from '@revolut/ui-kit'

export const Home = () => {
  const { data, status, fetchStatus } = useCurrentUserId()

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      data={data}
      renderLoading={() => (
        <Page.Main size="wide">
          <EntitySkeleton variant="details" hasActions={false} />
        </Page.Main>
      )}
      renderIdle={() => <NoAccessPage />}
      renderError={() => <RequestErrorPage />}
      renderSuccess={({ data: userId }) => (
        <Navigate to={generatePath(Url.User, { userId })} replace />
      )}
    />
  )
}
