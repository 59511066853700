import { MoreBar } from '@revolut/ui-kit'
import { Resource } from 'api/sam/types/resources'
import { useResourceActions } from './hooks/useResourceActions'

type Props = {
  resource: Resource
}

export const ResourceActions = ({ resource }: Props) => {
  const actions = useResourceActions({
    resource,
  })

  if (!actions.length) {
    return null
  }

  return (
    <MoreBar>
      {actions.map((action) => (
        <MoreBar.Action
          key={action.label}
          variant={action.variant}
          useIcon={action.useIcon}
          onClick={action.onClick}
        >
          {action.label}
        </MoreBar.Action>
      ))}
    </MoreBar>
  )
}
