import { Log as TLog, XCheckDetails } from 'api/types/xChecks'
import { Widget } from '@revolut/ui-kit'
import { Data } from '../../../../types'
import { EventsHeader, getEventsTabs, useEventsTabs } from './components/EventsHeader'
import { EventsContent } from './components/EventsContent'

type Props = {
  xCheck: Data<XCheckDetails>
  log: Data<TLog[]>
  currentUserId: Data<string>
}

export const Events = ({ log, xCheck, currentUserId }: Props) => {
  const tabs = getEventsTabs({ xCheck, log })
  const { tab, onTabClick } = useEventsTabs(tabs)

  return (
    <Widget p="s-12">
      <EventsHeader
        tabs={tabs}
        tab={tab}
        onTabClick={onTabClick}
        loading={xCheck.status === 'loading' || log.status === 'loading'}
      />
      <EventsContent xCheck={xCheck} log={log} tab={tab} currentUserId={currentUserId} />
    </Widget>
  )
}
