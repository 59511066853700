import { ActionResponse } from 'api/types/actionResponse'
import axios, { AxiosPromise } from 'axios'
import { splitPayloadHeader } from 'utils/api/splitBodyHeaders'
import { CrossCheckedPayload } from 'utils/types/crossChecked'

export type UserStates = 'TERMINATED' | 'ACTIVE' | 'DELETED'

export type UserRole = {
  role: string
  roleId: string
  expiryDate?: number
}

export type UserAccessLogs = {
  id: string
  ip?: string
  accessType: 'NEW' | 'REFRESH'
  userId: string // matched input path param :userId
  clientId: string
  userAgent: string // device's user agent
  date: number // access date
}

export type UserListItem = {
  firstName: string
  lastName: string
  username: string
  email: string
  id: string
  state: UserStates
  organisationId?: string
  externalRef?: {
    reference: string
    origin: string
  }
}

export type User = UserListItem & {
  roles: UserRole[]
  joined: number
}

export type UserUpdatePayload = {
  id: string
  firstName: string
  lastName: string
  email: string
  username: string
  organisationId?: string
}

export type CreateUserPayload = {
  firstName: string
  lastName: string
  email: string
  username: string
  organisation?: string
  clone?: string
}

export type TerminateUserPayload = {
  user_id: string
  state: 'TERMINATED'
}

export type ActivateUserPayload = {
  user_id: string
  state: 'ACTIVE'
}

export type AddUserRolePayload = {
  role: string
  user_id: string
  client_name: string
  expiry_date?: number
}

export type AddUserRoleMVPPayload = {
  id: string
  expiry_date?: number
}

export type GetUserAccessLogsPayload = {
  userId: string
  to?: number
}

export type UserResetPasswordPayload = { id: string }

export type UserListParams = {
  roleId?: string
  permissionId?: string
  state?: UserStates
}

type UnassignRolesPayload = { userId: string; roleId: string }[]

const user = {
  // Getters
  getUserList: (params: UserListParams = {}) =>
    axios.get<UserListItem[]>('/api/users', { params }),
  getUser: (id: string) => axios.get<User>(`/api/users/${id}`),
  getCurrentUser: () => axios.get<User>('/api/users/current'),

  getUserAccessLogs: ({
    userId,
    to,
  }: GetUserAccessLogsPayload): AxiosPromise<UserAccessLogs[]> =>
    axios.get(`/api/users/${userId}/logs/access${to ? `?to=${to}` : ''}`),

  // Actions

  createUser: (userDetails: CreateUserPayload): AxiosPromise<ActionResponse> =>
    axios.post('/api/users', userDetails),

  updateUser: (params: UserUpdatePayload): AxiosPromise<ActionResponse> =>
    axios.put('/api/users/update', params),

  terminateUser: (params: TerminateUserPayload): AxiosPromise<ActionResponse> =>
    axios.put('/api/users/terminate', params),

  activateUser: (params: ActivateUserPayload): AxiosPromise<ActionResponse> =>
    axios.post('/api/users/unterminate', params),

  resetPassword: (params: UserResetPasswordPayload): AxiosPromise<ActionResponse> =>
    axios.put('/api/users/reset', params),

  // Roles
  addRoles: (
    userId: string,
    payload: CrossCheckedPayload<AddUserRoleMVPPayload[]>,
  ): AxiosPromise<ActionResponse> => {
    const { body, headers } = splitPayloadHeader(payload)
    return axios.post(`/api/users/${userId}/roles`, body, {
      headers: {
        ...headers,
        'X-Version': 'future',
      },
    })
  },
  removeRole: (userId: string, roleId: string): AxiosPromise<ActionResponse> =>
    axios.delete(`/api/users/${userId}/roles/${roleId}`),

  unassignRoles: (body: UnassignRolesPayload): AxiosPromise<ActionResponse> =>
    axios.post('/api/users/roles/unassign', body),
}

export default user
