import { PermissionOversight as TPermissionOversight } from 'api/idave/permissions'

export const getOversightLabel = (oversight: TPermissionOversight) => {
  switch (oversight) {
    case TPermissionOversight.explicit:
      return 'Requires explicit addition on a need to know basis'
    case TPermissionOversight.masked:
      return 'Data point is masked'
    case TPermissionOversight.none:
      return 'None'
    case TPermissionOversight.xCheck:
      return 'Requires a cross-check approval from another approver'
    default:
      return 'Unknown'
  }
}
