import { createEnumMap } from 'utils/types/createEnumMap'
import { ValueOf } from 'utils/types/valueOf'

export type ParamType =
  | { type: 'text' }
  | { type: 'uuid' }
  | { type: 'bool' }
  | { type: 'number' }
  | { type: 'timestamp' }
  | { type: 'array'; subType: ParamType; arrayType: ArrayType }
  | { type: 'entity'; entityType: string }

export type Value<T extends Param = Param> = T['value']

export const ArrayType = createEnumMap('list', 'set')
export type ArrayType = ValueOf<typeof ArrayType>

type CreateParam<Type extends ParamType> = {
  name: string
  value: ParamValue<Type>
}

type ParamValue<Type extends ParamType> = {
  type: Type
  value?: InferValueType<Type>
  editConfig?: InferEditConfigType<Type>
}

type ArrayEditConfig = { type: 'array'; actions: Array<'ADD_ITEM' | 'DELETE_ITEM'> }
type SimpleEditConfig<T extends ParamType> = {
  type: 'simple'
  required?: boolean
  options?: ParamValue<T>[]
}

type InferEditConfigType<T extends ParamType> = T extends {
  type: 'array'
  subType: ParamType
}
  ? ArrayEditConfig
  : SimpleEditConfig<T>

export type InferValueType<T extends ParamType> = T extends { type: 'bool' }
  ? boolean
  : T extends { type: 'text' } | { type: 'uuid' }
  ? string
  : T extends { type: 'number' } | { type: 'timestamp' }
  ? number
  : T extends { type: 'entity'; entityType: string }
  ? Param[]
  : T extends {
      type: 'array'
      subType: infer SubType extends ParamType
      arrayType: ArrayType
    }
  ? InferArrayValue<SubType>
  : never

export type InferArrayValue<T extends ParamType> = T extends { type: 'bool' }
  ? Array<Value<BooleanParam>>
  : T extends { type: 'text' }
  ? Array<Value<TextParam>>
  : T extends { type: 'uuid' }
  ? Array<Value<UuidParam>>
  : T extends { type: 'number' }
  ? Array<Value<NumberParam>>
  : T extends { type: 'timestamp' }
  ? Array<Value<TimestampParam>>
  : T extends { type: 'entity'; entityType: string }
  ? Array<Value<EntityParam>>
  : T extends {
      type: 'array'
      subType: infer SubType extends ParamType
      arrayType: ArrayType
    }
  ? Array<ArrayParam<SubType>['value']>
  : never

export type TextParam = CreateParam<{ type: 'text' }>
export type UuidParam = CreateParam<{ type: 'uuid' }>
export type BooleanParam = CreateParam<{ type: 'bool' }>
export type NumberParam = CreateParam<{ type: 'number' }>
export type TimestampParam = CreateParam<{ type: 'timestamp' }>
export type EntityParam = CreateParam<{ type: 'entity'; entityType: string }>
export type ArrayParam<T extends ParamType = ParamType> = CreateParam<{
  type: 'array'
  subType: T
  arrayType: ArrayType
}>

export type Param =
  | TextParam
  | UuidParam
  | BooleanParam
  | NumberParam
  | TimestampParam
  | EntityParam
  | ArrayParam
