import { usePermissions } from '@revolut-internal/idave-web-auth'
import { Action } from '@revolut/ui-kit'
import { DART_PERMISSIONS } from 'security'
import { EmployeeModifier, UserRestrictionRow } from 'view/Dart/UserRestrictions/types'

type Props = {
  row: UserRestrictionRow
  onDeleteClick: (restriction: EmployeeModifier) => void
}

export const DeleteRestrictionCell = ({ row, onDeleteClick }: Props) => {
  const { hasPermission, hasSomePermissions } = usePermissions()
  const disabledUnlock =
    row.employeeModifierType === 'blockedEmployee' &&
    !hasPermission(DART_PERMISSIONS.EMPLOYEES_UNBLOCK)
  const disabledRelation =
    row.employeeModifierType === 'restrictedRelation' &&
    !hasSomePermissions(
      DART_PERMISSIONS.EMPLOYEES_DELETE_RESTRICTIONS,
      DART_PERMISSIONS.EMPLOYEES_DELETE_RESTRICTIONS_CROSS_CHECK,
    )
  const disabledUnknown = row.employeeModifierType === 'unknown'

  const disabled = disabledRelation || disabledUnknown || disabledUnlock

  return (
    <Action
      onClick={(e) => {
        e.stopPropagation()
        onDeleteClick(row.employeeModifier)
      }}
      disabled={disabled}
    >
      Delete
    </Action>
  )
}
