import { NumberParam } from 'api/types/xChecks'
import { Input } from '@revolut/ui-kit'
import { useCallback } from 'react'
import { notNullable } from 'utils/common'
import { InputProps } from '../types'

export const NumberItem = ({ value, onChange }: InputProps<NumberParam>) => {
  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.valueAsNumber
      return onChange({
        ...value,
        value: notNullable(newValue) ? newValue : undefined,
      })
    },
    [onChange, value],
  )

  return (
    <Input
      label="Numeric value"
      type="number"
      value={value.value}
      onChange={onInputChange}
    />
  )
}
