import { usePermissions } from '@revolut-internal/idave-web-auth'
import { MoreBar } from '@revolut/ui-kit'
import { DART_PERMISSIONS } from 'security'
import { filterActiveIds } from 'components/EntitiesTable/utils'
import { useAddUserPopup } from './components/AddUserPopup'

export const AddAction = ({
  companyId,
  selectedHash,
}: {
  companyId: string
  selectedHash: Record<string, boolean>
}) => {
  const { showAddUserPopup } = useAddUserPopup(companyId)

  const { hasSomePermissions } = usePermissions()
  const hasAddPermission = hasSomePermissions(
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_EMPLOYEE_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_EMPLOYEE_ACCESS_CROSS_CHECK,
  )
  const selectedCount = filterActiveIds(selectedHash).length

  return (
    <>
      {!selectedCount && hasAddPermission && (
        <MoreBar.Action useIcon="Plus" onClick={showAddUserPopup}>
          Add users
        </MoreBar.Action>
      )}
    </>
  )
}
