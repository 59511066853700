import { Group, useVirtualViewport } from '@revolut/ui-kit'
import { useCallback, useRef } from 'react'
import { EntityData } from 'view/XChecks/XCheck/lib/hooks'
import { UserListItem } from './components/UserListItem'
import { getSelectedValues } from './utils'
import { EmptyList } from './components/EmptyList'

type UserListProps = {
  update: (ids: string[]) => void
  items: EntityData[]
  selectedHash: Record<string, boolean>
  scrollRef?: React.RefObject<HTMLElement>
}

export const UserList = ({ items, scrollRef, selectedHash, update }: UserListProps) => {
  const parentRef = useRef<HTMLElement>(null)
  const estimateSize = useCallback(() => 76, [])
  const keyExtractor = useCallback((index: number) => items[index].id, [items])

  const list = useVirtualViewport({
    parentRef,
    size: items.length,
    estimateSize,
    keyExtractor,
    overscan: 16,
    scrollRef,
  })

  const onItemChange = useCallback(
    (newItemId: string) =>
      update(
        getSelectedValues({
          selectedHash,
          newItemId,
        }),
      ),
    [update, selectedHash],
  )

  if (!items.length) {
    return <EmptyList />
  }

  return (
    <Group
      ref={parentRef}
      style={{
        position: 'relative' as const,
        height: `${list.totalSize}px`,
      }}
    >
      {list.virtualItems.map((virtual) => {
        const userItem = items[virtual.index]

        return (
          <UserListItem
            key={userItem.id}
            onChange={onItemChange}
            selectedHash={selectedHash}
            user={userItem}
            virtualItem={virtual}
          />
        )
      })}
    </Group>
  )
}
