import { match } from 'ts-pattern'
import { ARRAY_CHANGE_LIST_PATTERN } from 'view/XChecks/XCheck/lib/utils'
import { getArrayChangeViewItems } from 'view/XChecks/XCheck/lib/components/Updates/utils'
import { TableItem } from './types'

export const getCounters = (table: TableItem) => {
  return match(table)
    .with(ARRAY_CHANGE_LIST_PATTERN, (change) => ({
      old: change.oldValue.value?.length,
      new: change.newValue.value?.length,
      diff: getArrayChangeViewItems({ change, view: 'diff' }).filter((i) => !i.variant)
        .length,
    }))
    .otherwise(() => undefined)
}
