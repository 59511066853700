import { ArrayParam, Param, Value } from 'api/types/xChecks'
import { useCallback } from 'react'
import { ActionButton, useToggle } from '@revolut/ui-kit'
import { ItemPopup } from '../ItemPopup'

type ArrayItemProps = {
  addItem: (item: Value<Param>) => void
  canAdd: boolean
  param: ArrayParam
  disabled: boolean
}

export const AddItem = ({ addItem, canAdd, param, disabled }: ArrayItemProps) => {
  const [open, toggleOpen] = useToggle()
  const onClick = useCallback(() => {
    toggleOpen.on()
  }, [toggleOpen])
  const onClose = useCallback(() => toggleOpen.off(), [toggleOpen])
  const defaultValue = getDefaultValue(param)

  return (
    <>
      <ActionButton
        variant="accent"
        size="xs"
        disabled={!canAdd}
        onClick={onClick}
        useEndIcon="Plus"
      >
        Add
      </ActionButton>
      {defaultValue && open && (
        <ItemPopup
          disabled={disabled}
          name={param.name}
          defaultValue={defaultValue}
          submit={addItem}
          onClose={onClose}
        />
      )}
    </>
  )
}

const getDefaultValue = (param: ArrayParam): Value<Param> | undefined => {
  const subtype = param.value.type.subType
  if (subtype.type === 'array') {
    return undefined
  }

  // all incompatible types are excluded
  return { type: param.value.type.subType, value: undefined } as Value<Param>
}
