import { Box, Button, Input, Page, VStack } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { Client } from 'api/idave/clients'
import { useCallback, useEffect, useState } from 'react'
import { useStorageItem } from 'hooks/useStorageItem'
import { DeepPartial, FormProvider, useForm } from 'react-hook-form'
import { FieldInput } from 'components/fields/FieldInput'
import { FieldTextArea } from 'components/fields/FieldTextArea'
import { FieldPermissionActionSelect } from 'components/fields/FieldPermissionActionSelect'
import { FieldPermissionClassificationSelect } from 'components/fields/FieldPermissionClassificationSelect'
import { FieldPermissionScopeSelect } from 'components/fields/FieldPermissionScopeSelect'
import { FieldPermissionOversightsSelect } from 'components/fields/FieldPermissionOversightsSelect'
import { getRequiredRule } from 'utils/form/rules'
import { checkSubmitEnabled, isValid, permissionToFormState } from '../utils'
import { useEditPermission } from '../hooks'
import { PermissionEditForm as TPermissionEditForm } from '../types'

type Props = {
  permissionId: string
  permission?: Permission
  loading?: boolean
  client?: Client
  toPermissionPage: () => void
}

export const PermissionEditForm = ({
  permission,
  client,
  permissionId,
  loading,
  toPermissionPage,
}: Props) => {
  const { clearItem, setItem, item } = useStorageItem<DeepPartial<TPermissionEditForm>>(
    `permissionEdit_${permissionId}`,
  )
  const { mutate, isLoading: isUploading } = useEditPermission({
    onSuccess: () => {
      clearItem()
      toPermissionPage()
    },
  })
  const permissionForm = useForm<TPermissionEditForm>({
    reValidateMode: 'onBlur',
    mode: 'all',
    defaultValues: permissionToFormState(item, permission),
  })
  const [isSubmitEnabled, setSubmitEnabled] = useState(
    checkSubmitEnabled(permissionForm.getValues(), permission),
  )

  useEffect(() => {
    const subscription = permissionForm.watch((value) => {
      setItem(value)
      setSubmitEnabled(checkSubmitEnabled(value, permission))
    })
    return () => subscription.unsubscribe()
  }, [permissionForm, setItem, permission])

  const onSubmit = useCallback(() => {
    const payload = permissionForm.getValues()
    if (isValid(payload)) {
      mutate({
        permissionId,
        payload,
      })
    }
  }, [mutate, permissionForm, permissionId])

  return (
    <>
      <FormProvider {...permissionForm}>
        <VStack space="s-24">
          <Input label="Name" disabled value={permission?.name} pending={loading} />
          <Input
            label="Client"
            disabled
            value={client?.name || permission?.client || 'Unknown'}
            pending={loading}
          />
          <Box>
            <FieldTextArea
              name="description"
              maxLength={255}
              label="Description"
              rules={getRequiredRule("Permission description can't remain empty")}
              invalid={!!permissionForm.formState.errors.description}
              errorMessage={permissionForm.formState.errors.description?.message}
            />
          </Box>
          <FieldPermissionActionSelect
            name="action"
            label="Action"
            rules={getRequiredRule("Permission action can't remain empty")}
            invalid={!!permissionForm.formState.errors.action}
            errorMessage={permissionForm.formState.errors.action?.message}
          />
          <FieldPermissionClassificationSelect
            name="classification"
            label="Data classification"
            rules={getRequiredRule("Permission data classification can't remain empty")}
            invalid={!!permissionForm.formState.errors.classification}
            errorMessage={permissionForm.formState.errors.classification?.message}
          />
          <FieldPermissionScopeSelect
            name="scope"
            label="Scope of permission"
            rules={getRequiredRule("Permission scope can't remain empty")}
            invalid={!!permissionForm.formState.errors.scope}
            errorMessage={permissionForm.formState.errors.scope?.message}
          />
          <FieldPermissionOversightsSelect
            name="oversight"
            label="Oversights"
            valueAsTags
            rules={getRequiredRule("Permission oversights can't remain empty")}
            invalid={!!permissionForm.formState.errors.oversight}
            errorMessage={permissionForm.formState.errors.oversight?.message}
          />
          <FieldInput
            name="domain"
            label="Domain"
            rules={getRequiredRule("Permission domain can't remain empty")}
            invalid={!!permissionForm.formState.errors.domain}
            errorMessage={permissionForm.formState.errors.domain?.message}
            maxLength={40}
          />
        </VStack>
      </FormProvider>

      <Page.MainActions>
        <Button
          variant="primary"
          elevated
          disabled={!isSubmitEnabled}
          onClick={onSubmit}
          pending={isUploading}
        >
          Save changes
        </Button>
      </Page.MainActions>
    </>
  )
}
