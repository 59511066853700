import { Resource, ResourceType } from 'api/sam/types/resources'
import {
  APP_STORE_ROLES_URL,
  APP_STORE_USER_PROPERTY_URL,
  ATLASSIAN_URL,
  OKTA_GROUP,
  TOWER_URL,
} from 'constants/urls'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export type ResourceLink = OuterLink | EntityLink
type OuterLink = {
  type: 'link'
  href: string
  label: string
}

type ResourceEntity = 'role' | 'customerCompany'
type EntityLink = {
  type: ResourceEntity
  id: string
  href: string
  label: string
}

export const getResourceLink = (resource: Resource): ResourceLink | undefined => {
  switch (resource.resourceType) {
    case ResourceType.AppStoreUserProperty:
      return {
        type: 'link',
        href: APP_STORE_USER_PROPERTY_URL,
        label: 'AppStore user property doc',
      }
    case ResourceType.AppStoreUserRole:
      return { type: 'link', href: APP_STORE_ROLES_URL, label: 'AppStore roles doc' }
    case ResourceType.GoogleGroup:
      return {
        type: 'link',
        href: `${ATLASSIAN_URL}/wiki/spaces/IS/pages/2653291177/Google+Groups+access+reference`,
        label: 'Google group doc (confluence)',
      }
    case ResourceType.IdaveRole:
      return {
        type: 'role',
        href: generatePath(Url.Role, { roleId: resource.externalId }),
        label: 'Role',
        id: resource.externalId,
      }
    case ResourceType.MetabaseGroup:
      return {
        type: 'link',
        label: 'Metabase group in Tower',
        href: `${TOWER_URL}/components?searchQuery=${
          resource.resourceName.split(' ')[0]
        }`,
      }
    case ResourceType.TeamCityGroup:
      return {
        type: 'link',
        href: `${ATLASSIAN_URL}/wiki/spaces/BD/pages/1468111265/TeamCity+Policy+Access+Controls`,
        label: 'TeamCity group doc (confluence)',
      }

    case ResourceType.OktaGroup:
      return {
        type: 'link',
        href: `${OKTA_GROUP}/${resource.externalId}`,
        label: 'Okta group',
      }
    case ResourceType.DartAllowCustomerCompany:
      return {
        type: 'customerCompany',
        href: generatePath(Url.DartCustomerCompany, { companyId: resource.externalId }),
        label: 'Customer company',
        id: resource.externalId,
      }

    default:
      return undefined
  }
}
