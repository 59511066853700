import { StatusWidget, Text } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { match, P } from 'ts-pattern'
import { UpdateTab } from '../types'

export const UpdatesError = ({ tab }: { tab?: UpdateTab }) => (
  <StatusWidget>
    <StatusWidget.Image {...get3DImageSrcSetProps('3D358', 'v2')} />
    <StatusWidget.Title>{getTitle(tab)}</StatusWidget.Title>
    <StatusWidget.Description>{getDescription(tab)}</StatusWidget.Description>
  </StatusWidget>
)

const getDescription = (tab?: UpdateTab) =>
  match(tab)
    .with(UpdateTab.params, () => (
      <Text>
        We couldn&apos;t retrieve the request input. This may be due to lack of access or
        a service issue. <br />
        Report this issue to the support team if the problem persists
      </Text>
    ))
    .with(UpdateTab.changes, () => (
      <Text>
        We couldn&apos;t retrieve the request caused changes. This may be due to lack of
        access or a service issue. <br />
        Report this issue to the support team if the problem persists
      </Text>
    ))
    .with(P.nullish, () => (
      <Text>
        We couldn&apos;t retrieve the request information. This may be due to lack of
        access or a service issue. <br />
        Report this issue to the support team if the problem persists
      </Text>
    ))
    .exhaustive()

const getTitle = (tab?: UpdateTab) =>
  match(tab)
    .with(UpdateTab.changes, () => 'Unable to load request caused changes')
    .with(UpdateTab.params, () => 'Unable to load request input details')
    .with(P.nullish, () => 'Unable to load request details')
    .exhaustive()
