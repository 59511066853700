import { BottomSheet, Button, Header, useToggle } from '@revolut/ui-kit'
import { useEffect } from 'react'
import { WarningSettings } from '../useActions/types'

type Props = {
  open: boolean
  confirmation?: WarningSettings
  onConfirmClick: () => void
  onCancelClick: () => void
}

const CONFIRM_DISABLED_TIMEOUT = 2500

export const WarningPopup = ({
  open,
  confirmation,
  onCancelClick,
  onConfirmClick,
}: Props) => {
  const [confirmationDisabled, toggleConfirmationDisabled] = useToggle()

  /**
   * Keep confirm button pending & disabled to make user read warning
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      toggleConfirmationDisabled.off()
    }, CONFIRM_DISABLED_TIMEOUT)

    return () => {
      clearTimeout(timer)
      toggleConfirmationDisabled.on()
    }
  }, [open, toggleConfirmationDisabled])

  if (!confirmation) {
    return null
  }

  return (
    <BottomSheet open={open}>
      <Header>
        <Header.Title>{confirmation.title}</Header.Title>
        <Header.Description>{confirmation.description}</Header.Description>
      </Header>

      <BottomSheet.Actions horizontal>
        <Button variant="secondary" elevated onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          variant={confirmation.proceedActionButtonVariant}
          elevated
          onClick={onConfirmClick}
          pending={confirmationDisabled}
          disabled={confirmationDisabled}
        >
          {confirmation.proceedActionLabel}
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
