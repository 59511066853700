import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router'
import { QueryKey } from 'helpers/configQuery'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { useCallback } from 'react'
import { usePermissions } from '@revolut-internal/idave-web-auth'
import { ServiceName, ServicePermission } from 'services/permissions'
import { useToasts } from './useToasts'

const getAppXCheckQueries = (app: ServiceName) => {
  switch (app) {
    case 'dart':
      return [QueryKey.DartCrossChecksList]
    case 'idave':
      return [QueryKey.CrossChecksList]
    case 'sam':
      return [QueryKey.SamCrossChecksList, QueryKey.SamMyCrossChecksList]
    default:
      return []
  }
}

const CROSSCHECK_SERVICE_VERSION = '3'
export const useCrossCheck = (
  actionPermission: ServicePermission | ServicePermission[],
  xCheckPermission: ServicePermission | ServicePermission[],
) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { showSuccessToast, showErrorToast } = useToasts()
  const { hasPermission, hasSomePermissions } = usePermissions()
  const hasCrossCheckPermissions = Array.isArray(xCheckPermission)
    ? hasSomePermissions(...xCheckPermission)
    : hasPermission(xCheckPermission)
  const isDirect = Array.isArray(actionPermission)
    ? hasSomePermissions(...actionPermission)
    : hasPermission(actionPermission)

  const isCrossCheck = hasCrossCheckPermissions && !isDirect
  const actionSystem = Array.isArray(actionPermission)
    ? actionPermission[0].service
    : actionPermission.service

  const toCrosscheck = useCallback(
    (params?: { id?: string; version?: string }) => {
      if (!params?.id) {
        return showErrorToast('Crosscheck creation failed')
      }

      const { id, version } = params
      showSuccessToast('Crosscheck created')

      if (version === CROSSCHECK_SERVICE_VERSION) {
        return navigate(generatePath(Url.XCheck, { xCheckId: id }))
      }

      const queryKeys = getAppXCheckQueries(actionSystem)
      queryKeys.forEach((qKey) => queryClient.invalidateQueries([qKey]))

      return navigate(
        generatePath(Url.CrossCheck, { source: actionSystem, crossCheckId: id }),
      )
    },
    [showErrorToast, showSuccessToast, navigate, actionSystem, queryClient],
  )

  return { toCrosscheck, isCrossCheck, isDirect }
}
