import { ToxicPair } from 'api/idave/toxic'
import { uniq } from 'lodash'
import { notNullableMap } from 'utils/array'
import { toPresenceMap } from 'utils/array/toPresenceMap'

/**
 * returns active toxic pair ids presented in role permissions
 */
export const matchToxicPairsByPermissions = (
  permissions: string[],
  toxicPairs: ToxicPair[],
) => {
  const permissionHash = toPresenceMap(permissions)
  const activeToxicPairs = toxicPairs.filter((toxicPair) => toxicPair.state === 'ACTIVE')
  return uniq(
    notNullableMap(activeToxicPairs, (pair) => {
      const { firstPermission, secondPermission } = pair
      return permissionHash[firstPermission] && permissionHash[secondPermission]
        ? pair
        : undefined
    }),
  )
}
