import { useToggle } from '@revolut/ui-kit'
import { Param } from 'api/types/xChecks'
import { set } from 'lodash/fp'
import { useCallback, useState } from 'react'
import { notNullable } from 'utils/common'
import { FieldValue } from '../types'

type FormValues = {
  params: Param[]
}
export const useForm = ({ params }: FormValues) => {
  const [formState, setFormState] = useState<FormValues>({ params })
  const [errors, setErrors] = useState({})
  const [dirty, toggleDirty] = useToggle({ defaultState: false })

  const updateForm = useCallback(
    (path: string, value: FieldValue) => {
      toggleDirty.on()
      setFormState((current) => set(path, value, current))
    },
    [setFormState, toggleDirty],
  )

  const setError = useCallback(
    (path: string, errorMessage?: string) => {
      setErrors((current) => ({ ...current, [path]: errorMessage }))
    },
    [setErrors],
  )

  const hasErrors = !!Object.values(errors).filter(notNullable).length

  return { updateForm, errors, setError, formState, dirty, hasErrors }
}
