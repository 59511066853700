import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { Data, HasPermissionFn } from '../../types'
import { useViews, ViewSettings } from '../../hooks'
import { Provider } from '../../hooks/Provider'
import { EditLayout } from './components/EditLayout'
import { ViewLayout } from './components/ViewLayout'

export const XCheck = ({
  onBackClick,
  xCheckId,
  hasPermission,
  userIdData,
  onXCheckUpdate,
  viewSettings,
  apiSettings,
}: {
  xCheckId: string

  userIdData: Data<string>
  onXCheckUpdate: () => void

  apiSettings: ApiSettings
  viewSettings: ViewSettings
  hasPermission: HasPermissionFn
  onBackClick: () => void
}) => {
  return (
    <Provider
      xCheckId={xCheckId}
      hasPermission={hasPermission}
      userIdData={userIdData}
      onXCheckUpdate={onXCheckUpdate}
      viewSettings={viewSettings}
      apiSettings={apiSettings}
    >
      <Inner onBackClick={onBackClick} />
    </Provider>
  )
}

const Inner = ({ onBackClick }: { onBackClick: () => void }) => {
  const { isEditing } = useViews()

  return isEditing ? (
    <EditLayout onBackClick={onBackClick} />
  ) : (
    <ViewLayout onBackClick={onBackClick} />
  )
}
