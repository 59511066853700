import { indexOf, sortBy } from 'lodash'
import { ChangeItem, Variant } from '../../types'
import { Indexed } from './indexChanges'
import { difference } from './difference'
import { uniq } from './uniq'

export const getSetDiffItems = <T>(newItems: T[], oldItems: T[]) => {
  const removed = difference(oldItems, newItems)
  const added = difference(newItems, oldItems)

  return uniq(oldItems.concat(newItems)).reduce<ChangeItem<T>[]>((acc, value) => {
    const isRemoved = indexOf(removed, value) !== -1
    if (isRemoved) {
      return [...acc, { value, variant: Variant.removed }]
    }

    const isAdded = indexOf(added, value) !== -1
    if (isAdded) {
      return [...acc, { value, variant: Variant.added }]
    }

    return acc
  }, [])
}

export const getArrayDiffItems = <K>(
  newItems: Indexed<K>[],
  oldItems: Indexed<K>[],
): ChangeItem<K>[] => {
  const removed = difference(oldItems, newItems)
  const added = difference(newItems, oldItems)
  const items = sortBy(uniq(newItems.concat(oldItems)), 'idx')

  return items.map((value) => {
    if (indexOf(removed, value) !== -1) {
      return { variant: Variant.removed, value: value.item }
    }

    if (indexOf(added, value) !== -1) {
      return { variant: Variant.added, value: value.item }
    }

    return { value: value.item }
  })
}
