import axios from 'axios'
import { EmployeeType, SamPolicySubjectType } from './policies'

type EntityType = 'access-policy' | 'cross-check' | 'employee'

export type SAMGetAuditLogsParams = {
  entityType: EntityType
  entityId: string
}

export type SAMPolicyCreateLog = {
  action: 'ACCESS_POLICY_CREATED'
  created_at: string
  id: string
  data: {
    policy_author: string
    policy_name: string
    business_reason?: string
    mandatory_trainings?: string[]
    resource_id?: string
    subject_type: SamPolicySubjectType
    subject_employee_types?: EmployeeType[]
    subject_employee_type?: EmployeeType
    subject_contract_types?: string[]
    subject_contract_type?: string
    subject_seniority_ids?: string[]
    subject_seniority_id?: string
    subject_team_ids?: string[]
    subject_team_id?: string
    subject_employee_ids?: string[]
    subject_employee_id?: string
    subject_specialisation_id?: string
    subject_specialisation_ids?: string[]
    subject_function_id?: string
    subject_function_ids?: string[]
    subject_position_id?: string
    subject_position_ids?: string[]
  }
}

export type SAMPolicyUpdateLog = {
  action: 'ACCESS_POLICY_UPDATED'
  created_at: string
  id: string
  data: {
    business_reason: string
    name: string
    updated_by?: string
    mandatory_trainings?: string[]
    mandatory_trainings_added?: string[]
    mandatory_trainings_deleted?: string[]
  }
}

export type SAMPolicyResourceUpdateLog = {
  action: 'ACCESS_POLICY_RESOURCES_SET'
  created_at: string
  id: string
  data: {
    updated_by?: string
    resource_ids?: string[]
    resource_deleted_ids?: string[]
    resource_summary_ids?: string[]
  }
}

export type SAMPolicyDeleteLog = {
  action: 'ACCESS_POLICY_DELETED'
  created_at: string
  id: string
  entity_id: string
  entity_type: 'ACCESS_POLICY'
  data: {
    policy_id: string
    deleted_by: string
  }
}

export type SAMLog =
  | SAMPolicyCreateLog
  | SAMPolicyUpdateLog
  | SAMPolicyResourceUpdateLog
  | SAMPolicyDeleteLog

export type SAMGetAuditLogsResponse = {
  data: SAMLog[]
}

export const auditLogs = {
  getLogs: (params: SAMGetAuditLogsParams) =>
    axios.get<SAMGetAuditLogsResponse>(
      `/sam-api/api/audit/${params.entityType}/${params.entityId}`,
    ),
}
