import { TableColumn } from '@revolut/ui-kit'
import { Resource as ApiResource } from 'api/sam/types/resources'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { getResourceTypeLabel } from 'utils/resource/getResourceTypeLabel'
import { GroupCell, getGroupCellText } from 'components/GroupCell'
import { Group } from 'api/idave/groups'

export interface Resource extends ApiResource {
  id: string
}

export const getColumns = (groupMap: Map<string, Group>): TableColumn<Resource>[] => [
  {
    id: 'name',
    Header: 'Name',
    accessor: 'resourceName',
  },
  {
    Header: 'Type',
    accessor: (resource: Resource) => getResourceTypeLabel(resource.resourceType),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'Owner group',
    accessor: ({ ownerGroupId }: Resource) =>
      getGroupCellText({ ownerGroupId, groupMap }),
    Cell: (params: { row: { original: Resource } }) => (
      <GroupCell groupMap={groupMap} ownerGroupId={params.row.original.ownerGroupId} />
    ),
    filter: 'includesValue',
    Filter: SelectItemFilter,
  },
  {
    Header: 'ID',
    accessor: 'resourceId',
  },
  {
    Header: 'External ID',
    accessor: 'externalId',
  },
]
