import { Subheader } from '@revolut/ui-kit'
import { ToxicPair } from 'api/idave/toxic'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { EntityDescription } from 'components/EntityDescription'
import { PermissionLink } from 'components/Links/PermissionLink'
import { UserLink } from 'components/Links/UserLink'
import { StateText } from 'components/StateText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'
import { formatDateTime } from 'utils/date'

type Props = {
  toxicPair: ToxicPair
}

export const ToxicPairInfo = ({ toxicPair }: Props) => {
  return (
    <>
      <Subheader variant="nested">
        <Subheader.Title>Details</Subheader.Title>
      </Subheader>
      <DetailsGroup
        rows={[
          ['Name', toxicPair.name],
          ['ID', <CopyableText value={toxicPair.id} key={toxicPair.id} />],
          ['Owner', <UserLink id={toxicPair.owner} key={toxicPair.owner} />],
          ['State', <StateText key={toxicPair.state} state={toxicPair.state} />],
          [
            'First permission',
            <PermissionLink
              id={toxicPair.firstPermission}
              key={toxicPair.firstPermission}
              withClient
            />,
          ],
          [
            'Second permission',
            <PermissionLink
              id={toxicPair.secondPermission}
              key={toxicPair.secondPermission}
              withClient
            />,
          ],
          [
            'Review date',
            toxicPair.reviewDate
              ? formatDateTime(toxicPair.reviewDate)
              : EMPTY_VALUE_FALLBACK,
          ],
        ]}
      />
      <EntityDescription description={toxicPair.justification} title="Justification" />
    </>
  )
}
