import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { Data, HasPermissionFn } from '../../../../lib/types'
import { NavigationSettings } from '../../hooks'
import { XCheckProvider } from '../../XCheckProvider'
import { Layout } from './components/Layout'

export const XChecks = ({
  hasPermission,
  userIdData,
  apiSettings,
  navigationSettings,
}: {
  userIdData: Data<string>
  hasPermission: HasPermissionFn
  apiSettings: ApiSettings
  navigationSettings: NavigationSettings
}) => {
  return (
    <XCheckProvider
      hasPermission={hasPermission}
      userIdData={userIdData}
      apiSettings={apiSettings}
      navigationSettings={navigationSettings}
    >
      <Layout />
    </XCheckProvider>
  )
}
