import { PageMainSize } from '@revolut/ui-kit'
import { OrganisationTab } from '../type'

export const tabToPageSize = (tab: OrganisationTab) => {
  switch (tab) {
    case 'auditLog':
      return PageMainSize.FULL
    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
