import { CreatedEntity as TCreatedEntity } from 'api/types/xChecks'
import { CreatedEntity } from './components/CreatedEntity'

type Props = {
  created?: TCreatedEntity[]
  firstItemExpanded: boolean
}
export const Created = ({ created, firstItemExpanded }: Props) => {
  if (!created?.length) {
    return null
  }
  return (
    <>
      {created.map((createdEntity, idx) => (
        <CreatedEntity
          key={idx}
          entity={createdEntity}
          defaultExpanded={idx === 0 && firstItemExpanded}
        />
      ))}
    </>
  )
}
