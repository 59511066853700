import { Page, TabBar } from '@revolut/ui-kit'
import { useQueryClient, useQueryClientCustomization } from 'queries/idave/clients'
import { ClientCustomization, Client as ClientType } from 'api/idave/clients'
import { Url } from 'routing'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { TabBarLink } from 'components/TabBarLink'
import { generatePath } from 'react-router'
import { IDAVE_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { useHeaderActions } from 'components/HeaderActions'
import { NoAccessPage } from 'components/NoAccessWidget'
import { Title } from 'components/Title'
import { useNavigateToList } from 'hooks/useBackToResults'
import { ClientAuditLogs } from './components/ClientAuditLogs'
import { ClientPermissions } from './components/ClientPermissions'
import { ClientDetails } from './components/ClientDetails'
import { ClientSkeleton } from './components/ClientSkeleton'
import { ClientProps, ClientTab } from './types'
import { ClientHeaderName } from './components/ClientHeaderName'
import { tabToPageSize } from './utils'

export const Client = ({ clientId, tab }: ClientProps) => {
  const {
    data: client,
    status: clientQS,
    fetchStatus: clientFS,
    refetch,
  } = useQueryClient(clientId)
  const {
    data: customization,
    status: customizationQS,
    fetchStatus: customizationFS,
  } = useQueryClientCustomization(clientId)

  const onBackClick = useNavigateToList({
    listUrl: Url.Clients,
    entityUrl: generatePath(Url.Client, { clientId }),
  })

  return (
    <QuerySwitch
      required={[{ qs: clientQS, fs: clientFS }]}
      optional={[{ qs: customizationQS, fs: customizationFS }]}
      data={client}
      renderError={() => (
        <RequestErrorPage
          pageTitle={clientId}
          title="Something went wrong"
          description="Requested client info fetch failed"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => <NoAccessPage pageTitle={clientId} onBackClick={onBackClick} />}
      renderLoading={() => (
        <Outer clientId={clientId} onBackClick={onBackClick} tab={tab}>
          <ClientSkeleton clientId={clientId} tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer clientId={clientId} client={data} onBackClick={onBackClick} tab={tab}>
          <Inner client={data} tab={tab} customization={customization} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  client?: ClientType
  clientId: string
  children: React.ReactNode
  onBackClick: () => void
  tab: ClientTab
}) => {
  const { clientId, client, children, onBackClick, tab } = props
  const HeaderActions = useHeaderActions()

  return (
    <>
      <Title value={[client?.name, 'Clients'].filter(Boolean).join(' | ')} />
      <Page.Header onBack={onBackClick} actions={HeaderActions && <HeaderActions />}>
        <ClientHeaderName client={client} />
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <TabBarLink to={generatePath(Url.ClientDetails, { clientId })}>
            Details
          </TabBarLink>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.ClientPermissions, { clientId })}>
              Permissions
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.ClientAuditLogs, { clientId })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main size={tabToPageSize(tab)}>{children}</Page.Main>
    </>
  )
}

const Inner = (props: {
  client: ClientType
  tab: ClientTab
  customization?: ClientCustomization
}) => {
  const { client, tab, customization } = props

  switch (tab) {
    case 'permissions':
      return <ClientPermissions client={client} />
    case 'log':
      return <ClientAuditLogs clientId={client.id} />
    default:
    case 'details':
      return <ClientDetails client={client} customization={customization} />
  }
}
