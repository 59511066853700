import { MoreBar } from '@revolut/ui-kit'
import { DART_PERMISSIONS } from 'security'
import { QueryKey } from 'helpers/configQuery'
import { useQueryClient } from '@tanstack/react-query'
import { useCreateRestriction } from 'view/Dart/UserRestrictions/hooks'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'

export const UserRestrictionAction = () => {
  const queryClient = useQueryClient()

  const { onCreateRestriction } = useCreateRestriction({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.DirectRelations])
    },
  })

  return (
    <PermissionsCheck permission={DART_PERMISSIONS.EMPLOYEES_ADD_RESTRICTIONS}>
      <MoreBar.Action useIcon="Plus" onClick={onCreateRestriction}>
        Add restriction
      </MoreBar.Action>
    </PermissionsCheck>
  )
}
