import {
  MentionsInput as RcMentionsInput,
  Mention,
  OnChangeHandlerFunc,
  DataFunc,
  MentionsInputStyle,
} from 'react-mentions'
import {
  Avatar,
  Dropdown,
  HStack,
  Token,
  Text,
  abbreviate,
  useTheme,
  Relative,
  Absolute,
  IconButton,
  useToggle,
  Box,
} from '@revolut/ui-kit'
import { useCallback, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { useApi, useXCheckData } from 'view/XChecks/XCheck/lib/hooks'
import { getAvatarProps } from 'utils/common/getAvatarProps'
import { useQuery } from '@tanstack/react-query'
import Fuse from 'fuse.js'
import { allKeys } from 'utils/array'
import { QueryKey } from 'view/XChecks/XCheck/lib/consts'
import { MarkdownRenderer } from 'view/XChecks/XCheck/lib/components/common/MarkdownRenderer'
import { formatUser } from './utils'

type Props = {
  value: string
  withPreview?: boolean
  rows?: number
  onChange: OnChangeHandlerFunc
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
  disabled?: boolean
  placeholder?: string
  renderActions?: () => JSX.Element
  onCloseClick?: () => void
  maxSuggestAmount?: number
  forceSuggestionsAboveCursor?: boolean
}

const PADDING_SIZE = 16
const ROW_HEIGHT = 22
const DEFAULT_MIN_HEIGHT = 70

const getMentionStyles = ({ rows }: Partial<Props>): MentionsInputStyle => ({
  control: {
    minHeight: rows ? PADDING_SIZE + ROW_HEIGHT * rows : DEFAULT_MIN_HEIGHT,
    background: Token.color.searchBackground,
    borderRadius: Token.radius.r16,

    color: Token.color.foreground,
    fontFamily: Token.font.brand,
    fontWeight: Token.fontWeight.body1,
    fontSize: Token.fontSize.body1,
    letterSpacing: Token.letterSpacing.emphasis1,
  },
  highlighter: {
    color: Token.color.accent,
    border: 'none',
    padding: `${Token.size.s8} ${Token.size.s16}`,
    lineHeight: Token.lineHeight.body1,
  },
  input: {
    padding: `${Token.size.s8} ${Token.size.s16}`,
    border: 'none',
    outline: 'none',
    lineHeight: Token.lineHeight.body1,
    borderRadius: Token.radius.r16,
  },

  suggestions: {
    background: 'transparent',
    list: {
      borderRadius: Token.radius.r16,
    },
  },
})

export const MentionsInput = ({
  value,
  onChange,
  disabled,
  onKeyDown,
  renderActions,
  onCloseClick,
  placeholder = 'Add comment',
  maxSuggestAmount = 50,
  forceSuggestionsAboveCursor,
  rows,
  withPreview,
}: Props) => {
  const [isPreviewMode, togglePreview] = useToggle({ defaultState: false })

  useEffect(() => {
    if (!value && isPreviewMode) {
      togglePreview.off()
    }
  }, [value, togglePreview, isPreviewMode])

  const { mode } = useTheme()
  const isDarkMode = mode === 'dark'

  const { xCheckParticipantIds } = useXCheckData()
  const api = useApi()

  const { data = [], isLoading } = useQuery({
    queryFn: api.fetchUsers,
    queryKey: [QueryKey.Users],
  })

  const xCheckParticipants = data
    .filter((user) => xCheckParticipantIds.includes(user.id))
    .map(formatUser)

  const options = useMemo(
    () => ({
      threshold: 0.15,
      keys: allKeys(data),
      ignoreLocation: true,
      shouldSort: true,
      includeScore: true,
    }),
    [data],
  )

  const fuse = useMemo(() => new Fuse(data, options), [data, options])

  const fetchUsers: DataFunc = useCallback(
    (query, callback) => {
      let res

      if (query) {
        res = fuse
          .search(query)
          .slice(0, maxSuggestAmount)
          .map(({ item: user }) => formatUser(user))
      } else {
        res = xCheckParticipants.slice(0, maxSuggestAmount)
      }

      callback(res)
    },
    [fuse, xCheckParticipants, maxSuggestAmount],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchUsersDebounced: DataFunc = useCallback(debounce(fetchUsers, 500), [
    fetchUsers,
  ])

  return (
    <Relative>
      {isPreviewMode ? (
        <Box pl="s-16" py="s-8" marginTop="s-2" minHeight={70}>
          <MarkdownRenderer>{value}</MarkdownRenderer>
        </Box>
      ) : (
        <RcMentionsInput
          style={getMentionStyles({ rows })}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled || isLoading}
          placeholder={placeholder}
          customSuggestionsContainer={(children) => (
            <Dropdown open focusTrap={false}>
              {children}
            </Dropdown>
          )}
          forceSuggestionsAboveCursor={forceSuggestionsAboveCursor}
        >
          <Mention
            markup="<@__id__|__display__>"
            displayTransform={(_id: string, display: string) => `@${display}`}
            trigger="@"
            data={fetchUsersDebounced}
            style={{ backgroundColor: isDarkMode ? '#2b3d3e' : '#cee4e5' }}
            appendSpaceOnAdd
            renderSuggestion={(
              { id, display, avatar },
              _search,
              highlightedDisplay,
              _index,
              focused,
            ) => {
              return (
                <Dropdown.Item use="button" aria-pressed={focused}>
                  <HStack space="s-16" align="center" aria-label="User option">
                    <Avatar
                      variant="brand"
                      {...getAvatarProps({
                        id: id.toString(),
                        label: display ? abbreviate(display) : undefined,
                        avatar,
                      })}
                    />
                    <Text variant="primary">{highlightedDisplay}</Text>
                  </HStack>
                </Dropdown.Item>
              )
            }}
          />
        </RcMentionsInput>
      )}

      {renderActions && (
        <Absolute bottom={8} right={8}>
          {renderActions()}
        </Absolute>
      )}
      <Absolute top={8} right={8}>
        <HStack gap="s-4">
          {withPreview && (
            <IconButton
              variant="icon"
              color={!value ? Token.color.shimmer : Token.color.accent}
              disabled={!value}
              useIcon={isPreviewMode ? 'Pencil' : 'EyeShow'}
              onClick={togglePreview.switch}
              title="Preview"
            />
          )}
          {onCloseClick && (
            <IconButton
              useIcon="Cross"
              variant="icon"
              onClick={onCloseClick}
              color={Token.color.neutral}
              title="Close"
            />
          )}
        </HStack>
      </Absolute>
    </Relative>
  )
}
