import { stringifySubjectType } from 'view/Sam/utils'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getPolicySubjectChangeType } from './utils'

export const getSubjectType = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  const requested =
    crossCheck.executableName === 'CreatePolicy' ||
    crossCheck.executableName === 'UpdatePolicy'
      ? crossCheck.executableParams.subject_params?.subject_type
      : undefined

  const current =
    entity.type === 'AccessPolicy' && entity.value
      ? entity.value.subject.subjectType
      : undefined

  return {
    title: 'Subject type',
    value: {
      current: current ? stringifySubjectType(current) : undefined,
      requested: requested ? stringifySubjectType(requested) : undefined,
      forecasted: requested ? stringifySubjectType(requested) : undefined,
      type: 'text',
    },
    changeType: getPolicySubjectChangeType(crossCheck),
    isContext: true,
  }
}
