import { StatusWidget, ActionButton, IconName } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'

export const EmptyStatusWidget = (props: {
  onClick?: () => void
  title: string
  actionLabel?: string
  disabled?: boolean
  imageCode: string
  imageVersion?: 'v2'
  actionAllowed?: boolean
  actionIcon?: IconName
  description?: string
}) => {
  const {
    onClick,
    title,
    actionLabel,
    disabled = false,
    imageCode,
    imageVersion,
    actionAllowed,
    description,
    actionIcon,
  } = props

  return (
    <StatusWidget>
      <StatusWidget.Image
        {...get3DImageSrcSetProps(imageCode, imageVersion)}
        size={104}
      />
      <StatusWidget.Title role="heading">{title}</StatusWidget.Title>
      {description && <StatusWidget.Description>{description}</StatusWidget.Description>}
      <EmptyStatusWidgetAction
        onClick={onClick}
        actionLabel={actionLabel}
        disabled={disabled}
        actionAllowed={actionAllowed}
        actionIcon={actionIcon}
      />
    </StatusWidget>
  )
}

const EmptyStatusWidgetAction = (props: {
  onClick?: () => void
  actionAllowed?: boolean
  actionLabel?: string
  actionIcon?: IconName
  disabled: boolean
}) => {
  const { actionAllowed, actionLabel, disabled, onClick, actionIcon = 'Plus' } = props

  const hasAction = actionLabel && onClick

  if (!actionAllowed || !hasAction) {
    return null
  }

  return (
    <StatusWidget.Action disabled={disabled} use="div">
      <ActionButton
        useIcon={actionIcon}
        onClick={onClick}
        disabled={disabled}
        label={actionLabel}
      >
        {actionLabel}
      </ActionButton>
    </StatusWidget.Action>
  )
}
