import { FormMultiStep, Tab } from 'components/FormMultiStep'
import {
  RoleEditForm,
  RoleEditFormSkeleton,
  RoleEditFormState,
} from 'view/Roles/components/RoleEditForm'
import { ToxicPair } from 'api/idave/toxic'
import { Role } from 'api/idave/roles'
import { FormTabs } from '../types'
import { RoleCreatePermissions } from './RoleCreatePermissions'
import { tabToPageSize } from './utils'

const TABS: Tab<FormTabs>[] = [
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'permissions',
    label: 'Permissions',
  },
]

type Props = {
  isSubmitting: boolean
  isCopying: boolean
  roleDetails: RoleEditFormState
  permissionIds: string[]
  toxicPairs: ToxicPair[]
  isLoading?: boolean
  roles: Role[]
  setPermissionIds: (ids: string[]) => void
  onCloseClick: () => void
  onSubmitClick: () => void
  checkTabValid: (tab: FormTabs) => boolean
  checkTabFilled: (tab: FormTabs) => boolean
}
export const RoleCreateForm = ({
  isSubmitting,
  isLoading,
  isCopying,
  roleDetails,
  permissionIds,
  toxicPairs,
  roles,
  setPermissionIds,
  onCloseClick,
  onSubmitClick,
  checkTabFilled,
  checkTabValid,
}: Props) => {
  const title = isCopying ? 'Duplicate role' : 'Create role'
  return (
    <FormMultiStep
      title={title}
      onCloseClick={onCloseClick}
      tabs={TABS}
      onSubmit={onSubmitClick}
      submitting={isSubmitting}
      checkTabValid={checkTabValid}
      checkTabFilled={checkTabFilled}
      tabToPageSize={tabToPageSize}
      renderTab={(currentTab) => {
        if (isLoading) {
          return <RoleEditFormSkeleton />
        }

        return (
          <>
            {currentTab === 'details' && <RoleEditForm roles={roles} />}

            {currentTab === 'permissions' && (
              <RoleCreatePermissions
                permissionIds={permissionIds}
                setPermissionIds={setPermissionIds}
                roleName={roleDetails.name}
                toxicPairs={toxicPairs}
                parent={roleDetails.parent}
              />
            )}
          </>
        )
      }}
    />
  )
}
