import { EmployeeCompany } from 'api/dart/types'
import { generatePath } from 'react-router'
import { Url } from 'routing'

export const getEmployeeCompanyLink = (
  companyId: string,
  type: EmployeeCompany['type'],
) =>
  type === 'EXTERNAL'
    ? generatePath(Url.OrganisationDetails, { organisationId: companyId })
    : 'https://people.revolut.com/apps/entities'
