import { AxiosResponse } from 'axios'
import { identity } from 'lodash'
import idaveApi from 'api/idave'
import samApi from 'api/sam'
import { mapify } from 'utils/array'
import { getName } from 'utils/string/getName'
import { Client } from 'api/idave/clients'
import { EntityData } from 'view/XChecks/XCheck/lib/hooks'

export const makeEntitiesFetcher = <T>({
  allowed,
  fetchItems,
  itemToEntity = identity,
}: {
  allowed: boolean
  fetchItems: () => Promise<AxiosResponse<T[]>>
  itemToEntity?: (item: T) => EntityData
}) => {
  if (!allowed) {
    return undefined
  }

  return () => fetchItems().then((r) => r.data.map(itemToEntity))
}

export const getOrgFlagUrl = (region?: string) =>
  region
    ? `https://assets.revolut.com/assets/flags/${region}@2x.webp`
    : 'https://assets.revolut.com/assets/icons/CompanyFilled.svg'

export const makeUsersFetcher = (
  fetchUsersAllowed: boolean,
  fetchAvatarsAllowed: boolean,
) => {
  if (!fetchUsersAllowed) {
    return undefined
  }

  return async () => {
    const avatarRequest = fetchAvatarsAllowed
      ? samApi.revoluters.getAvatars()
      : Promise.resolve([])

    const [users, avatars] = await Promise.allSettled([
      idaveApi.user.getUserList({ state: 'ACTIVE' }),
      avatarRequest,
    ])

    const avatarMap =
      avatars.status === 'fulfilled'
        ? mapify(avatars.value, (i) => i.revolutersId)
        : new Map()

    return users.status === 'fulfilled'
      ? users.value.data.map((user) => ({
          name: getName(user),
          description: user.email,
          id: user.id,
          avatarUrl: user.externalRef?.reference
            ? avatarMap.get(user.externalRef.reference)?.imageMedium
            : undefined,
        }))
      : []
  }
}

export const makeServiceAccountFetcher = (allowed: boolean) => {
  if (!allowed) {
    return undefined
  }
  return async () => {
    const [accounts, clients] = await Promise.all([
      idaveApi.accounts.getAccounts(),
      idaveApi.clients.getClients(),
    ])

    const clientMap = mapify(clients.data, ({ id }) => id)

    return accounts.data.map((account) => ({
      name: clientMap.get(account.clientId)?.name || account.id,
      description: account.id,
      id: account.id,
    }))
  }
}

export const makePermissionsFetcher = (
  fetchPermissionsAllowed: boolean,
  fetchClientsAllowed: boolean,
) => {
  if (!fetchPermissionsAllowed) {
    return undefined
  }
  return async () => {
    const clientsRequest = fetchClientsAllowed
      ? idaveApi.clients.getClients()
      : Promise.reject()

    const [clients, permissions] = await Promise.allSettled([
      clientsRequest,
      idaveApi.permissions.getPermissions(),
    ])

    const clientsMap =
      clients.status === 'fulfilled'
        ? mapify(clients.value.data, ({ id }) => id)
        : new Map<string, Client>()

    return permissions.status === 'fulfilled'
      ? permissions.value.data.map((permission) => ({
          name: permission.name,
          description: clientsMap.get(permission.client)?.name || permission.id,
          id: permission.id,
        }))
      : []
  }
}
