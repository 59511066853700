import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { EntityContext } from 'view/XChecks/XCheck/lib/types'
import { Text } from '@revolut/ui-kit'
import { EMPTY_VALUE_FALLBACK_TEXT } from 'constants/string'
import { EntityParam } from 'api/types/xChecks'
import { capitalizeFirst } from 'utils/string'
import { getEntityDescriptor } from 'view/XChecks/XCheck/lib/utils/params'
import { ItemValueProps } from './types'

export const EntityValue = ({
  value,
  isArrayItem,
  variant,
}: ItemValueProps<EntityParam>) => {
  const entityBaseContext = isArrayItem ? 'array' : 'details'
  const entityContext: EntityContext = variant
    ? `${entityBaseContext}${capitalizeFirst(variant)}`
    : entityBaseContext
  const { Entity } = useViews()

  return value ? (
    <Entity
      context={entityContext}
      descriptor={getEntityDescriptor(value)}
      attributes={value.value}
    />
  ) : (
    <Text>{EMPTY_VALUE_FALLBACK_TEXT}</Text>
  )
}
