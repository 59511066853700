import { match } from 'ts-pattern'
import { EntityComponentType, EntityContext } from '../../lib/types'
import { KNOWN_ENTITY_TYPE } from './consts'
import {
  RegisteredIdentity,
  RegionEntity,
  DefaultEntity,
  EntityTag,
  UserEntity,
} from './components'

export const Entity: EntityComponentType = (params) =>
  match(params)
    .with({ context: EntityContext.array }, EntityTag)
    .with({ context: EntityContext.arrayAdded }, EntityTag)
    .with({ context: EntityContext.arrayRemoved }, EntityTag)
    .with({ descriptor: { entityType: KNOWN_ENTITY_TYPE.region } }, RegionEntity)
    .with(
      { descriptor: { entityType: KNOWN_ENTITY_TYPE.registeredIdentity } },
      RegisteredIdentity,
    )
    .with({ descriptor: { entityType: KNOWN_ENTITY_TYPE.iDaveUser } }, UserEntity)
    .otherwise(() => <DefaultEntity {...params} />)
