import { Box, Subheader, Table } from '@revolut/ui-kit'
import { CrossCheckDetail } from 'api/types/crossCheck'
import { useQueryResourceMap } from 'queries/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { NoAccessWidget } from 'components/NoAccessWidget'
import { Resource } from 'api/sam/types/resources'
import { useCallback, useMemo } from 'react'
import { useResourcePreview } from 'components/previews/ResourcePreview'
import { getResourceRowsInfo } from './utils'
import { ResourceRow } from './types'
import { useColumns } from './columns'

export const ResourceAccessList = ({ crossCheck }: { crossCheck: CrossCheckDetail }) => {
  const {
    data: resourceMap = new Map(),
    status: qs,
    fetchStatus: fs,
    refetch,
  } = useQueryResourceMap()

  return (
    <QuerySwitch
      required={[{ qs, fs }]}
      data={resourceMap}
      renderError={() => <RequestErrorWidget action={refetch} />}
      renderIdle={() => (
        <NoAccessWidget title="Looks like you don’t have sufficient permissions to view resources" />
      )}
      renderLoading={() => (
        <Inner crossCheck={crossCheck} resourceMap={resourceMap} isLoading />
      )}
      renderSuccess={() => <Inner crossCheck={crossCheck} resourceMap={resourceMap} />}
    />
  )
}

const Inner = ({
  crossCheck,
  isLoading,
  resourceMap,
}: {
  crossCheck: CrossCheckDetail
  isLoading?: boolean
  resourceMap: Map<string, Resource>
}) => {
  const rows = useMemo(
    () =>
      getResourceRowsInfo({
        crossCheck,
        resourceMap,
      }),
    [crossCheck, resourceMap],
  )

  const { openResourceSide } = useResourcePreview()
  const onRowClick = useCallback(
    (row: ResourceRow) => openResourceSide(row.id),
    [openResourceSide],
  )
  const columns = useColumns({ rows })

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title style={{ alignSelf: 'center' }}>Resources</Subheader.Title>
      </Subheader>
      <Table
        columns={columns}
        data={rows}
        onRowClick={onRowClick}
        loadingState={isLoading ? 'pending' : 'ready'}
        labelEmptyState="There aren't requested resources"
      />
    </Box>
  )
}
