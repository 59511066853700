import { Decision } from 'api/types/xChecks'
import { TApiContext } from 'view/XChecks/XCheck/lib/hooks/useAPI/useApi'
import { XCheckActionSubmitHandlerParams, XCheckActionType } from '../../../../../types'

export const actionMethod = (
  { actionType, xCheckId, reasonText }: XCheckActionSubmitHandlerParams,
  api: TApiContext,
) => {
  switch (actionType) {
    case 'decline':
      return api.decline({ xCheckId, reason: reasonText || '' })
    case 'justify':
      return api.justify({ xCheckId, justification: reasonText || '' })
    case 'approve':
      return api.review({
        xCheckId,
        decision: Decision.approve,
        reason: reasonText,
      })
    case 'reject':
      return api.review({
        xCheckId,
        decision: Decision.reject,
        reason: reasonText,
      })
    case 'retry':
      return api.retry({ xCheckId })
    case 'requestInfo':
      return api.requestInfo({
        xCheckId,
        comment: reasonText || '',
      })
    case 'provideInfo':
      return api.provideInfo({
        xCheckId,
        comment: reasonText || '',
      })
    default:
      return Promise.reject("Method isn't defined")
  }
}

export const getLoadingPopupTitle = (actionType: XCheckActionType) => {
  switch (actionType) {
    case 'approve':
    case 'reject':
      return 'Submitting review...'
    case 'decline':
      return 'Declining...'
    case 'justify':
      return 'Submitting justification...'
    case 'retry':
      return 'Retrying execution...'
    case 'requestInfo':
      return 'Requesting info...'
    case 'provideInfo':
      return 'Submitting info...'
    default:
      return 'Performing...'
  }
}

export const getSuccessToastTitle = (actionType: XCheckActionType) => {
  switch (actionType) {
    case 'approve':
      return 'Approved'
    case 'reject':
      return 'Rejected'
    case 'decline':
      return 'Declined'
    case 'justify':
      return 'Justification added'
    case 'retry':
      return 'Execution started'
    default:
      return 'Done'
  }
}

export const getErrorPopupTitle = (actionType: XCheckActionType) => {
  switch (actionType) {
    case 'approve':
      return 'Approval failed'
    case 'reject':
      return 'Rejection failed'
    case 'decline':
      return 'Declining failed'
    case 'justify':
      return 'Justification failed'
    case 'retry':
      return 'Execution retry failed'
    default:
      return 'Oops!'
  }
}
