import { ClientDataWrapper } from './ClientDataWrapper'
import { RoleDataWrapper } from './RoleDataWrapper'
import { PolicyDataWrapper } from './PolicyDataWrapper'
import { DataWrapperRootProps } from './types'
import { RecertificationDataWrapper } from './RecertificationDataWrapper'
import { UserDataWrapper } from './UserDataWrapper'
import { EmployeeCompanyDataWrapper } from './EmployeeCompanyDataWrapper'
import { CustomerCompanyDataWrapper } from './CustomerCompanyDataWrapper'
import { AccountDataWrapper } from './AccountDataWrapper'

export const DataWrapper = ({ crossCheck, Component, source }: DataWrapperRootProps) => {
  const { entityType, entityId } = crossCheck

  switch (entityType) {
    case 'Client':
      return (
        <ClientDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'Role':
      return (
        <RoleDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'AccessPolicy':
      return (
        <PolicyDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'Recertification':
      if (source === 'dart') {
        return (
          <CustomerCompanyDataWrapper
            id={entityId}
            crossCheck={crossCheck}
            Component={Component}
            source={source}
          />
        )
      }
      return (
        <RecertificationDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'User':
      return (
        <UserDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'EmployeeCompany':
      return (
        <EmployeeCompanyDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'CustomerCompany':
      return (
        <CustomerCompanyDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    case 'ServiceAccount':
      return (
        <AccountDataWrapper
          id={entityId}
          crossCheck={crossCheck}
          Component={Component}
          source={source}
        />
      )
    default:
      return null
  }
}
