import { Box, Subheader } from '@revolut/ui-kit'
import { OrganisationData } from 'api/idave/organisations'
import { DetailsGroup } from 'components/DetailsGroup'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const OrganisationExternalInfo = (props: { organisation: OrganisationData }) => {
  const { organisation } = props

  if (!organisation.externalRef) {
    return null
  }

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>External Details</Subheader.Title>
      </Subheader>

      <DetailsGroup
        rows={[
          ['Origin', organisation.externalRef?.origin || EMPTY_VALUE_FALLBACK],
          ['Reference', organisation.externalRef?.reference || EMPTY_VALUE_FALLBACK],
        ]}
      />
    </Box>
  )
}
