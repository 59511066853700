import { getSpecialOccasionIcon } from './utils'

export const getAvatarProps = (params: {
  label?: string
  id: string
  avatar?: string
}) => {
  const { label, id, avatar } = params

  if (avatar) {
    return { label: undefined, useIcon: undefined, image: avatar, uuid: id }
  }

  const specialOccasionIcon = getSpecialOccasionIcon(id)

  if (specialOccasionIcon) {
    return {
      image: undefined,
      useIcon: specialOccasionIcon,
      label: undefined,
      uuid: id,
    }
  }

  return { label, uuid: id }
}
