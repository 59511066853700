import { Side } from '../../../hooks/useXCheckSide/types'
import { GroupRuleActions } from './components/GroupRuleActions'

export const SideActions = (props: { side: Side; xCheckId: string }) => {
  const { side, xCheckId } = props
  if (side.type === 'reviewGroup') {
    return <GroupRuleActions groupId={side.group.id} xCheckId={xCheckId} />
  }

  return null
}
