import { Text } from '@revolut/ui-kit'
import { EmployeeCompany } from 'api/dart/types'
import { RegionText } from 'components/RegionText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const DartCompany = ({ company }: { company?: EmployeeCompany }) =>
  company ? (
    <RegionText id={company.id} title={company.name} region={company.region} />
  ) : (
    <Text>{EMPTY_VALUE_FALLBACK}</Text>
  )
