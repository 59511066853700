import { IDAVE_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { Clients } from './Clients'
import { Client } from './Client'
import { ClientNew } from './Client/ClientNew/ClientNew'
import { ClientEdit } from './Client/ClientEdit'
import { CREATE_ACCOUNT, CREATE_CLIENT, EDIT_ACCOUNT, EDIT_CLIENT } from './permissions'
import { Account } from './Account'
import { AccountAdd } from './Account/AccountAdd'
import { AccountEdit } from './Account/AccountEdit'
import { ClientScope } from './Client/ClientScope'

export const routes = ({
  hasPermission,
  hasSomePermissions,
  hasEveryPermission,
}: PermissionUtils): Record<string, UIRoute> => ({
  [Url.Clients]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Clients tab="clients" />,
  },

  [Url.Accounts]: {
    type: 'component',
    withParams: false,
    allowed: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: () => <Clients tab="accounts" />,
  },

  [Url.Client]: {
    type: 'redirect',
    params: ['clientId'],
    newUrl: Url.ClientDetails,
  },

  [Url.ClientScope]: {
    type: 'component',
    withParams: true,
    params: ['scope'],
    allowed: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
    fallbackRoute: Url.Home,
    Component: (props: { scope: string }) => <ClientScope scope={props.scope} />,
  },

  [Url.ClientDetails]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_DETAILS),
    params: ['clientId'],
    fallbackRoute: Url.Clients,
    Component: (props: { clientId: string }) => (
      <Client tab="details" clientId={props.clientId} />
    ),
  },

  [Url.ClientPermissions]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST),
    params: ['clientId'],
    fallbackRoute: Url.Clients,
    Component: (props: { clientId: string }) => (
      <Client tab="permissions" clientId={props.clientId} />
    ),
  },

  [Url.ClientAuditLogs]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    params: ['clientId'],
    fallbackRoute: Url.Clients,
    Component: (props: { clientId: string }) => (
      <Client tab="log" clientId={props.clientId} />
    ),
  },

  [Url.Account]: {
    type: 'redirect',
    params: ['accountId'],
    newUrl: Url.AccountDetails,
  },

  [Url.AccountDetails]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_VIEW_DETAILS,
      IDAVE_PERMISSIONS.CLIENTS_VIEW_DETAILS,
    ),
    params: ['accountId'],
    fallbackRoute: Url.Accounts,
    Component: (props: { accountId: string }) => (
      <Account tab="details" id={props.accountId} />
    ),
  },

  [Url.AccountPermissions]: {
    type: 'component',
    withParams: true,
    allowed: hasEveryPermission(
      IDAVE_PERMISSIONS.SERVICE_ACCOUNTS_VIEW_DETAILS,
      IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST,
      IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST,
    ),
    params: ['accountId'],
    fallbackRoute: Url.Accounts,
    Component: (props: { accountId: string }) => (
      <Account tab="permissions" id={props.accountId} />
    ),
  },

  [Url.AccountAuditLogs]: {
    type: 'component',
    withParams: true,
    allowed: hasPermission(IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST),
    params: ['accountId'],
    fallbackRoute: Url.Clients,
    Component: (props: { accountId: string }) => (
      <Account tab="auditLog" id={props.accountId} />
    ),
  },

  [Url.ClientEdit]: {
    type: 'component',
    withParams: true,
    allowed: hasSomePermissions(...EDIT_CLIENT),
    params: ['clientId'],
    fallbackRoute: Url.Clients,
    Component: ClientEdit,
  },

  [Url.ClientCreate]: {
    type: 'component',
    withParams: false,
    allowed: hasSomePermissions(...CREATE_CLIENT),
    fallbackRoute: Url.Clients,
    Component: ClientNew,
  },

  [Url.AccountAdd]: {
    type: 'component',
    withParams: false,
    allowed: hasSomePermissions(...CREATE_ACCOUNT),
    fallbackRoute: Url.Accounts,
    Component: AccountAdd,
  },

  [Url.AccountEdit]: {
    type: 'component',
    withParams: true,
    allowed: hasSomePermissions(...EDIT_ACCOUNT),
    fallbackRoute: Url.Accounts,
    params: ['accountId'],
    Component: AccountEdit,
  },
})
