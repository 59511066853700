import { Box, Subheader, VStack } from '@revolut/ui-kit'
import { Permission } from 'api/idave/permissions'
import { CopyableText } from 'components/CopyableText'
import { DetailsGroup } from 'components/DetailsGroup'
import { EntityDescription } from 'components/EntityDescription'
import { ClientLink } from 'components/Links/ClientLink'
import { PermissionAction } from 'components/PermissionAction'
import { PermissionDataClassification } from 'components/PermissionDataClassification'
import { PermissionOversights } from 'components/PermissionOversights'
import { PermissionScope } from 'components/PermissionScope'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

type Props = {
  permission: Permission
}

export const PermissionInfo = ({ permission }: Props) => {
  return (
    <VStack space="s-16">
      <Box>
        <Subheader variant="nested">
          <Subheader.Title>Details</Subheader.Title>
        </Subheader>
        <DetailsGroup
          rows={[
            ['Name', permission.name],
            [
              'Application',
              <ClientLink id={permission.client} key={permission.client} />,
            ],
            ['ID', <CopyableText value={permission.id} key={permission.id} />],
            ['Action', <PermissionAction permission={permission} key="action" />],
            [
              'Data classification',
              <PermissionDataClassification
                permission={permission}
                key="classification"
              />,
            ],
            ['Scope', <PermissionScope permission={permission} key="scope" />],
            [
              'Oversight',
              <PermissionOversights permission={permission} key="oversights" />,
            ],
            ['Domain', permission.domain || EMPTY_VALUE_FALLBACK],
          ]}
        />
      </Box>
      <EntityDescription description={permission.description} />
    </VStack>
  )
}
