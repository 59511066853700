import { Table, Text } from '@revolut/ui-kit'
import { UuidParam } from 'api/types/xChecks'
import { CopyableText } from 'components/CopyableText'
import { EMPTY_VALUE_FALLBACK } from 'constants/string'

export const UuidValueCell = ({ param }: { param?: UuidParam }) => {
  const value = param?.value.value
  return (
    <Table.Cell>
      {value ? (
        <CopyableText value={value}>{value}</CopyableText>
      ) : (
        <Text>{EMPTY_VALUE_FALLBACK}</Text>
      )}
    </Table.Cell>
  )
}
