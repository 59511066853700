import { useMutation, useQueryClient } from '@tanstack/react-query'
import api from 'api/sam'
import { SamPolicy } from 'api/sam/policies'
import { useConfirmationPopup } from 'hooks/useConfirmationPopup'
import { useToasts } from 'hooks/useToasts'
import { useQueryResources } from 'queries/sam/resources'
import { QueryKey } from 'helpers/configQuery'
import { SAM_PERMISSIONS } from 'security'
import { normalizeResources } from 'view/Sam/utils'
import { useCallback } from 'react'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { useNavigateToPolicy } from 'hooks/useNavigateToPolicy'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { getToastText } from './utils'

type MutateParams = { resourceIds: string[]; type: 'add' | 'delete' }

export const useSetPolicyResources = (params: {
  policy?: SamPolicy
  onSuccess: () => void
}) => {
  const { policy, onSuccess } = params
  const { data: resources = [] } = useQueryResources()
  const { closePopup } = useConfirmationPopup()
  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()
  const reloadAndNavigateToPolicy = useNavigateToPolicy()

  const { isDirect, toCrosscheck } = useCrossCheck(
    SAM_PERMISSIONS.POLICIES_UPDATE_RESOURCES,
    [
      SAM_PERMISSIONS.POLICIES_UPDATE_WITH_CROSS_CHECK,
      SAM_PERMISSIONS.POLICIES_UPDATE_MULTI_SUBJECT_WITH_CROSS_CHECK,
    ],
  )

  const onError = useCallback(
    (error: unknown) => {
      hideLoadingPopup()
      showErrorPopup({
        title: 'Resource updating failed',
        error,
      })
      closePopup()
    },
    [hideLoadingPopup, showErrorPopup, closePopup],
  )

  const setMutation = useMutation({
    mutationFn: ({ resourceIds }: MutateParams) =>
      policy
        ? api.policies.updatePolicyResources({
            policyId: policy.policyId,
            resourceIds: normalizeResources(resourceIds, resources),
          })
        : Promise.reject('Policy not found'),
    onSuccess: (_data, { resourceIds, type }) => {
      hideLoadingPopup()
      onSuccess()
      showSuccessToast(getToastText(resourceIds, type))
      queryClient.resetQueries([QueryKey.SamAuditLogs, 'access-policy', policy?.policyId])
      queryClient.invalidateQueries([QueryKey.SamPolicy, policy?.policyId])
    },
    onError,
    onMutate: () => showLoadingPopup({ title: 'Updating policy resources' }),
  })

  const setCrossCheckMutation = useMutation({
    mutationFn: ({ resourceIds }: MutateParams) =>
      policy
        ? api.policies.crossCheckUpdatePolicy({
            policyId: policy.policyId,
            data: {
              businessReason: policy.businessReason,
              mandatoryTrainings: policy.mandatoryTrainings || [],
              policyName: policy.policyName,
              resourceIds,
            },
          })
        : Promise.reject('Policy not found'),
    onSuccess: (response) => {
      const baseDescriptor = getCrossCheckDescriptor(response)
      const data = response.data
      const crossCheckDescriptor = {
        id: data.crossCheckId || baseDescriptor.id,
        version: baseDescriptor.version,
      }
      if (crossCheckDescriptor.id) {
        hideLoadingPopup()
        onSuccess()

        toCrosscheck(crossCheckDescriptor)
      } else if (policy) {
        reloadAndNavigateToPolicy(policy.policyId)
        showSuccessToast('Policy edited')
      }
    },
    onError,
    onMutate: () => showLoadingPopup({ title: 'Creating resource update cross-check' }),
  })

  return isDirect
    ? {
        setResources: (mutateParams: MutateParams) => setMutation.mutate(mutateParams),
        isSetting: setMutation.isLoading,
      }
    : {
        setResources: (mutateParams: MutateParams) =>
          setCrossCheckMutation.mutate(mutateParams),
        isSetting: setCrossCheckMutation.isLoading,
      }
}
