import { PageMainSize } from '@revolut/ui-kit'

export const tabToPageSize = (tab: string) => {
  switch (tab) {
    case 'resources':
    case 'trainings':
    case 'employees':
    case 'logs':
      return PageMainSize.FULL

    case 'details':
    default:
      return PageMainSize.NARROW
  }
}
