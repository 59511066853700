import { EntityParam } from 'api/types/xChecks'
import { useApi } from 'view/XChecks/XCheck/lib/hooks'
import { match, P } from 'ts-pattern'
import { InputProps } from '../../../type'
import { EntitySelect, EntityIdInput, EntityPlaceholder } from './components'

export const Entity = (props: InputProps<EntityParam>) => {
  const { param } = props
  const entityType = param.value.type.entityType
  const { entitiesFetchers } = useApi()
  const fetcher = entitiesFetchers?.[entityType]

  return match({ param, fetcher })
    .with({ param: { value: { editConfig: P.optional(undefined) } } }, () => (
      <EntityPlaceholder {...props} />
    ))
    .with({ fetcher: P.nonNullable }, ({ fetcher: definedFetcher }) => (
      <EntitySelect {...props} fetcher={definedFetcher} />
    ))
    .otherwise(() => <EntityIdInput {...props} />)
}
