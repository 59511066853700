import '@revolut/ui-kit/styles.css'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { useMenu } from 'helpers/hooks/useMenu'
import { BackofficeProvider } from '@revolut-internal/backoffice-ui-provider'
import { Url } from 'routing'
import { useMenuAuth } from 'helpers/hooks/useMenuAuth'
import { useNavigate } from 'react-router'
import { RootProvider, TopNav } from '@revolut/ui-kit'
import { AppId } from '@revolut-internal/backoffice-config'
import { auth } from 'services/auth'
import { getCookieBackground, getCookieMode } from 'utils/common/getCookieMode'
import { randomKey } from 'utils/common/randomKey'

export const KitProvider = (props: { children?: React.ReactNode }) => {
  const Provider = auth.getUser() ? CustomProvider : EmptyProvider

  return <Provider>{props.children}</Provider>
}

const EmptyProvider = (props: { children?: React.ReactNode }) => {
  return (
    <RootProvider
      background={getCookieBackground()}
      mode={getCookieMode()}
      topNav={{
        title: '',
        appSwitcher: <></>,
        logo: <></>,
        profile: <></>,
      }}
    >
      {props.children}
    </RootProvider>
  )
}

const CustomProvider = (props: { children?: React.ReactNode }) => {
  const menuItems = useMenu()
  const menuAuth = useMenuAuth()
  const [random, setRandom] = useState<number>()
  useEffect(() => {
    // triggers rerender after mount just to match data-theme attribute in html
    setRandom(randomKey())
  }, [])

  const navigate = useNavigate()
  const onProfileClick = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      e.preventDefault()
      navigate(Url.Home)
    },
    [navigate],
  )

  return (
    <BackofficeProvider
      key={random}
      menu={<TopNav.Menu items={menuItems} />}
      appId={AppId.IDave}
      defaultRoute={Url.Home}
      auth={menuAuth}
      onProfileClick={onProfileClick}
      transparentMode
    >
      {props.children}
    </BackofficeProvider>
  )
}
