import { TabBar, TabBarSkeleton, Text, Token } from '@revolut/ui-kit'
import { Nullable } from 'view/XChecks/XCheck/lib/types'
import { EventTab, Tab } from '../../types'

type Props = {
  loading: boolean
  tabs: EventTab[]
  tab?: Tab
  onTabClick: (tabName: Nullable<Tab>) => void
}

export const EventsHeader = ({ tabs, tab, onTabClick, loading }: Props) => {
  if (loading) {
    return (
      <TabBarSkeleton variant="segmented" data-testid="loading-header">
        <TabBarSkeleton.Item />
      </TabBarSkeleton>
    )
  }

  return (
    <TabBar value={tab} onChange={onTabClick} variant="segmented">
      {tabs.map((item) => (
        <TabBar.Item to={item.tab} key={item.tab} disabled={!item.available}>
          <Text>
            {item.title}
            <Text color={Token.color.greyTone50}> · {item.counter}</Text>
          </Text>
        </TabBar.Item>
      ))}
    </TabBar>
  )
}
