import { omit } from 'lodash'
import { Text, Token, VStack } from '@revolut/ui-kit'
import { XChecksFilters } from '../types'
import { isEmptyFilters } from '../utils'

type Props = {
  filters: XChecksFilters
  currentReviewer?: string
}

const DEFAULT_EMPTY_LABEL = 'Nothing here'
export const NoResultsLabel = ({ filters, currentReviewer }: Props) => {
  const isOnlyAssignToMe =
    currentReviewer &&
    filters.currentReviewer === currentReviewer &&
    isEmptyFilters(omit(filters, 'currentReviewer'))

  if (isOnlyAssignToMe) {
    return (
      <VStack gap="s-16">
        <Text variant="emphasis1">Great job!</Text>
        <Text variant="body2" color={Token.color.grey50}>
          Nothing found to review
        </Text>
      </VStack>
    )
  }

  return <>{DEFAULT_EMPTY_LABEL}</>
}
