import { useCallback } from 'react'
import { notNullable } from 'utils/common'
import { Cell, Checkbox } from '@revolut/ui-kit'
import { BooleanParam } from 'api/types/xChecks'
import { InputProps } from '../types'

export const BoolItem = ({
  onChange: originalOnChange,
  value,
}: InputProps<BooleanParam>) => {
  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return originalOnChange({ ...value, value: event.target.checked })
    },
    [originalOnChange, value],
  )
  const nullable = !notNullable(value.value)
  return (
    <Cell use="label">
      <Checkbox checked={value.value} indeterminate={nullable} onChange={onChange}>
        Value {nullable ? '(unset)' : null}
      </Checkbox>
    </Cell>
  )
}
