import {
  TableWidget,
  MoreBar,
  Table,
  TableSortingRule,
  TableFilters,
  TableProps,
  Page,
} from '@revolut/ui-kit'
import { TableInstance } from 'react-table'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { isEqual } from 'lodash'
import { randomKey } from 'utils/common/randomKey'
import { WHEEL_BUTTON_ID } from 'utils/constants/mouseEvents'
import {
  filterActiveIds,
  filterActiveValues,
  filterSelectedEntities,
  isFiltered,
  openInOtherTab,
  openLink,
} from './utils'
import { DataLabel } from './types'
import { EntitiesListInfo } from './components/EntitiesListInfo'
import { EntitiesListSearch } from './components/EntitiesListSearch'
import { useTableNavigation } from './hooks/useTableNavigation'
import { useTableInstance } from './hooks/useTableInstance'
import { TableStatus } from './components/TableStatus/TableStatus'

export type EntitiesTableCustomProps<Entity extends { id: string }> = {
  pluralForms: [string, string]
  entitiesTypeLabel: string
  showSelected?: boolean
  selectedHash?: Record<string, boolean>
  searchValue?: string
  showSelectedSwitcherForce?: boolean
  dataLabels?: DataLabel[]
  totalCount?: number
  onFilter?: (filters: TableFilters<Entity>) => void
  onSortBy?: (sortingRules: TableSortingRule<Entity>[]) => void
  getRowLink?: (entity: Entity) => string
  renderActions?: () => ReactNode
  renderFiltersLeft?: () => ReactNode
  renderFiltersRight?: () => ReactNode
  setSelectedHash?: (selectedHash: Record<string, boolean>) => void
  switchShowSelected?: () => void
  onSearchChange?: (search: string) => void
  onRowClick?: (entity: Entity, event: React.MouseEvent<Element, MouseEvent>) => void
  searchAutoFocus?: boolean
  enableNavigation?: boolean
  isStickyHeader?: boolean
}

export const EntitiesTable = <Entity extends { id: string }>(
  props: EntitiesTableCustomProps<Entity> & TableProps<Entity>,
) => {
  const {
    data: entities,
    searchValue,
    columns,
    showSelected = false,
    selectedHash = {},
    showSelectedSwitcherForce,
    dataLabels,
    searchAutoFocus,
    totalCount,
    enableNavigation,
    entitiesTypeLabel,
    pluralForms,
    getRowLink,
    renderActions,
    renderFiltersLeft,
    renderFiltersRight,
    onSearchChange,
    switchShowSelected,
    setSelectedHash,
    onRowClick,
    onFilter: onFilterHandler,
    onSortBy: onSortByHandler,
    isStickyHeader,
    ...restProps
  } = props

  const { table, tableRef } = useTableInstance<Entity>()
  const [filters, setFilters] = useState<TableFilters<Entity> | undefined>()
  const [sorts, setSorts] = useState<TableSortingRule<Entity>[] | undefined>()
  const [searchKey, resetSearch] = useState(randomKey())

  // use selectedHash as single source of truth of selection
  useEffect(() => {
    Object.keys(selectedHash).forEach((id) => {
      table?.toggleRowSelected(id, !!selectedHash[id])
    })
  }, [selectedHash, table])

  useTableNavigation<Entity>({
    enableNavigation,
    searchValue,
    onSearchChange,
    resetSearch,
    loadingState: restProps.loadingState,
    filters,
    sorts,
    table,
  })

  const selectedEntitiesCount = filterActiveValues(selectedHash).length
  const selectable = !!setSelectedHash

  const onSwitchSelectedClick = useCallback(() => {
    onSearchChange && onSearchChange('')
    resetSearch(randomKey())
    switchShowSelected?.()
  }, [switchShowSelected, onSearchChange])

  const onSelect = useCallback(
    (eventHash: Record<string, boolean>) => {
      // dont't trigger setSelectedHash if selected items been already selected
      // prevent infinite rerener loop
      if (isEqual(selectedHash, eventHash)) {
        return
      }

      setSelectedHash?.(eventHash)
      // disable show select if newHash empty, avoid dedlocked emptiness of list
      if (!filterActiveIds(eventHash).length && showSelected) {
        switchShowSelected?.()
      }
    },
    [setSelectedHash, selectedHash, switchShowSelected, showSelected],
  )

  const onClick = useMemo(
    () =>
      !onRowClick && !getRowLink
        ? undefined
        : (row: Entity, event: React.MouseEvent) => {
            if (event.shiftKey && selectable) {
              return setSelectedHash({
                ...selectedHash,
                [row.id]: !selectedHash[row.id],
              })
            }

            const link = getRowLink?.(row)
            const openInOther = openInOtherTab(event)

            if (openInOther && link) {
              return openLink(link, openInOther)
            }

            if (onRowClick) {
              return onRowClick(row, event)
            }

            return link ? openLink(link, openInOther) : undefined
          },
    [getRowLink, onRowClick, setSelectedHash, selectedHash, selectable],
  )

  const onRowAuxClick = useCallback(
    (row: Entity, event: React.MouseEvent) => {
      if (event.button === WHEEL_BUTTON_ID) {
        const link = getRowLink?.(row)
        return link ? openLink(link, true) : undefined
      }
      return undefined
    },
    [getRowLink],
  )

  /**
   * Resets table view anytime when:
   * - selectable flag changing - BUG https://revolut.atlassian.net/browse/DEUI-2580
   * Leds to hooks rearanging and crashes
   */
  const tableKey = String(selectable)

  const onFilter = useCallback(
    (value: TableFilters<Entity>) => {
      onFilterHandler?.(value)
      setFilters(value)
    },
    [onFilterHandler, setFilters],
  )

  const onSortBy = useCallback(
    (value: TableSortingRule<Entity>[]) => {
      onSortByHandler?.(value)
      setSorts(value)
    },
    [setSorts, onSortByHandler],
  )

  const initialState = useMemo(
    () => ({
      selectedRowIds: selectedHash,
    }),
    [selectedHash],
  )

  const onShrinkReset = useCallback(() => {
    table?.setAllFilters([])
    table?.setSortBy([])
    onSearchChange && onSearchChange('')
    resetSearch(randomKey())
  }, [onSearchChange, resetSearch, table])

  const isResetShrinkVisible =
    !!searchValue?.length || isFiltered(filters) || !!sorts?.length

  const [rowsCount, setRowsCount] = useState<number | undefined>(undefined)
  const onUpdate = useCallback(
    (instance: TableInstance<Entity>) => {
      setRowsCount(instance?.filteredRows.length)
      props.onUpdate?.(instance)
    },
    [props],
  )

  return (
    <TableWidget>
      <EntitiesListInfo
        total={totalCount}
        selected={selectedEntitiesCount}
        dataLabels={dataLabels}
        loadingState={restProps.loadingState}
      />

      <EntitiesListSearch
        handler={onSearchChange}
        value={searchValue}
        autoFocus={searchAutoFocus}
        triggerSelected={switchShowSelected}
        resetKey={searchKey}
        showSelected={showSelected}
      />

      <TableWidget.Actions>
        {renderFiltersLeft?.()}

        <MoreBar>
          {!!selectedEntitiesCount || showSelectedSwitcherForce ? (
            <MoreBar.Action
              useIcon={showSelected ? 'SwitchOn' : 'SwitchOff'}
              variant={showSelected ? 'accent' : 'primary'}
              onClick={onSwitchSelectedClick}
              disabled={!selectedEntitiesCount}
            >
              Show selected
            </MoreBar.Action>
          ) : null}
          {renderActions?.()}
        </MoreBar>
      </TableWidget.Actions>

      {renderFiltersRight && (
        <TableWidget.Filters>{renderFiltersRight()}</TableWidget.Filters>
      )}

      <TableStatus
        entitiesTypeLabel={entitiesTypeLabel}
        isResetShrinkVisible={isResetShrinkVisible}
        onShrinkReset={onShrinkReset}
        pluralForms={pluralForms}
        count={rowsCount}
      />

      <TableWidget.Table>
        <Table
          // changing of select attribute may cause a bug in TableWidget
          // changing key with prop
          key={tableKey}
          ref={tableRef}
          stickyHeaderTop={isStickyHeader ? Page.STICKY_OFFSET : undefined}
          getRowId={(row) => row.id}
          selectable={selectable}
          columns={columns}
          data={showSelected ? filterSelectedEntities(entities, selectedHash) : entities}
          autoResetFilters={false}
          autoResetSortBy={false}
          initialState={initialState}
          onRowClick={onClick}
          onSelect={onSelect}
          onFilter={onFilter}
          onSortBy={onSortBy}
          onUpdate={onUpdate}
          onRowAuxClick={onRowAuxClick}
          {...restProps}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
