import { Table, TableColumn } from '@revolut/ui-kit'
import { SamPolicy, SamPolicySubject, SamPolicySubjectType } from 'api/sam/policies'
import { notNullableMap } from 'utils/array'
import { formatDateTime } from 'utils/date'
import { SelectItemsFilter, itemsToValue } from 'components/SelectItemsFilter'
import { SelectItemFilter } from 'components/SelectItemFilter'
import { stringifySubjectType } from '../utils'

type Row = SamPolicy & { id: string }

const getSubjectSpecId = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Team:
    case SamPolicySubjectType.Department:
    case SamPolicySubjectType.Specialisation:
      return subject.specialisationIds
        ? itemsToValue(subject.specialisationIds)
        : undefined

    default:
      return undefined
  }
}

const getSubjectTeamId = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Team:
      return subject.teamIds ? itemsToValue(subject.teamIds) : undefined

    default:
      return undefined
  }
}

const getSubjectDepartmentId = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Department:
      return subject.departmentIds ? itemsToValue(subject.departmentIds) : undefined

    default:
      return undefined
  }
}

const getSubjectEmpType = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Team:
    case SamPolicySubjectType.Department:
    case SamPolicySubjectType.EmployeeType:
    case SamPolicySubjectType.Specialisation:
    case SamPolicySubjectType.Role:
    case SamPolicySubjectType.Function:
      return subject.employeeTypes ? itemsToValue(subject.employeeTypes) : undefined

    default:
      return undefined
  }
}

const getSubjectEmpId = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Employee:
      return subject.employeeIds ? itemsToValue(subject.employeeIds) : undefined

    default:
      return undefined
  }
}

const getSubjectRoleId = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Role:
      return subject.positionIds ? itemsToValue(subject.positionIds) : undefined

    default:
      return undefined
  }
}

const getSubjectFunctionId = (subject: SamPolicySubject) => {
  switch (subject.subjectType) {
    case SamPolicySubjectType.Function:
      return subject.functionIds ? itemsToValue(subject.functionIds) : undefined

    default:
      return undefined
  }
}

export type ColumnsType =
  | 'subject'
  | 'team'
  | 'department'
  | 'specialisation'
  | 'employee'
  | 'employee_type'
  | 'role'
  | 'function'
  | 'updated'

export const getColumns = (columns: ColumnsType[]): TableColumn<Row>[] => {
  return [
    {
      Header: 'Policy name',
      accessor: 'policyName',
      width: 500,
    },
    ...mapColumns(columns),
  ]
}

const mapColumns = (columns: ColumnsType[]): TableColumn<Row>[] =>
  notNullableMap(columns, (val) => {
    switch (val) {
      case 'subject':
        return {
          Header: 'Subject type',
          accessor: (value) => stringifySubjectType(value.subject.subjectType),
          filter: 'includesValue',
          Filter: SelectItemFilter,
          width: 180,
        }
      case 'employee':
        return {
          Header: 'EmpId',
          accessor: (value) => getSubjectEmpId(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'employee_type':
        return {
          Header: 'EmpType',
          accessor: (value) => getSubjectEmpType(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'specialisation':
        return {
          Header: 'SpecId',
          accessor: (value) => getSubjectSpecId(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'team':
        return {
          Header: 'TeamId',
          accessor: (value) => getSubjectTeamId(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'department':
        return {
          Header: 'DepId',
          accessor: (value) => getSubjectDepartmentId(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'role':
        return {
          Header: 'RoleId',
          accessor: (value) => getSubjectRoleId(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'function':
        return {
          Header: 'FuncId',
          accessor: (value) => getSubjectFunctionId(value.subject),
          filter: 'includesSome',
          Filter: SelectItemsFilter,
          width: 120,
        }
      case 'updated':
        return {
          Header: 'Updated',
          accessor: (value) => new Date(value.updatedAt).getTime(),
          Cell: (params: { row: { original: SamPolicy } }) => {
            return (
              <Table.Cell>
                {formatDateTime(new Date(params.row.original.updatedAt))}
              </Table.Cell>
            )
          },

          width: 160,
        }
      default:
        return undefined
    }
  })
