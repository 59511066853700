import { useQueryResource } from 'queries/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { NoAccessPage } from 'components/NoAccessWidget'
import { Resource as TSamResource } from 'api/sam/types/resources'
import { generatePath } from 'react-router'
import { Page, TabBar } from '@revolut/ui-kit'
import { Url } from 'routing'
import { TabBarLink } from 'components/TabBarLink'
import { SAM_PERMISSIONS } from 'security'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { useNavigateToList } from 'hooks/useBackToResults'
import { PageHeader } from 'components/PageHeader'
import { SamResourceTab } from './types'
import { ResourceSkeleton } from './components/ResourceSkeleton'
import { ResourcePolicies } from './components/ResourcePolicies'
import { ResourceDetails } from './components/ResourceDetails'
import { tabToPageSize } from './utils'

type Props = {
  id: string
  tab: SamResourceTab
}
export const SamResource = ({ id, tab }: Props) => {
  const { data: resource, status, fetchStatus, refetch } = useQueryResource(id)
  const onBackClick = useNavigateToList({
    listUrl: Url.SamResources,
    entityUrl: generatePath(Url.SamResource, { resourceId: id }),
  })

  return (
    <QuerySwitch
      required={[{ qs: status, fs: fetchStatus }]}
      renderError={() => (
        <RequestErrorPage
          pageTitle={id}
          title="Something went wrong"
          description="Resource fetch failed or organisation not found"
          onBackClick={onBackClick}
          action={refetch}
        />
      )}
      renderIdle={() => <NoAccessPage pageTitle={id} onBackClick={onBackClick} />}
      data={resource}
      renderLoading={() => (
        <Outer id={id} onBackClick={onBackClick} tab={tab}>
          <ResourceSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data }) => (
        <Outer resource={data} id={id} onBackClick={onBackClick} tab={tab}>
          <Inner tab={tab} resource={data} />
        </Outer>
      )}
    />
  )
}

const Outer = (props: {
  id: string
  children: React.ReactNode
  resource?: TSamResource
  onBackClick: () => void
  tab: SamResourceTab
}) => {
  const { resource, children, id, onBackClick, tab } = props

  return (
    <>
      <PageHeader onBack={onBackClick} pageTitle={resource?.resourceName} />
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={SAM_PERMISSIONS.RESOURCES_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.SamResourceDetails, { resourceId: id })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={SAM_PERMISSIONS.POLICIES_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.SamResourcePolicies, { resourceId: id })}>
              Policies
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main size={tabToPageSize(tab)}>{children}</Page.Main>
    </>
  )
}

const Inner = ({ tab, resource }: { resource: TSamResource; tab: SamResourceTab }) => {
  switch (tab) {
    case 'policies':
      return <ResourcePolicies resourceId={resource.resourceId} />
    default:
    case 'details':
      return <ResourceDetails resource={resource} />
  }
}
