import { indexOf } from 'lodash'
import { ChangeItem } from '../../types'
import { difference } from './difference'

export const getOldTabItems = <T>(newItems: T[], oldItems: T[]): ChangeItem<T>[] => {
  const removed = difference(oldItems, newItems)

  return oldItems.map((value) => {
    const isRemoved = indexOf(removed, value) !== -1
    return { value, variant: isRemoved ? ('removed' as const) : undefined }
  })
}
