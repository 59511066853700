import { Box, copyToClipboard, Icon, Text } from '@revolut/ui-kit'
import { Log } from 'api/types/xChecks'
import { PrincipalComponentType, PrincipalContext } from 'view/XChecks/XCheck/lib/types'
import { useCallback } from 'react'
import { getLogIcon, getLogHeader } from '../utils'

export const LogItemHeader = ({
  log,
  Principal,
}: {
  log: Log
  Principal: PrincipalComponentType
}) => {
  const { iconName, iconColor } = getLogIcon(log)
  const { title, withAuthor } = getLogHeader(log)
  const onDbClick = useCallback(() => copyToClipboard(JSON.stringify(log)), [log])

  return (
    <Box>
      <Icon
        name={iconName}
        color={iconColor}
        onDoubleClick={onDbClick}
        size="s-20"
        marginBottom="s-4"
        marginRight="s-4"
        style={{ display: 'inline-block', verticalAlign: 'middle' }}
      />
      <Text variant="secondary">{title}</Text>
      {withAuthor && (
        <Principal principal={log.author} context={PrincipalContext.requester} />
      )}
    </Box>
  )
}
