import { Page, StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { Header } from '../../../../Header'

export const EmptyEditLayout = ({ onBackClick }: { onBackClick: () => void }) => {
  return (
    <>
      <Header onBackClick={onBackClick} />
      <Page.Main size="wide">
        <StatusWidget>
          <StatusWidget.Image {...get3DImageSrcSetProps('3D358', 'v2')} />
          <StatusWidget.Title role="heading">
            Unable to load request input details
          </StatusWidget.Title>
          <StatusWidget.Description>
            We couldn&apos;t retrieve the request input. This may be due to lack of access
            or a service issue. <br />
            Report this issue to the support team if the problem persists
          </StatusWidget.Description>
        </StatusWidget>
      </Page.Main>
    </>
  )
}
