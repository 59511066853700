import { Token } from '@revolut/ui-kit'
import { State } from 'api/types/xChecks'
import { capitalizeFirst } from 'utils/string'

export const getStateColor = (state: State) => {
  switch (state) {
    case State.Rejected:
    case State.Declined:
      return Token.color.red
    case State.Requested:
    case State.RequestedInfo:
      return Token.color.orange
    case State.Executed:
      return Token.color.green
    case State.Approved:
    case State.AwaitingReview:
      return Token.color.yellow
    default:
      return undefined
  }
}

export const stringifyState = (state: State) => {
  switch (state) {
    case State.Rejected:
      return 'Rejected'
    case State.Declined:
      return 'Declined'
    case State.Requested:
      return 'Requested'
    case State.RequestedInfo:
      return 'Info requested'
    case State.Executed:
      return 'Executed'
    case State.Approved:
      return 'Approved'
    case State.AwaitingReview:
      return 'Waiting for review'
    default:
      return capitalizeFirst(String(state).replaceAll('_', ' '))
  }
}
