import { BottomSheet, Button, Header } from '@revolut/ui-kit'
import { useInputStringChange } from 'hooks/useInputStringChange'
import { useCallback } from 'react'
import { isSubmitCombination } from 'view/XChecks/XCheck/lib/utils'
import { MentionsInput } from '../../../../Details/components/Events/components/EventsContent/components/CommentTab/components/MentionsInput'
import { PopupSettings } from '../useActions/types'

type Props = {
  reasonText?: string
  popupSettings?: PopupSettings
  onSubmitClick: () => void
  setReasonText: (value: string) => void
  open: boolean
  onClose: () => void
}

const DEFAULT_TEXT_ROWS_AMOUNT = 4

export const JustificationPopup = ({
  open,
  onClose,
  reasonText = '',
  setReasonText,
  popupSettings,
  onSubmitClick,
}: Props) => {
  const changeHandler = useInputStringChange(setReasonText)
  const submitDisabled = popupSettings?.isTextRequired && !reasonText

  const onPopupTextKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!submitDisabled && isSubmitCombination(event)) {
        return onSubmitClick()
      }

      return undefined
    },
    [onSubmitClick, submitDisabled],
  )

  if (!popupSettings) {
    return null
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header variant="bottom-sheet">
        <Header.Title>{popupSettings.title}</Header.Title>
        <Header.Description>{popupSettings.description}</Header.Description>
      </Header>
      <MentionsInput
        onChange={changeHandler}
        value={reasonText}
        onKeyDown={onPopupTextKeyDown}
        placeholder={popupSettings.textAreaLabel}
        rows={DEFAULT_TEXT_ROWS_AMOUNT}
        forceSuggestionsAboveCursor
        withPreview
      />

      <BottomSheet.Actions horizontal>
        <Button elevated onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          elevated
          onClick={onSubmitClick}
          variant={popupSettings.primaryActionVariant}
          disabled={submitDisabled}
        >
          {popupSettings.primaryActionLabel}
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
