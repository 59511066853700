import { State } from 'api/types/xChecks'
import { X_CHECKS_PERMISSIONS } from 'view/XChecks/lib/consts'
import { GetActionsParams, XCheckActionSettings } from '../types'

const DECLINE_STATES: State[] = [
  State.Requested,
  State.AwaitingReview,
  State.RequestedInfo,
  State.Approved,
]
export const getDeclineAction = ({
  xCheck,
  xCheckId,
  userId,
  hasXCheckPermission,
  onSubmit,
}: GetActionsParams): XCheckActionSettings | undefined => {
  const isRequester = xCheck && 'id' in xCheck.requester && xCheck.requester.id === userId

  if (
    !userId ||
    !xCheck ||
    !isRequester ||
    !DECLINE_STATES.includes(xCheck.state) ||
    !hasXCheckPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_UPDATE_DECLINE)
  ) {
    return undefined
  }

  return {
    onSubmit,
    xCheckId,
    actionType: 'decline',
    popupSettings: {
      title: 'Decline request',
      description:
        'Once you decline this request, it cannot be reversed, and the status will be updated to Declined. Please provide a reason below before confirming',
      primaryActionLabel: 'Decline',
      primaryActionVariant: 'negative',
      textAreaLabel: 'Decline reason',
      isTextRequired: true,
    },
    actionSettings: {
      label: 'Decline',
      variant: 'negative',
      useIcon: 'Reverted',
    },
    warningSettings: {
      proceedActionLabel: 'Decline',
      description:
        'This action cannot be undone, and the request will be marked as Declined permanently',
      title: 'Do you really want to decline the request?',
      proceedActionButtonVariant: 'negative',
    },
  }
}
