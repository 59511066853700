import { ActionButton, Avatar, Item, abbreviate } from '@revolut/ui-kit'
import { EntityListProps, Item as ItemType } from '../types'

export const EntityListItem = (
  props: EntityListProps & {
    item: ItemType
  },
) => {
  const { item, showAvatars, deleteEnabled, onDeleteClick, disabled } = props
  const { id, title, description, avatar, deleteDisabled } = item

  return (
    <Item key={id} role="row" inactive={disabled}>
      <Item.Content>
        <Item.Title role="cell">{title}</Item.Title>
        <Item.Description role="cell" whiteSpace="pre-wrap">
          {description}
        </Item.Description>
      </Item.Content>
      {showAvatars && (
        <Item.Avatar role="img">
          <Avatar size={40} uuid={id} label={abbreviate(title)} {...avatar} />
        </Item.Avatar>
      )}

      {deleteEnabled && (
        <Item.Side>
          <ActionButton
            useIcon="Delete"
            onClick={() => onDeleteClick(id)}
            disabled={deleteDisabled || disabled}
            label="delete"
            role="deletion"
          />
        </Item.Side>
      )}
    </Item>
  )
}
