import { CrossCheckDetail } from 'api/types/crossCheck'
import { CustomerCompany } from 'api/dart/customerCompanies'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { CrossCheckValueTab } from 'view/CrossChecks/CrossCheck/components/CrosscheckDetails/types'
import { getRows } from 'view/Dart/CustomerCompany/components/EmployeeCompanies/components/EmployeeCompanyList/utils'
import { EmployeeCompany } from 'api/dart/types'
import { getChangeRowsInfo } from '../../../../utils'
import { EmployeeCompanyRow } from './columns'
import { getAccessModifiersInfo } from '../../utils'

type GetAccessModifierRowsParams = {
  crossCheck: CrossCheckDetail
  entity?: CustomerCompany
  employeeCompaniesMap: Map<string, EmployeeCompany>
  tabs: CrossCheckValueTab[]
}

export const getAccessModifierRows = (params: GetAccessModifierRowsParams) => {
  const { crossCheck, entity, employeeCompaniesMap, tabs } = params

  const { accessModifiers, actionType } = getAccessModifiersInfo(crossCheck)

  const ids = accessModifiers.map(
    (v) => 'employeeCompanyId' in v && v.employeeCompanyId,
  ) as string[]

  const rows = getRows(Array.from(employeeCompaniesMap.values()), entity)

  const added = actionType === 'added' ? ids : []
  const deleted = actionType === 'removed' ? ids : []
  const values: string[] = []
  const current = rows.filter((v) => v.accessModifierId).map((v) => v.id)

  const addedHash = toPresenceMap(added)
  const deletedHash = toPresenceMap(deleted)

  return getChangeRowsInfo({
    tabs,
    added,
    addedHash,
    deleted,
    deletedHash,
    current,
    values,
    makeMapValue,
    employeeCompaniesMap,
  })
}

const makeMapValue =
  ({
    deletedHash = {},
    addedHash = {},
    employeeCompaniesMap,
  }: {
    deletedHash?: Record<string, boolean>
    addedHash?: Record<string, boolean>
    employeeCompaniesMap: Map<string, EmployeeCompany>
  }) =>
  (employeeCompanyId: string): EmployeeCompanyRow => {
    const removedStatus = deletedHash[employeeCompanyId] ? 'removed' : undefined
    const addedStatus = addedHash[employeeCompanyId] ? 'added' : undefined

    return {
      id: employeeCompanyId,
      name: employeeCompaniesMap.get(employeeCompanyId)?.name || employeeCompanyId,
      region: employeeCompaniesMap.get(employeeCompanyId)?.region,
      status: addedStatus || removedStatus,
    }
  }

export const getEmptyTableLabel = (status: CrossCheckValueTab) =>
  status === 'requested'
    ? 'No added or deleted employee companies'
    : 'No employee company has access to the customer company'
