import { useQueryDirectRelations } from 'queries/dart/employees'
import { useQueryCustomerCompanyMap } from 'queries/dart/companies'
import { useQueryUserIdMap } from 'queries/idave/users'
import { useCallback, useMemo } from 'react'
import { EntitiesTable } from 'components/EntitiesTable'
import { waitForAll } from 'utils/query'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { mapDirectRelations } from './utils'
import { DirectViewTable } from './types'
import { getColumns } from './columns'

export const UserAccesses = () => {
  const {
    data: userMap = new Map(),
    status: userStatus,
    fetchStatus: userFetchStatus,
  } = useQueryUserIdMap()

  const {
    data: customerCompanyMap = new Map(),
    status: customerCompaniesStatus,
    fetchStatus: customerCompaniesFetchStatus,
  } = useQueryCustomerCompanyMap()

  const {
    data: directRelations = [],
    status: directRelStatus,
    fetchStatus: directRelationsFetchStatus,
  } = useQueryDirectRelations()

  const columns = useMemo(() => getColumns({ customerCompanyMap }), [customerCompanyMap])

  const entities = useMemo(
    () =>
      mapDirectRelations({
        userMap,
        directRelations,
      }),
    [directRelations, userMap],
  )

  const { searched, searchValue, setSearchValue } = useSearchFilter({
    entities,
  })

  const queryStatus = waitForAll(
    [
      {
        qs: directRelStatus,
        fs: directRelationsFetchStatus,
      },
    ],
    [
      { qs: userStatus, fs: userFetchStatus },
      { qs: customerCompaniesStatus, fs: customerCompaniesFetchStatus },
    ],
  )

  const getEmployeeLink = useCallback(({ employeeId: userId }: DirectViewTable) => {
    return generatePath(Url.UserProfile, { userId })
  }, [])

  return (
    <EntitiesTable
      enableNavigation
      totalCount={entities.length}
      entitiesTypeLabel="Accesses"
      pluralForms={['access', 'accesses']}
      data={searched}
      columns={columns}
      loadingState={getLoadingState(queryStatus, entities.length)}
      onSearchChange={setSearchValue}
      searchValue={searchValue}
      searchAutoFocus
      getRowLink={getEmployeeLink}
    />
  )
}
