import { Token } from '@revolut/ui-kit'
import { match } from 'ts-pattern'
import { Variant } from 'view/XChecks/XCheck/lib/components/Updates/types'
import { TableEntity } from './getEntities'

export const getRowState = ({ value }: { value: TableEntity }) =>
  match(value.variant)
    .with(Variant.added, () => ({
      indicatorColor: Token.color.green,
    }))
    .with(Variant.removed, () => ({
      indicatorColor: Token.color.red,
    }))
    .otherwise(() => ({}))
