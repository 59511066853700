import { useToggle } from '@revolut/ui-kit'
import { useTemporaryResourcesAccesses } from 'queries/sam/resources'
import { QuerySwitch } from 'components/QuerySwitch'
import { EntitySkeleton } from 'components/EntitySkeleton'
import { ResourceRequest } from './components/ResourceRequest'
import { ResourcesList } from './components/ResourcesList'

export const UserResources = ({ userId: idaveUserId }: { userId: string }) => {
  const { data, fetchStatus: fs, status: qs } = useTemporaryResourcesAccesses(idaveUserId)

  const [isRequesting, toggleRequesting] = useToggle({ defaultState: false })

  return (
    <>
      {isRequesting && (
        <ResourceRequest idaveUserId={idaveUserId} close={toggleRequesting.off} />
      )}
      <QuerySwitch
        data={data}
        required={[{ qs, fs }]}
        renderLoading={() => <EntitySkeleton hasActions variant="table" />}
        renderSuccess={({ data: accesses }) => (
          <ResourcesList
            idaveUserId={idaveUserId}
            onRequestResourceClick={toggleRequesting.on}
            accesses={accesses}
          />
        )}
      />
    </>
  )
}
