import { EntityParam } from 'api/types/xChecks'
import { match, P } from 'ts-pattern'
import { useMemo } from 'react'
import { hasEditableAttributes } from './utils'
import { InputProps } from '../type'
import { EntityAttributes, EntityOptions, Entity } from './components'
import { PARAM_WITH_OPTION_PATTERN } from '../constants'

export const EntityInput = (props: InputProps<EntityParam>) => {
  const { param } = props

  const EditEntity = useMemo(
    () =>
      match(param)
        .with(
          { value: { editConfig: P.optional(undefined) } },
          hasEditableAttributes,
          () => EntityAttributes,
        )
        .with(PARAM_WITH_OPTION_PATTERN, () => EntityOptions)
        .otherwise(() => Entity),
    [param],
  )

  return <EditEntity {...props} />
}
