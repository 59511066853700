import React, { createContext, useContext, useMemo } from 'react'
import { noop } from 'view/XChecks/lib/hooks/utils'
import { FieldValue } from '../types'

type TFormContext = {
  updateForm: (path: string, value: FieldValue) => void
  setError: (path: string, message?: string) => void
  errors: Record<string, string | undefined>
}
const FormContext = createContext<TFormContext>({
  updateForm: noop,
  errors: {},
  setError: noop,
})

export const FormProvider = ({
  updateForm,
  errors,
  setError,
  children,
}: TFormContext & {
  children?: React.ReactNode
}) => {
  const value = useMemo(
    () => ({ updateForm, errors, setError }),
    [updateForm, errors, setError],
  )

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export const useFormContext = () => useContext(FormContext)
