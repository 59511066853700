import { Table } from '@revolut/ui-kit'
import { match } from 'ts-pattern'
import { valueTypeMatcher } from 'view/XChecks/XCheck/lib/utils'
import { EntityValueCell } from '../../components/EntityValueCell'
import { UuidValueCell } from '../../components/UuidValueCell'
import { BoolValueCell } from '../../components/BoolValueCell'
import { TableEntity } from '../getEntities'

export const Cell = (cell: {
  row: { original: TableEntity }
  column: { id: string }
  value: string
}) => {
  const key = cell.column.id
  const param = cell.row.original.entityAttrs.get(key)

  return match(param)
    .with(valueTypeMatcher('entity'), (entity) => (
      <EntityValueCell entity={entity.value} />
    ))
    .with(valueTypeMatcher('bool'), (bool) => <BoolValueCell param={bool} />)
    .with(valueTypeMatcher('uuid'), (uuid) => <UuidValueCell param={uuid} />)
    .otherwise(() => <Table.Cell>{String(cell.value)}</Table.Cell>)
}
