import { Action, TableColumn } from '@revolut/ui-kit'
import { EntityComponentType } from 'view/XChecks/XCheck/lib/types'
import { getEntityAttributesColumns } from './getEntityAttributesColumns'
import { EntityValueCell } from '../../components/EntityValueCell'
import { TableEntity } from '../getEntities'
import { EXPANDABLE_AMOUNT } from '../useTableInfo'

type Params = {
  entities: TableEntity[]
  isExpandable: boolean
  expanded: boolean
  toggleExpanded: () => void
  Entity: EntityComponentType
}
export const getArrayColumns = ({
  entities,
  toggleExpanded,
  expanded,
  isExpandable,
}: Params): TableColumn<TableEntity>[] => {
  const mainHeader = entities?.[0]?.descriptor.entityType || 'Entity'
  const expandAmount = entities.length - EXPANDABLE_AMOUNT

  const mainColumn: TableColumn<TableEntity> = {
    Header: mainHeader,
    accessor: (item) => item.descriptor.name,
    Cell: (params: { row: { original: TableEntity }; value: string }) => {
      const tableEntity = params.row.original
      return <EntityValueCell entity={tableEntity.entity} />
    },
    Footer: isExpandable ? (
      <ExpandCollapse
        expandAmount={expandAmount}
        toggleExpanded={toggleExpanded}
        expanded={expanded}
      />
    ) : undefined,
  }

  return [mainColumn, ...getEntityAttributesColumns(entities)]
}

type Props = {
  toggleExpanded: () => void
  expanded: boolean
  expandAmount: number
}
const ExpandCollapse = ({ toggleExpanded, expanded, expandAmount }: Props) => (
  <Action onClick={toggleExpanded} useEndIcon={expanded ? 'ChevronUp' : 'ChevronDown'}>
    {expanded ? 'Collapse' : `Expand (${expandAmount})`}
  </Action>
)
