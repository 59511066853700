import { EmployeeType, SamPolicySubjectType } from 'api/sam/policies'

export const EMPLOYEE_INPUTS = ['employeeIds'] as const
export const EMPLOYEE_TYPE_INPUTS = ['employeeTypes', 'contractTypes'] as const
export const TEAM_INPUTS = [
  'teamIds',
  'employeeTypes',
  'contractTypes',
  'specialisationIds',
  'seniorityIds',
] as const
export const DEPARTMENT_INPUTS = [
  'departmentIds',
  'employeeTypes',
  'specialisationIds',
  'seniorityIds',
] as const
export const SPEC_INPUTS = [
  'specialisationIds',
  'employeeTypes',
  'contractTypes',
  'seniorityIds',
] as const
export const ROLE_INPUTS = ['positionIds', 'employeeTypes', 'seniorityIds'] as const
export const FUNCTION_INPUTS = ['functionIds', 'employeeTypes', 'seniorityIds'] as const

export const DEFAULT_SUBJECT_TEAM = {
  subjectType: SamPolicySubjectType.Team as const,
  teamIds: [],
  employeeTypes: [EmployeeType.Internal],
  contractTypes: [],
  specialisationIds: [],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_DEPARTMENT = {
  subjectType: SamPolicySubjectType.Department as const,
  departmentIds: [],
  employeeTypes: [EmployeeType.Internal],
  specialisationIds: [],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_EMPLOYEE = {
  subjectType: SamPolicySubjectType.Employee as const,
  employeeIds: [],
}

export const DEFAULT_SUBJECT_SPEC = {
  subjectType: SamPolicySubjectType.Specialisation as const,
  specialisationIds: [],
  employeeTypes: [EmployeeType.Internal],
  contractTypes: [],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_EMPLOYEE_TYPE = {
  subjectType: SamPolicySubjectType.EmployeeType as const,
  employeeTypes: [EmployeeType.Internal],
  contractTypes: [],
}

export const DEFAULT_SUBJECT_ROLE = {
  subjectType: SamPolicySubjectType.Role as const,
  positionIds: [],
  employeeTypes: [EmployeeType.Internal],
  seniorityIds: [],
}

export const DEFAULT_SUBJECT_FUNCTION = {
  subjectType: SamPolicySubjectType.Function as const,
  functionIds: [],
  employeeTypes: [EmployeeType.Internal],
  seniorityIds: [],
}

export const DEFAULT_POLICY_DETAILS = {
  subject: {
    subjectType: undefined,
  },
  policyName: '',
  businessReason: '',
}
