import { createContext, useContext, useMemo } from 'react'
import { noop, uniq } from 'lodash'
import { ApiSettings } from 'view/XChecks/lib/hooks/useAPI/types'
import { makeErrorAsyncNoop } from '../utils'
import { makeService } from './service'
import { XCheckApi } from './types'

export type TApiContext = XCheckApi & ApiSettings & ApiAttributes

type ApiAttributes = {
  sources: string[]
}

const DEFAULT = {
  review: makeErrorAsyncNoop('review'),
  decline: makeErrorAsyncNoop('decline'),
  justify: makeErrorAsyncNoop('justify'),
  retry: makeErrorAsyncNoop('retry'),
  comment: makeErrorAsyncNoop('comment'),
  editComment: makeErrorAsyncNoop('editComment'),
  requestInfo: makeErrorAsyncNoop('requestInfo'),
  provideInfo: makeErrorAsyncNoop('provideInfo'),
  extendReviewGroup: makeErrorAsyncNoop('extendReviewGroup'),
  fetchXCheck: makeErrorAsyncNoop('fetchXCheck'),
  fetchXCheckLog: makeErrorAsyncNoop('fetchXCheckLog'),
  entitiesFetchers: {},
  fetchParams: makeErrorAsyncNoop('fetchParams'),
  fetchChanges: makeErrorAsyncNoop('fetchChanges'),
  refresh: makeErrorAsyncNoop('refresh'),
  sourceServiceMap: {},
  sourcePrefixMap: {},
  apiPrefix: '',
  apiName: '',
  sources: [],
  onXCheckApiError: noop,
  fetchUsers: makeErrorAsyncNoop('fetchUsers'),
  updateParams: makeErrorAsyncNoop('updateParams'),
}

export const ApiContext = createContext<TApiContext>(DEFAULT)

type ApiProviderProps = ApiSettings & {
  children?: React.ReactNode
}

export const ApiProvider = ({ children, ...apiSettings }: ApiProviderProps) => {
  const sources = useMemo(
    () =>
      uniq(
        Object.keys(apiSettings.sourcePrefixMap).concat(
          ...Object.keys(apiSettings.sourceServiceMap),
        ),
      ),
    [apiSettings],
  )

  const value: TApiContext = useMemo(
    () => ({
      ...DEFAULT,
      ...makeService(apiSettings),
      ...apiSettings,
      sources,
    }),
    [apiSettings, sources],
  )

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}
export const useApi = () => useContext(ApiContext)
