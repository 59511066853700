import { Page, Text } from '@revolut/ui-kit'
import { useMemo, useState } from 'react'
import { useDebouncedValue } from 'hooks/useDebouncedValue'
import { uniqBy } from 'lodash'
import { getLoadingState } from 'components/EntitiesTable/utils'
import { getDataValue } from 'view/XChecks/lib/utils'
import { Table } from '../..'
import { useXChecks } from '../hooks/useXChecks'
import { XChecksFilters } from '../../Table/types'
import { useNavigation, useXChecksData } from '../../../hooks'

export const Layout = () => {
  const { getRowLink, onRowClick } = useNavigation()

  const { userIdData } = useXChecksData()

  const defaultFilters = useMemo(
    () => ({
      currentReviewer: userIdData.status === 'success' ? userIdData.data : undefined,
    }),
    [userIdData],
  )

  const [search, setSearch] = useState<string>()
  const debouncedSearch = useDebouncedValue(search, 500)

  const [filters, setFilters] = useState<XChecksFilters>(defaultFilters)

  const {
    data: xCheckPages,
    status: xCheckPagesS,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useXChecks({
    limit: 25,
    query: debouncedSearch || undefined,
    ...filters,
  })

  const xChecks = useMemo(() => {
    return uniqBy(
      (xCheckPages?.pages || []).flatMap((data) => data),
      'id',
    )
  }, [xCheckPages])

  const loadingState = getLoadingState(xCheckPagesS, xChecks.length)

  return (
    <>
      <Page.Header>
        <Text>Requests</Text>
      </Page.Header>
      <Page.Main>
        <Table
          currentReviewer={getDataValue(userIdData)}
          data={xChecks}
          onRetry={refetch}
          hasMore={hasNextPage}
          onRequestMore={fetchNextPage}
          loadingState={loadingState}
          search={search}
          onSearch={setSearch}
          onFilter={setFilters}
          onRowClick={onRowClick}
          getRowLink={getRowLink}
          defaultFilters={defaultFilters}
        />
      </Page.Main>
    </>
  )
}
