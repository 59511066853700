import { QuerySwitch } from 'components/QuerySwitch'
import { generatePath } from 'react-router'
import { Url } from 'routing'
import { IDAVE_PERMISSIONS } from 'security'
import { TabBarLink } from 'components/TabBarLink'
import { Client } from 'api/idave/clients'
import { Account as TAccount } from 'api/idave/accounts'
import { TabBar, TextSkeleton, chain, Text, Token, Page } from '@revolut/ui-kit'
import { useQueryAccount, useQueryAccountCustomization } from 'queries/idave/accounts'
import { useQueryClient } from 'queries/idave/clients'
import { AccountTab } from 'view/Clients/Account/types'
import { PermissionsCheck } from '@revolut-internal/idave-web-auth'
import { NoAccessPage } from 'components/NoAccessWidget'
import { RequestErrorPage } from 'components/RequestErrorWidget'
import { Title } from 'components/Title'
import { useNavigateToList } from 'hooks/useBackToResults'
import { AccountSkeleton } from './components/Skeleton'
import { Details } from './components/Details'
import { Permissions } from './components/Permissions'
import { AccountAuditLog } from './components/AccountAuditLog'
import { tabToPageSize } from './utils'

type Props = {
  id: string
  tab: AccountTab
}

export const Account = ({ id, tab }: Props) => {
  const { data, fetchStatus: fs, status: qs } = useQueryAccount({ accountId: id })
  const {
    data: client,
    fetchStatus: clientFS,
    status: clientQS,
  } = useQueryClient(data?.clientId)
  const {
    data: customization,
    fetchStatus: customizationFS,
    status: customizationQS,
  } = useQueryAccountCustomization(id)

  const onBackClick = useNavigateToList({
    listUrl: Url.Accounts,
    entityUrl: generatePath(Url.Account, { accountId: id }),
  })

  return (
    <QuerySwitch
      required={[
        { qs, fs },
        { qs: clientQS, fs: clientFS },
      ]}
      optional={[
        {
          fs: customizationFS,
          qs: customizationQS,
        },
      ]}
      data={data}
      renderLoading={() => (
        <Outer id={id} client={client} onBackClick={onBackClick} tab={tab}>
          <AccountSkeleton tab={tab} />
        </Outer>
      )}
      renderSuccess={({ data: account }) => (
        <Outer
          id={id}
          client={client}
          account={account}
          onBackClick={onBackClick}
          tab={tab}
        >
          {tab === 'details' && (
            <Details account={account} client={client} customization={customization} />
          )}
          {tab === 'permissions' && <Permissions account={account} />}
          {tab === 'auditLog' && <AccountAuditLog accountId={id} />}
        </Outer>
      )}
      renderError={() => (
        <RequestErrorPage
          pageTitle={id}
          title="Something went wrong"
          description="Required entities info fetch failed (client and/or account)"
          onBackClick={onBackClick}
        />
      )}
      renderIdle={() => <NoAccessPage pageTitle={id} onBackClick={onBackClick} />}
    />
  )
}

const Outer = ({
  id,
  client,
  children,
  account,
  onBackClick,
  tab,
}: {
  id: string
  onBackClick: () => void
  client?: Client
  account?: TAccount
  children: React.ReactNode
  tab: AccountTab
}) => {
  return (
    <>
      <Title value={[client?.name, 'Accounts'].filter(Boolean).join(' | ')} />
      <Page.Header onBack={onBackClick}>
        {chain(
          client?.name || <TextSkeleton size={16} />,
          account?.state === 'TERMINATED' ? (
            <Text color={Token.color.red}>Terminated</Text>
          ) : undefined,
        )}
      </Page.Header>
      <Page.Tabs>
        <TabBar variant="navigation">
          <PermissionsCheck permission={IDAVE_PERMISSIONS.CLIENTS_VIEW_DETAILS}>
            <TabBarLink to={generatePath(Url.AccountDetails, { accountId: id })}>
              Details
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck
            everyPermission={[
              IDAVE_PERMISSIONS.CLIENTS_VIEW_LIST,
              IDAVE_PERMISSIONS.PERMISSIONS_VIEW_LIST,
            ]}
          >
            <TabBarLink to={generatePath(Url.AccountPermissions, { accountId: id })}>
              Permissions
            </TabBarLink>
          </PermissionsCheck>

          <PermissionsCheck permission={IDAVE_PERMISSIONS.AUDIT_LOG_VIEW_LIST}>
            <TabBarLink to={generatePath(Url.AccountAuditLogs, { accountId: id })}>
              Audit log
            </TabBarLink>
          </PermissionsCheck>
        </TabBar>
      </Page.Tabs>
      <Page.Main size={tabToPageSize(tab)}>{children}</Page.Main>
    </>
  )
}
