import axios from 'axios'

export type EmployeeDetails = {
  employeeId: string
  employeeType: string
  teamId: string
  specialisationId: string
  seniorityId: string
}

export type MandatoryTrainingDetails = {
  code: string
  name: string
  completed: boolean
  completedAt?: string
}

export type PolicyDetails = {
  policyId: string
  active: boolean
  policyName: string
  resourceIds: string[]
  subject: SamPolicySubject
  policyAuthor: string
  businessReason: string
  mandatoryTrainings?: MandatoryTrainingDetails[]
  createdAt: string
  updatedAt: string
}

export type SamUserDetails = {
  userId: string
  employee: EmployeeDetails
  policies: PolicyDetails[]
  incompleteTrainings: MandatoryTrainingDetails[]
  completeTrainings: MandatoryTrainingDetails[]
}

export enum SamPolicyStatus {
  Active = 'active',
  Removed = 'removed',
}

export enum SamPolicySubjectType {
  Team = 'team',
  Department = 'department',
  Employee = 'employee',
  Specialisation = 'specialisation',
  EmployeeType = 'employee_type',
  Role = 'role',
  Function = 'function',
}

export enum EmployeeType {
  Internal = 'internal',
  External = 'external',
}

export type SamPolicySubjectTeam = {
  subjectType: SamPolicySubjectType.Team
  teamIds: string[]
  employeeTypes: EmployeeType[]
  contractTypes?: string[]
  specialisationIds?: string[]
  seniorityIds?: string[] | null
}

export type SamPolicySubjectDepartment = {
  subjectType: SamPolicySubjectType.Department
  departmentIds: string[]
  employeeTypes: EmployeeType[]
  specialisationIds?: string[]
  seniorityIds?: string[] | null
}

export type SamPolicySubjectEmployee = {
  subjectType: SamPolicySubjectType.Employee
  employeeIds: string[]
}

export type SamPolicySubjectSpecialisation = {
  subjectType: SamPolicySubjectType.Specialisation
  employeeTypes: EmployeeType[]
  contractTypes?: string[]
  specialisationIds: string[]
  seniorityIds?: string[] | null
}

export type SamPolicySubjectEmployeeType = {
  subjectType: SamPolicySubjectType.EmployeeType
  employeeTypes: EmployeeType[]
  contractTypes?: string[]
}

export type SamPolicySubjectRole = {
  subjectType: SamPolicySubjectType.Role
  positionIds: string[]
  employeeTypes: EmployeeType[]
  seniorityIds?: string[] | null
}

export type SamPolicySubjectFunction = {
  subjectType: SamPolicySubjectType.Function
  functionIds: string[]
  employeeTypes: EmployeeType[]
  seniorityIds?: string[] | null
}

export type SamPolicySubject =
  | SamPolicySubjectTeam
  | SamPolicySubjectDepartment
  | SamPolicySubjectEmployee
  | SamPolicySubjectSpecialisation
  | SamPolicySubjectEmployeeType
  | SamPolicySubjectRole
  | SamPolicySubjectFunction

export type SamPolicy = {
  policyId: string
  policyName: string
  status: SamPolicyStatus
  resourceIds: string[]
  mandatoryTrainings: string[] | null
  subject: SamPolicySubject
  policyAuthor: string
  businessReason: string
  createdAt: string
  updatedAt: string
}

export type SamCreatePolicyPayload = {
  resourceIds: string[]
  subject: SamPolicySubject
  mandatoryTrainings: string[] | null
  businessReason: string
  policyName: string
}

export type SamUpdatePolicyResourcesPayload = {
  policyId: string
  resourceIds: string[]
}

export type SamMutatePolicyError = {
  detail:
    | string
    | {
        loc: string[]
        msg: string
        type: string
      }[]
}

export type SamPoliciesResponse = {
  data: SamPolicy[]
}

export type SamPatchPolicyPayload = {
  policyName: string
  businessReason: string
  mandatoryTrainings: string[] | null
}

export type SamCrossCheckPatchPolicyPayload = {
  policyName?: string
  businessReason?: string
  mandatoryTrainings?: string[]
  resourceIds?: string[]
  subject?: SamPolicySubject
}

export type SamCreateCrosscheck = {
  crossCheckId: string
}

export type UpdateSamPolicyResponse = {
  crossCheckId: null | string
  policy?: SamPolicy
}

export type PolicyEmployees = {
  employeeIds: string[]
}

export type GetPolicyParams = {
  resourceId?: string
}
export const policies = {
  getPolicies: (params?: GetPolicyParams) =>
    axios.get<SamPoliciesResponse>('/sam-api/api/policies/', { params }),

  getPolicy: (id: string) => axios.get<SamPolicy>(`/sam-api/api/policies/${id}`),

  createPolicy: (payload: SamCreatePolicyPayload) =>
    axios.post<SamPolicy>('/sam-api/api/policies/', payload),

  crossCheckCreatePolicy: (payload: SamCreatePolicyPayload) =>
    axios.post<SamCreateCrosscheck>('/sam-api/api/cross-checked-policies/', payload),

  updatePolicy: (payload: { policyId: string; data: SamPatchPolicyPayload }) =>
    axios.patch<void>(`/sam-api/api/policies/${payload.policyId}`, payload.data),

  crossCheckUpdatePolicy: (payload: {
    policyId: string
    data: SamCrossCheckPatchPolicyPayload
  }) =>
    axios.patch<UpdateSamPolicyResponse>(
      `/sam-api/api/cross-checked-policies/${payload.policyId}`,
      payload.data,
    ),

  deletePolicy: (id: string) => axios.delete<void>(`/sam-api/api/policies/${id}`),

  crossCheckDeletePolicy: (id: string) =>
    axios.delete<SamCreateCrosscheck>(`/sam-api/api/cross-checked-policies/${id}`),

  updatePolicyResources: ({ policyId, resourceIds }: SamUpdatePolicyResourcesPayload) =>
    axios.put<void>(`/sam-api/api/policies/${policyId}/resources`, resourceIds),

  applyPolicy: (id: string) => axios.post<void>(`/sam-api/api/policies/${id}/apply`),

  userProvision: (id: string) =>
    axios.post<void>(`/sam-api/api/user-policies/${id}/provision`),

  employees: (id: string) =>
    axios.get<PolicyEmployees>(`/sam-api/api/policies/${id}/employees`),

  getUserDetails: (userId: string) =>
    axios.get<SamUserDetails>(`/sam-api/api/user-policies/${userId}`),
}
