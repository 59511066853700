import { TableColumn } from '@revolut/ui-kit'
import { isEntityWellKnownAttr } from 'view/XChecks/XCheck/lib/utils'
import { makeTableAccessor } from './makeTableAccessor'
import { Cell } from './makeTableCell'
import { TableEntity } from '../getEntities'

export const getEntityAttributesColumns = (
  entities: TableEntity[],
): TableColumn<TableEntity>[] => {
  return Object.values(
    entities.reduce((attributeColumns, entity) => {
      return {
        ...attributeColumns,
        ...getEntityAttributeColumn(entity),
      }
    }, {}),
  )
}

const getEntityAttributeColumn = (row: TableEntity) => {
  const names = Array.from(row.entityAttrs.keys()).filter(
    (name) => !isEntityWellKnownAttr(name),
  )
  return names.reduce<Record<string, TableColumn<TableEntity>>>(
    (columns, name) => ({
      ...columns,
      [name]: {
        Header: name,
        id: name,
        accessor: makeTableAccessor(name),
        Cell,
      },
    }),
    {},
  )
}
