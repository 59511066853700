/* eslint-disable prettier/prettier */
export const SAM_PERMISSIONS = {
  AUDIT_VIEW_DETAILS: {
    endpoints: [
      'GET /api/audit/{entity_type}/{entity_id}'
    ],
    service: 'sam',
    value: 'audit.view.details'
  },
  AUDIT_VIEW_LIST: {
    endpoints: [
      'GET /api/audit/'
    ],
    service: 'sam',
    value: 'audit.view.list'
  },
  CROSS_CHECKS_COMMENT: {
    endpoints: [
      'POST /api/cross-checks-v2/{cross_check_id}/comment'
    ],
    service: 'sam',
    value: 'cross_checks.comment'
  },
  CROSS_CHECKS_DECLINE: {
    endpoints: [
      'POST /api/cross-checks-v2/{cross_check_id}/decline'
    ],
    service: 'sam',
    value: 'cross_checks.decline'
  },
  CROSS_CHECKS_JUSTIFY: {
    endpoints: [
      'POST /api/cross-checks-v2/{cross_check_id}/justify'
    ],
    service: 'sam',
    value: 'cross_checks.justify'
  },
  CROSS_CHECKS_RETRY: {
    endpoints: [
      'POST /api/cross-checks-v2/{cross_check_id}/retry'
    ],
    service: 'sam',
    value: 'cross_checks.retry'
  },
  CROSS_CHECKS_REVIEW: {
    endpoints: [
      'POST /api/cross-checks-v2/{cross_check_id}/review'
    ],
    service: 'sam',
    value: 'cross_checks.review'
  },
  CROSS_CHECKS_VIEW_DETAILS: {
    endpoints: [
      'GET /api/cross-checks-v2/{cross_check_id}'
    ],
    service: 'sam',
    value: 'cross_checks.view.details'
  },
  CROSS_CHECKS_VIEW_LIST: {
    endpoints: [
      'GET /api/cross-checks-v2/',
      'GET /api/cross-checks-v2/my',
      'GET /api/cross-checks-v2/temporary-resource-access-requests/{idave_user_id}'
    ],
    service: 'sam',
    value: 'cross_checks.view.list'
  },
  PERMISSIONS_VIEW_ACL_LIST: {
    endpoints: [
      'GET /permissions/acl'
    ],
    service: 'sam',
    value: 'permissions.view.acl.list'
  },
  POLICIES_CREATE: {
    endpoints: [
      'POST /api/policies/',
      'POST /service-api/policies/'
    ],
    service: 'sam',
    value: 'policies.create'
  },
  POLICIES_CREATE_MULTI_SUBJECT_WITH_CROSS_CHECK: {
    endpoints: [
      'POST /api/cross-checked-policies/'
    ],
    service: 'sam',
    value: 'policies.create.multi_subject_with_cross_check'
  },
  POLICIES_CREATE_WITH_CROSS_CHECK: {
    endpoints: [
      'POST /api/cross-checked-policies/'
    ],
    service: 'sam',
    value: 'policies.create.with_cross_check'
  },
  POLICIES_DELETE: {
    endpoints: [
      'DELETE /api/policies/{policy_id}',
      'DELETE /service-api/policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.delete'
  },
  POLICIES_DELETE_WITH_CROSS_CHECK: {
    endpoints: [
      'DELETE /api/cross-checked-policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.delete.with_cross_check'
  },
  POLICIES_UPDATE: {
    endpoints: [
      'PATCH /api/policies/{policy_id}',
      'PATCH /service-api/policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.update'
  },
  POLICIES_UPDATE_APPLY: {
    endpoints: [
      'POST /api/policies/{policy_id}/apply'
    ],
    service: 'sam',
    value: 'policies.update.apply'
  },
  POLICIES_UPDATE_MULTI_SUBJECT_WITH_CROSS_CHECK: {
    endpoints: [
      'PATCH /api/cross-checked-policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.update.multi_subject_with_cross_check'
  },
  POLICIES_UPDATE_RESOURCES: {
    endpoints: [
      'PUT /api/policies/{policy_id}/resources'
    ],
    service: 'sam',
    value: 'policies.update.resources'
  },
  POLICIES_UPDATE_WITH_CROSS_CHECK: {
    endpoints: [
      'PATCH /api/cross-checked-policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.update.with_cross_check'
  },
  POLICIES_VIEW_DETAILS: {
    endpoints: [
      'GET /api/policies/{policy_id}',
      'GET /service-api/policies/{policy_id}'
    ],
    service: 'sam',
    value: 'policies.view.details'
  },
  POLICIES_VIEW_EMPLOYEES_LIST: {
    endpoints: [
      'GET /api/policies/{policy_id}/employees'
    ],
    service: 'sam',
    value: 'policies.view.employees.list'
  },
  POLICIES_VIEW_LIST: {
    endpoints: [
      'GET /api/policies/',
      'GET /service-api/policies/'
    ],
    service: 'sam',
    value: 'policies.view.list'
  },
  RECERTIFICATIONS_VIEW_DETAILS: {
    endpoints: [
      'GET /api/recertifications/{recertification_id}'
    ],
    service: 'sam',
    value: 'recertifications.view.details'
  },
  RECERTIFICATIONS_VIEW_LIST: {
    endpoints: [
      'GET /api/recertifications/'
    ],
    service: 'sam',
    value: 'recertifications.view.list'
  },
  RECERTIFICATIONS_VIEW_POLICY_MAPPING_LIST: {
    endpoints: [
      'GET /api/recertifications/policy-mapping'
    ],
    service: 'sam',
    value: 'recertifications.view.policy_mapping.list'
  },
  RESOURCES_SET_OWNERS: {
    endpoints: [
      'POST /api/resources/set-owners'
    ],
    service: 'sam',
    value: 'resources.set_owners'
  },
  RESOURCES_VIEW_DETAILS: {
    endpoints: [
      'GET /api/resources/{resource_id}'
    ],
    service: 'sam',
    value: 'resources.view.details'
  },
  RESOURCES_VIEW_LIST: {
    endpoints: [
      'GET /api/resources/',
      'GET /service-api/resources/'
    ],
    service: 'sam',
    value: 'resources.view.list'
  },
  REVIEWER_GROUP_ADD_REVIEWERS: {
    endpoints: [
      'POST /api/cross-checks-v2/groups/{reviewer_group_id}/reviewers'
    ],
    service: 'sam',
    value: 'reviewer_group.add_reviewers'
  },
  REVIEWER_GROUP_VIEW_DETAILS: {
    endpoints: [
      'GET /api/cross-checks-v2/groups/{reviewer_group_id}'
    ],
    service: 'sam',
    value: 'reviewer_group.view.details'
  },
  REVOLUTERS_STRUCTURE_VIEW_LIST: {
    endpoints: [
      'GET /api/revoluters-structure/'
    ],
    service: 'sam',
    value: 'revoluters_structure.view.list'
  },
  REVOLUTERS_VIEW_AVATARS_LIST: {
    endpoints: [
      'GET /api/revoluters/avatars'
    ],
    service: 'sam',
    value: 'revoluters.view.avatars.list'
  },
  REVOLUTERS_VIEW_EMPLOYEES_LIST: {
    endpoints: [
      'GET /api/revoluters/employees'
    ],
    service: 'sam',
    value: 'revoluters.view.employees.list'
  },
  REVOLUTERS_VIEW_STRUCTURE_LIST: {
    endpoints: [
      'GET /api/revoluters/structure'
    ],
    service: 'sam',
    value: 'revoluters.view.structure.list'
  },
  TASKS_CREATE: {
    endpoints: [
      'POST /api/tasks/'
    ],
    service: 'sam',
    value: 'tasks.create'
  },
  TASKS_VIEW_LIST: {
    endpoints: [
      'GET /api/tasks/'
    ],
    service: 'sam',
    value: 'tasks.view.list'
  },
  TEMPORARY_RESOURCE_ACCESS_CREATE: {
    endpoints: [
      'POST /api/temporary-resource-access/'
    ],
    service: 'sam',
    value: 'temporary_resource_access.create'
  },
  TEMPORARY_RESOURCE_ACCESS_UPDATE_REVOKE: {
    endpoints: [
      'POST /api/temporary-resource-access/{temporary_resource_access_id}/revoke'
    ],
    service: 'sam',
    value: 'temporary_resource_access.update.revoke'
  },
  TEMPORARY_RESOURCE_ACCESS_VIEW_AVAILABLE_RESOURCES_DETAILS: {
    endpoints: [
      'GET /api/temporary-resource-access/available-resources/{idave_user_id}'
    ],
    service: 'sam',
    value: 'temporary_resource_access.view.available_resources.details'
  },
  TEMPORARY_RESOURCE_ACCESS_VIEW_DETAILS: {
    endpoints: [
      'GET /api/temporary-resource-access/{idave_user_id}'
    ],
    service: 'sam',
    value: 'temporary_resource_access.view.details'
  },
  TRAININGS_VIEW_LIST: {
    endpoints: [
      'GET /api/trainings/',
      'GET /service-api/trainings/'
    ],
    service: 'sam',
    value: 'trainings.view.list'
  },
  USER_POLICIES_PROVISION: {
    endpoints: [
      'POST /api/user-policies/{idave_user_id}/provision'
    ],
    service: 'sam',
    value: 'user_policies.provision'
  },
  USER_POLICIES_VIEW_DETAILS: {
    endpoints: [
      'GET /api/user-policies/{idave_user_id}'
    ],
    service: 'sam',
    value: 'user_policies.view.details'
  },
  WHOAMI_VIEW_DETAILS: {
    endpoints: [
      'GET /whoami'
    ],
    service: 'sam',
    value: 'whoami.view.details'
  }
} as const