import { Update as TUpdate } from 'api/types/xChecks'
import { useViews } from 'view/XChecks/XCheck/lib/hooks'
import { entityAttrsToDescriptor } from 'view/XChecks/XCheck/lib/utils'
import { Text, Token } from '@revolut/ui-kit'
import { EntityContext } from '../../../../common/EntityContext'
import { Details } from '../../../../common/Details'
import { Tables } from '../../../../common/Tables'
import { getUpdateInfo } from './utils'
import { Widgets } from '../../../../common/Widgets/Widgets'

type Props = {
  update: TUpdate
  defaultExpanded?: boolean
}
export const Update = ({ update, defaultExpanded }: Props) => {
  const descriptor = entityAttrsToDescriptor(update.entity)
  const { entityToEntityView } = useViews()
  const { details, tables, widgets } = getUpdateInfo({
    changes: update.changes,
    entityToEntityView,
  })

  return (
    <EntityContext
      entity={{
        type: { type: 'entity', entityType: descriptor.entityType },
        value: update.entity,
      }}
      defaultExpanded={defaultExpanded}
      descriptionLabel={<Text color={Token.color.warning}>Updated</Text>}
    >
      <Details title="Updated fields" details={details} />
      <Tables tables={tables} />
      <Widgets widgets={widgets} />
    </EntityContext>
  )
}
