import { getEmployeeTypes as extractEmployeeTypes } from 'view/Sam/utils'
import { DetailRow } from '../../../types'
import { DetailParams } from '../types'
import { getNormalizedValues } from '../utils'
import { getPolicySubjectChangeType } from './utils'

export const getEmployeeTypes = ({
  entity,
  crossCheck,
}: DetailParams): DetailRow | undefined => {
  const requested =
    (crossCheck.executableName === 'CreatePolicy' ||
      crossCheck.executableName === 'UpdatePolicy') &&
    crossCheck.executableParams.subject_params
      ? crossCheck.executableParams.subject_params?.subject_employee_types || []
      : undefined

  const current =
    entity.type === 'AccessPolicy' && entity.value
      ? extractEmployeeTypes(entity.value.subject)
      : undefined

  return {
    title: 'Employee types',
    value: {
      ...getNormalizedValues(current, requested),
      type: 'EmployeeTypes',
    },
    changeType: getPolicySubjectChangeType(crossCheck),
    isContext: true,
  }
}
