import { StatusWidget } from '@revolut/ui-kit'
import { get3DImageSrcSetProps } from 'utils/url'
import { Tab } from '../../../types'

export const ErrorTab = ({ tab }: { tab?: Tab }) => {
  return (
    <StatusWidget>
      <StatusWidget.Image {...get3DImageSrcSetProps('3D018', 'v2')} />
      <StatusWidget.Title>Something went wrong</StatusWidget.Title>
      <StatusWidget.Description>
        Couldn&apos;t show content of {tab || 'UNDEFINED'} tab
      </StatusWidget.Description>
    </StatusWidget>
  )
}
