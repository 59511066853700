import { X_CHECKS_PERMISSIONS } from 'security'
import { Url, UIRoute } from 'routing'
import { PermissionUtils } from 'services/permissions'
import { XCheck } from './XCheck'
import { XChecks } from './XChecks'

export const routes = ({ hasPermission }: PermissionUtils): Record<string, UIRoute> => ({
  [Url.XChecks]: {
    type: 'component',
    withParams: false,
    fallbackRoute: Url.Home,
    allowed: hasPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_LIST),
    Component: XChecks,
  },
  [Url.XCheck]: {
    type: 'component',
    fallbackRoute: Url.XChecks,
    allowed: hasPermission(X_CHECKS_PERMISSIONS.CROSS_CHECKS_VIEW_DETAILS),
    withParams: true,
    params: ['xCheckId'],
    Component: (props: { xCheckId: string }) => <XCheck id={props.xCheckId} />,
  },
})
