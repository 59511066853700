import { HStack, Token, Icon, TextStyle, Text } from '@revolut/ui-kit'

export const SearchErrorMessage = ({ message }: { message: string }) => {
  return (
    <HStack
      space={Token.space.s8}
      py={Token.space.s2}
      px={Token.space.s16}
      color={Token.color.red}
      align="center"
    >
      <Icon name="ExclamationMarkOutline" size={12} />
      <Text flex="1" variant={TextStyle.BODY3} aria-live="polite">
        {message}
      </Text>
    </HStack>
  )
}
