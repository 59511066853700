import { useMutation, useQueryClient } from '@tanstack/react-query'
import dartApi from 'api/dart'
import { QueryKey } from 'helpers/configQuery'
import { useEditPopup } from 'hooks/useEditPopup'
import { useToasts } from 'hooks/useToasts'
import { useCallback } from 'react'
import { DART_PERMISSIONS } from 'security'
import { pluralForm } from 'utils/string'
import { useLoadingPopup } from 'hooks/useLoadingPopup'
import { useErrorPopup } from 'hooks/useErrorPopup'
import { CustomerEmployeesAccessPayload } from 'api/dart/types'
import { getCrossCheckDescriptor } from 'helpers/utils'
import { useCrossCheck } from 'hooks/useCrossCheck'
import { AddEmployeePopup } from './AddEmployeePopup'

export const useAddUserPopup = (companyId: string) => {
  const { setPopup, closePopup } = useEditPopup()
  const { showErrorPopup } = useErrorPopup()
  const { showLoadingPopup, hideLoadingPopup } = useLoadingPopup()

  const { showSuccessToast } = useToasts()
  const queryClient = useQueryClient()
  const { isCrossCheck, toCrosscheck } = useCrossCheck(
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_EMPLOYEE_ACCESS,
    DART_PERMISSIONS.CUSTOMER_COMPANIES_ADD_EMPLOYEE_ACCESS_CROSS_CHECK,
  )

  const { mutate: add } = useMutation({
    mutationFn: (payload: CustomerEmployeesAccessPayload) =>
      dartApi.customerCompany.addAccessEmployees(companyId, {
        payload,
        crossCheck: isCrossCheck,
      }),
    onMutate() {
      showLoadingPopup({ title: 'Adding...' })
    },
    onSuccess(response, payload) {
      const crosscheck = getCrossCheckDescriptor(response)
      hideLoadingPopup()
      closePopup()
      if (isCrossCheck) {
        toCrosscheck(crosscheck)
      } else {
        showSuccessToast(
          pluralForm(payload.employeeIds.length, ['Access added', 'Accesses added']),
        )
        queryClient.invalidateQueries([QueryKey.CustomerCompany, companyId])
        queryClient.invalidateQueries([QueryKey.DirectRelations])
      }
    },
    onError(error) {
      hideLoadingPopup()
      showErrorPopup({ error, title: 'Addition failed' })
    },
  })

  const showAddUserPopup = useCallback(() => {
    setPopup({
      title: 'Add users',
      content: <AddEmployeePopup add={add} />,
      focusTrap: false,
    })
  }, [setPopup, add])

  return { showAddUserPopup }
}
