import { JustificationPopup } from 'components/JustificationPopup'
import { pluralForm } from 'utils/string'

const getPopupSettings = (employeesAmount: number) => ({
  title: 'Are you sure you want to revoke access to customer company?',
  description: pluralForm(employeesAmount, [
    'User will no longer have access to the customer company data.',
    'Users will no longer have access to the customer company data.',
  ]),
  submitButtonLabel: 'Revoke',
})

type GetJustificationPopupParams = {
  onSubmit: (justification?: string) => void
  employeesAmount: number
}
export const getJustificationPopup = ({
  employeesAmount,
  onSubmit,
}: GetJustificationPopupParams) => {
  const { submitButtonLabel, ...popupSettings } = getPopupSettings(employeesAmount)
  return {
    ...popupSettings,
    isBottomSheet: true,
    content: (
      <JustificationPopup onSubmit={onSubmit} submitButtonLabel={submitButtonLabel} />
    ),
  }
}
