import { Box, Search, Widget } from '@revolut/ui-kit'
import { useSearchFilter } from 'hooks/useSearchFilter'
import { memo, useCallback, useMemo, useState } from 'react'
import { toPresenceMap } from 'utils/array/toPresenceMap'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { QueryResult } from 'utils/query'
import { EntityData } from 'view/XChecks/XCheck/lib/hooks'
import { UserListHeader } from './components/UserListHeader'
import { UserListSkeleton } from './components/UserListSkeleton'
import { UserList as UserListComponent } from './components/UserList'
import { getFilteredUsers, getUserEntities } from './utils'

type UserListProps = {
  exclude?: string[]
  bubbleUp?: string[]
  scrollRef?: React.RefObject<HTMLDivElement>
  onChange: (selected: string[]) => void
  defaultValue?: string[]
  users: EntityData[]
  loadingState?: QueryResult
}

export const UserList = memo(
  ({
    onChange,
    exclude,
    scrollRef,
    defaultValue = [],
    bubbleUp,
    users,
    loadingState,
  }: UserListProps) => {
    const [value, setValue] = useState<string[]>(defaultValue)
    const selectedHash = useMemo(() => toPresenceMap(value), [value])
    const excludeHash = useMemo(() => toPresenceMap(exclude || []), [exclude])

    const [showSelected, setShowSelected] = useState(false)

    const update = useCallback(
      (newIds: string[]) => {
        onChange(newIds)
        setValue(newIds)
        if (!newIds.length && showSelected) {
          setShowSelected(false)
        }
      },
      [onChange, showSelected],
    )

    const entities = useMemo(
      () =>
        getUserEntities({
          users,
          bubbleUp,
          excludeHash,
        }),
      [users, bubbleUp, excludeHash],
    )
    const { searchValue, searched, setSearchValue } = useSearchFilter({ entities })

    const items = useMemo(
      () =>
        getFilteredUsers({
          users: searched,
          selectedHash,
          showSelected,
        }),
      [searched, selectedHash, showSelected],
    )

    const onShowSelectedClick = useCallback(() => {
      setShowSelected((v) => !v)
      setSearchValue('')
    }, [setShowSelected, setSearchValue])

    const onSearchUpdate = useCallback(
      (search: string) => {
        setShowSelected(false)
        setSearchValue(search)
      },
      [setSearchValue, setShowSelected],
    )

    return (
      <>
        <UserListHeader
          count={value.length}
          size={users.length}
          onShowSelectedClick={onShowSelectedClick}
          showSelected={showSelected}
        />
        <Widget>
          <Box p="s-16">
            <Search
              value={searchValue}
              placeholder="Search"
              onChange={onSearchUpdate}
              autoFocus
            />
          </Box>
          {loadingState === 'loading' ? <UserListSkeleton /> : null}
          {loadingState === 'error' || loadingState === 'forbidden' ? (
            <RequestErrorWidget />
          ) : null}
          {loadingState === 'success' ? (
            <UserListComponent
              update={update}
              items={items}
              selectedHash={selectedHash}
              scrollRef={scrollRef}
            />
          ) : null}
        </Widget>
      </>
    )
  },
)
