import { useCallback, useState } from 'react'
import { useQuerySamPolicy } from 'queries/sam/policies'
import { generatePath, useNavigate } from 'react-router'
import { Url } from 'routing'
import { FormMultiStep, Tab } from 'components/FormMultiStep'
import { RequestErrorWidget } from 'components/RequestErrorWidget'
import { QuerySwitch } from 'components/QuerySwitch'
import { SamPolicy } from 'api/sam/policies'
import { useStorageItem } from 'hooks/useStorageItem'
import { SamEditPolicyDetails } from '../components/SamEditPolicyDetails'
import { SamPolicyCreateSkeleton } from './SamCreatePolicySkeleton'
import { FormState, FormTab } from './types'
import {
  mapPolicyDetails,
  mapPolicyResources,
  mapPolicyTrainings,
  checkTabFullness,
  tabToPageSize,
} from './utils'
import { SamCreatePolicyResources } from './SamCreatePolicyResources'
import { SamCreatePolicyTrainings } from './SamCreatePolicyTrainings'
import { useCreatePolicy } from './useCreatePolicy'

interface SamCreatePolicyProps {
  policyId?: string
}

const TABS: Tab<FormTab>[] = [
  {
    value: 'details',
    label: 'Details',
  },
  {
    value: 'resources',
    label: 'Resources',
  },
  {
    value: 'trainings',
    label: 'Trainings',
  },
]

export const SamCreatePolicy = (props: SamCreatePolicyProps) => {
  const navigate = useNavigate()
  const { policyId } = props
  const { data: policy, status, fetchStatus } = useQuerySamPolicy(policyId)

  const onClose = useCallback(() => {
    if (policyId) {
      return navigate(generatePath(Url.SamPolicy, { policyId }))
    }
    return navigate(Url.SamPolicies)
  }, [policyId, navigate])

  return (
    <QuerySwitch
      data={policy}
      required={[{ qs: status, fs: fetchStatus }]}
      renderLoading={() => <SamPolicyCreateSkeleton onClose={onClose} />}
      renderIdle={() =>
        policyId ? <RequestErrorWidget /> : <InnerCreatePolicy onClose={onClose} />
      }
      renderSuccess={({ data }) => <InnerCreatePolicy policy={data} onClose={onClose} />}
    />
  )
}

export const InnerCreatePolicy = (props: { policy?: SamPolicy; onClose: () => void }) => {
  const { policy, onClose } = props

  const storageName = !policy ? 'policyCreate' : undefined
  const { setItem, item } = useStorageItem<FormState>(storageName)

  const { create, isCreating } = useCreatePolicy(storageName)

  const [formState, setFormState] = useState<FormState>({
    policyDetails: mapPolicyDetails(policy, item?.policyDetails),
    resources: mapPolicyResources(policy, item?.resources),
    mandatoryTrainings: mapPolicyTrainings(policy, item?.mandatoryTrainings),
  })

  const setState = useCallback(
    (field: Partial<FormState>) => {
      setFormState((state) => {
        const newValue = { ...state, ...field }
        setItem(newValue)
        return newValue
      })
    },
    [setFormState, setItem],
  )

  const setPolicyDetails = useCallback(
    (policyDetails: FormState['policyDetails']) => setState({ policyDetails }),
    [setState],
  )

  const setPolicyResources = useCallback(
    (resources: FormState['resources']) => setState({ resources }),
    [setState],
  )

  const setMandatoryTrainings = useCallback(
    (mandatoryTrainings: FormState['mandatoryTrainings']) =>
      setState({ mandatoryTrainings }),
    [setState],
  )

  const submit = useCallback(() => {
    create(formState)
  }, [create, formState])

  const [hasErrors, setHasErrors] = useState(false)
  const checkTabValid = useCallback((_tab: FormTab) => !hasErrors, [hasErrors])

  const checkTabFilled = useCallback(
    (tab: FormTab) => checkTabFullness(tab, formState),
    [formState],
  )
  const title = policy ? 'Copy policy' : 'Create policy'

  return (
    <FormMultiStep
      onCloseClick={onClose}
      title={title}
      tabs={TABS}
      onSubmit={submit}
      submitting={isCreating}
      checkTabValid={checkTabValid}
      checkTabFilled={checkTabFilled}
      tabToPageSize={tabToPageSize}
      renderTab={(currentTab) => (
        <>
          {currentTab === 'details' && (
            <SamEditPolicyDetails
              policyDetails={formState.policyDetails}
              setPolicyDetails={setPolicyDetails}
              setHasErrors={setHasErrors}
            />
          )}
          {currentTab === 'resources' && (
            <SamCreatePolicyResources
              policyResources={formState.resources}
              setPolicyResources={setPolicyResources}
              policyName={formState.policyDetails.policyName}
            />
          )}
          {currentTab === 'trainings' && (
            <SamCreatePolicyTrainings
              mandatoryTrainings={formState.mandatoryTrainings}
              setMandatoryTrainings={setMandatoryTrainings}
              policyName={formState.policyDetails.policyName}
            />
          )}
        </>
      )}
    />
  )
}
